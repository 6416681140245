import { useSelector, useDispatch } from "react-redux"
import { useCallback, useEffect } from "react"
import {
  fetchWalletBalance,
  fetchWalletHistory,
  getCreditedWalletHistory,
  getDebitedWalletHistory,
  loadingWalletSelector,
  recentCreditWalletHistorySelector,
  recentDebitWalletHistorySelector,
  totalBalanceSelector,
  totalSpentSelector,
  updateWalletBalance,
  walletHistoryLoadingSelector,
  walletHistorySelector,
  walletStateSelector,
} from "redux/actions"
import { useIsMounted } from "hooks"

const useWallet = (currentUserID) => {
  const isMounted = useIsMounted()
  const dispatch = useDispatch()
  const walletState = useSelector((state) => walletStateSelector(state))
  const totalBalance = useSelector((state) => totalBalanceSelector(state))
  const totalSpent = useSelector((state) => totalSpentSelector(state))
  const isLoadingWalletBalance = useSelector((state) =>
    loadingWalletSelector(state)
  )
  const walletTransactions = useSelector((state) =>
    walletHistorySelector(state)
  )
  const isLoadingWalletTransactions = useSelector((state) =>
    walletHistoryLoadingSelector(state)
  )
  // credited wallet history
  const creditedWalletTransactions = useSelector((state) =>
    getCreditedWalletHistory(state)
  )
  // debited wallet history
  const debitedWalletTransactions = useSelector((state) =>
    getDebitedWalletHistory(state)
  )
  // recent credited wallet history
  const recentCreditedWalletTransaction = useSelector((state) =>
    recentCreditWalletHistorySelector(state)
  )
  // recent debited wallet history
  const recentDebitedWalletTransaction = useSelector((state) =>
    recentDebitWalletHistorySelector(state)
  )

  // other actions
  useEffect(() => {
    if (isMounted() && currentUserID) {
      dispatch(fetchWalletBalance(currentUserID))
    }
  }, [currentUserID, dispatch, isMounted])

  useEffect(() => {
    if (isMounted() && currentUserID) {
      dispatch(fetchWalletHistory(currentUserID))
    }
  }, [currentUserID, dispatch, isMounted])

  // update wallet balance
  const handleUpdateWalletBalance = useCallback(
    (amount, type, title, desc) => {
      dispatch(
        updateWalletBalance(
          currentUserID,
          amount,
          type,
          title,
          desc,
          totalBalance,
          totalSpent
        )
      )
    },
    [currentUserID, dispatch, totalBalance, totalSpent]
  )

  return {
    walletState,
    totalBalance,
    totalSpent,
    isLoadingWalletBalance,
    walletTransactions,
    isLoadingWalletTransactions,
    creditedWalletTransactions,
    debitedWalletTransactions,
    recentCreditedWalletTransaction,
    recentDebitedWalletTransaction,
    // update
    handleUpdateWalletBalance,
  }
}

export default useWallet
