import {
  CelebrationOutlined,
  CloseOutlined,
  DeleteOutlined,
  EventOutlined,
  InfoOutlined,
  MessageOutlined,
  VideoCallOutlined,
} from "@mui/icons-material"
import {
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { USER_PLACEHOLDER } from "assets"
import { CustomButton, Loader } from "components"
import {
  useIsMounted,
  useLoader,
  usePersonalizedMessages,
  useWindow,
} from "hooks"
import { primaryDateFormat, sortNewToOld } from "helpers"
import { CustomConfirmationDialog } from "components/core"

const ArtistManagePersonalizedMessages = ({ currentUserID, isArtist }) => {
  const { namesInLoaderActions } = useLoader()
  const { isMobileView } = useWindow().windowData
  const isMounted = useIsMounted()
  const navigate = useNavigate()
  // delete images dialog
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    message: null,
  })
  //   fetch and remove personalized messages
  const {
    handleFetchPersonalizedMessages,
    personalizedMessages,
    handleRemovePersonalizedMessage,
    isLoadingPersonalizedMessage,
    isPersonalizeRemoveSuccess,
  } = usePersonalizedMessages(currentUserID)
  useEffect(() => {
    if (isMounted()) {
      handleFetchPersonalizedMessages("artist")
    }
  }, [
    isMounted,
    currentUserID,
    handleFetchPersonalizedMessages,
    isPersonalizeRemoveSuccess,
  ])

  //   single message details
  const [message, setMessage] = useState({
    id: "",
    user: {},
    open: false,
    booking: {},
  })
  const handleSetMessage = (msg) => {
    setMessage({
      id: msg?._id || "",
      user: msg?.user || {},
      open: true,
      booking: msg?.booking || {},
    })
  }
  const handleCloseMessage = () => {
    setMessage({
      id: "",
      user: {},
      open: false,
      booking: {},
    })
  }

  return (
    <div className="p-1em">
      <div>
        <Typography variant="h6">Personalized Messages</Typography>
      </div>
      {/* dialog for showing individual message */}
      <Dialog
        open={message?.open}
        maxWidth="sm"
        fullWidth
        fullScreen={isMobileView}
      >
        <DialogContent>
          {/* dialog header */}
          <div className="flex-row flex-align-center flex-justify-between">
            <Typography variant="h6" className="font-bold text-primary-2">
              Message Details
            </Typography>
            <IconButton
              onClick={() => {
                handleCloseMessage()
              }}
            >
              <CloseOutlined />
            </IconButton>
          </div>
          <Divider className="m-0_5em-0" />
          {/* dialog body */}
          <div>
            <Grid container spacing={2}>
              {/* user details */}
              <Grid item xs={12}>
                <div className="flex-row flex-align-start flex-justify-center box-shadow-normal p-1em-0">
                  <img
                    src={message?.user?.profileImageUrl || USER_PLACEHOLDER}
                    alt=""
                    height={50}
                    width={50}
                    className="object-fit-contain bg-primary-2 border-circle "
                  />
                  <div className="m-0-1em">
                    <Typography variant="body1" className="font-bold">
                      {`${message?.user?.firstName} ${message?.user?.lastName}`}
                    </Typography>
                    <Typography variant="body2">
                      {`${message?.user?.email}`}
                    </Typography>
                    <Typography variant="body2">
                      {`${message?.user?.location?.address || "N/A"}`}
                    </Typography>
                  </div>
                </div>
              </Grid>
              {/* event type */}
              <Grid item xs={12} sm={6}>
                <div className="flex-row flex-align-start flex-justify-center p-1em-0 bg-whitesmoke">
                  <CelebrationOutlined
                    fontSize={"large"}
                    className="text-primary-9"
                  />
                  <div className="m-0-1em">
                    <Typography variant="body1" className="font-bold">
                      {`Booking For`}
                    </Typography>
                    <Typography variant="body2">
                      {`${message?.booking?.eventType?.eventName || "N/A"}`}
                    </Typography>
                  </div>
                </div>
              </Grid>
              {/* event date */}
              <Grid item xs={12} sm={6}>
                <div className="flex-row flex-align-start flex-justify-center p-1em-0 bg-whitesmoke">
                  <EventOutlined
                    fontSize={"large"}
                    className="text-primary-9"
                  />
                  <div className="m-0-1em">
                    <Typography variant="body1" className="font-bold">
                      {`Event Date`}
                    </Typography>
                    <Typography variant="body2">
                      {`${
                        message?.booking?.personalizedMsgDate
                          ? primaryDateFormat(
                              message?.booking?.personalizedMsgDate
                            )
                          : "N/A"
                      }`}
                    </Typography>
                  </div>
                </div>
              </Grid>
              {/* Message details*/}
              <Grid item xs={12}>
                <div className="flex-row flex-align-start flex-justify-center bg-whitesmoke p-1em-0">
                  <MessageOutlined
                    fontSize={"large"}
                    className="text-primary-9"
                  />
                  <div className="m-0-1em">
                    <Typography variant="body1" className="font-bold">
                      {`Personalized Message`}
                    </Typography>
                    <Typography variant="body2">
                      {`${message?.booking?.personalizedMessage ?? "N/A"}`}
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
      {/* delete confirm dialog */}
      <CustomConfirmationDialog
        open={deleteDialog?.open}
        headerTitle={`Delete Message`}
        description={`Are you sure you want to delete this Message?`}
        headerIcon={<DeleteOutlined />}
        handleAction={() => {
          handleRemovePersonalizedMessage(deleteDialog?.message?._id)
          setDeleteDialog({
            open: false,
            message: null,
          })
        }}
        handleClose={() => {
          setDeleteDialog({
            open: false,
            message: null,
          })
        }}
      />
      {/* body */}
      <div className="m-1-0">
        {/* all videos */}
        <div>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <div className="p-1em position-center flex-column box-shadow-normal border-5">
                <Typography className="text-center" variant="body1">
                  All personalized messages you have sent to your customers is
                  here.
                </Typography>
                <CustomButton
                  btnText={"Add Now"}
                  variant="contained"
                  disableElevation={true}
                  className="bg-primary-2 text-white m-t-1em"
                  size={"large"}
                  endIcon={<VideoCallOutlined />}
                  onClick={() =>
                    isArtist
                      ? navigate("/artist-bookings")
                      : navigate(`/artist-bookings/${currentUserID}`)
                  }
                />
              </div>
            </Grid>
            {isLoadingPersonalizedMessage && (
              <Grid item xs={12}>
                <div className="h-50vh">
                  <Loader isCircularLoader={true} bgNone />
                </div>
              </Grid>
            )}
            {!isLoadingPersonalizedMessage && (
              <>
                {[...personalizedMessages]
                  ?.sort((a, b) => sortNewToOld(a, b, "timestamp"))
                  ?.map((personalizedMessage) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      lg={4}
                      key={personalizedMessage?._id}
                    >
                      <div className="border-10 box-shadow-1 overflow-hidden bg-primary-10">
                        <video
                          src={personalizedMessage?.videoUrl}
                          height={"180px"}
                          className="object-fit-cover"
                          width={"100%"}
                          controls
                          poster={personalizedMessage?.thumbImageUrl}
                        />
                        <div className="flex-row flex-align-center flex-justify-center p-0_2em">
                          <IconButton
                            className="m-0-0_5em border-5 bg-whitesmoke"
                            onClick={() => {
                              handleSetMessage(personalizedMessage)
                            }}
                          >
                            <InfoOutlined className="text-primary-2" />
                          </IconButton>
                          <IconButton
                            className="border-5 bg-whitesmoke"
                            onClick={() => {
                              setDeleteDialog({
                                open: true,
                                message: personalizedMessage,
                              })
                            }}
                            disabled={namesInLoaderActions?.includes(
                              `REMOVE_PERSONALIZED_MESSAGE_${personalizedMessage?._id}`
                            )}
                          >
                            {namesInLoaderActions?.includes(
                              `REMOVE_PERSONALIZED_MESSAGE_${personalizedMessage?._id}`
                            ) ? (
                              <Loader
                                isCircularLoader={true}
                                bgNone
                                circularProgressSize={23}
                              />
                            ) : (
                              <DeleteOutlined className="text-primary-1" />
                            )}
                          </IconButton>
                        </div>
                      </div>
                    </Grid>
                  ))}
              </>
            )}
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default ArtistManagePersonalizedMessages
