import {
  CloseOutlined,
  DoneOutlined,
  RestartAltOutlined,
  StarBorderOutlined,
} from "@mui/icons-material"
import {
  Button,
  Dialog,
  Divider,
  IconButton,
  ListItemIcon,
  MenuItem,
  Slider,
  TextField,
  Typography,
} from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
// import from other files
import { CustomDrawer } from "components"
import { countries, RATINGS } from "helpers"
import {
  useCategories,
  useCurrencyContext,
  useEventTypes,
  useLanguages,
  useWindow,
} from "hooks"
import {
  getFilterState,
  setFilterCategories,
  resetFilter,
  setEvents,
  setServices,
  setRatings,
  setLanguages,
  setGender,
  setPriceRange,
  setFilterLocation,
} from "redux/actions"

const GENDERS = [
  {
    id: "male",
    name: "Male",
    icon: "https://cdn-icons-png.flaticon.com/128/949/949822.png",
  },
  {
    id: "female",
    name: "Female",
    icon: "https://cdn-icons-png.flaticon.com/128/949/949823.png",
  },
]

// get value of price range with customize typography
const getValueText = (value) => {
  return (
    <Typography
      className="font-bold text-primary-2"
      gutterBottom
      variant="body1"
    >{`${value}`}</Typography>
  )
}

const ArtistFilterDialog = ({
  open,
  setOpen,
  position,
  category,
  setFilteredResult,
  filteredArtists,
}) => {
  const { currencyWiseValue } = useCurrencyContext()
  const { categories } = useCategories()
  const { allEventTypes, allServices } = useEventTypes()
  const { allLanguages } = useLanguages()

  // filter states from redux
  const filterState = useSelector((state) => getFilterState(state))
  const dispatch = useDispatch()
  // other states
  const isShowFullDialog = useWindow().windowData.isMobileView

  // handle price range
  const handlePriceRangeChange = (event, newValue) => {
    dispatch(setPriceRange(newValue?.[0], newValue?.[1]))
  }
  const RenderDrawer = () => {
    return (
      <>
        <div className="filter-drawer-content bg-white p-1em">
          {/* filter drawer header */}
          <div className="filter-drawer-header flex-row flex-justify-between flex-align-center">
            <Typography variant="h4" className="text-primary-2 font-bold">
              Filter
            </Typography>
            <div className="filter-dialog-close-icon">
              <IconButton
                onClick={() => {
                  setOpen(false)
                }}
              >
                <CloseOutlined />
              </IconButton>
            </div>
          </div>
          <Divider />
          {/* filter drawer body */}
          <div className="filter-drawer-body p-1em-0">
            {/* category wise filter */}
            {!category && (
              <div className="category-wise-filter">
                <Typography
                  variant="body1"
                  className="font-size-1_1em font-bold"
                  gutterBottom
                >
                  Categories
                </Typography>
                <div className="category-filter-items flex-row flex-align-center flex-wrap">
                  {categories?.map((category) => (
                    <div
                      className={`filter-item border-25 cursor-pointer flex-row flex-align-center ${
                        filterState?.categories?.includes(category?._id) &&
                        "filter-item-active"
                      }`}
                      key={category?._id}
                      role="button"
                      onClick={() => {
                        dispatch(
                          setFilterCategories(
                            filterState?.categories,
                            category?._id
                          )
                        )
                      }}
                    >
                      <img
                        src={category.iconUrl}
                        alt={category?.title}
                        className="category-filter-item-icon"
                        width={"20px"}
                      />
                      <Typography>{category?.title}</Typography>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {/* Event wise filter */}
            <div className="event-wise-filter m-t-1em">
              <Typography
                variant="body1"
                className="font-size-1_1em font-bold"
                gutterBottom
              >
                Events
              </Typography>
              <div className="event-filter-items flex-row flex-align-center flex-wrap">
                {allEventTypes?.map((event) => (
                  <div
                    className={`filter-item border-25 cursor-pointer flex-row flex-align-center ${
                      filterState?.events?.includes(event?._id) &&
                      "filter-item-active"
                    }`}
                    key={event?._id}
                    role="button"
                    onClick={() => {
                      dispatch(setEvents(filterState?.events, event?._id))
                    }}
                  >
                    {/* <img
                      src={event.iconUrl}
                      alt={""}
                      className="category-filter-item-icon"
                      width={"20px"}
                    /> */}
                    <Typography>{event?.eventName}</Typography>
                  </div>
                ))}
              </div>
            </div>
            {/* Services wise filter */}
            <div className="service-wise-filter m-t-1em">
              <Typography
                variant="body1"
                className="font-size-1_1em font-bold"
                gutterBottom
              >
                Services
              </Typography>
              <div className="service-filter-items flex-row flex-align-center flex-wrap">
                {allServices?.map((service, s_index) => (
                  <div
                    className={`filter-item border-25 cursor-pointer flex-row flex-align-center ${
                      filterState?.services?.includes(service?._id) &&
                      "filter-item-active"
                    }`}
                    key={service?._id}
                    role="button"
                    onClick={() => {
                      dispatch(setServices(filterState?.services, service?._id))
                    }}
                  >
                    <img
                      src={service.iconUrl}
                      alt={service?.serviceName}
                      className="category-filter-item-icon"
                      width={"20px"}
                    />
                    <Typography>{service?.serviceName}</Typography>
                  </div>
                ))}
              </div>
            </div>
            {/* ratings */}
            <div className="ratings-wise-filter m-t-1em">
              <Typography
                variant="body1"
                className="font-size-1_1em font-bold"
                gutterBottom
              >
                Ratings
              </Typography>
              <div className="category-filter-items flex-row flex-align-center flex-wrap">
                {RATINGS?.map((rating) => (
                  <div
                    className={`filter-item border-25 cursor-pointer flex-row flex-align-center ${
                      filterState?.ratings?.includes(+rating?.value) &&
                      "filter-item-active"
                    }`}
                    key={rating.value}
                    role="button"
                    onClick={() => {
                      dispatch(setRatings(filterState?.ratings, +rating?.value))
                    }}
                  >
                    <StarBorderOutlined
                      className="category-filter-item-icon"
                      htmlColor={
                        filterState?.ratings?.includes(+rating?.value)
                          ? "var(--col-primary-3)"
                          : "var(--col-primary-6)"
                      }
                    />
                    <Typography>{rating.label}</Typography>
                  </div>
                ))}
              </div>
            </div>
            {/* Gender */}
            <div className="gender-wise-filter m-t-1em">
              <Typography
                variant="body1"
                className="font-size-1_1em font-bold"
                gutterBottom
              >
                Genders
              </Typography>
              <div className="category-filter-items flex-row flex-align-center flex-wrap">
                {GENDERS?.map((gender) => (
                  <div
                    className={`filter-item border-25 cursor-pointer flex-row flex-align-center ${
                      filterState?.gender?.id === gender?.id &&
                      "filter-item-active"
                    } `}
                    key={gender.id}
                    role="button"
                    onClick={() => {
                      dispatch(
                        setGender(
                          filterState?.gender?.id === gender?.id ? "" : gender
                        )
                      )
                    }}
                  >
                    <img
                      src={gender?.icon}
                      alt={gender?.name}
                      className="category-filter-item-icon"
                      width={"20px"}
                    />
                    <Typography>{gender?.name}</Typography>
                  </div>
                ))}
              </div>
            </div>
            {/* Languages */}
            <div className="language-wise-filter m-t-1em">
              <Typography
                variant="body1"
                className="font-size-1_1em font-bold"
                gutterBottom
              >
                Languages
              </Typography>
              <div className="category-filter-items flex-row flex-align-center flex-wrap">
                {allLanguages?.map((language) => (
                  <div
                    className={`filter-item border-25 cursor-pointer flex-row flex-align-center ${
                      filterState?.languages?.includes(language) &&
                      "filter-item-active"
                    } `}
                    key={language?._id}
                    role="button"
                    onClick={() => {
                      dispatch(setLanguages(filterState?.languages, language))
                    }}
                  >
                    <Typography>{language?.languageName}</Typography>
                  </div>
                ))}
              </div>
            </div>
            {/* location */}
            <div className="location-wise-filter m-t-1em ">
              <Typography
                variant="body1"
                className="font-size-1_1em font-bold"
                gutterBottom
              >
                Select Country
              </Typography>
              <div className="location-filter-items flex-row flex-align-center flex-wrap w-100">
                <TextField
                  fullWidth
                  value={filterState?.location?.label || ""}
                  onChange={(e) => {
                    dispatch(
                      setFilterLocation(
                        countries?.find((item) => item.label === e.target.value)
                      )
                    )
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: "outline-none border-25",
                    },
                  }}
                  select
                >
                  {countries?.map((country) => (
                    <MenuItem key={country?.code} value={country?.label}>
                      <ListItemIcon className="p-0">
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${country?.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${country?.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                      </ListItemIcon>
                      {country?.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
            {/* price range */}
            <div className="price-wise-filter m-t-1em">
              <Typography
                variant="body1"
                className="font-size-1_1em font-bold"
                gutterBottom
              >
                Price Range
              </Typography>
              <div className="price-filter-items flex-row flex-align-center flex-wrap p-0-2em w-100 overflow-x-auto hide-scrollbar">
                <Slider
                  className="text-primary-3"
                  min={100}
                  max={15000}
                  value={
                    filterState?.priceRange?.[0]
                      ? filterState?.priceRange
                      : [100, 15000]
                  }
                  onChange={handlePriceRangeChange}
                  marks={[
                    {
                      value: filterState?.priceRange?.[0] || 100,
                      label: getValueText(
                        currencyWiseValue(filterState?.priceRange?.[0] || 100)
                      ),
                    },
                    {
                      value: filterState?.priceRange?.[1] || 15000,
                      label: getValueText(
                        currencyWiseValue(filterState?.priceRange?.[1] || 15000)
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          {/* filter drawer actions */}
          <div className="filter-drawer-action-root flex-row flex-align-center flex-justify-center p-1em-0 ">
            <Button
              className={`bg-primary-8 m-0-0_5em text-transform-none text-white font-bold`}
              size="large"
              fullWidth
              startIcon={<RestartAltOutlined />}
              onClick={() => {
                dispatch(resetFilter())
                setFilteredResult(filteredArtists)
                setOpen(false)
              }}
            >
              Reset
            </Button>
            <Button
              size="large"
              fullWidth
              className="bg-primary-3 m-0-0_5em text-transform-none text-white font-bold"
              startIcon={<DoneOutlined />}
              onClick={() => {
                setOpen(false)
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      {isShowFullDialog ? (
        <Dialog fullScreen open={open}>
          <RenderDrawer />
        </Dialog>
      ) : (
        <CustomDrawer
          drawerOpen={open}
          setDrawerOpen={setOpen}
          position={position}
          onClose={() => {
            dispatch(resetFilter())
            setFilteredResult(filteredArtists)
          }}
        >
          <RenderDrawer />
        </CustomDrawer>
      )}
    </>
  )
}

export default ArtistFilterDialog
