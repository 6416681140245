import { Button } from "@mui/material"
import { useWindow } from "hooks"

const ManageProfileSaveBtn = ({ onClick, disabled, type }) => {
  const isFullBtn = useWindow().windowData.width < 600
  return (
    <>
      <div className="text-center m-1-0">
        <Button
          className={disabled ? "disabled-btn" : "btn-gradient"}
          size="large"
          fullWidth={isFullBtn}
          onClick={onClick}
          disabled={disabled ?? false}
          type={type}
        >
          Save Changes
        </Button>
      </div>
    </>
  )
}

export default ManageProfileSaveBtn
