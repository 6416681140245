import { TranslateOutlined } from "@mui/icons-material"
import { Grid, Typography } from "@mui/material"
import { useEffect } from "react"
// import from other files
import { setArtistLanguages } from "redux/actions"
import { ManageProfileSaveBtn } from "."
import {
  useLanguages,
  useUsers,
  useIsMounted,
  useApi,
  useSnackbar,
} from "hooks"
import { CustomSnackbar, Loader } from "components/core"

const ArtistManageLanguages = ({
  artistProfileState,
  dispatch,
  currentUserID,
}) => {
  const { allLanguages, isLoadingLanguages } = useLanguages()
  const { handleGetUserDetails, userDetails } = useUsers()
  const isMounted = useIsMounted()
  const { isPosting, postData, isPosted, handleResetPostApi } = useApi()
  const { snackbar, handleOpenSnackbar, handleCloseSnackbar } = useSnackbar()

  const handleUpdateLanguages = () => {
    postData(
      `user/account-update/${currentUserID}`,
      {
        languages: artistProfileState?.selectLanguages,
      },
      "PUT"
    )
  }

  useEffect(() => {
    if (isMounted() && userDetails?._id) {
      dispatch(setArtistLanguages(userDetails?.languages?.map((i) => i?._id)))
    }
  }, [dispatch, isMounted, userDetails])

  useEffect(() => {
    if (isMounted() && isPosted) {
      handleGetUserDetails(currentUserID)
      handleOpenSnackbar({
        message: "Updated successfully",
        variant: "success",
      })
    }
  }, [
    handleGetUserDetails,
    isMounted,
    currentUserID,
    isPosted,
    handleOpenSnackbar,
  ])

  return (
    <div>
      <CustomSnackbar
        open={snackbar.open}
        handleClose={() => {
          handleCloseSnackbar()
        }}
        variant={snackbar.variant}
        message={snackbar.message}
        autoHideDuration={3000}
        resetFunction={handleResetPostApi}
      />
      <Typography variant="h6" className="text-primary-8">
        Select Languages
      </Typography>
      <div className="artist-manage-languages-body m-1-0">
        {!isLoadingLanguages && allLanguages?.length > 0 && (
          <Grid container spacing={2}>
            {allLanguages?.map((language) => (
              <Grid item xs={12} sm={6} lg={4} key={language?._id}>
                <div
                  className="artist-manage-languages-item p-1em position-center flex-column cursor-pointer border-5"
                  role={"button"}
                  onClick={() => {
                    dispatch(
                      setArtistLanguages(
                        artistProfileState?.selectLanguages,
                        language?._id
                      )
                    )
                  }}
                >
                  <TranslateOutlined
                    className={
                      artistProfileState?.selectLanguages?.includes(
                        language?._id
                      )
                        ? "text-primary-3"
                        : "text-primary-7"
                    }
                  />
                  <Typography
                    variant="body1"
                    className={`m-0_5em-0 text-ellipses ${
                      artistProfileState?.selectLanguages?.includes(
                        language?._id
                      )
                        ? "text-primary-3 font-bold"
                        : "text-primary-7"
                    }`}
                  >
                    {language?.languageName}
                  </Typography>
                </div>
              </Grid>
            ))}
            <Grid item xs={12}>
              <ManageProfileSaveBtn
                disabled={isPosting}
                onClick={() => {
                  handleUpdateLanguages()
                }}
              />
            </Grid>
          </Grid>
        )}
        {isLoadingLanguages && (
          <div className="h-50vh">
            <Loader isCircularLoader={true} />
          </div>
        )}
      </div>
    </div>
  )
}

export default ArtistManageLanguages
