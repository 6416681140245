import { CloseOutlined } from "@mui/icons-material"
import { Button, IconButton, Typography } from "@mui/material"
import moment from "moment"
import { USER_PLACEHOLDER } from "assets"
import { CustomDatePicker, CustomSnackbar } from "components"
import { useEffect, useState } from "react"
import { useApi, useIsMounted, useSnackbar, useBlockedDates } from "hooks"

const RescheduleEvent = ({
  handleCloseRescheduleEventDialog,
  isPersonalizedMessage,
  artist,
  bookedData,
  handleChangeDate,
  changeDate,
}) => {
  const isMounted = useIsMounted()
  const { snackbar, handleOpenSnackbar, handleCloseSnackbar } = useSnackbar()
  const { blockedDates } = useBlockedDates(artist?._id)
  const { postData, isPosted, setIsPosted, isPosting, postStatus } = useApi()
  const [error, setError] = useState({
    startErr: "",
    endErr: "",
    validErr: "",
  })

  useEffect(() => {
    if (isMounted() && isPersonalizedMessage) {
      handleChangeDate("", "", "", bookedData?.personalizedMsgDate)
      return
    }
    if (isMounted() && !isPersonalizedMessage) {
      handleChangeDate(
        "other",
        bookedData?.eventDate?.start,
        bookedData?.eventDate?.end,
        ""
      )
      return
    }
  }, [isPersonalizedMessage, bookedData, handleChangeDate, isMounted])
  const handleUpdateDate = () => {
    if (error?.validErr) {
      return
    }
    // if (
    //   moment(changeDate.startDate).isSameOrAfter(moment(changeDate.endDate))
    // ) {
    //   handleOpenSnackbar({
    //     message: "End date should be greater than start date",
    //     variant: "error",
    //   })
    //   return
    // }
    if (!isPersonalizedMessage) {
      postData(`booking-reschedule/create`, {
        bookingId: bookedData?._id,
        rescheduleStartDate:
          changeDate?.startDate || bookedData?.eventDate?.start,
        rescheduleEndDate: changeDate?.endDate || bookedData?.eventDate?.end,
        personalizedMsgDate: "",
        rescheduleBy: "user",
      })
      return
    }
    postData(`booking-reschedule/create`, {
      bookingId: bookedData?._id,
      rescheduleStartDate: "",
      rescheduleEndDate: "",
      personalizedMsgDate:
        changeDate?.personalizedMsgDate || bookedData?.personalizedMsgDate,
      rescheduleBy: "user",
    })
  }

  useEffect(() => {
    if (isMounted() && isPosted) {
      handleOpenSnackbar({
        message: "Reschedule Request Sent Successfully",
        variant: "success",
      })
    }
  }, [isMounted, isPosted, handleOpenSnackbar])

  return (
    <div className="w-100">
      <div className="reschedule-event-root p-1em h-fit-content bg-white">
        <CustomSnackbar
          open={snackbar.open}
          handleClose={() => {
            setIsPosted(false)
            handleCloseSnackbar()
          }}
          variant={snackbar.variant}
          message={snackbar.message}
        />
        <div className="reschedule-close">
          <IconButton
            onClick={() => {
              handleCloseRescheduleEventDialog()
            }}
          >
            <CloseOutlined />
          </IconButton>
        </div>
        {/* header */}
        <div className="reschedule-event-header position-relative p-1em-2em">
          <Typography
            className="font-bold text-primary-2 text-center"
            variant="h4"
          >
            Reschedule Event
          </Typography>
        </div>
        {/* body */}
        <div className="reschedule-event-body m-0_5em-0">
          <div className="reschedule-artist-img-details bg-whitesmoke p-0_5em-0 border-10">
            <div className="text-center w-100">
              <img
                src={artist?.profileImageUrl || USER_PLACEHOLDER}
                alt=""
                height={"150px"}
                width={"150px"}
                className="object-fit-cover border-circle"
              />
              <Typography
                variant="h6"
                className="text-ellipses w-100 text-ellipses"
              >
                <span className="font-bold">{`${artist?.firstName} ${artist?.lastName}`}</span>
              </Typography>
              <Typography variant="body1" className="text-ellipses">
                <span className="font-bold text-primary-3">
                  {artist?.category?.title || "N/A"}
                </span>
              </Typography>
              <Typography variant="body1" className="text-ellipses">
                <span className="font-bold">
                  {artist?.location?.address || "N/A"}
                </span>
              </Typography>
            </div>
          </div>
          {/* event date */}
          <div className="reschedule-date m-1-0">
            <Typography variant="body1" gutterBottom>
              <span className="font-bold text-primary-1">
                Change Event Date
              </span>
            </Typography>
            {isPersonalizedMessage ? (
              <div className="reschedule-event-date">
                <Typography variant="body2" gutterBottom>
                  <span>Event Date</span>
                </Typography>
                <CustomDatePicker
                  selectedDate={changeDate?.personalizedMsgDate}
                  onChange={(date) => {
                    handleChangeDate("personalizedMsgDate", "", "", date)
                  }}
                  disablePast
                  blockedDates={blockedDates?.map((bd) => bd?.date)}
                />
              </div>
            ) : (
              <div className="reschedule-date-range">
                <div className="reschedule-start-date m-1-0">
                  <Typography variant="body2" gutterBottom>
                    <span className="font-bold">Event Date</span>
                  </Typography>
                  <CustomDatePicker
                    isMobilePicker={true}
                    selectedDate={moment(changeDate?.startDate).toString()}
                    onChange={(date) => {
                      if (moment(date)?.toString() === "Invalid date")
                        return setError({
                          ...error,
                          startErr: "Please enter a valid date",
                        })
                      handleChangeDate("other", date, date, "")
                      return setError({
                        ...error,
                        startErr: "",
                      })
                    }}
                    disablePast
                    error={error.startErr ? true : false}
                    helperText={error.startErr}
                    blockedDates={blockedDates?.map((bd) => bd?.date)}
                  />
                </div>
                {/* <div className="reschedule-end-date m-1-0">
                  <Typography variant="body2" gutterBottom>
                    <span className="font-bold">End Date</span>
                  </Typography>
                  <CustomDatePicker
                    selectedDate={moment(changeDate?.endDate).toString()}
                    onChange={(date) => {
                      if (moment(date)?.toString() === "Invalid date")
                        return setError({
                          ...error,
                          endErr: "Please enter a valid date",
                        })
                      handleChangeDate("other", changeDate?.startDate, date, "")
                      return setError({
                        ...error,
                        endErr: "",
                      })
                    }}
                    disablePast
                    error={error.endErr ? true : false}
                    helperText={error.endErr}
                    blockedDates={blockedDates?.map((bd) => bd?.date)}
                  />
                </div> */}
              </div>
            )}
          </div>
          {/* action button */}
          <div className="reschedule-event-action">
            {postStatus?.status === 409 && (
              <div className="text-center p-1em bg-whitesmoke">
                <p className="text-primary-9 font-bold ">
                  {postStatus?.message}
                </p>
              </div>
            )}
            {error?.validErr?.length > 0 && (
              <div className="text-center p-1em bg-whitesmoke">
                <p className="text-primary-9 font-bold ">{error?.validErr}</p>
              </div>
            )}
            <Button
              className={`font-bold text-transform-none m-1-0 ${
                isPosting || error.startErr || error.endErr
                  ? "disabled-btn"
                  : "btn-gradient"
              }`}
              fullWidth
              size="large"
              variant="contained"
              onClick={() => {
                // if (moment(changeDate?.startDate).diff(moment(), "days") <= 7)
                //   return setError({
                //     ...error,
                //     validErr: "You can't reschedule event before 7 days",
                //   })
                handleUpdateDate()
              }}
              disableElevation
              disabled={
                isPosting || error.startErr || error.endErr ? true : false
              }
              color="inherit"
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RescheduleEvent
