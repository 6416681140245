import {
  AccessTimeOutlined,
  CancelOutlined,
  ChatBubbleOutline,
  CloseOutlined,
  DateRangeOutlined,
  EventOutlined,
  GroupsOutlined,
  LocationOnOutlined,
  MiscellaneousServicesOutlined,
  TimelapseOutlined,
} from "@mui/icons-material";
import { Button, Divider, Grid, IconButton, Typography } from "@mui/material";
import moment from "moment";
import {
  CustomButton,
  CustomConfirmationDialog,
  CustomDatePicker,
  CustomSnackbar,
  Loader,
} from "components/core";
import {
  getTimeFromNow,
  lowerCaseText,
  primaryDateFormat,
  primaryTimeFormat,
} from "helpers";
import {
  useApi,
  useBlockedDates,
  useIsMounted,
  useLoader,
  useSnackbar,
} from "hooks";
import { useEffect, useState } from "react";

const BookingDetailItem = ({ icon, textPrimary, textSecondary }) => {
  return (
    <div className="flex-row flex-align-first col-gap-1em p-1em box-shadow-normal">
      <div className="m-0_5em-0 text-primary-3">{icon}</div>
      <div>
        <Typography variant="body1" className="font-size-1_2em">
          {textPrimary}
        </Typography>
        <Typography variant="body2" className="ml-1-5">
          {textSecondary}
        </Typography>
      </div>
    </div>
  );
};

const ArtistBookingDetails = ({
  handleCloseBookingDetails,
  booking,
  handleCancelBooking,
  isCancelBookingSuccess,
  isCancelBookingError,
}) => {
  const isMounted = useIsMounted();
  const { namesInLoaderActions } = useLoader();
  // chose for cancel booking
  const [isOpenCancelBooking, setIsOpenCancelBooking] = useState(false);
  // chose for reschedule booking
  const [isOpenRescheduleBooking, setIsOpenRescheduleBooking] = useState(false);
  const [changeDate, setChangeDate] = useState({
    start: "",
    end: "",
    startErr: "",
    endErr: "",
  });
  const { postData, postStatus, isPosted, isPosting, handleResetPostApi } =
    useApi();
  const { snackbar, handleOpenSnackbar, handleCloseSnackbar } = useSnackbar();
  const { blockedDates } = useBlockedDates(booking?.artist);

  useEffect(() => {
    if (isMounted() && isCancelBookingSuccess) {
      setIsOpenCancelBooking(false);
      handleCloseBookingDetails();
    }
  }, [isMounted, isCancelBookingSuccess, handleCloseBookingDetails]);

  useEffect(() => {
    if (isMounted() && booking?.eventDate?.start && booking?.eventDate?.end) {
      setChangeDate({
        start: booking.eventDate.start,
        end: booking.eventDate.end,
      });
    }
  }, [isMounted, booking]);

  const handleRescheduleDate = () => {
    // if (moment(changeDate.start).isSameOrAfter(moment(changeDate.end))) {
    //   handleOpenSnackbar({
    //     message: "End date should be greater than start date",
    //     variant: "error",
    //   })
    //   return
    // }
    postData(`booking-reschedule/create`, {
      bookingId: booking?._id,
      rescheduleStartDate: changeDate.start,
      rescheduleEndDate: changeDate.end,
      rescheduleBy: "artist",
    });
  };

  useEffect(() => {
    if (isMounted() && isPosted) {
      handleOpenSnackbar({
        message: "Reschedule request sent successfully",
        variant: "success",
      });
    }
  }, [isMounted, isPosted, handleOpenSnackbar]);

  return (
    <div className="artist-booking-details-root">
      {/* snackbar */}
      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        variant={snackbar.variant}
        handleClose={() => {
          handleCloseSnackbar();
        }}
        resetFunction={handleResetPostApi}
      />
      {namesInLoaderActions?.includes(`CANCEL_BOOKING_${booking?._id}`) && (
        <Loader
          isBackDrop={true}
          fullScreen
          isCircularLoader
          className={"zIndex-9999"}
        />
      )}
      {/* booking details header */}
      <div className="artist-booking-details-header flex-row flex-align-center flex-justify-between">
        <div className="artist-booking-details-header-left">
          <Typography variant="h5" className="font-bold text-primary-2">
            Booking Details
          </Typography>
          <Typography
            variant="caption"
            className="text-primary-1 box-shadow-normal p-0_5em border-25"
          >
            {getTimeFromNow(booking?.timestamp)}
          </Typography>
        </div>
        <div className="artist-booking-details-header-right">
          <IconButton
            onClick={() => {
              handleCloseBookingDetails();
              setIsOpenCancelBooking(false);
              setIsOpenRescheduleBooking(false);
            }}
          >
            <CloseOutlined />
          </IconButton>
        </div>
      </div>
      <Divider className="m-1-0" />
      {/* booking details body */}
      {!isOpenRescheduleBooking && (
        <div className="artist-booking-details-body">
          <Grid container spacing={2}>
            {/* event type */}
            <Grid item xs={12}>
              <BookingDetailItem
                icon={<EventOutlined />}
                textPrimary={"Event Type"}
                textSecondary={booking?.eventType?.eventName || "N/A"}
              />
            </Grid>
            {/* service type */}
            <Grid item xs={12}>
              <BookingDetailItem
                icon={<MiscellaneousServicesOutlined />}
                textPrimary={"Service"}
                textSecondary={booking?.serviceType?.serviceName || "N/A"}
              />
            </Grid>
            {/* event time */}
            <Grid item xs={12}>
              <BookingDetailItem
                icon={<AccessTimeOutlined />}
                textPrimary={"Event Time"}
                textSecondary={primaryTimeFormat(booking?.eventTime) || "N/A"}
              />
            </Grid>
            {/* total hours */}
            <Grid item xs={12}>
              <BookingDetailItem
                icon={<TimelapseOutlined />}
                textPrimary={"Total Hours"}
                textSecondary={booking?.eventDuration || "N/A"}
              />
            </Grid>
            {/* event date */}
            <Grid item xs={12}>
              <BookingDetailItem
                icon={<DateRangeOutlined />}
                textPrimary={"Event Date"}
                textSecondary={
                  lowerCaseText(booking?.bookingType) === "other"
                    ? `${primaryDateFormat(
                        booking?.eventDate?.start
                      )} - ${primaryDateFormat(booking?.eventDate?.end)}`
                    : `${primaryDateFormat(booking?.personalizedMsgDate)}`
                }
              />
            </Grid>
            {/* event location */}
            <Grid item xs={12}>
              <BookingDetailItem
                icon={<LocationOnOutlined />}
                textPrimary={"Event Location"}
                textSecondary={`${booking?.eventLocation || "N/A"}`}
              />
            </Grid>
            {/* crowd size */}
            <Grid item xs={12}>
              <BookingDetailItem
                icon={<GroupsOutlined />}
                textPrimary={"Crowd Size"}
                textSecondary={booking?.crowdSize || "N/A"}
              />
            </Grid>
            {/* other details */}
            <Grid item xs={12}>
              <BookingDetailItem
                icon={<ChatBubbleOutline />}
                textPrimary={
                  lowerCaseText(booking?.bookingType) === "other"
                    ? `Other Details`
                    : `Message`
                }
                textSecondary={
                  lowerCaseText(booking?.bookingType) === "other"
                    ? `${booking?.OtherDetails || "N/A"}`
                    : `${booking?.personalizedMessage || "N/A"}`
                }
              />
            </Grid>
          </Grid>
        </div>
      )}
      {/* cancel booking body */}
      <CustomConfirmationDialog
        open={isOpenCancelBooking}
        handleClose={() => {
          setIsOpenCancelBooking(false);
        }}
        headerIcon={<CancelOutlined fontSize="large" />}
        headerTitle={"Cancel Booking"}
        description={"Are you sure you want to cancel this booking?"}
        handleAction={() => {
          handleCancelBooking(
            booking?._id,
            booking?.walletAmount ?? 0,
            booking?.bankAmount ?? 0,
            "artist"
          );
          setIsOpenCancelBooking(false);
        }}
      >
        {isCancelBookingError && (
          <div className="m-b-1em">
            <span className="text-primary-1 font-size-small">
              Something went wrong. Please try again later.
            </span>
          </div>
        )}
      </CustomConfirmationDialog>
      {/* reschedule booking body */}
      {isOpenRescheduleBooking && (
        <div className="artist-booking-reschedule-root">
          <Typography variant="h6" className="text-primary-8 font-bold">
            Reschedule your booking
          </Typography>
          <div className="m-0_5em-0">
            <Typography>Event Date</Typography>
            <CustomDatePicker
              selectedDate={changeDate?.start}
              onChange={(date) => {
                if (moment(date)?.toString() === "Invalid date")
                  return setChangeDate({
                    ...changeDate,
                    startErr: "Please select a valid date",
                  });
                setChangeDate({
                  ...changeDate,
                  start: moment(date)?.toString(),
                  end: moment(date)?.toString(),
                  startErr: "",
                  endErr: "",
                });
              }}
              disablePast
              error={changeDate?.startErr ? true : false}
              helperText={changeDate?.startErr}
              isMobilePicker
              blockedDates={blockedDates?.map((date) => date?.date) || []}
            />
          </div>
          {/* <div>
            <Typography>End Date</Typography>
            <CustomDatePicker
              selectedDate={changeDate?.end}
              onChange={(date) => {
                if (moment(date)?.toString() === "Invalid date")
                  return setChangeDate({
                    ...changeDate,
                    endErr: "Please select a valid date",
                  })
                setChangeDate({
                  ...changeDate,
                  end: moment(date)?.toString(),
                  endErr: "",
                })
              }}
              disablePast
              error={changeDate?.endErr ? true : false}
              helperText={changeDate?.endErr}
              isMobilePicker
              blockedDates={blockedDates?.map((date) => date?.date) || []}
            />
          </div> */}
          {postStatus?.status === 409 && (
            <div className="text-center p-1em bg-whitesmoke m-0_5em-0">
              <p className="text-primary-9 font-bold">{postStatus?.message}</p>
            </div>
          )}
          <div className="m-1-0 ">
            <CustomButton
              btnText={"Back"}
              className={`bg-primary-1 text-white`}
              variant="contained"
              disableElevation
              onClick={() => {
                setIsOpenRescheduleBooking(false);
              }}
            />
            <CustomButton
              btnText={"Reschedule Now"}
              className={`m-0-0_5em ${
                isPosting || changeDate?.startErr || changeDate?.endErr
                  ? "disabled-btn"
                  : "btn-gradient"
              }`}
              variant="contained"
              disableElevation
              onClick={() => {
                handleRescheduleDate();
              }}
              disabled={
                isPosting || changeDate?.startErr || changeDate?.endErr
                  ? true
                  : false
              }
            />
          </div>
        </div>
      )}
      {/* booking details action */}
      {lowerCaseText(booking?.status) === "confirm" &&
        lowerCaseText(booking?.bookingType) === "other" && (
          <>
            {!isOpenCancelBooking && !isOpenRescheduleBooking && (
              <>
                <Divider className="m-1-0" />
                <div className="artist-booking-details-action flex-column flex-align-center flex-justify-between row-gap-1em">
                  <Button
                    className="text-primary-3 border-primary-3 text-transform-none font-bold"
                    variant="outlined"
                    fullWidth
                    onClick={() => {
                      setIsOpenCancelBooking(true);
                    }}
                  >
                    Cancel Booking
                  </Button>
                  <Button
                    className="text-primary-2 border-primary-2 text-transform-none font-bold"
                    variant="outlined"
                    fullWidth
                    onClick={() => {
                      setIsOpenRescheduleBooking(true);
                    }}
                  >
                    Reschedule Booking
                  </Button>
                </div>
              </>
            )}
          </>
        )}
    </div>
  );
};

export default ArtistBookingDetails;
