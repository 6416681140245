import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getMessaging } from "firebase/messaging"
const firebaseConfig = {
  apiKey: "AIzaSyDHaHQs5WffujJi9uLzjoimI0RXVWRKhqY",
  authDomain: "skyrise-celebrity.firebaseapp.com",
  projectId: "skyrise-celebrity",
  storageBucket: "skyrise-celebrity.appspot.com",
  messagingSenderId: "447557359511",
  appId: "1:447557359511:web:4368672bd52e2d2d7916d2",
  measurementId: "G-CWPXV41XLW",
}

export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const messaging = getMessaging(app)
