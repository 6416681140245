import { apiInstance } from "configs"
import { createSelector } from "reselect"
import { endAction, startAction } from "./LoaderAction"
import { sendMail } from "./MailAction"

// assigned managers
export const assignedUsersStateSelector = (state) => state?.assignedUsersState
export const assignedManagersStateSelector = createSelector(
  [assignedUsersStateSelector],
  (assignedUsers) => assignedUsers?.assignedManagers
)
export const assignedManagersSelector = createSelector(
  [assignedManagersStateSelector],
  (assignedManagers) => assignedManagers?.data
)
export const loadingAssignedManagersSelector = createSelector(
  [assignedManagersStateSelector],
  (assignedManagers) => assignedManagers?.isLoading
)
export const fetchAssignedManagers = (artistID) => async (dispatch) => {
  try {
    await apiInstance
      .get(`assign-artist/assigned-managers/${artistID}`)
      .then(({ data }) => {
        const { success } = data
        dispatch({
          type: "ASSIGNED_MANAGERS_SUCCESS",
          payload: success?.data,
        })
      })
      .catch(({ response }) => {
        dispatch({
          type: "ASSIGNED_MANAGERS_ERROR",
          payload: response?.data?.error?.message,
        })
      })
  } catch (error) {
    dispatch({
      type: "ASSIGNED_MANAGERS_ERROR",
      payload: error?.message,
    })
  }
}

// managed artists
export const managedArtistsStateSelector = createSelector(
  [assignedUsersStateSelector],
  (assignedUsers) => assignedUsers?.managedArtists
)
export const managedArtistsSelector = createSelector(
  [managedArtistsStateSelector],
  (managedArtists) => managedArtists?.data
)
export const loadingManagedArtistsSelector = createSelector(
  [managedArtistsStateSelector],
  (managedArtists) => managedArtists?.isLoading
)
export const fetchManagedArtists = (managerID) => async (dispatch) => {
  try {
    dispatch({
      type: "MANAGED_ARTISTS_LOADING",
    })
    await apiInstance
      .get(`assign-artist/managed-artists/${managerID}`)
      .then(({ data }) => {
        dispatch({
          type: "MANAGED_ARTISTS_SUCCESS",
          payload: data?.success?.data,
        })
      })
      .catch(({ response }) => {
        dispatch({
          type: "MANAGED_ARTISTS_ERROR",
          payload: response?.data?.error?.message,
        })
      })
  } catch (error) {
    dispatch({
      type: "MANAGED_ARTISTS_ERROR",
      payload: error?.message,
    })
  }
}

// remove manager
export const removeManagerAction =
  (managerID, artistID, email, subject, message) => async (dispatch) => {
    try {
      dispatch(startAction(`REMOVE_MANAGER_${managerID}`))
      await apiInstance
        .put(`assign-artist/remove-artist`, {
          managerId: managerID,
          artistId: artistID,
        })
        .then(({ data }) => {
          dispatch({
            type: "REMOVE_MANAGER_SUCCESS",
            payload: data?.success?.message,
          })
        })
        .then(() => {
          dispatch(sendMail(email, subject, message))
        })
        .catch(({ response }) => {
          dispatch({
            type: "REMOVE_MANAGER_ERROR",
            payload: response?.data?.error?.message,
          })
        })
        .finally(() => {
          dispatch(endAction(`REMOVE_MANAGER_${managerID}`))
          dispatch({
            type: "REMOVE_MANAGER_RESET",
          })
        })
    } catch (error) {
      dispatch({
        type: "REMOVE_MANAGER_ERROR",
        payload: error?.message,
      })
    }
  }
