import { Pagination, PaginationItem } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { useIsMounted } from "hooks"

const CustomPagination = ({ data, setCurrentPageData, itemsPerPage }) => {
  // isMounted from hook
  const isMounted = useIsMounted()
  const [currentPage, setCurrentPage] = useState(1)
  const items = []
  const indexOfLast = currentPage * itemsPerPage
  const indexOfFirst = indexOfLast - itemsPerPage

  for (let i = 1; i <= Math.ceil(data?.length / itemsPerPage); i++) {
    items.push(i)
  }

  const handleChange = (e, value) => {
    setCurrentPage(value)
  }

  //   set current sliced data
  const setCurrentData = useCallback(() => {
    const currentData = data?.slice(indexOfFirst, indexOfLast)
    setCurrentPageData(currentData)
  }, [data, setCurrentPageData, indexOfFirst, indexOfLast])

  useEffect(() => {
    if (isMounted()) {
      setCurrentData()
    }
  }, [setCurrentData, isMounted])

  return (
    <div className="custom-pagination-container p-1em-0 position-center">
      {data?.length > 0 && (
        <Pagination
          page={currentPage}
          onChange={handleChange}
          variant="text"
          shape="circular"
          count={items?.length}
          size="medium"
          renderItem={(item) => (
            <PaginationItem
              {...item}
              classes={{
                selected: "bg-primary-3 font-bold text-white",
              }}
            />
          )}
        />
      )}
    </div>
  )
}

export default CustomPagination
