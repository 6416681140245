export const EVENT_TYPES = [
  {
    id: 1,
    name: "Wedding",
    image:
      "https://media.istockphoto.com/photos/beautiful-wedding-couple-cuddling-on-their-wedding-day-picture-id1300471625?b=1&k=20&m=1300471625&s=170667a&w=0&h=Q2AKZC_PwFj5656tpqRu2jsAzo00x7bXLVAtDd1Nph8=",
    icon: "https://cdn-icons.flaticon.com/png/128/3074/premium/3074270.png?token=exp=1640599586~hmac=3d8b53edaefc47d2b3651de8e4e55613",
  },
  {
    id: 2,
    name: "Birthday",
    image:
      "https://media.istockphoto.com/photos/asian-family-birthday-party-picture-id1292107228?b=1&k=20&m=1292107228&s=170667a&w=0&h=jK2OZ-qnvS3nSzbeDbBZJjS68MXun_ABZKk7qb0kB7s=",
    icon: "https://cdn-icons.flaticon.com/png/128/2454/premium/2454313.png?token=exp=1640599625~hmac=25ab26f7f9565d5cce702aae9b2b5ea1",
  },
  {
    id: 3,
    name: "House Party",
    image:
      "https://media.istockphoto.com/photos/friends-dancing-and-having-fun-at-kitchen-picture-id1137409767?b=1&k=20&m=1137409767&s=170667a&w=0&h=Uo5js16gsudYybDR4VBVI9a1xCyXjSM_2e9U2BaopO0=",
    icon: "https://cdn-icons.flaticon.com/png/128/3938/premium/3938552.png?token=exp=1640599973~hmac=a43b34a3ab7813927f5d756995f95630",
  },
  {
    id: 4,
    name: "Corporate Event",
    image:
      "https://images.unsplash.com/photo-1515169067868-5387ec356754?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8Y29ycG9yYXRlJTIwZXZlbnR8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60",
    icon: "https://cdn-icons.flaticon.com/png/128/2680/premium/2680341.png?token=exp=1640599721~hmac=dd2c77498fb0b980e2edafb04f956358",
  },
  {
    id: 5,
    name: "Anniversary Party",
    image:
      "https://media.istockphoto.com/photos/celebrating-birthday-with-confetti-picture-id1191790576?b=1&k=20&m=1191790576&s=170667a&w=0&h=_FeFuQ-g0cj9_0H2GEGAlNY_EhTTLwQEchGHvOGdp-A=",
    icon: "https://cdn-icons-png.flaticon.com/128/4414/4414073.png",
  },
  {
    id: 6,
    name: "Office Event",
    image:
      "https://media.istockphoto.com/photos/smiling-female-employee-sit-in-coworking-space-and-working-on-the-picture-id1283955441?b=1&k=20&m=1283955441&s=170667a&w=0&h=pnjDpumrNXIPYKqMn28CbuyL8TUNLzbcVDdQ_PaX8Sk=",
    icon: "https://cdn-icons.flaticon.com/png/128/1376/premium/1376457.png?token=exp=1640599820~hmac=4b154a64450fb68ed9e2d59c82c0c5fd",
  },
  {
    id: 7,
    name: "Private Party",
    image:
      "https://media.istockphoto.com/photos/secret-picture-id157529248?b=1&k=20&m=157529248&s=170667a&w=0&h=d4Nd1ZgVdpLn62RS5L7shFwOQ3WezNLC_i9DHqWN64I=",
    icon: "https://cdn-icons.flaticon.com/png/128/1126/premium/1126116.png?token=exp=1640599940~hmac=d340b8daea3d2c18b2003e282cb7fc43",
  },
  {
    id: 8,
    name: "Cafe",
    image:
      "https://images.unsplash.com/photo-1501339847302-ac426a4a7cbb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8Y2FmZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=600&q=60",
    icon: "https://cdn-icons.flaticon.com/png/128/1926/premium/1926492.png?token=exp=1640599898~hmac=231b1c75a5d22d11debcf9b796abf73d",
  },
]
// services after event type
export const SERVICES = [
  {
    id: 1,
    name: "Live Performance",
    icon: "https://cdn-icons-png.flaticon.com/128/6452/6452032.png",
  },
  {
    id: 2,
    name: "Meet & Greet",
    icon: "https://cdn-icons-png.flaticon.com/128/2548/2548761.png",
  },
  {
    id: 3,
    name: "Personalized Video Message",
    icon: "https://cdn-icons-png.flaticon.com/128/5336/5336523.png",
  },
  {
    id: 4,
    name: "Virtual Meeting",
    icon: "https://cdn-icons-png.flaticon.com/128/3003/3003027.png",
  },
]

export const US_CITIES = [
  {
    id: 1,
    name: "New York",
  },
  {
    id: 2,
    name: "Los Angeles",
  },
  {
    id: 3,
    name: "Chicago",
  },
  {
    id: 4,
    name: "Houston",
  },
  {
    id: 5,
    name: "Philadelphia",
  },
  {
    id: 6,
    name: "Phoenix",
  },
  {
    id: 7,
    name: "San Antonio",
  },
  {
    id: 8,
    name: "San Diego",
  },
  {
    id: 9,
    name: "Dallas",
  },
]

// all artists
export const ARTISTS = [
  {
    id: 1,
    name: "Kanye West",
    city: "Los Angeles",
    profession: "Singer",
    eventType: ["Wedding", "Corporate Event", "Private Party"],
    serviceTypes: ["Live Performance", "Meet & Greet"],
    rating: 4.5,
    bio: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam",
    image:
      "https://media.istockphoto.com/photos/young-female-rockstar-singing-in-concert-picture-id1268216044?b=1&k=20&m=1268216044&s=170667a&w=0&h=8wOyHPvc5E26Bkn6NgROlxDPhOpNAMKa8DS1-_poSYs=",
  },
  {
    id: 2,
    name: "ED Sherran",
    city: "Halifix",
    profession: "Singer",
    eventType: ["Wedding"],
    serviceTypes: ["Live Performance", "Meet & Greet"],
    rating: 3,
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5a/Ed_Sheeran_at_2012_Frequency_Festival_in_Austria_%287852625324%29.jpg/330px-Ed_Sheeran_at_2012_Frequency_Festival_in_Austria_%287852625324%29.jpg",
  },
  {
    id: 3,
    name: "Jonson",
    city: "Los Angeles",
    profession: "Dancer",
    isFav: true,
    eventType: ["Cafe", "Private Party"],
    rating: 2.5,
    image:
      "https://images.unsplash.com/photo-1548123378-bde4eca81d2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
  },
  {
    id: 4,
    name: "Kanye Junior",
    city: "Chicago",
    profession: "Actor",
    eventType: ["Cafe", "Office Event"],
    serviceTypes: ["Meet & Greet", "Personalized Video Message"],
    image:
      "https://images.unsplash.com/photo-1495791185843-c73f2269f669?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
  },
  {
    id: 5,
    name: "Joke King",
    city: "New York",
    profession: "Comedian",
    isFav: true,
    rating: 5,
    image:
      "https://images.unsplash.com/photo-1587504218914-44346092cef8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
  },
  {
    id: 6,
    name: "Kanye West",
    city: "Los Angeles",
    profession: "Singer",
    rating: 1.2,
    image:
      "https://media.istockphoto.com/photos/young-female-rockstar-singing-in-concert-picture-id1268216044?b=1&k=20&m=1268216044&s=170667a&w=0&h=8wOyHPvc5E26Bkn6NgROlxDPhOpNAMKa8DS1-_poSYs=",
  },
  {
    id: 7,
    name: "ED Sherran",
    city: "Halifix",
    profession: "Singer",
    isFav: true,
    rating: 1.2,
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5a/Ed_Sheeran_at_2012_Frequency_Festival_in_Austria_%287852625324%29.jpg/330px-Ed_Sheeran_at_2012_Frequency_Festival_in_Austria_%287852625324%29.jpg",
  },
  {
    id: 8,
    name: "Jonson",
    city: "Los Angeles",
    profession: "Dancer",
    isFav: true,
    rating: 1.2,
    image:
      "https://images.unsplash.com/photo-1548123378-bde4eca81d2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
  },
  {
    id: 9,
    name: "Kanye West",
    city: "Los Angeles",
    profession: "Singer",
    rating: 1.2,
    image:
      "https://media.istockphoto.com/photos/young-female-rockstar-singing-in-concert-picture-id1268216044?b=1&k=20&m=1268216044&s=170667a&w=0&h=8wOyHPvc5E26Bkn6NgROlxDPhOpNAMKa8DS1-_poSYs=",
  },
  {
    id: 10,
    name: "ED Sherran",
    city: "Halifix",
    profession: "Singer",
    rating: 1.2,
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5a/Ed_Sheeran_at_2012_Frequency_Festival_in_Austria_%287852625324%29.jpg/330px-Ed_Sheeran_at_2012_Frequency_Festival_in_Austria_%287852625324%29.jpg",
  },
  {
    id: 11,
    name: "Jonson",
    city: "Los Angeles",
    profession: "Dancer",
    isFav: true,
    rating: 1.2,
    image:
      "https://images.unsplash.com/photo-1548123378-bde4eca81d2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
  },
]

// recent reviews
export const RECENT_REVIEWS = [
  {
    id: 1,
    userName: "John Doe",
    title: "The best place to get a great experience",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
    rating: 5,
    reviewDate: "2021-12-23 08:30 AM",
  },
  {
    id: 2,
    userName: "John Doe",
    title: "The best place to get a great experience",
    description:
      " Lorem ipsum dolor sit, amet consectetur adipisicing elit. Harum vel dolor dicta quidem ut laudantium corporis similique molestias odit? Doloribus obcaecati dolor perspiciatis soluta sunt vero.Impedit tempore magni quod.",
    rating: 2,
    reviewDate: "12-21-2021 08:30 AM",
  },
  {
    id: 3,
    userName: "John Doe",
    title: "The best place to get a great experience",
    description:
      " Lorem ipsum dolor sit, amet consectetur adipisicing elit. Harum vel dolor dicta quidem ut laudantium corporis similique molestias odit? Doloribus obcaecati dolor perspiciatis soluta sunt vero.",
    rating: 5,
    reviewDate: "2021-09-10",
  },
  {
    id: 4,
    userName: "John Doe",
    title: "The best place to get a great experience",
    description:
      " Lorem ipsum dolor sit, amet consectetur adipisicing elit. Harum vel dolor dicta quidem ut laudantium corporis similique molestias odit? Doloribus obcaecati dolor perspiciatis soluta sunt vero.",
    rating: 3,
    reviewDate: "2021-09-10",
  },
  {
    id: 5,
    userName: "Biswaranjan Das",
    title: "The best place to get a great experience ",
    description:
      " Lorem ipsum dolor sit, amet consectetur adipisicing elit. Harum vel dolor dicta quidem ut laudantium corporis similique molestias odit? Doloribus obcaecati dolor perspiciatis soluta sunt vero.laudantium corporis similique molestias odit? Doloribus obcaecati dolor perspiciatis soluta sunt vero",
    rating: 5,
    reviewDate: "2021-12-23 11:00 AM",
  },
]

// artist personalized message data
export const ARTIST_VIDEOS = [
  {
    id: 1,
    artistName: "Kanye West",
    timestamp: "2021-12-23 08:30 AM",
    city: "Los Angeles",
    thumbImg:
      "https://images.unsplash.com/photo-1510759591315-6425cba413fe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8d2lzaHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=600&q=60",
    videoUrl:
      "https://media.istockphoto.com/videos/butterfly-video-id182135097",
  },
  {
    id: 2,
    artistName: "Nicki Minaj",
    timestamp: "2021-12-21 10:30 AM",
    city: "New York",
    thumbImg:
      "https://media.istockphoto.com/photos/cheerful-mother-with-little-daughter-dancing-at-favourite-song-in-picture-id1267502829?b=1&k=20&m=1267502829&s=170667a&w=0&h=EeTwacUd602KicYqpVkwnL-sIfq0AhAzsIRRBz3-97Q=",
    videoUrl:
      "https://media.istockphoto.com/videos/creative-idea-brain-lightbulb-video-id1171532372",
  },
  {
    id: 3,
    artistName: "Ed Sherran",
    timestamp: "2021-12-23 08:30 AM",
    city: "Los Angeles",
    thumbImg: "https://i.ytimg.com/vi/Q-_X-_X-_X8/maxresdefault.jpg",
    videoUrl:
      "https://media.istockphoto.com/videos/purpleviolet-scene-wine-glass-bottle-drink-object-concept-many-3d-video-id1149225679?s=256_srp_is",
  },
  {
    id: 4,
    artistName: "Rihanna ",
    timestamp: "2021-12-23 09:30 AM",
    city: "Los Angeles",
    thumbImg:
      "https://media.istockphoto.com/photos/happy-asian-lady-singing-using-smartphone-as-mic-picture-id1284041637?b=1&k=20&m=1284041637&s=170667a&w=0&h=WxJJO5rKkBPjklTXy8C2z3r4HFp4y5RB8VvJ7NDHsio=",
    videoUrl:
      "https://media.istockphoto.com/videos/enjoying-a-cocktail-at-the-beachfront-tropical-bar-tropical-night-video-id1289147053?s=256_srp_is",
  },
  {
    id: 5,
    artistName: "Taylor Swift",
    timestamp: "2021-12-23 08:30 AM",
    city: "Los Angeles",
    thumbImg: "https://i.ytimg.com/vi/Q-_X-_X-_X8/maxresdefault.jpg",
    videoUrl:
      "https://media.istockphoto.com/videos/pink-rugby-ball-on-pink-background-video-id1328112074?s=256_srp_is",
  },
  {
    id: 6,
    artistName: "Justin Bieber",
    timestamp: "2021-12-23 08:30 AM",
    city: "Los Angeles",
    thumbImg:
      "https://images.unsplash.com/photo-1626407937756-0ca787f02f35?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fHNvbmd8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60",
    videoUrl:
      "https://media.istockphoto.com/videos/gift-box-with-red-ribbon-spinning-on-neon-purple-background-360-video-id1195637928",
  },
]

// ratings data
export const RATINGS = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
]

// coupon codes
export const COUPON_CODES = [
  {
    id: 1,
    code: "ABC123",
    discountInPercent: 10,
    expiryDate: "2021-12-23",
  },
  {
    id: 2,
    code: "XYZ456",
    discountInPercent: 20,
    expiryDate: "2021-12-23",
  },
  {
    id: 3,
    code: "PQR789",
    discountInPercent: 30,
    expiryDate: "2021-12-23",
  },
  {
    id: 4,
    code: "LMNOP",
    discountInPercent: 40,
    expiryDate: "2021-12-23",
  },
  {
    id: 5,
    code: "STUVW",
    discountInPercent: 50,
    expiryDate: "2021-12-23",
  },
  {
    id: 6,
    code: "XYZ123456ABC",
    discountInPercent: 60,
    expiryDate: "2021-12-23",
  },
]

// wallet data
export const WALLET_DATA = [
  {
    id: "1",
    type: "credit",
    title: "Money added to your wallet",
    description:
      " Due to your cancellation of booking money has been added to your wallet.",
    amount: "25.00",
    timestamp: "12/25/2021 10:30 AM",
  },
  {
    id: "2",
    type: "debit",
    title: "Money debited from your wallet",
    description:
      "You have successfully booking your event using your wallet money.",
    amount: "30.00",
    timestamp: "12/29/2021 10:30 AM",
  },
]

// requests data fro event reschedule
export const REQUEST_DATA = [
  {
    id: 1,
    eventType: "Birthday",
    serviceType: "Live Performance",
    eventDate: {
      start: "12/25/2021",
      end: "12/27/2021",
    },
    rescheduleDate: {
      start: "12/28/2021 ",
      end: "01/03/2022 ",
    },
    eventLocation: "Los Angeles",
    eventTime: "11:30 AM",
    eventDuration: "3",
    otherDetails:
      "lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    status: "ACCEPTED",
    type: "reschedule",
  },
  {
    id: 2,
    eventType: "Birthday",
    serviceType: "Live Performance",
    eventDate: {
      start: "12/25/2021 10:30 AM",
      end: "12/27/2021 10:30 AM",
    },
    rescheduleDate: {
      start: "12/28/2021 10:30 AM",
      end: "01/03/2022 10:30 AM",
    },
    status: "REJECTED",
    type: "reschedule",
  },
  {
    id: 3,
    eventType: "Birthday",
    serviceType: "Live Performance",
    eventDate: {
      start: "12/25/2021 10:30 AM",
      end: "12/27/2021 10:30 AM",
    },
    rescheduleDate: {
      start: "12/28/2021 10:30 AM",
      end: "01/03/2022 10:30 AM",
    },
    status: "PENDING",
    type: "reschedule",
  },
  {
    id: 4,
    eventType: "Birthday",
    serviceType: "Live Performance",
    eventDate: {
      start: "12/25/2021 10:30 AM",
      end: "12/27/2021 10:30 AM",
    },
    rescheduleDate: {
      start: "12/28/2021 10:30 AM",
      end: "01/03/2022 10:30 AM",
    },
    type: "reschedule",
  },
  {
    id: 5,
    eventType: "Birthday",
    serviceType: "Live Performance",
    eventDate: {
      start: "12/25/2021 10:30 AM",
      end: "12/27/2021 10:30 AM",
    },
    rescheduleDate: {
      start: "12/28/2021 10:30 AM",
      end: "01/03/2022 10:30 AM",
    },
    type: "reschedule",
  },
  {
    id: 6,
    eventType: "Birthday",
    serviceType: "Personalized Message",
    personalizedMsgDate: "12/25/2021 10:30 AM",
    reschedulePersonalizedDate: "01/03/2022 10:30 AM",
    personalizedMessage: "Hi, I am going to be late for the party",
    type: "personalized",
  },
  {
    id: 7,
    eventType: "Birthday",
    serviceType: "Virtual Event",
    eventDate: {
      start: "12/25/2021 10:30 AM",
      end: "12/27/2021 10:30 AM",
    },
    cityName: "Bangalore",
    status: "",
    type: "location",
  },
  {
    id: 8,
    eventType: "Birthday",
    serviceType: "Personalized Message",
    personalizedMsgDate: "12/25/2021 10:30 AM",
    reschedulePersonalizedDate: "01/03/2022 10:30 AM",
    personalizedMessage: "Hi, I am going to be late for the party",
    status: "ACCEPTED",
    type: "personalized",
  },
  {
    id: 9,
    eventType: "Birthday",
    serviceType: "Virtual Event",
    eventDate: {
      start: "12/25/2021 10:30 AM",
      end: "12/27/2021 10:30 AM",
    },
    rescheduleDate: {
      start: "12/28/2021 10:30 AM",
      end: "01/03/2022 10:30 AM",
    },
    status: "ACCEPTED",
    type: "reschedule",
  },
  {
    id: 10,
    managerName: "Manager Name",
    managerEmail: "manager123@gmail.com",
    managerLocation: "Bangalore",
    yearsOfExperience: 5,
    phoneNumber: "+19876543210",
    gender: "Male",
    type: "manager",
  },
  {
    id: 11,
    managerName: "Accepted Manager Name",
    managerEmail: "acceptedmanager123@gmail.commm",
    managerLocation: "Bangalore",
    yearsOfExperience: 5,
    phoneNumber: "+1234567890",
    gender: "Female",
    type: "manager",
    status: "ACCEPTED",
  },
]

// artist dashboard data
export const ARTIST_DASHBOARD_DATA = [
  {
    id: 1,
    title: "Total Bookings",
    value: "10",
  },
  {
    id: 2,
    title: "No of Times Profile Viewed",
    value: "20",
  },
  {
    id: 3,
    title: "No of Times Shortlisted",
    value: "50",
  },
  {
    id: 4,
    title: "No of Minutes Spent On Profile",
    value: "100",
  },
]

// languages
export const LANGUAGES = [
  {
    id: 1,
    name: "English",
  },
  {
    id: 2,
    name: "Japanese",
  },
  {
    id: 3,
    name: "French",
  },
  {
    id: 4,
    name: "Spanish",
  },
  {
    id: 5,
    name: "German",
  },
]

// manager for artists
export const MANAGERS_FOR_ARTIST = [
  {
    managerID: "abc123",
    name: "John Doe ",
    email: "example1@gamil.com",
    location: "New York",
    yearsOfExperience: "5",
    languages: "English, French",
    profileImage: "https://via.placeholder.com/150",
    phoneNumber: "+1234567890",
    gender: "Male",
    noOfArtistsManaged: 10,
    status: "Pending",
  },
  {
    managerID: "abc231",
    name: "New John Doe",
    email: "example2@gmail.com",
    location: "California",
    yearsOfExperience: "2",
    languages: "English, Spanish",
    profileImage: "https://via.placeholder.com/150",
    phoneNumber: "+1234567890",
    gender: "Male",
    noOfArtistsManaged: 13,
    status: "Accepted",
  },
  {
    managerID: "abc987",
    name: "Again John Doe 1",
    email: "example3@gmail.com",
    location: "Canada",
    yearsOfExperience: "08",
    languages: "English, Spanish",
    profileImage: "https://via.placeholder.com/150",
    phoneNumber: "+1234567890",
    gender: "Male",
    noOfArtistsManaged: 18,
    status: "Accepted",
  },
  {
    managerID: "abc789",
    name: "Old John Doe 2",
    email: "example4@gmail.com",
    location: "New York City",
    yearsOfExperience: "05",
    languages: "English, Spanish",
    profileImage: "https://via.placeholder.com/150",
    phoneNumber: "+1234567890",
    gender: "Male",
    noOfArtistsManaged: 12,
    status: "Accepted",
  },
]

// manager all requests
export const MANAGER_ALL_REQUESTS = [
  {
    artistID: "abc123",
    name: "John Doe ",
    email: "john1@gmail.com",
    location: "New York",
    category: "Singer",
    yearsOfExperience: "5",
    status: "Pending",
  },
  {
    artistID: "abc1234",
    name: "John Doe New",
    email: "john123@gmail.com",
    location: "New York,NY",
    category: "Dancer",
    yearsOfExperience: "5",
    status: "Accept",
  },
  {
    artistID: "abc12345",
    name: "Manager Name",
    email: "john1234@gmail.com",
    location: "California,USA",
    category: "Musician",
    yearsOfExperience: "5",
    status: "Reject",
  },
]
