import { lowerCaseText } from "helpers";
import * as Yup from "yup";

export const artistPricingErrSchemas = Yup.object().shape({
  serviceType: Yup.mixed()
    .required("Please enter a service type.")
    .test("serviceType", "Please select a service size", (val) => {
      // if (val === "normal") {
      //   return true;
      // }
      return true;
    }),
  crowdSize: Yup.mixed().when("serviceType", {
    is: (val) => lowerCaseText(val) === "normal",
    then: Yup.mixed()
      .required("Please enter a crowd size")
      .test("crowdSize", "Please select a crowd size", (val) => {
        if (val === "Other") {
          return val.length > 0;
        }
        return true;
      }),
    otherwise: Yup.mixed().notRequired(),
  }),
  // check if crowd is set to other and min and max are set
  otherCrowdSizeMin: Yup.number().when(["crowdSize"], {
    is: (val) => lowerCaseText(val) === "other",
    then: Yup.number()
      .required("Please enter a crowd size")
      .test("max-validation", "Must be minimum", function (value) {
        return this.parent.otherCrowdSizeMax > value;
      }),
    otherwise: Yup.number().notRequired(),
  }),
  otherCrowdSizeMax: Yup.number().when(["crowdSize"], {
    is: (val) => {
      return lowerCaseText(val) === "other";
    },
    then: Yup.number().required("Please enter a crowd size"),
    otherwise: Yup.number().notRequired(),
  }),
  location: Yup.string().when(["crowdSize"], {
    is: (val) => lowerCaseText(val) === "other",
    then: Yup.number().required("Please enter a location"),

    otherwise: Yup.string().notRequired(),
  }),

  day: Yup.mixed().when("crowdSize", {
    is: (val) => lowerCaseText(val) === "other",
    then: Yup.number()
      .required("Please enter a day")
      .min(1, "Please enter a day"),
    otherwise: Yup.mixed().notRequired(),
  }),
  otherDay: Yup.number().when("day", {
    is: (val) => lowerCaseText(val) === "other",
    then: Yup.number()
      .required("Please enter a otherDay")
      .min(1, "Please enter a day"),
    otherwise: Yup.number().notRequired(),
  }),
  pricePerHour: Yup.number().required("Please enter a price per hour"),
});
