import produce from "immer"

const initialState = {
  isLoading: false,
  error: null,
  categories: [],
  subCategories: [],
  genres: [],
}
const CategoriesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type } = action
    switch (type) {
      case "LOADING_CATEGORIES":
        draft.isLoading = true
        draft.error = null
        break
      case "SET_CATEGORIES":
        draft.isLoading = false
        draft.error = null
        draft.categories = action.payload
        break
      case "SET_SUB_CATEGORIES":
        draft.isLoading = false
        draft.error = null
        draft.subCategories = action.payload
        break
      case "SET_GENRES":
        draft.isLoading = false
        draft.error = null
        draft.genres = action.payload
        break
      case "FETCHING_CATEGORIES_ERROR":
        draft.isLoading = false
        draft.error = action.payload
        break
      case "RESET_SUB_CATEGORIES":
        draft.subCategories = []
        break
      case "RESET_GENRES":
        draft.genres = []
        break
      default:
        return state
    }
  })

export default CategoriesReducer
