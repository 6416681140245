import {
  RemoveRedEyeOutlined,
  VisibilityOffOutlined,
} from "@mui/icons-material"
import { Grid, IconButton, TextField, Typography } from "@mui/material"
import { useEffect } from "react"
// import from other files
import { FORGOT_PWD_LOGO } from "assets"
import { CustomButton, CustomSnackbar } from "components"
import { useApi, useIsMounted, useSnackbar } from "hooks"

const ManagerChangePassword = ({
  currentPwd,
  handleChangeCurrentPwd,
  isShowCurrentPwd,
  toggleCurrentPwd,
  isMobileView,
  password,
  handleChangeNewPassword,
  toggleShowPassword,
  isShowPassword,
  isHideImage,
  confirmPwd,
  handleChangeConfirmPwd,
  toggleConfirmShowPassword,
  isShowConfirmPwd,
  currentUser,
  isManager,
  isArtistSection,
}) => {
  const {
    postData,
    isPosted,
    isPostErr,
    handleResetPostApi,
    isPosting,
    postStatus,
  } = useApi()
  const isMounted = useIsMounted()
  const { snackbar, handleOpenSnackbar, handleCloseSnackbar } = useSnackbar()

  const handleChangePwd = (current, newPwd, confirm, userEmail) => {
    if (!current || !newPwd || !confirm) {
      handleOpenSnackbar({
        message: "All fields are required",
        variant: "error",
      })
      return
    }
    if (newPwd !== confirm) {
      handleOpenSnackbar({
        message: "Password does not match",
        variant: "error",
      })
      return
    }
    postData(
      `auth/set-password/changePassword`,
      {
        email: userEmail,
        oldPassword: current,
        newPassword: newPwd,
      },
      "PUT"
    )
  }
  useEffect(() => {
    if (isMounted() && isPosted) {
      handleOpenSnackbar({
        message: "Password changed successfully",
        variant: "success",
      })
    }
  }, [handleOpenSnackbar, isMounted, isPosted])
  useEffect(() => {
    if (isMounted() && isPostErr) {
      handleOpenSnackbar({
        message: postStatus?.message,
        variant: "error",
      })
    }
  }, [handleOpenSnackbar, isMounted, isPostErr, postStatus])
  return (
    <div className="manager-change-pwd-root">
      <CustomSnackbar
        open={snackbar?.open}
        handleClose={() => {
          handleCloseSnackbar()
        }}
        variant={snackbar?.variant}
        message={snackbar?.message}
        autoHideDuration={3000}
        resetFunction={handleResetPostApi}
      />
      <Grid container spacing={2} alignItems="center">
        {!isHideImage && (
          <Grid item xs={12} sm={5} md={5} lg={6}>
            <div className="position-center w-100">
              <img src={FORGOT_PWD_LOGO} alt="" width={"80%"} />
            </div>
          </Grid>
        )}
        <Grid item xs={12} sm={7} md={7} lg={6}>
          <div className="m-2em-0">
            <div className="m-1-0">
              <Typography variant="h6">Reset your Password</Typography>
              <Typography className="font-size-small text-primary-1">
                All * fields are mandatory
              </Typography>
            </div>

            <div className="manager-change-pwd-inputs">
              <label htmlFor="currentPassword">Current Password *</label>
              <TextField
                fullWidth
                className="m-0_5em-0"
                value={currentPwd}
                onChange={(e) => handleChangeCurrentPwd(e.target.value)}
                type={isShowCurrentPwd ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => {
                        toggleCurrentPwd()
                      }}
                    >
                      {isShowCurrentPwd ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <RemoveRedEyeOutlined />
                      )}
                    </IconButton>
                  ),
                }}
              />
            </div>
            <div className="manager-change-pwd-inputs">
              <label htmlFor="newPassword">New Password *</label>
              <TextField
                type={isShowPassword ? "text" : "password"}
                fullWidth
                className="m-0_5em-0"
                value={password}
                onChange={(e) => {
                  handleChangeNewPassword(e?.target?.value)
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => {
                        toggleShowPassword()
                      }}
                    >
                      {isShowPassword ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <RemoveRedEyeOutlined />
                      )}
                    </IconButton>
                  ),
                }}
              />
            </div>
            <div className="manager-change-pwd-inputs">
              <label htmlFor="confirmPassword">Confirm Password *</label>
              <TextField
                type={isShowConfirmPwd ? "text" : "password"}
                fullWidth
                className="m-0_5em-0"
                value={confirmPwd}
                onChange={(e) => handleChangeConfirmPwd(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => {
                        toggleConfirmShowPassword()
                      }}
                    >
                      {isShowConfirmPwd ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <RemoveRedEyeOutlined />
                      )}
                    </IconButton>
                  ),
                }}
              />
            </div>
            <div className="manager-update-pwd-action m-1-0 text-center">
              {isArtistSection && isManager ? (
                <div className="flex-column flex-align-center text-center">
                  <CustomButton
                    btnText="Change Password"
                    fullWidth={isMobileView}
                    variant="contained"
                    disableElevation
                    className={"disabled-btn"}
                    disabled
                    size="large"
                  />
                  <spn className="text-primary-1 font-bold font-size-small m-1-0">
                    You can't change password for this user
                  </spn>
                </div>
              ) : (
                <CustomButton
                  btnText="Change Password"
                  fullWidth={isMobileView}
                  variant="contained"
                  disableElevation
                  className={isPosting ? "disabled-btn" : "btn-gradient"}
                  disabled={isPosting}
                  size="large"
                  onClick={() => {
                    handleChangePwd(
                      currentPwd,
                      password,
                      confirmPwd,
                      currentUser?.email
                    )
                  }}
                />
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default ManagerChangePassword
