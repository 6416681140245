// import { CircularProgress } from "@mui/material"
import { CircularProgress } from "@mui/material"
import { LOADING_SVG } from "assets"

const Loader = ({
  className,
  isCircularLoader,
  isBackDrop,
  circularProgressSize,
  fullScreen,
  bgNone,
}) => {
  return (
    <div
      className={`h-100 w-100 position-center ${
        className ? className : "bg-col-white"
      } ${isBackDrop && "overlay-blur"} ${fullScreen && "position-fixed"} ${
        bgNone && "bg-none"
      }`}
    >
      {/* <CircularProgress className="circularProgress" /> */}
      {!isCircularLoader && (
        <img src={LOADING_SVG} alt="" className="circularProgress" />
      )}
      {isCircularLoader && (
        <CircularProgress
          className={`text-primary-3 ${className ? className : ""}`}
          size={circularProgressSize ?? 40}
        />
      )}
    </div>
  )
}

export default Loader
