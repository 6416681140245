import { apiInstance } from "configs"
import { useState } from "react"
import { useCallback } from "react"

const useSocialLinks = () => {
  const [postLinks, setPostLinks] = useState({
    isPosting: false,
    successMsg: "",
    errorMsg: "",
  })
  const [getLinks, setGetLinks] = useState({
    isGetting: false,
    errorMsg: "",
    data: [],
  })
  const [deleteLinks, setDeleteLinks] = useState({
    isDeleting: false,
    successMsg: "",
    errorMsg: "",
  })
  //   *post social links
  const onPostLinks = useCallback(async (artistID, links) => {
    try {
      setPostLinks((pre) => ({ ...pre, isPosting: true }))
      await apiInstance
        .post(`artist-media/add-social-link`, {
          artistId: artistID,
          linksArray: links,
        })
        .then(({ data }) => {
          setPostLinks((pre) => ({
            ...pre,
            isPosting: false,
            successMsg: data?.success?.message,
          }))
        })
        .catch(({ response }) => {
          setPostLinks((pre) => ({
            ...pre,
            isPosting: false,
            errorMsg: response?.data?.error?.message,
          }))
        })
    } catch (error) {
      setPostLinks((pre) => ({
        ...pre,
        isPosting: false,
        errorMsg: error.message,
      }))
    }
  }, [])

  //   *get social links
  const onGetLinks = useCallback(async (artistID) => {
    try {
      setGetLinks((pre) => ({ ...pre, isGetting: true }))
      await apiInstance
        .get(`artist-media/social-links/${artistID}`)
        .then(({ data }) => {
          setGetLinks((pre) => ({
            ...pre,
            isGetting: false,
            data: data?.success?.data,
          }))
        })
        .catch(({ response }) => {
          setGetLinks((pre) => ({
            ...pre,
            isGetting: false,
            errorMsg: response?.data?.error?.message,
          }))
        })
    } catch (error) {
      setGetLinks((pre) => ({
        ...pre,
        isGetting: false,
        errorMsg: error.message,
      }))
    }
  }, [])

  //   *delete social links
  const onDeleteLinks = useCallback(async (artistID, linkIdsArray) => {
    try {
      setDeleteLinks((pre) => ({ ...pre, isDeleting: true }))
      await apiInstance
        .delete(`artist-media/social-links/delete`, {
          data: {
            artistId: artistID,
            linkIdsArray,
          },
        })
        .then(({ data }) => {
          setDeleteLinks((pre) => ({
            ...pre,
            isDeleting: false,
            successMsg: data?.success?.message,
          }))
        })
        .catch(({ response }) => {
          console.log({ response })
          setDeleteLinks((pre) => ({
            ...pre,
            isDeleting: false,
            errorMsg: response?.data?.error?.message,
          }))
        })
    } catch (error) {
      setDeleteLinks((pre) => ({
        ...pre,
        isDeleting: false,
        errorMsg: error.message,
      }))
    }
  }, [])

  //   *reset social links
  const onResetLinks = useCallback(() => {
    setPostLinks({
      isPosting: false,
      successMsg: "",
      errorMsg: "",
    })
    setDeleteLinks({
      isDeleting: false,
      successMsg: "",
      errorMsg: "",
    })
  }, [])

  return {
    postLinks,
    onPostLinks,
    getLinks,
    onGetLinks,
    deleteLinks,
    onDeleteLinks,
    onResetLinks,
  }
}

export default useSocialLinks
