import styled from "@emotion/styled"
import { CloseOutlined } from "@mui/icons-material"
import { IconButton, Typography } from "@mui/material"
import { useCallback, useEffect } from "react"
// import from other files
import { useIsMounted } from "hooks"

const CustomSnackbar = ({
  variant,
  open,
  message,
  handleClose,
  autoHideDuration,
  resetFunction,
}) => {
  const isMounted = useIsMounted()
  // close callback
  const handleCloseCallback = useCallback(() => {
    if (open) {
      setTimeout(
        () => {
          handleClose()
        },
        autoHideDuration ? autoHideDuration : 2000
      )
      return
    }
  }, [open, handleClose, autoHideDuration])

  //   use effect
  useEffect(() => {
    if (isMounted()) {
      handleCloseCallback()
    }
  }, [handleCloseCallback, isMounted])

  useEffect(() => {
    if (isMounted() && !open && resetFunction) {
      resetFunction()
      return
    }
  }, [isMounted, open, resetFunction])
  return (
    <div>
      <Snackbar variant={variant} open={open}>
        <div className="flex-row flex-align-center flex-justify-between">
          <Typography>{message}</Typography>
          <IconButton
            className="m-l-1em bg-none"
            onClick={() => {
              handleClose()
            }}
          >
            <CloseOutlined />
          </IconButton>
        </div>
      </Snackbar>
    </div>
  )
}

const Snackbar = styled.div`
  position: fixed;
  top: 1em;
  left: 50%;
  right: 0;
  background-color: ${({ variant }) => {
    switch (variant) {
      case "success":
        return "#4caf50"
      case "error":
        return "#f44336"
      case "warning":
        return "#ff9800"
      default:
        return "var(--col-primary-2)"
    }
  }};
  color: #fff;
  padding: 0.5em 1em;
  z-index: 9999;
  text-align: center;
  font-size: 1em;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
  transform: ${({ open }) =>
    open ? "translate(-50%, 0)" : "translate(-50%, -150%)"};
  width: fit-content;
  opacity: ${({ open }) => (open ? 1 : 0)};
`

export default CustomSnackbar
