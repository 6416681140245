import { useDispatch, useSelector } from "react-redux"
import { useCallback } from "react"
import {
  fetchPersonalizedMessages,
  getLoadingPersonalizedMessage,
  getPersonalizedMessage,
  personalizedMsgStateSelector,
  removePersonalizedMessageAction,
  removePersonalizedMessageErrorSelector,
  removePersonalizedMessageSuccessSelector,
  resetRemovePersonalizedMessageAction,
} from "redux/actions"

const usePersonalizedMessages = (currentUserID) => {
  const dispatch = useDispatch()
  const personalizedMessageState = useSelector((state) =>
    personalizedMsgStateSelector(state)
  )
  const personalizedMessages = useSelector((state) =>
    getPersonalizedMessage(state)
  )
  const isLoadingPersonalizedMessage = useSelector((state) =>
    getLoadingPersonalizedMessage(state)
  )

  const handleFetchPersonalizedMessages = useCallback(
    (type) => {
      dispatch(fetchPersonalizedMessages(currentUserID, type))
    },
    [dispatch, currentUserID]
  )

  // remove action
  const isPersonalizeRemoveSuccess = useSelector((state) =>
    removePersonalizedMessageSuccessSelector(state)
  )
  const isPersonalizeRemoveError = useSelector((state) =>
    removePersonalizedMessageErrorSelector(state)
  )
  const resetRemovePersonalizedMessage = useCallback(() => {
    dispatch(resetRemovePersonalizedMessageAction())
  }, [dispatch])
  const handleRemovePersonalizedMessage = useCallback(
    (videoID) => {
      dispatch(removePersonalizedMessageAction(currentUserID, videoID))
    },
    [dispatch, currentUserID]
  )

  return {
    personalizedMessageState,
    isLoadingPersonalizedMessage,
    personalizedMessages,
    handleFetchPersonalizedMessages,
    // remove
    handleRemovePersonalizedMessage,
    isPersonalizeRemoveSuccess,
    isPersonalizeRemoveError,
    resetRemovePersonalizedMessage,
  }
}

export default usePersonalizedMessages
