import { useMemo } from "react"
import { useSelector } from "react-redux"
import { loaderActionsSelector, uiActionStateSelector } from "redux/actions"

const useLoader = () => {
  const uiActionState = useSelector((state) => uiActionStateSelector(state))
  const loaderActions = useSelector((state) => loaderActionsSelector(state))
  const namesInLoaderActions = useMemo(() => {
    return loaderActions?.map((action) => action.name)
  }, [loaderActions])
  return {
    uiActionState,
    loaderActions,
    namesInLoaderActions,
  }
}

export default useLoader
