import {
  AutoDeleteOutlined,
  DeleteOutline,
  ManageAccountsOutlined,
  PersonOutline,
  StarOutlined,
} from "@mui/icons-material"
import { Divider, IconButton, Tooltip, Typography } from "@mui/material"
import { USER_RECTANGLE_PLACEHOLDER } from "assets"
import { CustomButton } from "components"
import { useCurrencyContext } from "hooks"

const ManagedArtistCard = ({
  artist,
  handleNavigateToArtistProfile,
  handleActionMenusDialog,
  handleOpenConfirmDialog,
  sentRequests,
}) => {
  const { currencyWiseValue } = useCurrencyContext()
  return (
    <div className="managed-artist-card-root position-relative box-shadow-normal border-5 h-100">
      {/* artist image */}
      <div className="text-center w-100 bg-whitesmoke">
        <div className="managed-artist-img-root w-100 position-relative">
          <img
            src={artist?.profileImageUrl ?? USER_RECTANGLE_PLACEHOLDER}
            alt={artist?.firstName}
            className="border-10 object-fit-cover managed-artist-card-img"
            height={120}
            width={130}
          />
          {artist?.ratings > 0 && (
            <div className="managed-artist-ratings flex-row flex-align-center text-primary-3 bg-col-white  border-5">
              <StarOutlined />
              <Typography className="m-0-0_5em" noWrap={false}>
                {artist?.ratings}
              </Typography>
            </div>
          )}
        </div>
      </div>
      {/* artist details */}
      <div className="managed-artist-card-details position-relative">
        <div className="managed-artist-name text-center w-100">
          {/* name */}
          <Typography
            className="text-ellipse-vertical line-clamp-2"
            variant="h6"
          >
            {`${artist?.firstName} ${artist?.lastName}`}
          </Typography>
          {/* city */}
          <Typography
            className="text-ellipse-vertical line-clamp-2 text-secondary"
            variant="body1"
          >
            {artist?.location?.address}
          </Typography>
          {/* starting price */}
          <div className="managed-artist-price text-primary-8 text-center">
            <Typography className="m-0-0_5em font-bold font-size-small">{`Starting From`}</Typography>
            <Typography className="m-0-0_5em font-bold" variant="h6">
              {artist?.minPrice
                ? `${currencyWiseValue(artist?.minPrice)}/hr`
                : "N/A"}
            </Typography>
          </div>
          {/* profession */}
          <div className="flex-row flex-align-center flex-justify-center m-0_5em-0 text-primary-2">
            <PersonOutline />
            <Typography className="text-ellipses font-bold" variant="body1">
              {artist?.category?.title}
            </Typography>
          </div>
        </div>
      </div>
      {/* artist actions */}
      <div className="managed-artist-actions-root position-relative w-100">
        <div className="managed-artist-actions-divider">
          <Divider />
        </div>
        <div className="managed-artist-actions-content text-center p-0_5em-1em">
          <CustomButton
            btnText="VIEW PROFILE"
            variant="text"
            className="text-primary-8 bg-whitesmoke"
            onClick={() => handleNavigateToArtistProfile(artist?._id)}
            size="small"
          />
        </div>
      </div>
      {/* favorite & view other menus of artists */}
      <div className="view-managed-artist-menus text-center flex-row flex-align-center">
        {sentRequests?.find(
          (i) =>
            i?.requestType === "managerRemove" &&
            i?.receiverUser?._id === artist?._id &&
            i?.status === "pending"
        ) ? (
          <Tooltip title="Already sent remove request">
            <IconButton className="text-primary-9 bg-col-white m-0-0_5em border-10">
              <AutoDeleteOutlined />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton
            className="text-primary-1 bg-col-white m-0-0_5em border-10"
            onClick={() => {
              handleOpenConfirmDialog()
            }}
          >
            <DeleteOutline />
          </IconButton>
        )}
        <IconButton
          className="text-primary-7 bg-col-white border-10"
          onClick={() => handleActionMenusDialog(artist?._id)}
        >
          <ManageAccountsOutlined />
        </IconButton>
      </div>
    </div>
  )
}

export default ManagedArtistCard
