import { Button, Typography } from "@mui/material"
import { useWindow } from "hooks"
import { useNavigate } from "react-router-dom"

const CommonHeader = ({ title, subtitle, btnText, route, isShowBtn }) => {
  const navigate = useNavigate()
  const isSmallScreen = useWindow().windowData.width < 600
  return (
    <div className="top-search-header m-1-0 flex-row flex-justify-between flex-align-center">
      <div>
        <Typography variant="h5" className="heading-text-color">
          {title}
        </Typography>
        {isSmallScreen ? (
          <></>
        ) : (
          <Typography variant="body1" color="var(--col-secondary)">
            {subtitle}
          </Typography>
        )}
      </div>
      {isShowBtn ? (
        <div>
          <Button
            variant="outlined"
            className="font-size-small font-bold text-primary-2 border-primary-2"
            onClick={() => {
              navigate(`${route}`)
            }}
          >
            {btnText}
          </Button>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default CommonHeader
