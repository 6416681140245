import {
  CalendarTodayOutlined,
  CancelOutlined,
  DeleteOutlined,
  LocationOnOutlined,
  MiscellaneousServicesOutlined,
  ModeEditOutlined,
  MoneyOutlined,
  MoreVertOutlined,
  TimelapseOutlined,
  CheckCircleOutlineOutlined,
} from "@mui/icons-material"
import { Chip, IconButton, Typography } from "@mui/material"
import { useCallback, useEffect, useMemo, useRef, useState, forwardRef } from "react"
import { CustomPopper, CustomPopperBody } from "components"
import { USER_RECTANGLE_PLACEHOLDER } from "assets"
import {
  calculateServiceCharge,
  getTimeFromNow,
  lowerCaseText,
  primaryDateFormat,
  primaryTimeFormat,
} from "helpers"
import { useUsers, useIsMounted, useCurrencyContext, useAdmin } from "hooks"
import PayStack from "PayStack"
import { CustomButton } from "components/core"
import { apiInstance } from "configs"
import {Dialog, Button} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const MyBookingCard = ({
  bookedData,
  handleOpenRescheduleEventDialog,
  isNoAction,
  isConfirmed,
  isPast,
  removePastBookings,
  handleOpenCancelBookingDialog,
  isPayNow,
  handleSuccess,
  isEventDoneAction,
  handleConfirmEventDone,
  handleChoseEventDone,
  handleOpenAlertForBlockDate,
}) => {
  const { serviceCharge } = useAdmin()
  const { currencyWiseValue } = useCurrencyContext()
  const isMounted = useIsMounted()
  const { currentUser } = useUsers()
  const [openPopper, setOpenPopper] = useState(false)
  const [cashDialog, setCashDialog] = useState(false)
  const mainRef = useRef(null)

  const [isValidPayment, setIsValidPayment] = useState(null)
  const { artistID, eventDate } = useMemo(() => {
    return {
      artistID: bookedData?.artist?._id,
      eventDate: bookedData?.eventDate?.start,
    }
  }, [bookedData])

  const handleValidatePaymentAction = useCallback(async () => {
    try {
      await apiInstance
        .post(`artist-block-date/block-date-check`, {
          artistId: artistID,
          eventDate: eventDate,
        })
        .then(({ data }) => {
          if (data?.success?.data?.isBlock === false) {
            setIsValidPayment(true)
            return
          }
          setIsValidPayment(false)
        })
        .catch(({ response }) => {
          console.log({
            response: response?.data?.error?.message,
          })
        })
    } catch (error) {
      throw error
    }
  }, [artistID, eventDate])

  useEffect(() => {
    if (isMounted() && eventDate) {
      handleValidatePaymentAction()
    }
  }, [isMounted, handleValidatePaymentAction, eventDate])

  // *payable amount with service charge
  const amountToPay = useMemo(() => {
    if (!bookedData?.bookingPrice) return 0
    const sum =
      calculateServiceCharge(bookedData?.bookingPrice, serviceCharge?.value) +
      bookedData?.bookingPrice
    return sum
  }, [bookedData?.bookingPrice, serviceCharge?.value])

  const makeDialogOpen = ()=>{
    setCashDialog(true)
  }
  const cashDialogClose = ()=>{
    setCashDialog(false)
  }

  return (
    <div className="box-shadow-1 h-100 border-10 w-100 p-1em">
      {/* timestamp */}
      <div className="w-100 text-left m-b-1em flex-row flex-justify-between">
        <Typography className="font-size-small text-primary-1 font-bold">
          {getTimeFromNow(bookedData?.timestamp)}
        </Typography>
        {lowerCaseText(bookedData?.eventStatus) !== "pending" &&
          isEventDoneAction && (
            <div className="text-center">
              {lowerCaseText(bookedData?.eventStatus) === "complete" ? (
                <CheckCircleOutlineOutlined
                  fontSize="small"
                  className="text-primary-2"
                />
              ) : (
                <CancelOutlined fontSize="small" className="text-primary-1" />
              )}
              <Typography className="font-size-small font-bold text-primary-6">
                {lowerCaseText(bookedData?.eventStatus) === "complete"
                  ? "Completed"
                  : "Not Completed"}
              </Typography>
            </div>
          )}
      </div>
      {/* details */}
      <div className="booking-tab-content-card flex-row bg-white w-100 flex-align-center bg-white">
        <div>
          <img
            src={
              bookedData?.artist?.profileImageUrl || USER_RECTANGLE_PLACEHOLDER
            }
            alt=""
            height={"100px"}
            width={"100px"}
            className="object-fit-cover border-10 box-shadow-1 booked-artist-img bg-whitesmoke"
            onError={(e) => {
              e.target.src = USER_RECTANGLE_PLACEHOLDER
            }}
          />
        </div>

        <div className="booked-artist-info m-0-0_5em border-5 text-ellipses w-100">
          {/* artist name */}
          <div className="booked-artist-name text-ellipses">
            <Typography
              variant="h6"
              className="text-primary-2 font-bold text-ellipses "
            >
              {`${bookedData?.artist?.firstName} ${bookedData?.artist?.lastName}`}
            </Typography>
          </div>
          {/* artist location */}
          {bookedData?.artist?.category?.title && (
            <div className="booked-artist-city flex-row flex-align-center">
              <Typography variant="body1">{"India"}</Typography>
              <Chip
                label={bookedData?.artist?.category?.title || "N/A"}
                className="m-0-0_5em font-bold font-size-small text-primary-3"
                variant="outlined"
              />
            </div>
          )}
          {/* booked for */}
          {bookedData?.eventType?.eventName && (
            <div className="booked-artist-reason w-100">
              <Typography
                variant="body2"
                className="text-ellipses"
                gutterBottom
              >
                Booked for
                <span className="font-size-1_1em font-bold m-0-0_5em">
                  {bookedData?.eventType?.eventName}
                </span>
              </Typography>
            </div>
          )}
          {bookedData?.serviceType?.serviceName && (
            <div className="booked-artist-city flex-row flex-align-center">
              <MiscellaneousServicesOutlined htmlColor="grey" />
              <Typography variant="body1" className="m-0-0_5em text-ellipses">
                {bookedData?.serviceType?.serviceName}
              </Typography>
            </div>
          )}
          {/* city */}
          {lowerCaseText(bookedData?.eventLocation) && (
            <div className="booked-artist-city flex-row flex-align-center">
              <LocationOnOutlined htmlColor="grey" />
              <Typography variant="body1" className="m-0-0_5em text-ellipses">
                {bookedData?.eventLocation}
              </Typography>
            </div>
          )}

          {/* date & time */}
          {bookedData?.eventDate?.start && (
            <div className="booked-artist-date flex-row flex-align-center">
              <CalendarTodayOutlined htmlColor="grey" />
              <Typography variant="body1" className="m-0-0_5em text-ellipses">
                {`${primaryDateFormat(bookedData?.eventDate?.start)}`}
              </Typography>
            </div>
          )}
          {bookedData?.eventTime && (
            <div className="booked-artist-date flex-row flex-align-center">
              <TimelapseOutlined htmlColor="grey" />
              <Typography variant="body1" className="m-0-0_5em text-ellipses">
                {`${primaryTimeFormat(bookedData?.eventTime)}`}
              </Typography>
            </div>
          )}
          {/* personalized message date */}
          {bookedData?.personalizedMsgDate && (
            <div className="booked-artist-date flex-row flex-align-center">
              <CalendarTodayOutlined htmlColor="grey" />
              <Typography variant="body1" className="m-0-0_5em text-ellipses">
                {`${primaryDateFormat(bookedData?.personalizedMsgDate)}`}
              </Typography>
            </div>
          )}
          {/* price */}
          {bookedData?.bookingPrice > 0 && (
            <>
              <div className="booked-artist-price flex-row flex-align-center">
                <MoneyOutlined htmlColor="grey" />
                <Typography variant="body1" className="m-0-0_5em text-ellipses">
                  {currencyWiseValue(amountToPay)}
                </Typography>
              </div>
              <Typography
                variant="caption"
                className="text-ellipses text-primary-7 font-bold"
              >
                {`Including ${serviceCharge?.value}% service charge`}
              </Typography>
            </>
          )}
        </div>
        {/* action menu */}
        <div className="flex-column flex-justify-start h-100">
          {!isNoAction &&
            (lowerCaseText(bookedData?.bookingType) === "other" || isPast) && (
              <CustomPopper mainRef={mainRef}>
                <IconButton
                  onClick={() => {
                    setOpenPopper(!openPopper)
                  }}
                >
                  <MoreVertOutlined />
                </IconButton>
                <CustomPopperBody
                  open={openPopper}
                  position="right"
                  setOpen={setOpenPopper}
                  mainRef={mainRef}
                >
                  <div className="booked-artist-action-popper-root border-5">
                    {!isConfirmed && (
                      <div
                        className="booked-artist-action-menu cursor-pointer flex-row flex-align-center"
                        role="button"
                        onClick={() => {
                          removePastBookings(bookedData)
                          setOpenPopper(false)
                        }}
                      >
                        <DeleteOutlined />
                        <Typography
                          variant="body2"
                          className="text-ellipses font-bold"
                        >
                          Remove
                        </Typography>
                      </div>
                    )}
                    {!isPast &&
                      lowerCaseText(bookedData?.bookingType) === "other" && (
                        <div
                          className="booked-artist-action-menu cursor-pointer flex-row flex-align-center"
                          role={"button"}
                          onClick={() => {
                            handleOpenCancelBookingDialog(bookedData)
                            setOpenPopper(false)
                          }}
                        >
                          <CancelOutlined />
                          <Typography
                            variant="body2"
                            className="text-ellipses font-bold"
                          >
                            Cancel
                          </Typography>
                        </div>
                      )}
                    {!isPast &&
                      lowerCaseText(bookedData?.bookingType) === "other" && (
                        <div
                          className="booked-artist-action-menu cursor-pointer flex-row flex-align-center"
                          role={"button"}
                          onClick={() => {
                            handleOpenRescheduleEventDialog(
                              bookedData?.bookingType,
                              bookedData?.artist,
                              bookedData
                            )
                            setOpenPopper(false)
                          }}
                        >
                          <ModeEditOutlined />
                          <Typography
                            variant="body2"
                            className="text-ellipses font-bold"
                          >
                            Reschedule
                          </Typography>
                        </div>
                      )}
                  </div>
                </CustomPopperBody>
              </CustomPopper>
            )}
        </div>
      </div>
      {/* pay now action */}
      {isPayNow && !serviceCharge?.isFetching && (
        <>
          <div className="w-100 text-center m-t-1em">
          <div className="booking-payment-button">
      
           
            {isValidPayment || bookedData?.personalizedMsgDate ? (
            <>
              <PayStack
                email={currentUser?.email}
                amount={+bookedData?.bookingPrice}
                handleSuccess={(reference) => {
                  handleSuccess(bookedData, reference)
                }}
              >
                <CustomButton
                  btnText={"Online Pay2"}
                  className="p-0-0_5em btn-gradient"
                  variant={"contained"}
                />
              </PayStack>
              <div className="cash-button">
              <CustomButton
              onClick={()=>{
           
                const paymentModeAdd = {
                  ...bookedData,
                  paymentMode:"cash"
                }
                handleSuccess(paymentModeAdd)

              }}
                  btnText={"Cash Pay"}
                  className="p-0-0_5em btn-gradient"
                  variant={"contained"}
                />
                </div>
            </>

             


            ) : (
        <>
              <CustomButton
                btnText={"Online Payr"}
                className="p-0-0_5em btn-gradient"
                variant={"contained"}
                onClick={() => {
                  handleOpenAlertForBlockDate()
                }}
              />

              <div className="cash-button">
              <CustomButton
                  btnText={"Cash Pay"}
                  className="p-0-0_5em btn-gradient"
                  variant={"contained"}
                  onClick={() => {
                    makeDialogOpen()
                  
                    // const paymentModeAdd = {
                    //   ...bookedData,
                    //   paymentMode:"cash"
                    // }
                    // handleSuccess(paymentModeAdd)
      
                    // handleOpenAlertForBlockDate()
                  }}
                />
                </div>
                </>
            
            )}
            </div>
          </div>
        </>
      )}
      {isEventDoneAction && (
        <>
          {lowerCaseText(bookedData?.eventStatus) === "pending" && (
            <div className="w-100 text-center m-t-1em">
              <span className="font-bold font-size-small text-primary-3">
                Please confirm the event completion within 14 days.
              </span>
              <div className="w-100 text-center m-0_5em-0">
                <CustomButton
                  btnText={"Completed"}
                  className="font-size-small bg-primary-2 text-white"
                  size={"small"}
                  variant={"contained"}
                  disableElevation
                  onClick={() => {
                    handleChoseEventDone()
                    handleConfirmEventDone(bookedData?._id, true)
                  }}
                />
                <CustomButton
                  btnText={"Not Completed"}
                  className="font-size-small m-0-0_5em bg-primary-1 text-white"
                  size={"small"}
                  variant={"contained"}
                  disableElevation
                  onClick={() => {
                    handleChoseEventDone()
                    handleConfirmEventDone(bookedData?._id, false)
                  }}
                />
              </div>
            </div>
          )}
        </>
      )}


<Dialog
        open={cashDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={cashDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Cash Payment"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          Pay Later options are currently available for South Africa and Zimbabwe. Your booking will only be confirmed on receipt of payment. If you do not pay for the defined before the defined date and time stipulated on your booking confirmation, this booking will be cancelled, and you will need to rebook.

In South Africa: Pay for your booking at SearchingYard Software Pty Ltd office located at 209B Office & Co. Building, 134 River Road, Kyalami, Johannesburg, 1684. Payments will need to be made in the same currency that the booking has been made in.

In Zimbabwe: Pay for your booking at Calmlock Digital Marketing office located at Suite 4 at 1 Union Ave Building, 1 Nkwame Nkrumah Avenue, Harare. Payments will need to be made in the same currency that the booking has been made in and will only be accepted for USD or ZAR bookings.

Our standard Service Fee will be automatically applied for using our Pay Later service. No other booking or payment fees will be charged at any of our ticketing sales offices or payment points.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            className={`font-bold text-transform-none ${
              "btn-cancel"
             }`}
          onClick={cashDialogClose}>Cancel</Button>
          <Button 
           className={`font-bold text-transform-none ${
           "btn-gradient"
          }`}
          onClick={()=>{
            cashDialogClose()
            const paymentModeAdd = {
              ...bookedData,
              paymentMode:"cash"
            }
            handleSuccess(paymentModeAdd)

          }}>Make Cash Payment</Button>
        
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default MyBookingCard
