import { Container, Grid, Typography } from "@mui/material";
import { APPLE_APP, APP_SCREENSHOT_IMG, GOOGLE_APP } from "assets";

const DownloadApp = () => {
  return (
    <div className="download-app-root bg-whitesmoke">
      <Container>
        <Grid container className="w-100">
          <Grid item xs={12} sm={6} md={4}>
            <img
              src={APP_SCREENSHOT_IMG}
              alt=""
              className="object-fit-cover w-100 h-100"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <div className="position-center h-100 w-100 flex-column">
              <Typography
                variant="h5"
                className="text-center font-bold text-primary-2"
                gutterBottom
              >
                TRY THE SKYRISE CELEBRITY APP⁣
              </Typography>
              <Typography className="body1 font-bold text-primary-3 text-center">
                Discover and book musicians, DJs, magicians, comedians, dancers
                & more.⁣
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <div className="position-center h-100 w-100 flex-column flex-align-center m-1-0">
              <img
                src={GOOGLE_APP}
                alt=""
                width="150px"
                className="cursor-pointer"
                onClick={() => {
                  window.open(
                    `https://play.google.com/store/apps/details?id=com.skyrisecelebrity`,
                    "_blank"
                  );
                }}
              />
              <img
                src={APPLE_APP}
                alt=""
                width="150px"
                className="m-0_5em-0 cursor-pointer"
                onClick={() => {
                  window.open(
                    `https://apps.apple.com/us/app/skyrise-celebrity/id1610064452`,
                    "_blank"
                  );
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default DownloadApp;
