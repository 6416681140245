import { Typography } from "@mui/material"
import { convertBigNumber } from "helpers"

const ArtistDashboardCard = ({ index, data, onClick }) => {
  return (
    <div
      className={`artist-dashboard-card h-100 p-1em border-5 cursor-pointer ${
        index % 2 === 0
          ? "artist-dashboard-card-even"
          : "artist-dashboard-card-odd"
      }`}
      onClick={onClick}
    >
      <div className="artist-dashboard-card-icon text-center">
        <div className="font-size-1em">{data.icon}</div>
        <Typography variant="body1" className="text-white">
          {data?.title}
        </Typography>
      </div>
      <div className="artist-dashboard-card-content text-center">
        <Typography variant="h3" className="font-bold text-white">
          {convertBigNumber(data?.value) || 0}
        </Typography>
      </div>
    </div>
  )
}

export default ArtistDashboardCard
