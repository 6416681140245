import {
  Container,
  //  Divider,
  Typography,
} from "@mui/material"
// import { useNavigate } from "react-router-dom";
const BottomFooter = () => {
  // const navigate = useNavigate();
  return (
    <div className="copy-right-section p-1em-0">
      <Container>
        <div className="copy-right-text flex-row flex-align-center w-100 flex-justify-center">
          <Typography className="text-white font-size-1em" variant="body2">
            © {`${new Date().getFullYear()}`} Skyrise. All rights reserved.
          </Typography>
          <div className="privacy-policy flex-row">
            {/* <Button
              disableElevation
              variant="text"
              className="text-white text-transform-none bg-transparent p-0-0_5em font-size-1em footer-term-condition-btn"
              disableFocusRipple
              disableRipple
              onClick={() => {
                navigate("/terms-and-conditions")
              }}
            >
              Term & Conditions
            </Button>
            <Divider orientation="vertical" flexItem className="bg-white" />
            <Button
              disableElevation
              variant="text"
              className="text-white text-transform-none bg-transparent p-0-0_5em font-size-1em footer-privacy-policy-btn"
              disableFocusRipple
              disableRipple
              onClick={() => {
                navigate("/privacy-policy")
              }}
            >
              Privacy Policy
            </Button> */}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default BottomFooter
