import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { countries } from "constant";
export default function PhoneNumberField({
  setCountryCode,
  setPhoneNumber,
  values,
  setFieldTouched,
  handleChange,
  sendOtpFn,
}) {
  const [sendDisable, setSendDisable] = useState(false);
  const [count, setCount] = useState({
    minutes: 0,
    second: 0,
  });
  const [resendOtp, setResendOtp] = useState(false);

  useEffect(() => {
    if (!sendDisable) return;
    const interval =
      count.minutes < 2 &&
      setInterval(() => {
        setCount((prev) => {
          if (prev.second < 59)
            return {
              second: prev.second + 1,
              minutes: prev.minutes,
            };
          return {
            second: 0,
            minutes: prev.minutes + 1,
          };
        });
      }, 1500);
    if (count.minutes > 1) {
      setResendOtp(true);
      setSendDisable(false);
    }

    return () => clearInterval(interval);
  }, [sendDisable, count.minutes]);

  return (
    <TextField
      classes={{
        root: "registration-text-field-input border-25",
      }}
      //   label={"Phone Number"}
      placeholder="Phone Number"
      margin="normal"
      variant="outlined"
      fullWidth
      name="phoneNumber"
      onChange={(e) => {
        setPhoneNumber(e.target.value);
        setFieldTouched("phoneNumber", true, false);
        handleChange(e);
      }}
      value={values.phoneNumber}
      required
      InputProps={{
        classes: {
          notchedOutline: "border-none",
          input: "border-25",
        },
        startAdornment: (
          <div>
            <FormControl>
              <div>
                <Select
                  onChange={(e) => {
                    setCountryCode(e.target.value);
                    setFieldTouched("countryCode", true, false);
                    handleChange(e);
                  }}
                  value={values.countryCode}
                  // value={"244"}
                  //   error={Boolean(props.meta.touched && props.meta.error)}

                  defaultValue={values.countryCode}
                  autoWidth
                  variant="standard"
                  disableUnderline
                  name={"countryCode"}
                >
                  {console.log({ countries })}
                  {countries?.map((option) => (
                    <MenuItem key={option?.phone} value={option?.phone}>
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option?.code?.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option?.code?.toLowerCase()}.png 2x`}
                        alt=""
                        style={{ margin: "0 1vw" }}
                      />
                      {option?.code}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {/* {props.meta.touched && props.meta.error} */}
                </FormHelperText>
              </div>
            </FormControl>
          </div>
        ),
        endAdornment: (
          <>
            {sendDisable ? (
              <p className="otp-count-down">{`${count.minutes}:${count.second}s`}</p>
            ) : (
              <p
                onClick={() => {
                  if (sendDisable) return;
                  if (resendOtp) {
                    setCount({
                      minutes: 0,
                      second: 0,
                    });
                  }
                  setResendOtp(false);
                  setSendDisable(true);
                  sendOtpFn();
                }}
                className={
                  values.phoneNumber
                    ? `otp-send-button-active`
                    : `otp-send-button-disable`
                }
              >
                {resendOtp
                  ? values.phoneNumber
                    ? "Resend OTP"
                    : "Send OTP"
                  : "Send OTP"}
              </p>
            )}
          </>
        ),
      }}
    />
  );
}
