import produce from "immer"

const authInitialState = {
  isLoading: true,
  isLoggedIn: false,
  currentUser: {},
  userRole: "",
  error: null,
  authToken: "",
  isUserBlocked: 0,
}

const AuthReducer = (state = authInitialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "SET_AUTH_LOADING":
        draft.isLoading = true
        break
      case "SET_CURRENT_USER":
        draft.isLoggedIn = action.payload.isLoggedIn
        draft.currentUser = action.payload.currentUser
        draft.isUserBlocked = action.payload.isUserBlocked
        break
      case "SET_AUTH_TOKEN":
        draft.authToken = action.payload
        break
      case "SET_USER_ROLE":
        draft.userRole = action.payload
        draft.isLoading = false
        draft.error = null
        break
      case "SET_AUTH_ERROR":
        draft.error = action.payload
        draft.isLoading = false
        draft.currentUser = {}
        draft.userRole = ""
        break
      case "LOGOUT_USER":
        return authInitialState
      default:
        return draft
    }
  })
}

export default AuthReducer
