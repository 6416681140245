import { useSelector, useDispatch } from "react-redux"
import { useCallback, useEffect } from "react"
import { useIsMounted } from "hooks"
import {
  addArtistPhotosAction,
  addArtistPhotosResetAction,
  addArtistPhotosSuccessSelector,
  addArtistVideosAction,
  addArtistVideosErrorSelector,
  addArtistVideosResetAction,
  addArtistVideosSuccessSelector,
  artistMediasStateSelector,
  artistPhotosSelector,
  artistPhotosStateSelector,
  artistVideosSelector,
  artistVideosStateSelector,
  fetchArtistPhotos,
  fetchArtistVideos,
  isRemoveArtistPhotosSuccessSelector,
  loadingArtistPhotosSelector,
  loadingArtistVideosSelector,
  removeArtistPhotosAction,
  removeArtistPhotosReset,
  removeArtistVideos,
  removeArtistVideosErrorSelector,
  removeArtistVideosInProgressSelector,
  removeArtistVideosReset,
  removeArtistVideosSuccessSelector,
} from "redux/actions"

const useArtistMedias = (artistID) => {
  const isMounted = useIsMounted()
  const dispatch = useDispatch()
  const artistMediasState = useSelector((state) =>
    artistMediasStateSelector(state)
  )
  //   artist videos
  const artistVideosState = useSelector((state) =>
    artistVideosStateSelector(state)
  )
  const artistVideos = useSelector((state) => artistVideosSelector(state))
  const isLoadingArtistVideos = useSelector((state) =>
    loadingArtistVideosSelector(state)
  )

  //   artist photos
  const artistPhotosState = useSelector((state) =>
    artistPhotosStateSelector(state)
  )
  const artistPhotos = useSelector((state) => artistPhotosSelector(state))
  const isLoadingArtistPhotos = useSelector((state) =>
    loadingArtistPhotosSelector(state)
  )
  const isRemoveArtistPhotosSuccess = useSelector((state) =>
    isRemoveArtistPhotosSuccessSelector(state)
  )
  const isAddArtistPhotosSuccess = useSelector((state) =>
    addArtistPhotosSuccessSelector(state)
  )

  //   fetch artist photos
  const handleFetchArtistPhotos = useCallback(
    (aID) => {
      dispatch(fetchArtistPhotos(aID))
    },
    [dispatch]
  )

  // add photos
  const handleAddArtistPhotos = useCallback(
    (photos) => {
      dispatch(addArtistPhotosAction(artistID, photos))
    },
    [dispatch, artistID]
  )
  const handleAddArtistPhotosReset = useCallback(() => {
    dispatch(addArtistPhotosResetAction())
  }, [dispatch])
  // remove photos
  const removeArtistPhotos = useCallback(
    (photoIDs) => {
      dispatch(removeArtistPhotosAction(artistID, photoIDs))
    },
    [dispatch, artistID]
  )
  const handleRemoveArtistPhotosReset = useCallback(() => {
    dispatch(removeArtistPhotosReset())
  }, [dispatch])

  // add videos
  const isAddArtistVideosSuccess = useSelector((state) =>
    addArtistVideosSuccessSelector(state)
  )
  const isAddArtistVideosError = useSelector((state) =>
    addArtistVideosErrorSelector(state)
  )
  const handleAddArtistVideosReset = useCallback(() => {
    dispatch(addArtistVideosResetAction())
  }, [dispatch])
  const handleAddArtistVideos = useCallback(
    (videos, links, thumbnails) => {
      dispatch(addArtistVideosAction(artistID, videos, links, thumbnails))
    },
    [dispatch, artistID]
  )

  // remove videos
  const isRemovingArtistVideos = useSelector((state) =>
    removeArtistVideosInProgressSelector(state)
  )
  const isRemoveArtistVideosSuccess = useSelector((state) =>
    removeArtistVideosSuccessSelector(state)
  )
  const isRemoveArtistVideosError = useSelector((state) =>
    removeArtistVideosErrorSelector(state)
  )
  const handleRemoveArtistVideosReset = useCallback(() => {
    dispatch(removeArtistVideosReset())
  }, [dispatch])
  const removeVideosCallback = useCallback(
    (videosIDs) => {
      if (artistID && videosIDs?.length > 0) {
        dispatch(removeArtistVideos(artistID, videosIDs))
      }
    },
    [artistID, dispatch]
  )
  //   fetch artist videos
  useEffect(() => {
    if (isMounted() && artistID) {
      dispatch(fetchArtistVideos(artistID))
    }
  }, [
    dispatch,
    artistID,
    isMounted,
    isRemoveArtistVideosSuccess,
    isRemoveArtistVideosError,
    isAddArtistVideosSuccess,
    isAddArtistVideosError,
  ])

  return {
    artistMediasState,
    //  artist videos
    artistVideosState,
    artistVideos,
    isLoadingArtistVideos,
    removeVideosCallback,
    isRemovingArtistVideos,
    isRemoveArtistVideosSuccess,
    isRemoveArtistVideosError,
    handleRemoveArtistVideosReset,
    handleAddArtistVideos,
    handleAddArtistVideosReset,
    isAddArtistVideosSuccess,
    isAddArtistVideosError,
    // artist photos
    artistPhotosState,
    artistPhotos,
    isLoadingArtistPhotos,
    removeArtistPhotos,
    isRemoveArtistPhotosSuccess,
    handleRemoveArtistPhotosReset,
    handleFetchArtistPhotos,
    handleAddArtistPhotos,
    isAddArtistPhotosSuccess,
    handleAddArtistPhotosReset,
  }
}

export default useArtistMedias
