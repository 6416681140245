import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addPricingErrMsgSelector,
  addPricingList,
  artistPricingListSelector,
  artistPricingListsLoadingSelector,
  artistPricingListsSelector,
  fetchArtistPricingLists,
  getArtistPricingState,
  isPricingListAddedSelector,
  isPricingListRemovedSelector,
  removePricingListAction,
  resetPricingListAction,
  resetPricingStateAction,
  setPricingListAction,
  sortedArtistPricingListsSelector,
  updatePricingListAction,
} from "redux/actions";
// import { resetPricing } from "redux/actions/ArtistPricingAction";
import useIsMounted from "./useIsMounted";

const usePricing = (artistID) => {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const artistPricingListsState = useSelector((state) =>
    getArtistPricingState(state)
  );
  const artistPricingLists = useSelector((state) =>
    artistPricingListsSelector(state)
  );
  const sortedArtistPricingList = useSelector((state) =>
    sortedArtistPricingListsSelector(state)
  );
  const isLoadingPricingLists = useSelector((state) =>
    artistPricingListsLoadingSelector(state)
  );
  const isPricingListAdded = useSelector((state) =>
    isPricingListAddedSelector(state)
  );
  const isPricingListRemoved = useSelector((state) =>
    isPricingListRemovedSelector(state)
  );

  const handleFetchedArtistPricingLists = useCallback(
    (aID) => {
      dispatch(fetchArtistPricingLists(aID));
    },
    [dispatch]
  );
  useEffect(() => {
    if (isMounted()) {
      handleFetchedArtistPricingLists(artistID);
    }
  }, [artistID, handleFetchedArtistPricingLists, isMounted]);

  // add pricing list
  const addPricingErrMsg = useSelector((state) =>
    addPricingErrMsgSelector(state)
  );
  const handleAddPricingList = useCallback(
    (pricingList) => {
      dispatch(addPricingList(artistID, pricingList));
    },
    [dispatch, artistID]
  );
  // remove pricing list
  const handleRemovePricingList = useCallback(
    (pricingListID) => {
      dispatch(removePricingListAction(artistID, pricingListID));
    },
    [dispatch, artistID]
  );

  // update pricing
  const handleUpdatePricingList = useCallback(
    (pricingID, values) => {
      dispatch(updatePricingListAction(artistID, pricingID, values));
    },
    [dispatch, artistID]
  );

  // set & reset pricing list
  const artistPricingList = useSelector((state) =>
    artistPricingListSelector(state)
  );
  const handleSetPricingList = useCallback(
    (pricingList) => {
      dispatch(setPricingListAction(artistID, pricingList));
    },
    [dispatch, artistID]
  );

  const handleResetPricingList = useCallback(() => {
    dispatch(resetPricingListAction());
  }, [dispatch]);

  // reset whole pricing state
  const handleResetPricingState = useCallback(() => {
    dispatch(resetPricingStateAction());
  }, [dispatch]);

  return {
    artistPricingListsState,
    handleResetPricingState,
    artistPricingLists,
    isLoadingPricingLists,
    sortedArtistPricingList,
    // add pricing list
    handleAddPricingList,
    isPricingListAdded,
    handleFetchedArtistPricingLists,
    addPricingErrMsg,
    // remove pricing list
    handleRemovePricingList,
    isPricingListRemoved,
    // set & reset pricing list
    artistPricingList,
    handleSetPricingList,
    handleResetPricingList,
    // update
    handleUpdatePricingList,
  };
};

export default usePricing;
