import { Typography } from "@mui/material"

const EventCard = ({ data }) => {
  return (
    <div
      className="event-type-card object-fit-cover border-5 position-relative"
      style={{
        backgroundImage: `url(${data?.imageUrl})`,
      }}
    >
      <div className="event-card-overlay border-5">
        <div className="event-type w-100 position-absolute text-center ">
          <Typography
            variant="h6"
            className="text-white text-ellipses p-0-1em text-white"
          >
            {data?.eventName}
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default EventCard
