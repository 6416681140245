import {
  Typography,
  TextField,
  InputAdornment,
  Button,
  IconButton,
} from "@mui/material"
import { EmailOutlined, CloseOutlined } from "@mui/icons-material"
import { useEffect, useState } from "react"
// import from other files
import { FORGOT_PWD_LOGO } from "assets"
import { useApi, useIsMounted, useSnackbar } from "hooks"
import { CustomSnackbar } from "components"

const ForgotPassword = ({ setForgotPwdContentOpen }) => {
  const [email, setEmail] = useState("")
  const { handleCloseSnackbar, handleOpenSnackbar, snackbar } = useSnackbar()
  const {
    postData,
    isPosting,
    isPosted,
    isPostErr,
    handleResetPostApi,
    postStatus,
  } = useApi()
  const isMounted = useIsMounted()

  const handleSendResetLink = (givenMail) => {
    if (email) {
      postData(
        `auth/set-password/forgetPassword`,
        {
          email: givenMail,
          baseUrl: window.location.origin,
        },
        "PUT"
      )
      return
    }
  }

  // after posted successfully
  useEffect(() => {
    if (isMounted() && isPosted) {
      handleOpenSnackbar({
        message: postStatus?.message,
        variant: "success",
      })
    }
  }, [handleOpenSnackbar, isMounted, isPosted, postStatus])
  // after posting failed
  useEffect(() => {
    if (isMounted() && isPostErr) {
      handleOpenSnackbar({
        message: postStatus?.message,
        variant: "error",
      })
    }
  }, [isMounted, isPostErr, handleOpenSnackbar, postStatus])

  return (
    <div className="forgot-pwd-root h-100 bg-white p-1em w-100">
      <CustomSnackbar
        open={snackbar?.open}
        handleClose={() => {
          handleCloseSnackbar()
        }}
        variant={snackbar?.variant}
        message={snackbar?.message}
        autoHideDuration={3000}
        resetFunction={handleResetPostApi}
      />
      <div className="forgot-pwd-back-btn">
        <IconButton
          onClick={() => {
            setForgotPwdContentOpen(false)
          }}
        >
          <CloseOutlined />
        </IconButton>
      </div>
      <div className="flex-column flex-justify-center flex-align-center">
        <div className="forgot-pwd-header w-100 text-center">
          <img
            src={FORGOT_PWD_LOGO}
            alt=""
            height={"200px"}
            className="object-fit-cover"
          />
        </div>
        <div className="forgot-pwd-content">
          <Typography
            variant="h4"
            className="forgot-pwd-content-title font-bold text-primary-2"
          >
            Forgot Password?
          </Typography>
          <Typography
            variant="body2"
            className="font-bold text-primary-3"
            gutterBottom
          >
            Don't worry, we'll send you an email with instructions to reset your
            password.
          </Typography>
          <TextField
            placeholder="Email ID"
            fullWidth
            variant="outlined"
            className="registration-text-field-input border-5"
            margin="normal"
            InputProps={{
              classes: {
                notchedOutline: "border-none",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlined />
                </InputAdornment>
              ),
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            className={`text-transform-none font-bold text-white m-1-0 ${
              isPosting || !email ? "disabled-btn" : "btn-gradient"
            }`}
            fullWidth
            size="large"
            onClick={() => handleSendResetLink(email)}
            disabled={!email || isPosting}
          >
            Reset Password
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
