import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { CustomCarousel } from "components/core";
import { CommonHeader } from ".";
import { useCategories } from "hooks";

const PopularCategories = () => {
  const navigate = useNavigate();
  const { categories } = useCategories();

  return (
    <div>
      {categories?.length > 0 && (
        <>
          <CommonHeader
            title="Popular Categories"
            subtitle="Chose from the most popular categories"
            isShowBtn={false}
          />
          <CustomCarousel
            columnGap={"1em"}
            id="popular-categories"
            hideArrow={categories?.length < 5}
          >
            {categories?.map((category, ind) => (
              <div
                className="category cursor-pointer"
                key={category?._id}
                onClick={() => {
                  navigate(`/artists/${category?._id}`);
                }}
              >
                <img
                  src={category?.imageUrl}
                  alt=""
                  width={"200px"}
                  className="object-fit-cover border-circle card-circle-img-size-200px box-shadow-normal"
                />
                <Typography variant="body1" color="initial">
                  {category?.title}
                </Typography>
              </div>
            ))}
          </CustomCarousel>
        </>
      )}
    </div>
  );
};

export default PopularCategories;
