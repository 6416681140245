import produce from "immer";

const actionState = {
  isDone: false,
  isErr: false,
  successMsg: null,
  errorMsg: null,
};

const artistPricingInitialState = {
  crowdSize: "",
  serviceType: "",
  otherCrowdSize: "",
  location: "",
  otherLocation: "",
  day: "",
  otherDay: "",
  pricePerHour: "",
  latLng: {
    lat: "",
    lng: "",
  },
  pricingLists: {
    data: [],
    isLoading: true,
    error: null,
  },
  addPricingList: actionState,
  removePricing: actionState,
  pricingList: {},
};

const ArtistPricingReducer = (state = artistPricingInitialState, action) => {
  return produce(state, (draft) => {
    const { pricingLists, addPricingList, latLng, removePricing } = draft;
    switch (action.type) {
      case "SET_CROWD_SIZE":
        draft.crowdSize = action.payload;
        break;
      case "SET_OTHER_CROWD_SIZE":
        draft.otherCrowdSize = action.payload;
        break;
      case "SET_LOCATION":
        draft.location = action.payload;
        break;
      case "SET_OTHER_LOCATION":
        draft.otherLocation = action.payload;
        break;
      case "SET_LAT_LNG":
        latLng.lat = action.payload.lat;
        latLng.lng = action.payload.lng;
        break;
      case "SET_DAY":
        draft.day = action.payload;
        break;
      case "SET_OTHER_DAY":
        draft.otherDay = action.payload;
        break;
      case "SET_PRICE":
        draft.pricePerHour = action.payload;
        break;
      case "SET_SERVICE_TYPE":
        draft.serviceType = action.payload;
        break;

     
      case "RESET_ARTIST_PRICING":
        draft.crowdSize = "";
        draft.serviceType = "";
        draft.otherCrowdSize = "";
        draft.location = "";
        draft.otherLocation = "";
        draft.day = "";
        draft.otherDay = "";
        draft.pricePerHour = "";
        draft.latLng = {
          lat: "",
          lng: "",
        };
        break;
      // pricing lists set by the artist
      case "ARTIST_PRICING_LISTS_LOADING":
        pricingLists.isLoading = true;
        pricingLists.error = null;
        break;
      case "ARTIST_PRICING_LISTS_SUCCESS":
        pricingLists.data = action.payload;
        pricingLists.isLoading = false;
        pricingLists.error = null;
        break;
      case "ARTIST_PRICING_LISTS_ERROR":
        pricingLists.isLoading = false;
        pricingLists.error = action.payload;
        break;
      // add pricing list
      case "ADD_PRICING_LIST_SUCCESS":
        addPricingList.isDone = true;
        addPricingList.isErr = false;
        addPricingList.successMsg = action.payload;
        break;
      case "ADD_PRICING_LIST_ERROR":
        addPricingList.isDone = false;
        addPricingList.isErr = true;
        addPricingList.errorMsg = action.payload;
        break;
      case "ADD_PRICING_LIST_RESET":
        addPricingList.isDone = false;
        addPricingList.isErr = false;
        addPricingList.successMsg = null;
        addPricingList.errorMsg = null;
        break;
      // remove pricing list
      case "REMOVE_PRICING_LIST_SUCCESS":
        removePricing.isDone = true;
        removePricing.isErr = false;
        removePricing.successMsg = action.payload;
        break;
      case "REMOVE_PRICING_LIST_ERROR":
        removePricing.isDone = false;
        removePricing.isErr = true;
        removePricing.errorMsg = action.payload;
        break;
      case "REMOVE_PRICING_LIST_RESET":
        removePricing.isDone = false;
        removePricing.isErr = false;
        removePricing.successMsg = null;
        removePricing.errorMsg = null;
        break;
      // set & reset pricing
      case "SET_PRICING_LIST":
      
        draft.pricingList = action.payload;
        break;
      case "RESET_PRICING_LIST":
        draft.pricingList = {};
        break;
      default:
        return state;
    }
  });
};

export default ArtistPricingReducer;
