import { Container, Grid, Typography } from "@mui/material"
import { WHITE_IMG_BG_TRANSPARENT } from "assets"
import { useCategories, useEventTypes, useWindow } from "hooks"
import { useNavigate } from "react-router-dom"
import BottomFooter from "./BottomFooter"

const BaseFooter = () => {
  const navigate = useNavigate()
  const isSmallScreen = useWindow().windowData.width < 600
  const { allEventTypes } = useEventTypes()
  const { categories } = useCategories()
  const footersMenus = [
    {
      title: "Home",
      handleClick: () => navigate(`/`),
      menus: [
        {
          menuTitle: "Why Skyrise",
          menuLink: "/why-skyrise-celebrity",
        },
        {
          menuTitle: "Contact Us",
          menuLink: "/contact",
        },
        {
          menuTitle: "About Us",
          menuLink: "/about",
        },
        {
          menuTitle: "FAQ",
          menuLink: "/faq",
        },
      ],
    },
    {
      title: "Events",
      handleClick: () => navigate(`/events`),
      menus: allEventTypes?.slice(0, 4).map((ev) => {
        return {
          menuTitle: ev?.eventName,
          menuLink: `/artists/all`,
        }
      }),
    },
    {
      title: "Live Entertainers",
      handleClick: () => navigate(`/artists/all`),
      menus: categories?.slice(0, 4).map((cat) => {
        return {
          menuTitle: cat?.title,
          menuLink: `/artists/${cat?.title}`,
        }
      }),
    },
  ]

  return (
    <>
      <div className="base-footer-root min-h-100px bg-black p-t-2em">
        <Container>
          <div className="base-footer-container flex-row flex-justify-between flex-align-center flex-wrap row-gap-1em p-1em-0">
            <Grid container spacing={1}>
              {/* logo */}
              <Grid item xs={12} sm={6} lg={3} justifyContent={"flex-start"}>
                <img
                  src={WHITE_IMG_BG_TRANSPARENT}
                  alt=""
                  width={"100%"}
                  className="text-left footer-logo-img"
                />
              </Grid>
              {/* footer menus */}
              <Grid item xs={12} sm={8} lg={6}>
                <Grid container spacing={2}>
                  <div className="footer-menu-items flex-row flex-align-start w-100 ">
                    {footersMenus?.map((footerMenu, index) => (
                      <Grid item xs={12} sm={6} lg={4} key={index}>
                        <div className="footer-menu-item">
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              footerMenu.handleClick()
                            }}
                          >
                            <Typography
                              className="font-size-1_2em font-bold text-primary-5 footer-menu-title"
                              gutterBottom
                            >
                              {footerMenu?.title}
                            </Typography>
                          </div>
                          <div
                            className={`footer-menu-root ${
                              isSmallScreen
                                ? "flex-row flex-align-center flex-wrap col-gap-0_5em"
                                : ""
                            }`}
                          >
                            {footerMenu?.menus?.map((menu, menu_index) => (
                              <div
                                key={menu_index}
                                className={`w-fit cursor-pointer ${
                                  isSmallScreen
                                    ? "footer-menu-small text-primary-6"
                                    : "footer-menu text-primary-6"
                                }`}
                                role={"button"}
                                onClick={() => {
                                  navigate(menu.menuLink)
                                }}
                              >
                                <Typography
                                  className="font-bold"
                                  variant="body2"
                                  gutterBottom
                                >
                                  {menu?.menuTitle}
                                </Typography>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Grid>
                    ))}
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4} lg={3}>
                {/* contact details box */}
                <div className="footer-contact-root flex-column ">
                  <div className="footer-contact-details p-1em w-fit text-left overflow-hidden">
                    <Typography className="text-white">Reach us at:</Typography>
                    <a
                      href="mailto:help@skyrisecelebrity.com"
                      className="text-decoration-none"
                    >
                      <Typography
                        className="text-primary-3 font-bold text-decoration-none"
                        variant="body2"
                      >
                        help@skyrisecelebrity.com
                      </Typography>
                    </a>
                  </div>
                  {/* <div className="footer-social-media p-1em w-fit text-right flex-row flex-align-center col-gap-0_5em">
                    <IconButton className="social-btn">
                      <Facebook />
                    </IconButton>
                    <IconButton className="social-btn">
                      <Instagram />
                    </IconButton>
                    <IconButton className="social-btn">
                      <LinkedIn />
                    </IconButton>
                    <IconButton className="social-btn">
                      <Twitter />
                    </IconButton>
                  </div> */}
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
        {/* copy-right section */}
        <BottomFooter />
      </div>
    </>
  )
}

export default BaseFooter
