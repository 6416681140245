import { CategoryOutlined, CloseOutlined } from "@mui/icons-material"
import {
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material"
import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"
// import from other files
import { useApi, useCategories, useIsMounted, useSnackbar } from "hooks"
import {
  setArtistCategory,
  setArtistGenresAction,
  setArtistSubCategory,
} from "redux/actions"
import { ManageProfileSaveBtn } from "."
import { CustomSnackbar, Loader } from "components/core"

const ArtistManageCategory = ({
  artistProfileState,
  currentUser,
  currentUserID,
}) => {
  const { postData, isPosting, isPosted, isPostErr, handleResetPostApi } =
    useApi()
  const { snackbar, handleOpenSnackbar, handleCloseSnackbar } = useSnackbar()
  const isMounted = useIsMounted()
  const dispatch = useDispatch()
  const {
    categories,
    loadingCategories,
    fetchSubcategoriesByCategory,
    subcategoriesByCategoryID,
    fetchGenresData,
    genresBySubcategoryID,
  } = useCategories()
  const [isOpenWarn, setIsOpenWarn] = useState(false)

  // fetch subcategories by category id
  useEffect(() => {
    if (artistProfileState?.selectCategory && isMounted()) {
      fetchSubcategoriesByCategory(artistProfileState.selectCategory)
    }
  }, [
    isMounted,
    artistProfileState?.selectCategory,
    fetchSubcategoriesByCategory,
  ])
  // fetch genres by subcategory id
  useEffect(() => {
    if (artistProfileState?.selectSubCategory?.length > 0 && isMounted()) {
      fetchGenresData(artistProfileState?.selectSubCategory)
    }
  }, [isMounted, artistProfileState?.selectSubCategory, fetchGenresData])

  // category fetch
  useEffect(() => {
    if (isMounted() && currentUser?.category?._id) {
      dispatch(setArtistCategory(currentUser?.category?._id))
    }
  }, [dispatch, currentUser, isMounted])
  // subcategory fetch
  useEffect(() => {
    if (isMounted() && currentUser?.subcategories?.length > 0) {
      const res = currentUser?.subcategories?.map((item) => item._id)
      dispatch(setArtistSubCategory(res))
    }
  }, [dispatch, currentUser, isMounted])
  // genres fetch
  useEffect(() => {
    if (isMounted() && currentUser?.genres?.length > 0) {
      const res = currentUser?.genres?.map((item) => item?._id)
      dispatch(setArtistGenresAction(res))
    }
  }, [dispatch, currentUser, isMounted])

  // handle submit details
  const handleSubmitCategoryDetails = () => {
    if (!artistProfileState?.selectCategory) {
      setIsOpenWarn(true)
      return
    }
    setIsOpenWarn(false)
    postData(
      `user/account-update-category`,
      {
        userId: currentUserID,
        categoryId: artistProfileState?.selectCategory,
        subcategories: artistProfileState?.selectSubCategory,
        genres: artistProfileState?.selectGenres,
      },
      "PATCH"
    )
  }

  useEffect(() => {
    if (isMounted()) {
      if (isPosted) {
        handleOpenSnackbar({
          message: "Category details updated successfully",
          variant: "success",
        })
        return
      }
      if (isPostErr) {
        handleOpenSnackbar({
          message: "Something went wrong",
          variant: "error",
        })
        return
      }
    }
  }, [isMounted, isPosted, isPostErr, handleOpenSnackbar])

  return (
    <div className="artist-manage-category-root">
      <CustomSnackbar
        open={snackbar.open}
        handleClose={() => {
          handleCloseSnackbar()
        }}
        message={snackbar.message}
        variant={snackbar.variant}
        autoHideDuration={3000}
        resetFunction={handleResetPostApi}
      />
      {/* manage category */}
      <div className="artist-manage-category">
        <div className="artist-manage-category-title">
          <Typography variant="h6" className="text-primary-8">
            Select Categories
          </Typography>
          {isOpenWarn && (
            <div
              className={`category-warn flex-row flex-align-center flex-justify-between p-0_5em-1em bg-primary-1 border-5 ${
                !isOpenWarn ? "inactive" : "active"
              }`}
            >
              <Typography variant="body2" className="text-white">
                Select Categories
              </Typography>
              <IconButton onClick={() => setIsOpenWarn(false)}>
                <CloseOutlined fontSize="small" />
              </IconButton>
            </div>
          )}
        </div>
        <div className="artist-manage-category-body m-0_5em-0">
          {!loadingCategories && (
            <Grid container spacing={2}>
              {categories?.map((category) => (
                <Grid item xs={12} sm={6} lg={4} key={category?._id}>
                  <div
                    className="manage-category-card p-1em position-center flex-column cursor-pointer border-5 w-100"
                    role={"button"}
                    onClick={() => {
                      if (artistProfileState.selectCategory === category?._id) {
                        dispatch(setArtistCategory(""))
                        return
                      }
                      dispatch(setArtistCategory(category?._id))
                      dispatch(setArtistSubCategory([]))
                      dispatch(setArtistGenresAction([]))
                      dispatch({
                        type: "RESET_GENRES",
                      })
                    }}
                  >
                    <CategoryOutlined
                      htmlColor={
                        artistProfileState?.selectCategory === category?._id
                          ? "var(--col-primary-3)"
                          : "var(--col-primary-7)"
                      }
                    />
                    <Typography
                      variant="body1"
                      className={`m-0_5em-0 text-ellipses ${
                        artistProfileState?.selectCategory === category?._id
                          ? "text-primary-3 font-bold"
                          : "text-primary-7"
                      }`}
                    >
                      {category?.title}
                    </Typography>
                  </div>
                </Grid>
              ))}
              <Grid item xs={12}>
                <Grid container spacing={2} alignItems={"flex-end"}>
                  {/* subcategories */}
                  <Grid item xs={12} sm={6}>
                    <Typography
                      gutterBottom
                      variant="h6"
                      className="text-primary-8"
                    >
                      Select Subcategories
                    </Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      select
                      InputProps={{
                        classes: {
                          notchedOutline: "border-none box-shadow-1 border-5",
                        },
                      }}
                      value={artistProfileState?.selectSubCategory}
                      onChange={(e) => {
                        dispatch(setArtistSubCategory(e.target.value))
                        dispatch(setArtistGenresAction([]))
                        dispatch({
                          type: "RESET_GENRES",
                        })
                      }}
                      SelectProps={{
                        multiple: true,
                        renderValue: (selected) => {
                          if (selected?.includes("none")) {
                            dispatch(setArtistSubCategory([]))
                            return `Select Subcategories`
                          }
                          return `${selected?.length} item(s) selected`
                        },
                      }}
                    >
                      <MenuItem key={"none"} value={"none"}>
                        <Typography>None</Typography>
                      </MenuItem>
                      {subcategoriesByCategoryID?.map((subcategory) => (
                        <MenuItem
                          key={subcategory?._id}
                          value={subcategory?._id}
                        >
                          <Typography>{subcategory?.title}</Typography>
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {/* genres */}
                  <Grid item xs={12} sm={6}>
                    <Typography
                      gutterBottom
                      variant="h6"
                      className="text-primary-8"
                    >
                      Select Genres
                    </Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      select
                      InputProps={{
                        classes: {
                          notchedOutline: "border-none box-shadow-1 border-5",
                        },
                      }}
                      error={true}
                      value={artistProfileState?.selectGenres}
                      onChange={(e) => {
                        dispatch(setArtistGenresAction(e.target.value))
                      }}
                      SelectProps={{
                        multiple: true,
                        renderValue: (selected) => {
                          if (selected?.includes("none")) {
                            dispatch(setArtistGenresAction([]))
                            return "Select Genres"
                          }
                          return `${selected?.length} item(s) selected`
                        },
                      }}
                    >
                      <MenuItem key={"none"} value={"none"}>
                        <Typography>None</Typography>
                      </MenuItem>
                      {genresBySubcategoryID?.map((genre) => (
                        <MenuItem key={genre?._id} value={genre?._id}>
                          <Typography>{genre?.title}</Typography>
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <ManageProfileSaveBtn
                  onClick={() => {
                    handleSubmitCategoryDetails()
                  }}
                  disabled={isPosting}
                />
              </Grid>
            </Grid>
          )}
          {loadingCategories && (
            <div className="h-50vh">
              <Loader isCircularLoader={true} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ArtistManageCategory
