import { Avatar, IconButton, MenuItem, Typography } from "@mui/material"
import {
  DeleteOutlined,
  EventOutlined,
  MoneyOutlined,
  MoreHorizOutlined,
  VideoCallOutlined,
} from "@mui/icons-material"
import moment from "moment"
import { useRef, useState } from "react"
// import from other files
import {
  CustomConfirmationDialog,
  CustomPopper,
  CustomPopperBody,
} from "components"
import { getTimeFromNow, lowerCaseText } from "helpers"

const ArtistBookingCard = ({
  bookedData,
  handleOpenBookingDetails,
  isPast,
  removePastBookings,
  isConfirmed,
  handleOpenAddVideos,
}) => {
  //   open action popper
  const [openPopper, setOpenPopper] = useState(false)
  const mainRef = useRef(null)
  // show confirmation dialog
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false)

  return (
    <div className="artist-booking-card-root box-shadow-1 m-0_5em-0 position-relative border-5 h-100">
      {/* remove confirmation dialog open */}
      <CustomConfirmationDialog
        open={isConfirmationDialogOpen}
        handleClose={() => setIsConfirmationDialogOpen(false)}
        headerIcon={<DeleteOutlined fontSize="large" />}
        headerTitle="Remove Booking"
        description="Are you sure you want to remove this booking?"
        handleAction={() => {
          console.log("remove booking")
        }}
      />
      {/* artist booking card actions */}
      <div className="artist-booking-card-content-actions text-right flex-row flex-align-center flex-justify-between">
        <Typography
          className="text-ellipses p-0-1em font-bold text-primary-2"
          variant="body2"
        >
          {getTimeFromNow(bookedData?.timestamp)}
        </Typography>
        <div className="flex-row flex-align-center">
          {lowerCaseText(bookedData?.bookingType) !== "other" &&
            isConfirmed &&
            !bookedData?.personalizedVideo?._id && (
              <IconButton
                onClick={() => {
                  handleOpenAddVideos(bookedData?._id)
                }}
              >
                <VideoCallOutlined className="text-primary-1" />
              </IconButton>
            )}
          <CustomPopper mainRef={mainRef}>
            <IconButton
              className="text-transform-none font-bold text-primary-2"
              onClick={() => setOpenPopper(!openPopper)}
            >
              <MoreHorizOutlined />
            </IconButton>
            <CustomPopperBody
              open={openPopper}
              position={"right"}
              setOpen={setOpenPopper}
              mainRef={mainRef}
            >
              <div className="bg-white box-shadow-1 border-5">
                <MenuItem
                  onClick={() => {
                    handleOpenBookingDetails(bookedData)
                  }}
                >
                  View Details
                </MenuItem>
                {isPast && (
                  <MenuItem
                    onClick={() => {
                      removePastBookings(bookedData)
                    }}
                  >
                    Remove
                  </MenuItem>
                )}
              </div>
            </CustomPopperBody>
          </CustomPopper>
        </div>
      </div>
      <div className="artist-booking-card-content">
        {/* artist booking body content */}
        <div className="artist-booking-card-content-body w-100 flex-row bg-whitesmoke p-1em flex-align-start">
          <div className="artist-booking-card-content-body-icon">
            <Avatar
              className="bg-primary-2 text-white"
              src={bookedData?.user?.profileImageUrl}
            >
              {`${bookedData?.user?.firstName?.slice(0, 1)?.toUpperCase()}`}
            </Avatar>
          </div>
          <div className="m-0-1em text-ellipses">
            <Typography
              className="text-ellipses font-bold"
              variant="body1"
            >{`${bookedData?.user?.firstName} ${bookedData?.user?.lastName}`}</Typography>
            <Typography variant="body2" className="text-ellipses">{`${
              bookedData?.eventType?.eventName || ""
            }${
              bookedData?.serviceType?.serviceName
                ? ", " + bookedData?.serviceType?.serviceName
                : ""
            }`}</Typography>
            <Typography variant="body2" className="text-ellipses">{`${
              bookedData?.eventLocation ? bookedData?.eventLocation : "N/A"
            }`}</Typography>
          </div>
        </div>
        {/* date and price */}
        <div className={`artist-booking-card-content-header flex-column p-1em`}>
          {/* event booking date */}
          <div className={`artist-booking-date flex-row flex-align-center`}>
            <EventOutlined className="text-secondary" />
            {lowerCaseText(bookedData?.bookingType) === "other" ? (
              <div className="artist-booking-event-start-date m-0-1em">
                <Typography variant="body1" className="font-bold">
                  {`Event Date`}
                </Typography>
                <Typography variant="body1" className="text-secondary">
                  {`${moment(bookedData?.eventDate?.start)?.format(
                    "Do MMM,YYYY"
                  )}`}
                </Typography>
              </div>
            ) : (
              <div className="artist-booking-event-start-date m-0-1em">
                <Typography variant="body1" className="font-bold">
                  {`Event Date`}
                </Typography>
                <Typography variant="body1" className="text-secondary">
                  {`${moment(bookedData?.personalizedMsgDate)?.format(
                    "Do MMM,YYYY"
                  )} `}
                </Typography>
              </div>
            )}
          </div>
          {/* event price */}
          {bookedData?.price && (
            <div className="flex-row flex-align-center">
              <MoneyOutlined className="text-secondary" />
              <div className="artist-booking-price m-0-1em">
                <Typography variant="body1" className="font-bold">
                  {`Price`}
                </Typography>
                <Typography variant="body1" className="text-secondary">
                  {`$${bookedData?.price}`}
                </Typography>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ArtistBookingCard
