import * as Yup from "yup"

export const userProfileErrSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  dob: Yup.string().required("Date of birth is required"),
  phoneNumber: Yup.string().matches(
    /^[0-9]{8,16}$/,
    "Please enter a valid phone number"
  ),
})

// artist profile
export const artistProfileErrSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phoneNumber: Yup.string().matches(
    /^[0-9]{8,16}$/,
    "Please enter a valid phone number"
  ),
  yearsOfExperience: Yup.number().test(
    "yearsOfExperience",
    "Years of experience must be a number",
    function (value) {
      return !isNaN(value)
    }
  ),
})

// manager profile
export const managerProfileErrSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phoneNumber: Yup.string().matches(
    /^[0-9]{8,16}$/,
    "Please enter a valid phone number"
  ),
  yearsOfExperience: Yup.number().test(
    "yearsOfExperience",
    "Years of experience must be a number",
    function (value) {
      return !isNaN(value)
    }
  ),
})
