// import from packages
import { Typography, TextField, Button } from "@mui/material"
import { useEffect, useState } from "react"
import { CustomSnackbar } from "components"
import { useApi, useIsMounted, useSnackbar } from "hooks"

const ChangePassword = ({ currentUser }) => {
  const {
    postData,
    isPosted,
    isPostErr,
    handleResetPostApi,
    postStatus,
    isPosting,
  } = useApi()
  const isMounted = useIsMounted()
  const { snackbar, handleOpenSnackbar, handleCloseSnackbar } = useSnackbar()
  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    isPasswordChanged: false,
    isPasswordMatched: false,
  })
  const handleChangePwd = () => {
    if (password.newPassword === password.confirmPassword) {
      postData(
        `auth/set-password/changePassword`,
        {
          email: currentUser?.email,
          oldPassword: password?.oldPassword,
          newPassword: password?.newPassword,
        },
        "PUT"
      )
      return
    }
    handleOpenSnackbar({
      message: "Password does not match",
      variant: "error",
    })
  }
  useEffect(() => {
    if (isMounted() && isPosted) {
      handleOpenSnackbar({
        message: "Password changed successfully",
        variant: "success",
      })
    }
  }, [handleOpenSnackbar, isMounted, isPosted])
  useEffect(() => {
    if (isMounted() && isPostErr) {
      handleOpenSnackbar({
        message: postStatus?.message || "Your current password is incorrect",
        variant: "error",
      })
    }
  }, [handleOpenSnackbar, isMounted, isPostErr, postStatus])

  return (
    <div className="user-change-password-root p-1em">
      <CustomSnackbar
        open={snackbar?.open}
        handleClose={() => {
          handleCloseSnackbar()
        }}
        variant={snackbar?.variant}
        message={snackbar?.message}
        autoHideDuration={3000}
        resetFunction={handleResetPostApi}
      />
      {/* change password header */}
      <div className="user-change-password-header">
        <Typography variant="h4" className="text-primary-2">
          Change Password
        </Typography>
        <span className="text-primary-1 font-size-small font-bold">
          All fields are required.
        </span>
      </div>
      {/* change password body */}
      <div className="user-change-password-body m-1-0 flex-column row-gap-1em">
        {/* old password */}
        <div className="old-password">
          <Typography variant="body1" gutterBottom>
            Old Password
          </Typography>
          <TextField
            value={password.oldPassword}
            fullWidth
            onChange={(ev) => {
              setPassword({
                ...password,
                oldPassword: ev.target.value,
              })
            }}
          />
        </div>
        {/* new password */}
        <div className="new-password">
          <Typography variant="body1" gutterBottom>
            New Password
          </Typography>
          <TextField
            value={password.newPassword}
            onChange={(e) => {
              setPassword({
                ...password,
                newPassword: e.target.value,
              })
            }}
            fullWidth
          />
        </div>
        {/* confirm password */}
        <div className="confirm-password">
          <Typography variant="body1" gutterBottom>
            Confirm Password
          </Typography>
          <TextField
            value={password.confirmPassword}
            onChange={(e) => {
              setPassword({
                ...password,
                confirmPassword: e.target.value,
                isPasswordChanged: e.target.value.length > 0,
                isPasswordMatched: e.target.value === password.newPassword,
              })
            }}
            fullWidth
            error={password.isPasswordChanged && !password.isPasswordMatched}
            helperText={
              password.isPasswordChanged && !password.isPasswordMatched
                ? "Password does not match"
                : null
            }
          />
        </div>
      </div>
      {/* change password action */}
      <div className="user-change-password-action">
        <Button
          fullWidth
          className={`m-1-0 text-transform-none ${
            password?.isPasswordChanged &&
            password?.isPasswordMatched &&
            !isPosting
              ? "btn-gradient text-white"
              : "disabled-btn "
          }`}
          disabled={!password?.isPasswordMatched || isPosting}
          size="large"
          onClick={() => {
            handleChangePwd()
          }}
        >
          Change Password
        </Button>
      </div>
    </div>
  )
}

export default ChangePassword
