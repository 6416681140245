import { Grid, Typography } from "@mui/material"
import { NoDataAvailable } from "components"

const BioGraphyTabItem = ({ artist }) => {
  return (
    <div className="artist-details-biography-root">
      <Typography variant="h5" gutterBottom className="text-primary-2">
        About Me
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {artist?.bio && (
            <Typography className="font-size-1_1em">{artist?.bio}</Typography>
          )}
          {!artist?.bio && <NoDataAvailable />}
        </Grid>
      </Grid>
    </div>
  )
}

export default BioGraphyTabItem
