import produce from "immer"

const DefaultState = {
  loading: false,
  error: null,
  message: null,
  isRemoved: false,
  isAdded: false,
}

const initialState = {
  favorites: [],
  isLoading: true,
  error: null,
  addFav: DefaultState,
  removeFav: DefaultState,
}
const FavoriteReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action
    const { addFav, removeFav } = draft
    switch (type) {
      case "LOADING_FAVORITE":
        draft.isLoading = true
        break
      case "GET_FAVORITE_SUCCESS":
        draft.favorites = payload
        draft.isLoading = false
        draft.error = null
        break
      case "GET_FAVORITE_FAILURE":
        draft.error = payload
        draft.isLoading = false
        break
      // add favorite
      case "ADD_FAVORITE_LOADING":
        addFav.loading = true
        addFav.error = null
        break
      case "ADD_FAVORITE_SUCCESS":
        addFav.loading = false
        addFav.message = payload
        addFav.isAdded = true
        break
      case "ADD_FAVORITE_FAILURE":
        addFav.loading = false
        addFav.error = payload
        break
      case "ADD_FAVORITE_RESET":
        addFav.isAdded = false
        break
      // remove favorite
      case "REMOVE_FAVORITE_LOADING":
        removeFav.loading = true
        removeFav.error = null
        break
      case "REMOVE_FAVORITE_SUCCESS":
        removeFav.loading = false
        removeFav.message = payload
        removeFav.isRemoved = true
        break
      case "REMOVE_FAVORITE_FAILURE":
        removeFav.loading = false
        removeFav.error = payload
        break
      case "REMOVE_FAVORITE_RESET":
        removeFav.isRemoved = false
        break
      default:
        return state
    }
  })
export default FavoriteReducer
