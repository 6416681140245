import {
  DeleteOutlined,
  EventOutlined,
  InfoOutlined,
  MiscellaneousServicesOutlined,
  PersonOutlined,
} from "@mui/icons-material"
import { Divider, IconButton, Typography } from "@mui/material"
// import from other files
import { getTimeFromNow, lowerCaseText, primaryDateFormat } from "helpers"

const ArtistRecentBookingsCard = ({
  bookingData,
  handleOpenBookingDetails,
  handleOpenConfirmationDialog,
}) => {
  return (
    <div className="artist-recent-bookings-card p-0_5em-1em border-5 box-shadow-1 h-100">
      {/* action button */}
      <div className="flex-row flex-justify-between flex-align-center">
        <div>
          <span className="font-size-small text-primary-1 font-bold">
            {getTimeFromNow(bookingData?.timestamp)}
          </span>
        </div>
        <div className="artist-recent-bookings-card-action flex-row flex-align-center flex-justify-end m-0_5em-0">
          {lowerCaseText(bookingData?.status) === "past" && (
            <IconButton
              className="box-shadow-normal m-0-0_5em border-10"
              onClick={() => {
                handleOpenConfirmationDialog(bookingData)
              }}
            >
              <DeleteOutlined className="text-primary-3" />
            </IconButton>
          )}
          <IconButton
            className="box-shadow-normal border-10"
            onClick={() => {
              handleOpenBookingDetails(bookingData)
            }}
          >
            <InfoOutlined className="text-primary-2" />
          </IconButton>
        </div>
      </div>
      {/* booking content */}
      <div className="artist-recent-bookings-card-content">
        {/* event date */}
        <div>
          {lowerCaseText(bookingData?.bookingType) === "other" && (
            <div className="flex-row flex-align-center flex-justify-between m-b-0_5em">
              <Typography variant="body1" className="font-bold text-primary-9">
                {primaryDateFormat(bookingData?.eventDate?.start)}
              </Typography>
            </div>
          )}
          {lowerCaseText(bookingData?.bookingType) !== "other" && (
            <Typography variant="body1" className="font-bold text-primary-9">
              {primaryDateFormat(bookingData?.personalizedMsgDate)}
            </Typography>
          )}
        </div>

        <Divider />
        {/* booked user */}
        <div className="artist-recent-booking-event flex-row m-0_5em-0">
          <PersonOutlined className="text-primary-6" />
          <Typography className="m-0-0_5em">{`${bookingData?.user?.firstName} ${bookingData?.user?.lastName}`}</Typography>
        </div>
        {/* event type */}
        <div className="artist-recent-booking-event flex-row m-0_5em-0">
          <EventOutlined className="text-primary-6" />
          <Typography className="m-0-0_5em">
            {bookingData?.eventType?.eventName}
          </Typography>
        </div>
        {/* service type */}
        <div className="artist-recent-booking-event flex-row m-0_5em-0">
          <MiscellaneousServicesOutlined className="text-primary-6" />
          <Typography className="m-0-0_5em">
            {bookingData?.serviceType?.serviceName}
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default ArtistRecentBookingsCard
