// import from packages
import { Divider } from "@mui/material"
import moment from "moment"

// get id from youtube url
export const getYoutubeID = (url) => {
  if (!url) return null
  if (url.includes("youtu.be")) return url.split("/")[3]
  return url.split("v=")[1]
}

// get slice of long text
export const getText = (txt, maxLength) => {
  if (txt?.length > maxLength) {
    return txt?.slice(0, maxLength) + "..."
  }
  return txt
}

// hide divider for last item

export const hideDivider = (index, length) => {
  if (index === length - 1) {
    return null
  }
  return <Divider />
}

// primary time & date format showing on UI
export const primaryTimeFormat = (time) => {
  if (!time) {
    return ""
  }
  return moment(time).format("hh:mm A")
}
export const primaryDateFormat = (date) => {
  if (!date) {
    return ""
  }
  return moment(date)?.format("Do MMM, YYYY")
}

// get minute,hours and day from now
export const getTimeFromNow = (date) => {
  if (moment(date).isBefore(moment())) {
    return moment(date).fromNow()
  }
}

// multiple select
export const getSelectedItems = (items, selectedItem) => {
  if (items?.includes(selectedItem)) {
    return items?.filter((item) => item !== selectedItem)
  }
  return [...items, selectedItem]
}

// lowercase text
export const lowerCaseText = (text) => {
  if (typeof text === "string") {
    return text?.toLowerCase()
  }
  return false
}

// is wallet type credit or debit
export const isTypeCredit = (type) => {
  if (type) {
    return type?.toLowerCase() === "credit"
  }
  return false
}

// sort new to old
export const sortNewToOld = (a, b, dateType) => {
  return moment(new Date(b?.[dateType])) - moment(new Date(a?.[dateType]))
}
// sort old to new
export const sortOldToNew = (a, b, dateType) => {
  return moment(new Date(a?.[dateType])) - moment(new Date(b?.[dateType]))
}

// export countries
export { countries } from "./countries"

// convert big number to shorten format
export const convertBigNumber = (num) => {
  if (num) {
    num = num?.toString().replace(/[^0-9.]/g, "")
    if (num < 1000) {
      return num
    }
    let si = [
      { v: 1e3, s: "K" },
      { v: 1e6, s: "M" },
      { v: 1e9, s: "B" },
      { v: 1e12, s: "T" },
      { v: 1e15, s: "P" },
      { v: 1e18, s: "E" },
    ]
    let index
    for (index = si.length - 1; index > 0; index--) {
      if (num >= si[index].v) {
        break
      }
    }
    return (
      (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") +
      si[index].s
    )
  }
  return 0
}

// export testData.js
export {
  EVENT_TYPES,
  US_CITIES,
  ARTISTS,
  RECENT_REVIEWS,
  ARTIST_VIDEOS,
  SERVICES,
  RATINGS,
  COUPON_CODES,
  WALLET_DATA,
  REQUEST_DATA,
  ARTIST_DASHBOARD_DATA,
  LANGUAGES,
  MANAGERS_FOR_ARTIST,
  MANAGER_ALL_REQUESTS,
} from "./testData"

// booking artist helper functions
export const diffInDays = (startDate, endDate) => {
  const diff = moment(endDate).diff(moment(startDate), "days")
  if (diff > 0) {
    return diff
  }
  return 1
}

// match youtube url
export function matchYoutubeUrl(url) {
  var p =
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
  if (url.match(p)) {
    return url.match(p)[1]
  }
  return false
}

// -----------------------------------------------------Upload thumb image from video file-----------------------------------------------------
// get thumb from video file
export const generateVideoThumbnail = (file) => {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas")
    const video = document.createElement("video")
    // this is important
    video.autoplay = true
    video.muted = true
    video.src = URL.createObjectURL(file)
    video.onloadeddata = () => {
      let ctx = canvas.getContext("2d")
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
      video.pause()
      return resolve(canvas.toDataURL("image/png"))
    }
  })
}

// upload base64 image
export const handleFileUpload = async (file) => {
  try {
    const thumb = await generateVideoThumbnail(file)
    return thumb
  } catch (error) {
    console.log(error)
  }
}

// convert base64 to blob
export const DataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(",")
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1])
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0]

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i)

  return new Blob([ia], { type: mimeString })
}

// date between two dates
export const getTwoDatesBetweenDates = (start, end) => {
  if (moment(start).isValid) {
    let now = moment(start).clone()
    const getDates = []
    while (now.isSameOrBefore(moment(end))) {
      getDates.push(now.toString())
      now.add(1, "days")
    }
    return getDates
  }
  return []
}

// get from local storage
export const getFromLocalStorage = (key) => {
  if (!key) return
  const val = localStorage?.getItem(key)
  return val
}

// format currency by currency
export const formatCurrency = (amount, currency) => {
  const formattedAmount = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(+amount)
  return formattedAmount
}

// currency rate ZAR to USD
export const getCurrencyRate = (value, currency) => {
  if (currency === "ZAR") {
    return formatCurrency(value, currency)
  }
  return formatCurrency(Math.round(value * 0.061), currency)
}

// calculate service charge for the booking amount
export const calculateServiceCharge = (amount, serviceCharge) => {
  if (serviceCharge) {
    // return Math.round(amount * (serviceCharge / 100))
    return Math.round( Number(serviceCharge))
  }
  return 0
}
