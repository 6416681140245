import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useIsMounted } from "hooks"
import {
  fetchLanguagesFromApi,
  languageSelector,
  languagesLoadingSelector,
} from "redux/actions"

const useLanguages = () => {
  const isMounted = useIsMounted()
  const dispatch = useDispatch()
  const languagesState = useSelector((state) => state?.languagesState)
  const allLanguages = languageSelector(languagesState)
  const isLoadingLanguages = languagesLoadingSelector(languagesState)

  useEffect(() => {
    if (isMounted()) {
      dispatch(fetchLanguagesFromApi())
    }
  }, [dispatch, isMounted])

  return {
    allLanguages,
    isLoadingLanguages,
  }
}

export default useLanguages
