import { lazy } from "react"

// all pages
export const Home = lazy(() => import("./Home"))
export const NotFound = lazy(() => import("./NotFound"))
export const SetPassword = lazy(() => import("./SetPassword"))
export const ArtistDetails = lazy(() => import("./ArtistDetails"))
export const EventTypesAll = lazy(() => import("./EventTypesAll"))
export const AllArtists = lazy(() => import("./AllArtists"))
export const AllReviews = lazy(() => import("./AllReviews"))
export const Registration = lazy(() => import("./Registration"))
export const WhySkyrise = lazy(() => import("./WhySkyrise"))
export const FAQ = lazy(() => import("./FAQ"))
export const ContactUs = lazy(() => import("./ContactUs"))
export const AboutUs = lazy(() => import("./AboutUs"))
export const PrivacyPolicy = lazy(() => import("./PrivacyPolicy"))
export const TermAnConditions = lazy(() => import("./TermAnConditions"))

// user related pages
export const MyBookings = lazy(() => import("./MyBookings"))
export const BookingArtist = lazy(() => import("./BookingArtist"))
export const ArtistBookingPayment = lazy(() => import("./ArtistBookingPayment"))
export const Notifications = lazy(() => import("./Notifications"))
export const MyFavorites = lazy(() => import("./MyFavorites"))
export const UserAccount = lazy(() => import("./UserAccount"))
export const UserPersonalizedMessage = lazy(() =>
  import("./UserPersonalizedMessage")
)
export const UserSupport = lazy(() => import("./UserSupport"))
export const MyWallet = lazy(() => import("./MyWallet"))
export const RescheduledEventRequests = lazy(() =>
  import("./RescheduledEventRequests")
)

// artist related pages
export const ArtistDashboard = lazy(() => import("./artist/ArtistDashboard"))
export const ArtistManageProfile = lazy(() =>
  import("./artist/ArtistManageProfile")
)
export const ArtistBookings = lazy(() => import("./artist/ArtistBookings"))
export const ArtistManagers = lazy(() => import("./artist/ArtistManagers"))
export const ArtistRequests = lazy(() => import("./artist/ArtistRequests"))
export const ArtistPricing = lazy(() => import("./artist/ArtistPricing"))
export const ArtistCalendar = lazy(() => import("./artist/ArtistCalendar"))
export const ArtistReviews = lazy(() => import("./artist/ArtistReviews"))
export const ArtistProfileViews = lazy(() =>
  import("./artist/ArtistProfileViews")
)
export const ArtistShortListedViews = lazy(() =>
  import("./artist/ArtistShortListedViews")
)
export const ArtistRevenue = lazy(() => import("./artist/ArtistRevenue"))

// manager related pages
export const ManagerProfile = lazy(() => import("./manager/ManagerProfile"))
export const ManagedArtists = lazy(() => import("./manager/ManagedArtists"))
export const ManagerRequests = lazy(() => import("./manager/ManagerRequests"))

// payment related pages
export const BookingSuccess = lazy(() => import("./BookingSuccess"))
