import { apiInstance } from "configs"
import { useIsMounted } from "hooks"
import { useState } from "react"
import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  adminStateSelector,
  crowdSizesSelector,
  crowdSizesStateSelector,
  daysSelector,
  daysStateSelector,
  eventDurationsSelector,
  eventDurationsStateSelector,
  fetchCrowdSizes,
  fetchDays,
  fetchEventDurationsAction,
  loadingCrowdSizesSelector,
  loadingEventDurationsSelector,
} from "redux/actions"
const useAdmin = () => {
  const isMounted = useIsMounted()
  const dispatch = useDispatch()
  const [serviceCharge, setServiceCharge] = useState({
    value: 0,
    error: "",
    isFetching: false,
  })
  const adminState = useSelector((state) => adminStateSelector(state))
  // crowd sizes
  const crowdSizesState = useSelector((state) => crowdSizesStateSelector(state))
  const crowdSizes = useSelector((state) => crowdSizesSelector(state))
  const isLoadingCrowdSizes = useSelector((state) =>
    loadingCrowdSizesSelector(state)
  )
  // days
  const daysState = useSelector((state) => daysStateSelector(state))
  const days = useSelector((state) => daysSelector(state))

  // event durations
  const eventDurationsState = useSelector((state) =>
    eventDurationsStateSelector(state)
  )
  const eventDurations = useSelector((state) => eventDurationsSelector(state))
  const isLoadingEventDurations = useSelector((state) =>
    loadingEventDurationsSelector(state)
  )

  // *fetch charges set by admin
  const fetchServiceCharge = useCallback(async () => {
    setServiceCharge((pre) => ({ ...pre, isFetching: true }))
    try {
      await apiInstance
        .get(`booking-service-charge`)
        .then(({ data }) => {
          const percent = data?.success?.data?.charges || 0
          setServiceCharge((pre) => ({
            ...pre,
            value: percent,
            isFetching: false,
            error: "",
          }))
        })
        .catch(({ response }) => {
          setServiceCharge((pre) => ({
            ...pre,
            error: response?.data?.message,
            isFetching: false,
            value: 0,
          }))
        })
    } catch (error) {
      setServiceCharge((pre) => ({
        ...pre,
        error: error.message,
        isFetching: false,
        value: 0,
      }))
    }
  }, [])

  // fetch by useEffect
  // crowd sizes
  useEffect(() => {
    if (isMounted()) {
      dispatch(fetchCrowdSizes())
    }
  }, [isMounted, dispatch])
  // days
  useEffect(() => {
    if (isMounted()) {
      dispatch(fetchDays())
    }
  }, [isMounted, dispatch])
  // event durations
  useEffect(() => {
    if (isMounted()) {
      dispatch(fetchEventDurationsAction())
    }
  }, [isMounted, dispatch])
  // *fetch charges set by admin
  useEffect(() => {
    if (isMounted()) {
      fetchServiceCharge()
    }
  }, [isMounted, fetchServiceCharge])

  return {
    adminState,
    crowdSizesState,
    crowdSizes,
    isLoadingCrowdSizes,
    // days
    daysState,
    days,
    // event durations
    eventDurationsState,
    eventDurations,
    isLoadingEventDurations,
    // service charge
    serviceCharge,
  }
}

export default useAdmin
