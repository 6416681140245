import { Loader } from "components"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import { getAuthState } from "redux/actions"

const ManagerRoutes = ({ component: Component }) => {
  const auth = useSelector((state) => getAuthState(state))
  const isManager = useMemo(
    () => auth?.userRole?.toLowerCase() === "manager",
    [auth?.userRole]
  )

  return auth?.isLoading ? (
    <Loader />
  ) : isManager ? (
    <Component />
  ) : (
    <Navigate to="/login" replace />
  )
}

export default ManagerRoutes
