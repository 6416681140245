import {
  DatePicker,
  LocalizationProvider,
  MobileDatePicker,
  //
} from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";

// import {
//   DatePicker,
//   LocalizationProvider,
//   MobileDatePicker,
// } from "@mui/x-date-pickers-pro";
// import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";

import { TextField } from "@mui/material";
import moment from "moment";
const CustomDatePicker = ({
  selectedDate,
  format,
  onChange,
  minDate,
  maxDate,
  name,
  disableFuture,
  disablePast,
  error,
  helperText,
  blockedDates,
  isMobilePicker,
  showToolbar,
}) => {
  const params = {
    shouldDisableDate: (date) => {
      if (blockedDates?.length > 0) {
        return blockedDates
          ?.map((bd) => moment(bd).format("YYYY-MM-DD"))
          .includes(moment(date).format("YYYY-MM-DD"));
      }
      return false;
    },
    disablePast: disablePast || false,
    disableFuture: disableFuture || false,
    autoOk: true,
    value: selectedDate,
    format: format,
    inputValue: selectedDate,
    onChange: onChange,
    inputVariant: "outlined",
    minDate: minDate,
    maxDate: maxDate,
    renderInput: (params) => (
      <TextField
        {...params}
        fullWidth={true}
        error={error || false}
        name={name}
        helperText={helperText || ""}
      />
    ),
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {isMobilePicker ? (
        <DatePicker {...params} />
      ) : (
        <MobileDatePicker {...params} showToolbar={showToolbar || false} />
      )}
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
