import { FavoriteBorderOutlined, FavoriteOutlined } from "@mui/icons-material"
import { CircularProgress, IconButton, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { USER_PLACEHOLDER } from "assets"
import { useSelector } from "react-redux"
import { getAuthState } from "redux/actions"
import { useCurrencyContext } from "hooks"
const ArtistCard = ({
  data,
  isFavIcon,
  handleToggleFavorite,
  isFavArtist,
  isAddingToFav,
}) => {
  const { currencyWiseValue } = useCurrencyContext()
  const navigate = useNavigate()
  const auth = useSelector((state) => getAuthState(state))

  return (
    <div className="artist-card position-relative bg-whitesmoke">
      {/* favorite icon */}
      {isFavIcon && (
        <div className="artist-fav-icon">
          <IconButton
            onClick={() => {
              auth?.userRole
                ? handleToggleFavorite(data?._id)
                : navigate("/login")
            }}
            className="artist-fav-icon-btn"
          >
            {isAddingToFav ? (
              <CircularProgress className="text-primary-3" size={20} />
            ) : (
              <>
                {isFavArtist(data?._id) && (
                  <FavoriteOutlined className="text-primary-3" />
                )}
                {!isFavArtist(data?._id) && (
                  <FavoriteBorderOutlined className="text-primary-3" />
                )}
              </>
            )}
          </IconButton>
        </div>
      )}
      {/* <div className="artist-rating">
      </div> */}
      <div
        className="artist-card-content bg-whitesmoke border-5 p-1em-2em cursor-pointer"
        role={"button"}
        onClick={() => {
          navigate(`/artist/${data?._id}`)
        }}
      >
        <div className="card-img text-center">
          <img
            src={data?.profileImageUrl || USER_PLACEHOLDER}
            alt=""
            className="border-circle object-fit-cover box-shadow-10px-1px card-circle-img-size-200px"
            onError={(e) => {
              e.target.src = { USER_PLACEHOLDER }
            }}
          />
        </div>
        <div className="card-content w-100 p-0-1em text-center">
          <Typography variant="body1" color="initial" className="text-ellipses">
            {`${data?.firstName} ${data?.lastName}`}
          </Typography>
          <Typography
            variant="body2"
            color="var(--col-secondary)"
            className="text-ellipses"
            gutterBottom
          >
            {data?.location?.address}
          </Typography>

          <>
            <Typography className="text-ellipses font-bold text-primary-8 font-size-small">
              {data?.minPrice ? "Starting From" : "Request for Price"}
            </Typography>
            <Typography
              className="text-ellipses font-bold text-primary-8"
              variant="h6"
            >
              {data?.minPrice
                ? `${currencyWiseValue(data?.minPrice)}/hr`
                : "See More"}
            </Typography>
          </>

          <div className="artist-designation p-0_5em-1em bg-white flex-row flex-justify-center flex-align-center m-0_5em-0 border-5">
            <Typography
              variant="body1"
              color="var(--bg-col-primary-2)"
              className="text-ellipses font-bold"
            >
              {data?.category?.title ?? "Artist"}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArtistCard
