import {
  LocationOnOutlined,
  PeopleOutline,
  TimelapseOutlined,
} from "@mui/icons-material"
import { Grid, Typography } from "@mui/material"
import { NoDataAvailable } from "components"
import { useCurrencyContext } from "hooks"

const ArtistChargesTabItem = ({
  artistPricingLists,
  isLoadingPricingLists,
}) => {
  const { currencyWiseValue } = useCurrencyContext()

  return (
    <div className="artist-charges-root">
      <Typography variant="h5" gutterBottom className="text-primary-2">
        Charges
      </Typography>
      {!isLoadingPricingLists && artistPricingLists?.length > 0 && (
        <Grid container spacing={2}>
          {artistPricingLists?.map((item, index) => (
            <Grid item xs={12} sm={6} lg={4} key={item?._id}>
              <div className="box-shadow-1 border-5 overflow-hidden">
                <div className="artist-charges-price text-center bg-primary-9 text-col-white p-1em-0">
                  <Typography
                    variant="h5"
                    className="font-bold text-white"
                  >{`${currencyWiseValue(item?.pricePerHour)}/hr`}</Typography>
                </div>
                <div className="artist-charges-detail p-1em">
                  {/*  location*/}
                  <div className="flex-row flex-align-center m-0_5em-0">
                    <LocationOnOutlined />
                    <div className="flex-column m-0-1em text-ellipses">
                      <Typography variant="body1" className="font-bold">
                        Location
                      </Typography>
                      <Typography variant="body2" className="text-ellipses">
                        {item?.location?.address}
                      </Typography>
                    </div>
                  </div>
                  {/* crowd size */}
                  <div className="flex-row flex-align-center m-0_5em-0">
                    <PeopleOutline />
                    <div className="flex-column m-0-1em text-ellipses">
                      <Typography variant="body1" className="font-bold">
                        Crowd Size
                      </Typography>
                      {item?.maxCrowdSize ? (
                        <Typography variant="body2" className="text-ellipses">
                          {`${item?.minCrowdSize} - ${item?.maxCrowdSize}`}
                        </Typography>
                      ) : (
                        "N/A"
                      )}
                    </div>
                  </div>

                  {/* days */}
                  <div className="flex-row flex-align-center m-0_5em-0">
                    <TimelapseOutlined />
                    <div className="flex-column m-0-1em text-ellipses">
                      <Typography variant="body1" className="font-bold">
                        Day(s)
                      </Typography>
                      <Typography variant="body2" className="text-ellipses">
                        {item?.numberOfDays}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      )}
      {!isLoadingPricingLists && !artistPricingLists?.length > 0 && (
        <NoDataAvailable />
      )}
    </div>
  )
}

export default ArtistChargesTabItem
