import { Container, Grid, Typography } from "@mui/material"

const ChooseSkyRise = () => {
  const CHOOSE_REASONS = [
    {
      label: "Secure Booking & Payment",
      icon: "https://cdn-icons-png.flaticon.com/512/1077/1077976.png",
    },
    {
      label: "Exceptional Talent",
      icon: "https://cdn-icons-png.flaticon.com/512/1527/1527547.png",
    },
    {
      label: "Last Minute Backups",
      icon: "https://cdn-icons-png.flaticon.com/512/3078/3078914.png",
    },
    {
      label: "No Hidden Charges",
      icon: "https://cdn-icons-png.flaticon.com/512/2762/2762657.png",
    },
    {
      label: "Artist Management & Tracking",
      icon: "https://cdn-icons-png.flaticon.com/512/3143/3143173.png",
    },
    {
      label: "Easy Cancellation",
      icon: "https://cdn-icons-png.flaticon.com/512/2140/2140667.png",
    },
  ]
  return (
    <div className="choose-sky-rise-root m-2em-0 p-1em-0">
      <Container>
        <div className="choose-sky-rise-title flex-row flex-align-center flex-justify-between flex-wrap">
          <Typography variant="h4" className="font-bold text-col-white">
            Why Choose Sky Celebrity
          </Typography>
        </div>
        <div className="choose-sky-rise-body m-2em-0">
          <Grid container spacing={2}>
            {CHOOSE_REASONS?.map((reason, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <div className="reason-content flex-column flex-align-center row-gap-1em p-1em-0 border-5">
                  <div className="reason-icon position-center">
                    <img
                      src={reason.icon}
                      alt=""
                      className="object-fit-cover"
                      height="45px"
                    />
                  </div>
                  <div className="reason-label">
                    <Typography
                      variant="h6"
                      className="font-bold text-col-white"
                    >
                      {reason.label}
                    </Typography>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </Container>
    </div>
  )
}

export default ChooseSkyRise
