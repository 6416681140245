import { apiInstance } from "configs"
import { createSelector } from "reselect"
import moment from "moment"
import { endAction, startAction } from "./LoaderAction"

export const promoCodeStateSelector = (state) => state.promoCodeState
export const promoCodesSelector = createSelector(
  [promoCodeStateSelector],
  (promoCodeState) => promoCodeState?.promoCode?.data
)
export const promoCodesLoadingSelector = createSelector(
  [promoCodeStateSelector],
  (promoCodeState) => promoCodeState?.promoCode?.isLoading
)
export const expiredPromoCodesSelector = createSelector(
  [promoCodesSelector],
  (promoCodes) =>
    promoCodes?.filter(
      (promoCode) =>
        moment(promoCode?.startingDate).isSameOrBefore(moment()) &&
        moment(promoCode?.endingDate).isBefore(moment())
    )
)

export const upcomingPromoCodesSelector = createSelector(
  [promoCodesSelector],
  (promoCodes) =>
    promoCodes?.filter((promoCode) =>
      moment(promoCode?.startingDate).isAfter(moment())
    )
)

export const fetchPromoCodesData = () => async (dispatch) => {
  try {
    dispatch({ type: "PROMO_CODES_LOADING" })
    await apiInstance
      .get(`promo-code/all-promo-code`)
      .then(({ data }) => {
        dispatch({
          type: "PROMO_CODES_SUCCESS",
          payload: data?.success?.data,
        })
      })
      .catch(({ response }) => {
        const errMsg = response?.data?.error?.message
        dispatch({
          type: "PROMO_CODES_ERROR",
          payload: errMsg,
        })
      })
  } catch (error) {
    dispatch({
      type: "PROMO_CODES_ERROR",
      payload: error?.message,
    })
  }
}

// apply coupon code
export const applyCouponCodeAction =
  (secString, userID, amount) => async (dispatch) => {
    try {
      dispatch(startAction(`APPLY_PROMO_CODE_${secString}`))
      dispatch({
        type: `CLEAR_APPLY_PROMO_CODE_MSG`,
      })
      await apiInstance
        .put(`promo-code/apply`, {
          secretString: secString,
          userID,
          amount,
        })
        .then(({ data }) => {
          dispatch({
            type: "APPLY_PROMO_CODE_SUCCESS",
            payload: data?.success?.message,
          })
        })
        .catch(({ response }) => {
          const errMsg = response?.data?.error?.message
          dispatch({
            type: "APPLY_PROMO_CODE_ERROR",
            payload: errMsg,
          })
        })
        .finally(() => {
          dispatch(endAction(`APPLY_PROMO_CODE_${secString}`))
        })
    } catch (error) {
      dispatch({
        type: "APPLY_PROMO_CODE_ERROR",
        payload: error?.message,
      })
    }
  }
