import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  addBlockedDates,
  blockedDatesErrorSelector,
  blockedDatesSelector,
  blockedDatesStateSelector,
  blockedDatesSuccessSelector,
  fetchBlockedDates,
  loadingBlockDatesSelector,
  removeBlockDates,
  removeBlockDatesErrorSelector,
  removeBlockDatesSelector,
} from "redux/actions"
import { useIsMounted } from "hooks"
const useBlockedDates = (userID) => {
  const isMounted = useIsMounted()
  const dispatch = useDispatch()
  const blockedDatesState = useSelector((state) =>
    blockedDatesStateSelector(state)
  )
  const blockedDates = useSelector((state) => blockedDatesSelector(state))
  const isLoadingBlockDates = useSelector((state) =>
    loadingBlockDatesSelector(state)
  )
  const isBlockedDatesSuccess = useSelector((state) =>
    blockedDatesSuccessSelector(state)
  )
  const isBlockedDatesError = useSelector((state) =>
    blockedDatesErrorSelector(state)
  )
  const isRemovedBlockDates = useSelector((state) =>
    removeBlockDatesSelector(state)
  )
  const removeBlockDatesErrMsg = useSelector((state) =>
    removeBlockDatesErrorSelector(state)
  )

  // fetch and set blocked dates
  const handleFetchBlockedDates = useCallback(async () => {
    try {
      dispatch(fetchBlockedDates(userID))
    } catch (error) {
      console.log(error)
    }
  }, [dispatch, userID])

  useEffect(() => {
    if (isMounted()) {
      handleFetchBlockedDates()
    }
  }, [isMounted, handleFetchBlockedDates])

  // add block dates
  const handleAddBlockedDates = useCallback(
    (blockedDates) => {
      dispatch(addBlockedDates(userID, blockedDates))
    },
    [dispatch, userID]
  )
  // remove block dates
  const handleRemoveBlockedDates = useCallback(
    (blockDateIDs) => {
      dispatch(removeBlockDates(blockDateIDs))
    },
    [dispatch]
  )

  return {
    blockedDatesState,
    blockedDates,
    isLoadingBlockDates,
    handleAddBlockedDates,
    isBlockedDatesSuccess,
    isBlockedDatesError,
    handleFetchBlockedDates,
    handleRemoveBlockedDates,
    isRemovedBlockDates,
    removeBlockDatesErrMsg,
  }
}

export default useBlockedDates
