import produce from "immer"

const defaultState = {
  loading: true,
  data: null,
  error: null,
  wholeLength: 0,
  recentPayment: 0,
  totalRevenue: 0,
}
const initialState = {
  visitors: defaultState,
  favByUsers: defaultState,
  revenues: defaultState,
}

const ArtistDashboardReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action
    const { visitors, favByUsers, revenues } = draft
    switch (type) {
      // fetch all visitors
      case "VISITORS_LOADING":
        visitors.loading = true
        visitors.error = null
        visitors.data = []
        break
      case "VISITORS_SUCCESS":
        visitors.loading = false
        visitors.error = null
        visitors.data = payload?.data
        visitors.wholeLength = payload?.length
        break
      case "VISITORS_ERROR":
        visitors.loading = false
        visitors.error = payload
        visitors.data = []
        break
      // fetch all favByUsers
      case "FAV_BY_USERS_LOADING":
        favByUsers.loading = true
        favByUsers.error = null
        favByUsers.data = []
        break
      case "FAV_BY_USERS_SUCCESS":
        favByUsers.loading = false
        favByUsers.error = null
        favByUsers.data = payload?.data
        favByUsers.wholeLength = payload?.length
        break
      case "FAV_BY_USERS_ERROR":
        favByUsers.loading = false
        favByUsers.error = payload
        favByUsers.data = []
        break
      // total revenues
      case "REVENUES_LOADING":
        revenues.loading = true
        revenues.error = null
        revenues.data = []
        revenues.wholeLength = 0
        revenues.recentPayment = 0
        revenues.totalRevenue = 0
        break
      case "REVENUES_SUCCESS":
        revenues.loading = false
        revenues.error = null
        revenues.data = payload?.data
        revenues.totalRevenue = payload?.totalRevenue
        revenues.recentPayment = payload?.recentPayment
        revenues.wholeLength = payload?.length
        break
      case "REVENUES_ERROR":
        revenues.loading = false
        revenues.error = payload
        revenues.data = null
        revenues.wholeLength = 0
        revenues.recentPayment = 0
        revenues.totalRevenue = 0
        break
      default:
        return draft
    }
  })

export default ArtistDashboardReducer
