import {
  EmailOutlined,
  LockOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
// import from other files
import { CustomSnackbar } from "components";
import { useIsMounted, useSnackbar } from "hooks";
import { logInErrSchema, logInValues } from "schemas";
import { useCallback } from "react";
import { getCurrentUserDetails, setAuthToken } from "redux/actions";
const SignIn = ({
  isViewPwd,
  setIsViewPwd,
  setIsChoseSignUp,
  setForgotPwdContentOpen,
  postData,
  postStatus,
  isPosting,
  handleResetPostApi,
}) => {
  const { snackbar, handleOpenSnackbar, handleCloseSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { type } = useParams();
  const isMounted = useIsMounted();
  const handleSignIn = (values) => {
    postData("auth/login", {
      email: values?.email,
      password: values?.password,
    });
  };

  const handleCookies = useCallback(() => {
    Cookies.set("skyRiseToken", postStatus?.data, {
      // never expire
      expires: new Date("9999-12-31"),
    });
    const token = Cookies.get("skyRiseToken");
    if (token) {
      dispatch(setAuthToken(token));
      dispatch(getCurrentUserDetails(token));
      navigate("/");
    }
  }, [postStatus, navigate, dispatch]);
  useEffect(() => {
    if (postStatus?.status && isMounted()) {
      handleOpenSnackbar({
        message:
          postStatus?.status === 200
            ? "You have successfully logged in"
            : postStatus?.message,
        variant: postStatus?.status === 200 ? "success" : "error",
      });
      if (postStatus?.status === 200) {
        handleCookies();
      }
    }
  }, [postStatus, handleOpenSnackbar, isMounted, handleCookies]);

  return (
    <div className="sign-in-form-root">
      <CustomSnackbar
        variant={snackbar?.variant}
        open={snackbar?.open}
        message={snackbar?.message}
        handleClose={() => {
          handleCloseSnackbar();
          handleResetPostApi();
        }}
      />
      <Typography
        variant="h4"
        className="font-bold text-primary-2"
        gutterBottom
        sx={{ textTransform: "capitalize" }}
      >
        {type ? `Welcome back ${type},` : "Welcome Back,"} Sign In
      </Typography>
      {/* login body */}
      <Formik
        initialValues={logInValues}
        validationSchema={logInErrSchema}
        onSubmit={handleSignIn}
      >
        {({
          values,
          errors,
          setFieldTouched,
          touched,
          handleChange,
          handleSubmit,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <div className="registration-body flex-column row-gap-1em">
                <div>
                  <TextField
                    fullWidth
                    classes={{
                      root: "registration-text-field-input border-25 position-center",
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: "border-none",
                        input: "border-25",
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <div className="registration-text-field-adornment position-center">
                            <EmailOutlined fontSize="medium" />
                          </div>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Email"
                    required
                    name="email"
                    value={values.email}
                    onChange={(e) => {
                      setFieldTouched("email", true);
                      handleChange(e);
                    }}
                  />
                  {touched?.email && errors.email && (
                    <div className="p-0-1em">
                      <span className="text-primary-1">{errors.email}</span>
                    </div>
                  )}
                </div>
                <div>
                  <TextField
                    fullWidth
                    classes={{
                      root: "registration-text-field-input border-25 position-center",
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: "border-none",
                        input: "border-25",
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <div className="registration-text-field-adornment">
                            <LockOutlined fontSize="medium" />
                          </div>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="cursor-pointer"
                        >
                          <IconButton
                            onClick={() => {
                              setIsViewPwd(!isViewPwd);
                            }}
                          >
                            {isViewPwd ? (
                              <VisibilityOffOutlined fontSize="medium" />
                            ) : (
                              <VisibilityOutlined fontSize="medium" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Password"
                    type={isViewPwd ? "text" : "password"}
                    required
                    name="password"
                    value={values.password}
                    onChange={(e) => {
                      setFieldTouched("password", true);
                      handleChange(e);
                    }}
                  />
                  {touched?.password && errors.password && (
                    <div className="p-0-1em">
                      <span className="text-primary-1">{errors.password}</span>
                    </div>
                  )}
                </div>
                {/* login caption */}
                <div className="login-caption p-0-1em">
                  <Typography className="text-red font-bold" variant="body2">
                    All fields are required. Make sure you enter the right email
                    and password. If you have forgotten your password, click on
                    the forgot password button below.
                  </Typography>
                </div>
                {/* login action button */}
                <div className="registration-action">
                  <Button
                    className={`${
                      isPosting ? "disabled-btn" : "btn-gradient"
                    } border-25 text-transform-none font-size-1_1em font-bold`}
                    fullWidth
                    size="large"
                    type="submit"
                    disabled={isPosting}
                  >
                    Sign In
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
      {/* forgot password */}
      <div className="link-to-forget-pwd text-center m-1-0">
        <Button
          className="font-bold text-primary-2 text-transform-none bg-none font-size-1em p-0"
          disableRipple
          onClick={() => {
            setForgotPwdContentOpen(true);
          }}
        >
          {` Forgot Password? `}
        </Button>
      </div>
      {/* link to register */}
      <div className="link-to-register flex-row flex-align-center p-0-1em flex-justify-center">
        <Typography className="font-bold" variant="body1">
          {` Don't have an account? `}
        </Typography>
        <Button
          className="font-bold text-primary-3 text-transform-none bg-none font-size-1em p-0-0_5em"
          disableRipple
          onClick={() => {
            setIsChoseSignUp(true);
          }}
        >
          {` Register `}
        </Button>
      </div>
    </div>
  );
};

export default SignIn;
