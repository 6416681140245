import * as Yup from "yup";

export const authValues = {
  firstName: "",
  lastName: "",
  password: "",
  confirmPassword: "",
  countryCode: "27",
  phoneNumber: "",
  otp: "",
  email: "",
  location: "",
  userRole: "User",
};

export const authErrSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  countryCode: Yup.string().required("Country code is require."),
  phoneNumber: Yup.string()
    .required("Phone number is required.")
    .max(18, "Phone Number must be at most 18 characters")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    ),
  otp: Yup.string()
    .required("OTP is required.")
    .min(6, "OTP must be 6 digits.")
    .max(6, "OTP must be 6 digits."),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is Required")
    .min(6, "Password must be at least 6 character.")
    .max(30, "Password must be less than 30 character."),
  confirmPassword: Yup.string()
    .required("This field is required.")
    .oneOf([Yup.ref("password"), null], "Password not match."),
  location: Yup.string().required("Location is required"),
  userRole: Yup.string().required("User role is required"),
});

export const logInValues = {
  email: "",
  password: "",
};

export const logInErrSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});
