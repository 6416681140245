import { apiInstance } from "configs"
import { createSelector } from "reselect"

export const eventTypesStateSelector = (state) => state.eventTypesState
export const eventTypesSelector = createSelector(
  (eventTypeState) => eventTypeState,
  (state) => state?.eventTypes
)
export const servicesSelector = createSelector(
  (eventTypeState) => eventTypeState,
  (state) => state?.services
)
export const loadingEventTypes = createSelector(
  (eventTypeState) => eventTypeState,
  (state) => state?.isLoading
)
export const loadingServices = createSelector(
  (eventTypeState) => eventTypeState,
  (state) => state?.servicesLoading
)

export const fetchingEventTypes = () => {
  return {
    type: "EVENT_TYPES_LOADING",
  }
}
export const fetchingEventTypesSuccess = (eventTypes) => {
  return {
    type: "EVENT_TYPES_SUCCESS",
    payload: eventTypes,
  }
}

export const fetchingEventTypesError = (error) => {
  return {
    type: "EVENT_TYPES_ERROR",
    payload: error,
  }
}

export const fetchingServices = () => {
  return {
    type: "SERVICES_LOADING",
  }
}
export const fetchingServicesSuccess = (services) => {
  return {
    type: "SERVICES_SUCCESS",
    payload: services,
  }
}

export const fetchingServicesError = (error) => {
  return {
    type: "SERVICES_ERROR",
    payload: error,
  }
}

export const fetchEventTypes = () => async (dispatch) => {
  try {
    dispatch(fetchingEventTypes())
    await apiInstance
      .get(`event/all-events`)
      .then(({ data }) => {
        dispatch(fetchingEventTypesSuccess(data?.success?.data))
      })
      .catch(({ response }) => {
        dispatch(fetchingEventTypesError(response?.data?.error?.message))
      })
  } catch (error) {
    dispatch(fetchingEventTypesError(error?.message))
  }
}

export const fetchServices = () => async (dispatch) => {
  try {
    dispatch(fetchingServices())
    await apiInstance
      .get(`service/all-services`)
      .then(({ data }) => {
        dispatch(fetchingServicesSuccess(data?.success?.data))
      })
      .catch(({ response }) => {
        dispatch(fetchingServicesError(response?.data?.error?.message))
      })
  } catch (error) {
    dispatch(fetchingServicesError(error?.message))
  }
}
