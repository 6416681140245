import { CheckCircleOutlined, CircleOutlined } from "@mui/icons-material"
import { IconButton } from "@mui/material"

const CustomStepper = ({ steps, currentSteps, disabled }) => {
  return (
    <div className="booking-artist-stepper-root flex-row flex-justify-between m-1-0 booking-artist-stepper-item p-0-1em">
      {[...Array(steps).keys()]?.map((_, i) => (
        <div
          className="text-center flex-column position-relative p-1em-0 "
          key={i}
        >
          <IconButton
            className="position-absolute stepper-indicator bg-col-white "
            disabled={disabled ?? false}
          >
            {currentSteps?.includes(i + 1) ? (
              <CheckCircleOutlined
                fontSize="large"
                className="text-primary-3"
              />
            ) : (
              <CircleOutlined />
            )}
          </IconButton>
        </div>
      ))}
    </div>
  )
}

export default CustomStepper
