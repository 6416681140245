import {
  Grid,
  Typography,
  TextField,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material"
import { useEffect, useState } from "react"
import moment from "moment"
import { Formik } from "formik"
// import from other files
import {
  CustomButton,
  CustomDatePicker,
  CustomPhoneNumberPicker,
  CustomSnackbar,
} from "components"
import { useApi, useIsMounted, useSnackbar, useUsers } from "hooks"
import { lowerCaseText } from "helpers"
import { userProfileErrSchema } from "schemas"
const UserProfileInfo = ({ isMobileView, userDetails }) => {
  const { snackbar, handleOpenSnackbar, handleCloseSnackbar } = useSnackbar()
  const { currentUserID } = useUsers()
  const { postData, isPosting, isPosted, handleResetPostApi } = useApi()
  const isMounted = useIsMounted()
  // user details
  const [user, setUser] = useState(null)
  useEffect(() => {
    if (isMounted() && userDetails?._id) {
      setUser(userDetails)
    }
  }, [isMounted, userDetails])

  //update user details
  const handleUpdateUserDetails = (values, { setSubmitting }) => {
    if (values?.dob === "Invalid date") {
      handleOpenSnackbar({
        message: "Invalid date",
        variant: "error",
      })
      setSubmitting(false)
      return
    }
    postData(
      `user/account-update/${currentUserID}`,
      {
        ...values,
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        phoneNumber: values?.phoneNumber,
        countryCode: values?.countryCode,
        Dob: values?.dob,
        gender: values?.gender,
      },
      "PUT"
    )
    setSubmitting(false)
  }

  useEffect(() => {
    if (isMounted()) {
      if (isPosted) {
        handleOpenSnackbar({
          message: "Updated successfully",
          variant: "success",
        })
      }
    }
  }, [isPosted, isMounted, handleOpenSnackbar])

  return (
    <div
      className={`user-account-details p-1em ${isMobileView ? "" : "m-1-0"}`}
    >
      <CustomSnackbar
        open={snackbar?.open}
        variant={snackbar?.variant}
        message={snackbar?.message}
        handleClose={() => {
          handleCloseSnackbar()
          handleResetPostApi()
        }}
        autoHideDuration={2000}
      />
      <Typography variant="h4" gutterBottom className="text-primary-2">
        Personal Information
      </Typography>
      <Formik
        initialValues={{
          firstName: user?.firstName || "",
          lastName: user?.lastName || "",
          dob: user?.Dob || "",
          phoneNumber: user?.phoneNumber || "",
          countryCode: user?.countryCode || "+27",
          location: user?.location?.address || "",
          gender: user?.gender || "Male",
        }}
        enableReinitialize
        onSubmit={handleUpdateUserDetails}
        validationSchema={userProfileErrSchema}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          setFieldTouched,
          errors,
          touched,
          setFieldValue,
          isSubmitting,
        }) => (
          <form action="" noValidate onSubmit={handleSubmit}>
            {/* user name */}
            <div className="user-account-name m-0_3em-0">
              <Typography gutterBottom>Full Name</Typography>
              <Grid
                container
                spacing={isMobileView ? 0 : 2}
                className="translate-X-0_5em"
              >
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={values.firstName}
                    onChange={(e) => {
                      handleChange(e)
                      setFieldTouched("firstName", true, false)
                    }}
                    label="First Name"
                    className="m-0_5em "
                    fullWidth
                    name="firstName"
                    error={touched.firstName && errors.firstName}
                    helperText={
                      touched.firstName && errors.firstName
                        ? errors.firstName
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={values.lastName}
                    onChange={(e) => {
                      handleChange(e)
                      setFieldTouched("lastName", true, false)
                    }}
                    label="Last Name"
                    className="m-0_5em"
                    fullWidth
                    name="lastName"
                    error={touched.lastName && errors.lastName}
                    helperText={
                      touched.lastName && errors.lastName
                        ? errors.lastName
                        : null
                    }
                  />
                </Grid>
              </Grid>
            </div>
            {/* user email */}
            <div className="user-account-email">
              <Grid
                container
                spacing={isMobileView ? 0 : 2}
                alignItems={"flex-end"}
              >
                <Grid item xs={12} sm={6} className="m-0_3em-0">
                  <Typography>Email Address</Typography>
                  <span className="font-bold text-primary-1 font-size-small">
                    You can't change your email address
                  </span>
                  <div className="translate-X-0_5em">
                    <TextField
                      value={userDetails?.email}
                      className="m-0_5em"
                      fullWidth
                      disabled
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} className="m-0_3em-0">
                  <Typography>Birth Day</Typography>
                  <div className="translate-X-0_5em m-0_5em w-100">
                    <CustomDatePicker
                      selectedDate={values?.dob || moment().subtract(18, "y")}
                      format={"Do MMM YY"}
                      onChange={(newValue) => {
                        setFieldTouched("dob", true, false)
                        setFieldValue("dob", moment(newValue).toString())
                      }}
                      name="dob"
                      maxDate={moment().subtract(18, "y")}
                      disableFuture={true}
                      error={touched.dob && errors.dob ? true : false}
                      helperText={touched.dob && errors.dob ? errors.dob : ""}
                      isMobilePicker
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            {/* user phone number */}
            <div className="user-account-mobile-number m-0_3em-0">
              <Typography>Mobile Number</Typography>
              <Grid item xs={12} sm={6} className="translate-X-0_5em ">
                <div className="m-0_5em w-100">
                  <CustomPhoneNumberPicker
                    value={values?.phoneNumber}
                    onChange={(ev) => {
                      setFieldValue("phoneNumber", ev.target.value)
                      setFieldTouched("phoneNumber", true, false)
                    }}
                    countryCode={values?.countryCode}
                    onChangeCountryCode={(ev) => {
                      setFieldValue("countryCode", ev.target.value)
                      setFieldTouched("countryCode", true, false)
                    }}
                    name="phoneNumber"
                    countryCodeName={`countryCode`}
                    error={touched.phoneNumber && errors.phoneNumber}
                    helperText={
                      touched.phoneNumber && errors.phoneNumber
                        ? errors.phoneNumber
                        : null
                    }
                  />
                </div>
              </Grid>
            </div>
            {/* user gender */}
            <div className="user-account-gender m-1-0">
              <Typography>Your Gender</Typography>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  name="gender"
                  value={values?.gender}
                  onChange={(ev) => {
                    setFieldValue("gender", ev.target.value)
                    setFieldTouched("gender", false, true)
                  }}
                >
                  {["Male", "Female", "Other"]?.map((gender) => (
                    <FormControlLabel
                      key={gender}
                      value={gender}
                      control={
                        <Radio
                          className="text-primary-2"
                          checked={
                            lowerCaseText(values?.gender) ===
                            lowerCaseText(gender)
                          }
                        />
                      }
                      label={gender}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
            {/* save details or reset details */}
            <div className="user-account-action flex-row flex-align-center flex-wrap translate-X-0_5em m-1-0">
              <Grid item xs={12} sm={6}>
                <CustomButton
                  btnText="Save Details"
                  className={`m-0-0_5em font-bold font-size-1em text-transform-none ${
                    isPosting || isSubmitting ? "disabled-btn" : "btn-gradient"
                  }`}
                  disabled={isPosting || isSubmitting}
                  variant="contained"
                  disableElevation={true}
                  fullWidth
                  type="submit"
                />
              </Grid>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default UserProfileInfo
