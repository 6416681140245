import { Grid, Typography } from "@mui/material"
import { useState } from "react"
// import from other files
import { CustomCarousel, CustomLightBox, NoDataAvailable } from "components"
import { useWindow } from "hooks"

const ArtistPhotosTabItem = ({ artistPhotos }) => {
  const isMobileScreen = useWindow().windowData.isMobileView
  // open light box
  const [openLightBox, setOpenLightBox] = useState(false)
  // current index
  const [currentIndex, setCurrentIndex] = useState(0)

  return (
    <div>
      <CustomLightBox
        imgData={artistPhotos?.map((i) => i?.imageUrl)}
        setOpenLightBox={setOpenLightBox}
        openLightBox={openLightBox}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />
      <Typography variant="h5" gutterBottom className="text-primary-2">
        All Photos
      </Typography>
      {artistPhotos?.length > 0 && (
        <>
          {isMobileScreen ? (
            <div className="artist-photos-container">
              {/* Carousel for mobile view */}
              <CustomCarousel>
                {artistPhotos?.map((img, img_index) => (
                  <div
                    className="artist-photos-root cursor-pointer"
                    key={img?._id}
                    role={"button"}
                    onClick={() => {
                      setCurrentIndex(img_index)
                      setOpenLightBox(true)
                    }}
                  >
                    <img
                      src={img?.imageUrl}
                      alt=""
                      height={"200px"}
                      width={"250px"}
                      className="object-fit-cover box-shadow-1 border-5 cursor-pointer artist-photo m-0-0_5em"
                    />
                  </div>
                ))}
              </CustomCarousel>
            </div>
          ) : (
            <>
              {/*grid container web view photos */}
              <Grid container spacing={2} alignItems={"center"}>
                {artistPhotos?.map((img, img_index) => (
                  <Grid item xs={12} md={4} sm={6} lg={4} key={img?._id}>
                    <div
                      className="artist-photos-root cursor-pointer"
                      role="button"
                      onClick={() => {
                        setCurrentIndex(img_index)
                        setOpenLightBox(true)
                      }}
                    >
                      <img
                        src={img?.imageUrl}
                        alt=""
                        height={"200px"}
                        width={"100%"}
                        className="object-fit-cover box-shadow-1 border-5 cursor-pointer artist-photo"
                      />
                    </div>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </>
      )}
      {!artistPhotos?.length > 0 && <NoDataAvailable />}
    </div>
  )
}

export default ArtistPhotosTabItem
