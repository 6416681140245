import { Button, Grid, Typography } from "@mui/material"
import { CustomCarousel } from "components/core"
import { useEventTypes, useWindow } from "hooks"
import { useNavigate } from "react-router-dom"
import EventCard from "./EventCard"

const EventTypes = () => {
  // navigate
  const navigate = useNavigate()
  const { windowData } = useWindow()
  const { allEventTypes } = useEventTypes()

  return (
    <div>
      <div className="event-type-header m-1-0 flex-row flex-justify-between flex-align-center">
        <div>
          <Typography variant="h5" className="heading-text-color">
            Book for any event
          </Typography>
          {windowData?.width < 600 ? null : (
            <Typography variant="body1" color="var(--col-secondary)">
              Book for any event, from a wedding to a birthday party.
            </Typography>
          )}
        </div>
        <div>
          <Button
            variant="outlined"
            className="font-size-small font-bold text-primary-2 border-primary-2"
            onClick={() => {
              navigate("/events")
            }}
          >
            View All
          </Button>
        </div>
      </div>
      <div className="event-type-body w-100">
        {windowData?.width < 600 ? (
          <CustomCarousel columnGap={".5em"} id="event-type-cards">
            <div className="flex-row flex-align-center col-gap-1em m-0_5em-0">
              {allEventTypes?.map((event, i) => (
                <EventCard key={i} data={event} />
              ))}
            </div>
          </CustomCarousel>
        ) : (
          <Grid container spacing={2}>
            {allEventTypes?.map((event, i) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                <EventCard key={i} data={event} />
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </div>
  )
}

export default EventTypes
