import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchSentRequests,
  loadingReceivedRequests,
  loadingSentRequests,
  receivedRequestsSelector,
  requestStateSelector,
  sentRequestsSelector,
  fetchReceivedRequests,
  acceptRequestAction,
  setRequestDetailsAction,
  resetRequestDetailsAction,
  requestDetailsSelector,
  removeRequestAction,
  removeRequestStateSelector,
  removeRequestResetAction,
  setPriceAction,
  paymentRequestAcceptAction,
  rejectRequestAllAction,
  acceptManagerRequestAction,
  acceptRescheduleRequestSuccessSelector,
} from "redux/actions"

const useRequests = () => {
  const dispatch = useDispatch()
  const requestsState = useSelector((state) => requestStateSelector(state))
  const sentRequests = useSelector((state) => sentRequestsSelector(state))
  const receivedRequests = useSelector((state) =>
    receivedRequestsSelector(state)
  )
  const isLoadingSentRequests = useSelector((state) =>
    loadingSentRequests(state)
  )
  const isLoadingReceivedRequests = useSelector((state) =>
    loadingReceivedRequests(state)
  )

  const handleFetchSentRequests = useCallback(
    (currentUserID) => {
      dispatch(fetchSentRequests(currentUserID))
    },
    [dispatch]
  )

  const handleFetchReceivedRequests = useCallback(
    (currentUserID) => {
      dispatch(fetchReceivedRequests(currentUserID))
    },
    [dispatch]
  )

  // action
  const isRescheduleRequestAccepted = useSelector((state) =>
    acceptRescheduleRequestSuccessSelector(state)
  )
  const handleAcceptRequest = useCallback(
    (requestID, permission, actionBy) => {
      dispatch(acceptRequestAction(requestID, permission, actionBy))
    },
    [dispatch]
  )
  const handleRejectRequestAll = useCallback(
    (requestID, reason, actionBy, type) => {
      dispatch(rejectRequestAllAction(requestID, reason, actionBy, type))
    },
    [dispatch]
  )

  // set & reset request details
  const requestDetails = useSelector((state) => requestDetailsSelector(state))
  const handleSetRequestDetails = useCallback(
    (request) => {
      dispatch(setRequestDetailsAction(request))
    },
    [dispatch]
  )
  const handleResetRequestDetails = useCallback(() => {
    dispatch(resetRequestDetailsAction())
  }, [dispatch])

  // remove request
  const handleRemoveRequest = useCallback(
    (reqID, userID) => {
      dispatch(removeRequestAction(reqID, userID))
    },
    [dispatch]
  )
  const removeRequestState = useSelector((state) =>
    removeRequestStateSelector(state)
  )
  const handleResetRemoveRequest = useCallback(() => {
    dispatch(removeRequestResetAction())
  }, [dispatch])

  // set price
  const handleSetPrice = useCallback(
    (requestID, price) => {
      dispatch(setPriceAction(requestID, price))
    },
    [dispatch]
  )

  // payment request
  const handleAcceptPaymentRequest = useCallback(
    (reqID) => {
      dispatch(paymentRequestAcceptAction(reqID))
    },
    [dispatch]
  )

  // check if request is already sent and status is pending
  const isRequestSentAndPending = useCallback((requests, receiverID) => {
    if (requests) {
      return (
        requests?.filter(
          (request) =>
            request?.receiverUser?._id === receiverID &&
            request?.status?.toLowerCase() === "pending" &&
            request?.requestType === "manager"
        )?.length > 0
      )
    }
    return false
  }, [])

  // accept manager requests
  const handleAcceptManagerRequest = useCallback(
    (reqID) => {
      dispatch(acceptManagerRequestAction(reqID))
    },
    [dispatch]
  )

  return {
    requestsState,
    sentRequests,
    receivedRequests,
    isLoadingSentRequests,
    isLoadingReceivedRequests,
    // action
    handleAcceptRequest,
    handleFetchReceivedRequests,
    handleFetchSentRequests,
    handleRejectRequestAll,
    isRescheduleRequestAccepted,
    // set & reset request details
    requestDetails,
    handleSetRequestDetails,
    handleResetRequestDetails,
    // remove request
    handleRemoveRequest,
    removeRequestState,
    handleResetRemoveRequest,
    // set price
    handleSetPrice,
    // payment request
    handleAcceptPaymentRequest,
    // check if request is already sent and status is pending
    isRequestSentAndPending,
    // accept manager requests
    handleAcceptManagerRequest,
  }
}

export default useRequests
