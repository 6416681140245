import {
  CancelOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@mui/icons-material"
import {
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material"
import { CustomButton } from "components"
const CustomReasonDialog = ({
  open,
  isMobileView,
  handleClose,
  onClick,
  reason,
  onChangeReason,
  isValidReason,
  headerTitle,
  actionTitle,
}) => {
  return (
    <Dialog open={open} maxWidth="sm" fullWidth fullScreen={isMobileView}>
      <DialogContent>
        <div className="flex-row flex-align-center flex-justify-between">
          <Typography variant="h5" className="font-bold text-primary-2">
            {headerTitle}
          </Typography>
          <IconButton
            onClick={() => {
              handleClose()
            }}
          >
            <CloseOutlined />
          </IconButton>
        </div>
        <Divider className="m-0_5em-0" />
        <Typography
          variant="body1"
          gutterBottom
          className="font-bold text-primary-1"
        >
          {actionTitle}
        </Typography>
        <TextField
          value={reason}
          onChange={onChangeReason}
          fullWidth
          variant="outlined"
          placeholder="Write your reason here"
          multiline
          rows={5}
          InputProps={{
            classes: {
              notchedOutline: "border-black-1px border-5",
            },
          }}
        />
        <div className="booking-cancel-action m-1-0">
          <CustomButton
            btnText={"Back"}
            disableElevation={true}
            className="text-primary-1"
            endIcon={<CancelOutlined />}
            onClick={() => {
              handleClose()
            }}
          />
          <CustomButton
            btnText={"Confirm Cancel"}
            className="m-0-0_5em text-primary-2"
            disableElevation={true}
            endIcon={<CheckOutlined />}
            onClick={() => {
              onClick()
            }}
            disabled={!isValidReason}
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default CustomReasonDialog
