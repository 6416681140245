import {
  Apartment,
  CheckCircleOutlined,
  DateRangeOutlined,
  LocationOnOutlined,
  MoneyOutlined,
  PeopleOutline,
} from "@mui/icons-material";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
// import form other files
import { CustomButton, Loader } from "components";
import { hideDivider, getTimeFromNow } from "helpers";
import { useCurrencyContext, useLoader } from "hooks";

const ArtistPricingCard = ({
  isArtistDetails,
  handleRemoveDialogOpen,
  handleOpenUpdatePricingDialog,
  pricing,
  handleSetPricingList,
  isUser,
  handleSelectPricing,
  isSelected,
}) => {
  const { currencyWiseValue } = useCurrencyContext();
  const dispatch = useDispatch();
  const { namesInLoaderActions } = useLoader();
  const PricingDetails = [
    {
      title: "Service Type",
      value: pricing?.type === "NORMAL"?"Standard Hourly Pricing":"Personalized Video Message Pricing",
      icon: <Apartment />,
    },
    {
      title: pricing?.type === "NORMAL" ? "Price Per Hour" : "Price",
      value: `${currencyWiseValue(
        pricing?.pricePerHour || pricing?.personalizePrice
      )}`,
      icon: <MoneyOutlined />,
    },
    {
      title: "Crowd Size",
      value:
        pricing?.minCrowdSize && pricing?.maxCrowdSize
          ? `${pricing?.minCrowdSize}-${pricing?.maxCrowdSize}`
          : "Not Provided",
      icon: <PeopleOutline />,
    },
    {
      title: "Location",
      value: pricing?.location?.address || "Not Provided",
      icon: <LocationOnOutlined />,
    },
    {
      title: "Day(s)",
      value: pricing?.numberOfDays || "Not Provided",
      icon: <DateRangeOutlined />,
    },
  ];
  return (
    <div className="artist-pricing-card-root box-shadow-1 w-100 h-100 p-1em border-5">
      <div className="flex-row flex-justify-end">
        <Typography variant="caption" className="font-bold text-primary-10">
          {getTimeFromNow(pricing?.timestamp)}
        </Typography>
      </div>
      <div className="artist-pricing-card-body">
        {PricingDetails?.map((item, index) => (
          <div key={item.title}>
            <div
              className={`artist-pricing-card-list-item flex-row flex-align-center w-100`}
            >
              <div className="text-secondary">{item.icon}</div>
              <div className="artist-pricing-card-list-item-text m-0-1em text-ellipses">
                <Typography className="font-bold" variant="body1">
                  {item.title}
                </Typography>
                <Typography variant="body2" className="text-ellipses">
                  {item.value}
                </Typography>
              </div>
            </div>
            <div className="m-0_5em-0">
              {hideDivider(index, PricingDetails.length)}
            </div>
          </div>
        ))}
        {/* pricing card action button */}
        {!isArtistDetails && !isUser && (
          <div className="artist-pricing-card-list-action m-1-0">
            <CustomButton
              btnText="Remove"
              className="border-primary-1 text-primary-1"
              onClick={() => {
                handleRemoveDialogOpen(pricing._id);
              }}
              startIcon={
                namesInLoaderActions?.includes(
                  `REMOVE_PRICING_LIST_${pricing?._id}`
                ) ? (
                  <Loader
                    circularProgressSize={20}
                    isCircularLoader
                    className={"bg-none"}
                  />
                ) : (
                  <></>
                )
              }
              disabled={namesInLoaderActions?.includes(
                `REMOVE_PRICING_LIST_${pricing?._id}`
              )}
            />
            <CustomButton
              btnText="Update"
              className="m-0-1em border-primary-2 text-primary-2"
              onClick={() => {
                handleOpenUpdatePricingDialog();
                const pricingObject = {
                  ...pricing,
                  serviceType:
                    pricing?.type === "NORMAL" ? "Normal" : "Personalize",
                  pricePerHour:
                    pricing?.pricePerHour || pricing?.personalizePrice,
                };
                delete pricingObject.type;
                delete pricingObject.personalizePrice;
                handleSetPricingList(pricingObject);
                dispatch({
                  type: "ADD_PRICING_LIST_RESET",
                });
                dispatch({
                  type: "RESET_ARTIST_PRICING",
                });
              }}
            />
          </div>
        )}
        {isUser && (
          <div className="artist-pricing-card-list-action text-center">
            <CustomButton
              btnText="Select"
              className="m-0-1em border-primary-2 text-primary-2"
              endIcon={isSelected ? <CheckCircleOutlined /> : <></>}
              onClick={() => {
                handleSetPricingList(pricing);
                dispatch({
                  type: "ADD_PRICING_LIST_RESET",
                });
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ArtistPricingCard;
