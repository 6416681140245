import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchAllReviews,
  fetchReviewsByArtistId,
  giveReviewForArtist,
  loadingReviewsSelector,
  ratingsOfArtistSelector,
  reviewsByArtistLoadingSelector,
  reviewsByArtistSelector,
  selectAllReviews,
  sortedAllReviewsSelector,
  sortedReviewsByArtistSelector,
  sortOldestReviewsSelector,
  totalReviewsOfArtistSelector,
} from "redux/actions"
import { useIsMounted } from "hooks"

const useReviews = (artistID) => {
  const isMounted = useIsMounted()
  const dispatch = useDispatch()
  const reviewsState = useSelector((state) => state?.reviewsState)
  const reviews = useSelector((state) => selectAllReviews(state))
  const sortedReviews = useSelector((state) => sortedAllReviewsSelector(state))
  const oldestReviews = useSelector((state) => sortOldestReviewsSelector(state))
  const isLoadingReviews = useSelector((state) => loadingReviewsSelector(state))

  // reviews by artist
  const reviewsByArtist = useSelector((state) => reviewsByArtistSelector(state))
  const sortedReviewsByArtist = useSelector((state) =>
    sortedReviewsByArtistSelector(state)
  )
  const isLoadingReviewsByArtist = useSelector((state) =>
    reviewsByArtistLoadingSelector(state)
  )
  const totalReviewsOfArtist = useSelector((state) =>
    totalReviewsOfArtistSelector(state)
  )
  const avgRatingOfArtist = useSelector((state) =>
    ratingsOfArtistSelector(state)
  )
  useEffect(() => {
    if (isMounted()) {
      dispatch(fetchAllReviews())
    }
  }, [dispatch, isMounted])

  const getReviewsByArtistId = useCallback(
    (artistId) => {
      dispatch(fetchReviewsByArtistId(artistId))
    },
    [dispatch]
  )

  useEffect(() => {
    if (isMounted()) {
      getReviewsByArtistId(artistID)
    }
  }, [artistID, getReviewsByArtistId, isMounted])

  const handleGiveReviewForArtist = useCallback(
    (userID, title, desc, ratings) => {
      dispatch(giveReviewForArtist(artistID, userID, title, desc, ratings))
    },
    [dispatch, artistID]
  )

  return {
    reviewsState,
    reviews,
    getReviewsByArtistId,
    sortedReviews,
    oldestReviews,
    isLoadingReviews,
    // reviews by artist
    reviewsByArtist,
    isLoadingReviewsByArtist,
    totalReviewsOfArtist,
    avgRatingOfArtist,
    sortedReviewsByArtist,
    // give review for artist
    handleGiveReviewForArtist,
  }
}

export default useReviews
