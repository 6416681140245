import {
  Circle,
  DeleteOutlined,
  NotificationsOutlined,
} from "@mui/icons-material"
import {
  Avatar,
  Badge,
  Chip,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material"
import { NOTIFICATION_ICON } from "assets"
import { Loader } from "components/core"
// import from other files
import { getTimeFromNow, getText } from "helpers"
import { useLoader } from "hooks"
const NotificationCard = ({
  notification,
  handleSetNotificationBody,
  handleReadNotifications,
  handleDeleteNotifications,
  handleCloseNotificationBody,
}) => {
  const { namesInLoaderActions } = useLoader()
  return (
    <div>
      <ListItem
        alignItems="flex-start"
        className="bg-whitesmoke cursor-pointer position-relative m-1-0"
        key={notification?._id}
      >
        <div
          className="flex-column w-100"
          onClick={() => {
            handleSetNotificationBody(notification)
            handleReadNotifications([notification?._id], false)
          }}
        >
          <div
            className="notification-list-item flex-row m-1-0 w-100"
            role="button"
          >
            <div className="unread-message-btn">
              {!notification?.isRead ? (
                <Circle className="text-primary-3 font-size-small" />
              ) : (
                <Circle className="text-transparent font-size-small" />
              )}
            </div>
            {/* status icon with avatar */}
            <ListItemAvatar>
              <Badge
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                badgeContent={
                  <IconButton className="bg-primary-10 text-white position-center p-0_1em border-circle">
                    <img
                      src={notification?.iconUrl}
                      alt=""
                      height={"15px"}
                      width="15px"
                      className="object-fit-cover"
                      onError={(e) => {
                        e.target.src = NOTIFICATION_ICON
                      }}
                    />
                  </IconButton>
                }
              >
                <Avatar className="bg-primary-2 text-white font-bold">
                  <NotificationsOutlined />
                </Avatar>
              </Badge>
            </ListItemAvatar>
            {/* notification title and description */}
            <ListItemText
              primary={
                <Typography variant="body1" className="font-bold text-ellipses">
                  {notification.title}
                </Typography>
              }
              secondary={
                <Typography variant="body2">
                  {getText(notification.description, 70)}
                </Typography>
              }
            />
          </div>
          {/* time from now */}
          <Chip
            label={getTimeFromNow(notification?.timestamp)}
            className="w-fit border-5 bg-col-white text-primary-6 font-bold"
          />
        </div>
        {namesInLoaderActions?.includes(
          `REMOVE_NOTIFICATION_${notification?._id}`
        ) ? (
          <div className="notification-delete-icon border-circle position-center">
            <Loader bgNone={true} isCircularLoader circularProgressSize={22} />
          </div>
        ) : (
          <div
            className="notification-delete-icon border-circle position-center"
            role={"button"}
            onClick={() => {
              handleCloseNotificationBody()
              handleDeleteNotifications([notification?._id], false)
            }}
          >
            <DeleteOutlined className="text-primary-1" />
          </div>
        )}
      </ListItem>
    </div>
  )
}

export default NotificationCard
