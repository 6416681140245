import { Close } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";

const CommonPageTextHeader = ({ title, subTitle, onClose }) => {
  return (
    <div className="position-relative">
      <div
        className={`position-relative flex-column flex-align-start flex-justify-end p-1em-0`}
      >
        <div className="d-flex flex-align-center">
          <Typography
            variant="h4"
            className="text-primary-2 zIndex-1 font-bold"
          >
            {title}
          </Typography>
          {onClose && (
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          )}
        </div>
        {subTitle?.length > 0 && (
          <Typography variant="body1" className="text-start" gutterBottom>
            {subTitle}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default CommonPageTextHeader;
