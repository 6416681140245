import produce from "immer"

const initialState = {
  uiAction: {
    loader: [],
  },
}
const LoaderReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    const { type, payload } = action
    const { uiAction } = draft
    switch (type) {
      case "START_ACTION":
        uiAction.loader = [...uiAction.loader, payload]
        break
      case "END_ACTION":
        uiAction.loader = uiAction.loader.filter(
          (item) => item?.name !== payload?.name
        )
        break
      default:
        return draft
    }
  })
}

export default LoaderReducer
