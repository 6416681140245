import { CameraAltOutlined } from "@mui/icons-material"
import {
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { Formik } from "formik"
import { USER_RECTANGLE_PLACEHOLDER } from "assets"
import {
  CustomButton,
  CustomPhoneNumberPicker,
  Loader,
  CustomSnackbar,
  PlaceAutoComplete,
} from "components"
import { useApi, useIsMounted, useSnackbar, useUsers } from "hooks"
import { managerProfileErrSchema } from "schemas"

const ManagerManageProfile = ({ isMobileView }) => {
  const isMounted = useIsMounted()
  const {
    currentUserID,
    handleGetUserDetails,
    userDetails,
    loadingUserDetails,
  } = useUsers()
  const imageUploadRef = useRef(null)
  const [managerImg, setManagerImg] = useState({
    objUrl: "",
    src: "",
  })
  const { postData, isPosted, handleResetPostApi, isPosting } = useApi()
  const { snackbar, handleOpenSnackbar, handleCloseSnackbar } = useSnackbar()

  useEffect(() => {
    if (isMounted() && userDetails) {
      setManagerImg({
        objUrl: "",
        src: userDetails?.profileImageUrl,
      })
    }
  }, [isMounted, userDetails])

  // upload images
  const handleUploadImage = (file) => {
    const formData = new FormData()
    if (file) {
      formData.append("profilePicture", file)
      postData(`user/account-update/${currentUserID}`, formData, "PUT")
      return
    }
  }

  useEffect(() => {
    if (isMounted() && isPosted) {
      handleOpenSnackbar({
        message: "Updated Successfully",
        variant: "success",
      })
    }
  }, [handleOpenSnackbar, isMounted, isPosted])

  // upload profile information
  const handleSubmitValues = (values) => {
    postData(
      `user/account-update/${currentUserID}`,
      {
        ...values,
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        phoneNumber: values?.phoneNumber,
        countryCode: values?.countryCode,
        gender: values?.gender,
        address: values?.address,
        country: values?.country,
        lat: values?.lat,
        lng: values?.lng,
        yearsOfExperience: values?.yearsOfExperience,
      },
      "PUT"
    )
  }

  useEffect(() => {
    if (isMounted() && currentUserID) {
      handleGetUserDetails(currentUserID)
    }
  }, [handleGetUserDetails, currentUserID, isMounted, isPosted])

  return (
    <div className="manager-manage-profile-root m-1-0">
      {isPosting && (
        <Loader
          isBackDrop={true}
          isCircularLoader
          fullScreen={true}
          className="zIndex-9999"
        />
      )}
      <CustomSnackbar
        open={snackbar.open}
        handleClose={() => {
          handleCloseSnackbar()
          handleResetPostApi()
        }}
        variant={snackbar.variant}
        message={snackbar.message}
      />
      {!loadingUserDetails && (
        <Grid container spacing={2} className="m-t-1em">
          <Grid item xs={12} sm={6} md={4} lg={3}>
            {/* manage profile image */}
            <div
              className={`manager-manage-profile-img position-center m-b-1em`}
            >
              <div className="manager-profile-img position-relative w-fit">
                <img
                  src={
                    managerImg?.objUrl
                      ? managerImg?.objUrl
                      : managerImg?.src
                      ? managerImg?.src
                      : USER_RECTANGLE_PLACEHOLDER
                  }
                  alt=""
                  height={"210px"}
                  width={"210px"}
                  className="border-15 object-fit-cover box-shadow-1"
                  onError={(e) => {
                    e.target.src = USER_RECTANGLE_PLACEHOLDER
                  }}
                />
                {/* upload image action button */}
                <div className="upload-manager-img text-center">
                  <input
                    type={"file"}
                    accept="image/*"
                    onChange={(ev) => {
                      const url = URL.createObjectURL(
                        new Blob([ev.target.files[0]])
                      )
                      setManagerImg({
                        objUrl: url,
                        src: "",
                      })
                      handleUploadImage(ev.target.files[0])
                    }}
                    hidden
                    ref={imageUploadRef}
                  />
                  <IconButton
                    className="bg-col-white box-shadow-1"
                    variant="outlined"
                    onClick={() => {
                      imageUploadRef.current.click()
                    }}
                  >
                    <CameraAltOutlined className="text-primary-2" />
                  </IconButton>
                </div>
              </div>
            </div>
          </Grid>
          {/* manage profile information */}
          <Grid item xs={12} sm={6} md={8} lg={9}>
            <div className="manager-profile-info">
              <Typography variant="h6" gutterBottom>
                Profile Information
              </Typography>
              <Formik
                initialValues={{
                  firstName: userDetails?.firstName ?? "",
                  lastName: userDetails?.lastName ?? "",
                  email: userDetails?.email ?? "",
                  phoneNumber: userDetails?.phoneNumber ?? "",
                  countryCode: userDetails?.countryCode ?? "",
                  address: userDetails?.location?.address ?? "",
                  country: userDetails?.location?.country ?? "",
                  lat: userDetails?.location?.lat ?? 0,
                  lng: userDetails?.location?.lng ?? 0,
                  gender: userDetails?.gender ?? "Male",
                  yearsOfExperience: userDetails?.yearsOfExperience ?? 0,
                }}
                validationSchema={managerProfileErrSchema}
                onSubmit={handleSubmitValues}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  setFieldTouched,
                  errors,
                  touched,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} alignItems="flex-end">
                      {/* first name */}
                      <Grid item xs={12} sm={6}>
                        <Typography gutterBottom className="flex-column">
                          First Name *
                          <span className="font-bold font-size-small text-primary-1">
                            {touched.firstName && errors.firstName
                              ? errors.firstName
                              : ""}
                          </span>
                        </Typography>
                        <TextField
                          fullWidth
                          name="firstName"
                          value={values.firstName}
                          onChange={(ev) => {
                            setFieldTouched("firstName", true, false)
                            handleChange(ev)
                          }}
                          error={
                            touched.firstName && errors.firstName ? true : false
                          }
                        />
                      </Grid>
                      {/* last name */}
                      <Grid item xs={12} sm={6}>
                        <Typography gutterBottom className="flex-column">
                          Last Name *
                          <span className="font-bold font-size-small text-primary-1">
                            {touched.lastName && errors.lastName
                              ? errors.lastName
                              : ""}
                          </span>
                        </Typography>
                        <TextField
                          fullWidth
                          name="lastName"
                          value={values.lastName}
                          onChange={(ev) => {
                            setFieldTouched("lastName", true, false)
                            handleChange(ev)
                          }}
                          error={
                            touched.lastName && errors.lastName ? true : false
                          }
                        />
                      </Grid>
                      {/* email */}
                      <Grid item xs={12} sm={6}>
                        <Typography gutterBottom className="flex-column">
                          Email
                          <span className="font-bold font-size-small text-primary-1">
                            You can't change your email address
                          </span>
                        </Typography>
                        <TextField
                          fullWidth
                          placeholder="example@gmail.com"
                          value={values.email}
                          disabled
                          name="email"
                        />
                      </Grid>
                      {/* gender */}
                      <Grid item xs={12} sm={6}>
                        <Typography gutterBottom>Gender</Typography>
                        <TextField
                          fullWidth
                          select
                          name="gender"
                          value={values.gender}
                          onChange={(ev) => {
                            handleChange(ev)
                            setFieldTouched("gender", true)
                          }}
                        >
                          {["Male", "Female", "Other"]?.map((gender) => (
                            <MenuItem key={gender} value={gender}>
                              {gender}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      {/* phone number */}
                      <Grid item xs={12} sm={6}>
                        <Typography gutterBottom className="flex-column">
                          Phone Number
                          <span className="font-bold font-size-small text-primary-1">
                            {touched.phoneNumber && errors.phoneNumber
                              ? errors.phoneNumber
                              : ""}
                          </span>
                        </Typography>
                        <CustomPhoneNumberPicker
                          name={"phoneNumber"}
                          value={values.phoneNumber}
                          onChange={(ev) => {
                            setFieldTouched("phoneNumber", true)
                            setFieldValue("phoneNumber", ev.target.value)
                          }}
                          error={
                            touched.phoneNumber && errors.phoneNumber
                              ? true
                              : false
                          }
                          countryCode={values.countryCode}
                          countryCodeName={"countryCode"}
                          onChangeCountryCode={(ev) => {
                            setFieldTouched("countryCode", true)
                            setFieldValue("countryCode", ev.target.value)
                          }}
                        />
                      </Grid>
                      {/* location */}
                      <Grid item xs={12} sm={6}>
                        <Typography gutterBottom>Location</Typography>
                        <PlaceAutoComplete
                          className={"border-5"}
                          address={values.address}
                          name={"address"}
                          handleLocationAddress={(address) => {
                            setFieldValue("address", address)
                            setFieldTouched("address", true)
                          }}
                          handleCountry={(country) => {
                            setFieldValue("country", country)
                            setFieldTouched("country", true)
                          }}
                          handleLocation={(lat, lng) => {
                            setFieldValue("lat", lat)
                            setFieldValue("lng", lng)
                            setFieldTouched("lat", true)
                            setFieldTouched("lng", true)
                          }}
                        />
                      </Grid>
                      {/* years of experience */}
                      <Grid item xs={12} sm={6}>
                        <Typography gutterBottom className="flex-column">
                          Years Of Experience
                          <span className="font-bold font-size-small text-primary-1">
                            {touched.yearsOfExperience &&
                            errors.yearsOfExperience
                              ? errors.yearsOfExperience
                              : ""}
                          </span>
                        </Typography>
                        <TextField
                          fullWidth
                          placeholder="Only Number"
                          name="yearsOfExperience"
                          value={values.yearsOfExperience}
                          onChange={(ev) => {
                            setFieldTouched("yearsOfExperience", true)
                            handleChange(ev)
                          }}
                          error={
                            touched.yearsOfExperience &&
                            errors.yearsOfExperience
                              ? true
                              : false
                          }
                        />
                      </Grid>
                      {/* submit action button */}
                      <Grid item xs={12} className="text-center">
                        <div className="m-1-0">
                          <CustomButton
                            btnText="Save Changes"
                            variant="contained"
                            className="btn-gradient font-size-1em font-bold"
                            disableElevation
                            fullWidth={isMobileView}
                            type="submit"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </div>
          </Grid>
        </Grid>
      )}
      {loadingUserDetails && (
        <div className="h-50vh">
          <Loader isCircularLoader />
        </div>
      )}
    </div>
  )
}

export default ManagerManageProfile
