import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  artistVisitorsSelector,
  artistVisitorsStateSelector,
  favByUsersSelector,
  favByUsersStateSelector,
  fetchArtistVisitorsAction,
  fetchFavByUsersAction,
  noOfTimesVisitedSelector,
  noOfMinutesSpentSelector,
  artistsVisitorsLengthSelector,
  artistVisitorsLoadingSelector,
  favByUsersLengthSelector,
  favByUsersLoadingSelector,
  fetchArtistRevenueAction,
  artistRevenueSelector,
  artistTotalRevenueSelector,
  artistLastRevenueSelector,
  artistRevenueLoadingSelector,
  artistRevenueLengthSelector,
} from "redux/actions"

const useArtistDashboard = () => {
  useSelector((state) => artistVisitorsStateSelector(state))
  const dispatch = useDispatch()
  const artistVisitors = useSelector((state) => artistVisitorsSelector(state))
  const noOfTimesVisitorsVisited = useSelector((state) =>
    noOfTimesVisitedSelector(state)
  )
  const noOfMinutesSpentByVisitors = useSelector((state) =>
    noOfMinutesSpentSelector(state)
  )
  const artistVisitorsLength = useSelector((state) =>
    artistsVisitorsLengthSelector(state)
  )
  const isLoadingArtistVisitors = useSelector((state) =>
    artistVisitorsLoadingSelector(state)
  )
  //   fav by users
  useSelector((state) => favByUsersStateSelector(state))
  const favByUsers = useSelector((state) => favByUsersSelector(state))
  const noOfFavByUsers = useSelector((state) => favByUsersLengthSelector(state))
  const isLoadingFavByUsers = useSelector((state) =>
    favByUsersLoadingSelector(state)
  )

  //   fetch artist visitors
  const handleFetchArtistVisitors = useCallback(
    (artistID, pageLength) => {
      dispatch(fetchArtistVisitorsAction(artistID, pageLength))
    },
    [dispatch]
  )
  //   fetch favorite by users
  const handleFetchFavByUsers = useCallback(
    (artistID) => {
      dispatch(fetchFavByUsersAction(artistID))
    },
    [dispatch]
  )

  // fetch artist revenue
  const artistRevenue = useSelector((state) => artistRevenueSelector(state))
  const artistTotalRevenue = useSelector((state) =>
    artistTotalRevenueSelector(state)
  )
  const artistLastRevenue = useSelector((state) =>
    artistLastRevenueSelector(state)
  )
  const isLoadingArtistRevenue = useSelector((state) =>
    artistRevenueLoadingSelector(state)
  )
  const artistRevenueLength = useSelector((state) =>
    artistRevenueLengthSelector(state)
  )
  const handleFetchArtistRevenue = useCallback(
    (artistID, count) => {
      dispatch(fetchArtistRevenueAction(artistID, count))
    },
    [dispatch]
  )

  return {
    artistVisitors,
    handleFetchArtistVisitors,
    noOfTimesVisitorsVisited,
    noOfMinutesSpentByVisitors,
    artistVisitorsLength,
    isLoadingArtistVisitors,
    //  fetch favorite by users
    handleFetchFavByUsers,
    favByUsers,
    noOfFavByUsers,
    isLoadingFavByUsers,
    // fetch artist revenue
    handleFetchArtistRevenue,
    artistRevenue,
    artistTotalRevenue,
    artistLastRevenue,
    isLoadingArtistRevenue,
    artistRevenueLength,
  }
}

export default useArtistDashboard
