import {
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  CloseOutlined,
  DeleteOutlineOutlined,
  FileUploadOutlined,
} from "@mui/icons-material"
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material"
import { LINK_LOGO } from "assets"
import {
  CustomButton,
  CustomSnackbar,
  EmptyData,
  Loader,
} from "components/core"
import { getSelectedItems } from "helpers"
import { useIsMounted, useSnackbar, useSocialLinks, useWindow } from "hooks"
import React, { useEffect, useState } from "react"
import { useMemo } from "react"

const ArtistManageLinks = ({ artistID }) => {
  const { windowData } = useWindow()
  const isMounted = useIsMounted()
  const { snackbar, handleOpenSnackbar, handleCloseSnackbar } = useSnackbar()
  const {
    postLinks,
    onPostLinks,
    getLinks,
    onGetLinks,
    onDeleteLinks,
    deleteLinks,
    onResetLinks,
  } = useSocialLinks()
  const [isLinkAddDialogOpen, setIsLinkAddDialogOpen] = useState(false)
  const [link, setLink] = useState("")
  const [linkPreviews, setLinkPreviews] = useState([])
  const [linkErr, setLinkErr] = useState({
    status: null,
    message: null,
  })
  const [selectedLinks, setSelectedLinks] = useState([])

  //   *link error state update
  const onSetLinkError = (errMsg) => {
    setLinkErr((pre) => {
      return {
        ...pre,
        status: 400,
        message: errMsg,
      }
    })
  }

  //   *add link, show preview and then upload
  const addLink = (id, link) => {
    if (!link) {
      onSetLinkError("Please enter the link")
      return
    }
    setLinkPreviews((pre) => {
      return [
        ...pre,
        {
          id,
          link,
        },
      ]
    })
  }

  //   *remove preview link
  const removePreviewLink = (link) => {
    const rest = linkPreviews?.reduce((acc, curr) => {
      if (curr?.id !== link?.id) {
        acc.push(curr)
        return acc
      }
      return acc
    }, [])
    setLinkPreviews(rest)
  }

  // *select multiple links
  const onSelectLink = (linkID) => {
    setSelectedLinks(getSelectedItems(selectedLinks, linkID))
  }

  // *fetch links
  useEffect(() => {
    if (isMounted()) {
      onGetLinks(artistID)
    }
  }, [
    onGetLinks,
    artistID,
    isMounted,
    postLinks?.successMsg,
    deleteLinks?.successMsg,
  ])

  // *links array
  const linksArr = useMemo(
    () => linkPreviews?.map((link) => link.link),
    [linkPreviews]
  )

  // *post links
  useEffect(() => {
    if (isMounted() && Boolean(postLinks?.successMsg)) {
      setIsLinkAddDialogOpen(false)
      setLinkPreviews([])
      setLink("")
      handleOpenSnackbar({
        message: "Link(s) added successfully",
        variant: "success",
      })
      onResetLinks()
    }
  }, [postLinks?.successMsg, isMounted, handleOpenSnackbar, onResetLinks])

  // *delete links
  useEffect(() => {
    if (isMounted() && Boolean(deleteLinks?.successMsg)) {
      setSelectedLinks([])
      handleOpenSnackbar({
        message: "Link(s) deleted successfully",
        variant: "success",
      })
      onResetLinks()
    }
  }, [deleteLinks?.successMsg, isMounted, handleOpenSnackbar, onResetLinks])

  return (
    <div className="artist-managed-links">
      <CustomSnackbar
        open={snackbar?.open}
        handleClose={() => {
          handleCloseSnackbar()
        }}
        message={snackbar.message}
        variant={snackbar.variant}
      />
      {getLinks?.isGetting && (
        <div className="h-50vh w-100">
          <Loader isCircularLoader={true} />
        </div>
      )}

      {!getLinks?.isGetting && (
        <>
          <Typography variant="h6" className="text-primary-8 " gutterBottom>
            All Links
          </Typography>
          <div className="select-files position-center flex-column p-1em border-5 m-b-1em box-shadow-normal m-t-1em">
            <Typography variant="h6" className="text-center">
              Click on the Add Link button to add more links
            </Typography>
            <CustomButton
              btnText="Add Link"
              className="m-b-1em border-primary-2 text-primary-2 m-0_5em-0"
              endIcon={<FileUploadOutlined />}
              onClick={() => {
                setLinkPreviews([])
                setIsLinkAddDialogOpen(true)
              }}
            />
          </div>
          {getLinks?.data?.length > 0 ? (
            <Box sx={{ width: "100%", mt: "2em" }}>
              {/* delete selected items */}
              {selectedLinks?.length > 0 && (
                <div className="flex flex-row flex-justify-between flex-align-center p-0_5em-1em bg-red text-white m-1-0 border-5 delete-link">
                  <Typography>
                    {`${selectedLinks?.length} item(s) selected`}
                  </Typography>
                  <CustomButton
                    btnText={deleteLinks?.isDeleting ? "Removing..." : "Remove"}
                    size="small"
                    onClick={() => {
                      onDeleteLinks(artistID, selectedLinks)
                    }}
                    disabled={deleteLinks?.isDeleting}
                  />
                </div>
              )}
              {/* all links */}
              <Grid container spacing={2}>
                {/* show all added images */}
                {getLinks?.data?.map((item) => (
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    lg={3}
                    key={item?._id}
                    className="m-1-0"
                  >
                    <div className="artist-manage-photos-add-action position-center position-relative flex-column cursor-pointer box-shadow-1 border-5">
                      <a
                        href={`${item?.link}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={item?.link}
                        className="w-100 h-100 position-relative"
                      >
                        <img
                          src={LINK_LOGO}
                          alt=""
                          height={"130px"}
                          className="object-fit-cover border-5"
                          width={"100%"}
                        />
                        <span className="position-absolute social-link text-ellipse-vertical line-clamp-3">
                          <Typography
                            variant="caption"
                            className="font-bold text-primary-2"
                          >
                            {item?.link}
                          </Typography>
                        </span>
                      </a>

                      {/* select multiple images */}
                      <div className="remove-artist-added-image position-absolute box-shadow-1 border-circle">
                        <IconButton
                          onClick={() => onSelectLink(item?._id)}
                          size="small"
                          className="text-primary-2"
                        >
                          {selectedLinks?.includes(item?._id) ? (
                            <CheckBoxOutlined />
                          ) : (
                            <CheckBoxOutlineBlank />
                          )}
                        </IconButton>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : (
            <>
              <EmptyData
                description='You have not added any links yet. Click "Add Link" to add links.'
                btnText="Add Link"
                isShowActionBtn={true}
                handleAction={() => {
                  setLinkPreviews([])
                  setIsLinkAddDialogOpen(true)
                }}
              />
            </>
          )}
        </>
      )}
      {/* dialog for add links */}
      <Dialog
        open={isLinkAddDialogOpen}
        maxWidth="sm"
        fullWidth
        fullScreen={windowData?.isMobileView}
      >
        <DialogContent>
          {/* dialog header */}
          <div className="upload-videos-dialog-header flex-row flex-align-center flex-justify-between">
            <Typography
              variant="h6"
              className="text-primary-2 font-bold "
              gutterBottom
            >
              Other Link
            </Typography>
            <IconButton
              onClick={() => {
                setLink("")
                setIsLinkAddDialogOpen(false)
              }}
              className="bg-whitesmoke"
            >
              <CloseOutlined />
            </IconButton>
          </div>
          <Divider className="m-1-0 bg-primary-5" />
          {/* dialog body */}
          <div className="upload-videos-dialog-body m-0_5em-0">
            {/* upload video url */}
            <div className="artist-upload-video-link w-100">
              <Typography variant="body1">
                Add your link here. You can add multiple links.
              </Typography>
              <TextField
                margin="normal"
                placeholder="Enter Link Here"
                fullWidth
                variant="outlined"
                value={link}
                onChange={(e) => {
                  setLink(e.target.value)
                  onSetLinkError("")
                }}
                InputProps={{
                  classes: {
                    notchedOutline: "border-none",
                  },
                  endAdornment: (
                    <IconButton
                      className={`text-primary-2 bg-whitesmoke font-bold`}
                      onClick={() => {
                        addLink(Date.now(), link)
                        setLink("")
                      }}
                    >
                      <FileUploadOutlined />
                    </IconButton>
                  ),
                }}
                className="box-shadow-1 border-5"
                required
                error={Boolean(linkErr?.message)}
              />
              {linkErr?.message && (
                <span>
                  <Typography className="text-red m-0-0_5em" variant="body2">
                    {linkErr?.message}
                  </Typography>
                </span>
              )}
            </div>
            {/* link previews */}
          </div>
          <div className={`m-t-1em w-100 flex flex-column row-gap-0_5em`}>
            {linkPreviews?.map((lp, index) => (
              <div
                className="w-100 p-1em h-100 bg-whitesmoke border-5 overflow-auto hide-scrollbar flex flex-row flex-align-center flex-justify-between"
                key={index?.toString()}
              >
                <div className="w-90">
                  <Typography>{lp?.link}</Typography>
                </div>
                <IconButton
                  onClick={() => {
                    removePreviewLink(lp)
                  }}
                >
                  <DeleteOutlineOutlined htmlColor="red" />
                </IconButton>
              </div>
            ))}
          </div>
        </DialogContent>
        {linkPreviews?.length > 0 && (
          <DialogActions className="p-0_5em">
            <div className="upload-dialog-action-btn text-left w-100 flex-row flex-justify-center">
              <CustomButton
                btnText={postLinks?.isPosting ? "LOADING..." : "UPLOAD NOW"}
                className={`${
                  true ? "border-primary-2 text-primary-2" : "disabled-btn "
                }`}
                disabled={postLinks?.isPosting}
                fullWidth={windowData?.isMobileView}
                onClick={async () => {
                  await onPostLinks(artistID, linksArr)
                }}
              />
            </div>
          </DialogActions>
        )}
      </Dialog>
    </div>
  )
}

export default ArtistManageLinks
