import { useLayoutEffect } from "react"
import { useLocation } from "react-router-dom"

const ScrollToTop = ({ children }) => {
  const location = useLocation()
  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    })
  }, [location])
  return children
}

export default ScrollToTop
