import { ArrowForwardOutlined, CloseOutlined } from "@mui/icons-material"
import {
  Dialog,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material"
import { CustomButton } from "components/core"
import { MyBookingCard } from "components/user"
import { useNavigate } from "react-router-dom"

const PayNow = ({
  bookingsToBePaid,
  payNowOpen,
  handleSuccess,
  handleClosePayNow,
  handleOpenAlertForBlockDate,
}) => {
  const navigate = useNavigate()
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={payNowOpen}
      components={Paper}
      classes={{
        paper: "border-10 p-1em hide-scrollbar",
      }}
    >
      <div className="flex-row flex-align-center flex-justify-between m-b-1em position-sticky">
        <div>
          <Typography className="font-bold text-primary-2" variant="h5">
            Pay Now
          </Typography>
        </div>
        <IconButton
          className="bg-none box-shadow-1"
          onClick={() => {
            handleClosePayNow()
          }}
        >
          <CloseOutlined />
        </IconButton>
      </div>
      <Divider />
      {/* bookings to be paid */}
      <div className="flex-column flex-align-center overflow-y-auto">
        <Grid container spacing={2}>
          {bookingsToBePaid?.map((bookedData) => (
            <Grid item xs={12} key={bookedData?._id}>
              <div className="m-0_5em">
                <MyBookingCard
                  bookedData={bookedData}
                  isNoAction={true}
                  isPayNow={true}
                  handleSuccess={handleSuccess}
                  handleOpenAlertForBlockDate={handleOpenAlertForBlockDate}
                />
              </div>
            </Grid>
          ))}
        </Grid>
        <div className="m-t-1em">
          <CustomButton
            variant={"text"}
            btnText="View All Bookings"
            className={"p-0 bg-none text-primary-9"}
            disableElevation
            endIcon={<ArrowForwardOutlined />}
            onClick={() => {
              navigate(`/my-bookings`)
            }}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default PayNow
