// import from packages
// import DateAdapter from "@date-io/moment"
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import {
  LocalizationProvider,
  MobileTimePicker,
} from "@mui/x-date-pickers-pro";
import { MenuItem, TextField, Typography } from "@mui/material";
import { useAdmin } from "hooks";
import moment from "moment";
// import from other files
import { setEventDuration, setEventTime } from "redux/actions";

const ChoseEventTime = ({ bookingArtistState, dispatch }) => {
  const { eventDurations } = useAdmin();
  return (
    <div className="booking-artist-time-root w-100 flex-column position-center m-1-0 ">
      {/* select event time */}
      <div className="booking-artist-time p-0_5em-0 booking-artist-input-root-width">
        <Typography variant="body1" gutterBottom>
          Choose Event Time
        </Typography>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MobileTimePicker
            label=""
            value={bookingArtistState?.eventTime}
            onChange={(newValue) => {
              dispatch(setEventTime(moment(newValue).toLocaleString()));
            }}
            InputProps={{
              classes: {
                root: "border-10",
              },
            }}
            renderInput={(params) => (
              <TextField {...params} fullWidth error={false} />
            )}
            showTodayButton={true}
            toolbarTitle="Select Event Time"
          />
        </LocalizationProvider>
      </div>
      {/* total hours selected by user */}
      <div className="booking-artist-total-hours booking-artist-input-root-width">
        <Typography variant="body1" gutterBottom>
          Total Hours
        </Typography>
        <TextField
          fullWidth
          InputProps={{
            classes: {
              root: "border-10",
            },
          }}
          select
          value={bookingArtistState?.eventDuration}
          onChange={(e) => dispatch(setEventDuration(e.target.value))}
        >
          {eventDurations?.map(({ eventDuration }, i) => (
            <MenuItem
              key={i}
              value={eventDuration}
            >{`${eventDuration} hours`}</MenuItem>
          ))}
        </TextField>
      </div>
    </div>
  );
};

export default ChoseEventTime;
