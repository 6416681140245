import produce from "immer"

const artistProfileState = {
  profileInfo: {
    imgSrc: "",
    firstName: "",
    lastName: "",
    email: "",
    gender: "Male",
    phone: {
      countryCode: "1",
      number: "",
    },
    address: "",
    yearOfExperience: "",
    lat: 0,
    lng: 0,
    country: "",
  },
  selectCategory: "",
  selectSubCategory: [],
  selectGenres: [],
  selectEvents: [],
  selectServices: [],
  selectLanguages: [],
}

const ArtistManageProfileReducer = (state = artistProfileState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "SET_ARTIST_PROFILE_INFO":
        draft.profileInfo = action.payload
        break
      case "SET_ARTIST_CATEGORY":
        draft.selectCategory = action.payload
        break
      case "SET_ARTIST_SUBCATEGORY":
        draft.selectSubCategory = action.payload
        break
      case "SET_ARTIST_GENRES":
        draft.selectGenres = action.payload
        break
      case "SET_ARTIST_EVENTS":
        draft.selectEvents = action.payload
        break
      case "SET_ARTIST_SERVICES":
        draft.selectServices = action.payload
        break
      case "SET_ARTIST_LANGUAGES":
        draft.selectLanguages = action.payload
        break
      default:
        break
    }
  })
}

export default ArtistManageProfileReducer
