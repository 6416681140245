import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  assignedManagersSelector,
  assignedManagersStateSelector,
  assignedUsersStateSelector,
  fetchAssignedManagers,
  fetchManagedArtists,
  loadingAssignedManagersSelector,
  loadingManagedArtistsSelector,
  managedArtistsSelector,
  managedArtistsStateSelector,
  removeManagerAction,
} from "redux/actions"
const useAssignedUsers = () => {
  const dispatch = useDispatch()
  const assignedUsersState = useSelector((state) =>
    assignedUsersStateSelector(state)
  )
  //   artist side
  const assignedManagersState = useSelector((state) =>
    assignedManagersStateSelector(state)
  )
  const assignedManagers = useSelector((state) =>
    assignedManagersSelector(state)
  )
  const loadingAssignedManagers = useSelector((state) =>
    loadingAssignedManagersSelector(state)
  )
  //   manager side
  const managedArtistsState = useSelector((state) =>
    managedArtistsStateSelector(state)
  )
  const managedArtists = useSelector((state) => managedArtistsSelector(state))
  const loadingManagedArtists = useSelector((state) =>
    loadingManagedArtistsSelector(state)
  )

  // fetch assigned managers and managed artists
  const handleFetchAssignedManagers = useCallback(
    (artistID) => {
      dispatch(fetchAssignedManagers(artistID))
    },
    [dispatch]
  )
  const handleFetchManagedArtists = useCallback(
    (managerID) => {
      dispatch(fetchManagedArtists(managerID))
    },
    [dispatch]
  )

  // remove manager from managed artists
  const handleRemoveManager = useCallback(
    (managerID, artistID, email, subject, message) => {
      dispatch(
        removeManagerAction(managerID, artistID, email, subject, message)
      )
    },
    [dispatch]
  )

  return {
    assignedUsersState,
    assignedManagersState,
    assignedManagers,
    loadingAssignedManagers,
    //  manager side
    managedArtistsState,
    managedArtists,
    loadingManagedArtists,
    // fetch assigned managers and managed artists
    handleFetchAssignedManagers,
    handleFetchManagedArtists,
    // remove manager from managed artists
    handleRemoveManager,
  }
}

export default useAssignedUsers
