import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  addFavLoadingSelector,
  addToFavorite,
  favoritesSelector,
  favoriteStateSelector,
  fetchingUserFavoritesData,
  loadingFavoritesSelector,
  removedFavSelector,
  removeFavLoadingSelector,
  removeFromFavorite,
} from "redux/actions"
import { useUsers, useIsMounted } from "hooks"

const useFavorites = () => {
  const { currentUserID } = useUsers()
  const isMounted = useIsMounted()
  const dispatch = useDispatch()
  const favoriteState = useSelector((state) => favoriteStateSelector(state))
  const isLoadingFavorites = loadingFavoritesSelector(favoriteState)
  const favorites = favoritesSelector(favoriteState)
  // add fav
  const isLoadingAddFav = useSelector((state) => addFavLoadingSelector(state))
  // remove fav
  const isLoadingRemoveFav = useSelector((state) =>
    removeFavLoadingSelector(state)
  )
  const isRemovedFav = useSelector((state) => removedFavSelector(state))

  useEffect(() => {
    if (isMounted()) {
      if (currentUserID) {
        dispatch(fetchingUserFavoritesData(currentUserID))
      }
    }
  }, [dispatch, currentUserID, isMounted, isLoadingAddFav, isLoadingRemoveFav])

  const isFavArtist = useCallback(
    (artistId) => {
      return favorites?.map((fav) => fav?.artist?._id)?.includes(artistId)
    },
    [favorites]
  )

  const handleAddToFav = useCallback(
    (artistID) => {
      dispatch(addToFavorite(artistID, currentUserID))
      dispatch({
        type: "ADD_FAVORITE_RESET",
      })
    },
    [dispatch, currentUserID]
  )

  const handleRemoveFromFav = useCallback(
    (artistID) => {
      dispatch(removeFromFavorite(artistID, currentUserID))
      dispatch({
        type: "REMOVE_FAVORITE_RESET",
      })
    },
    [dispatch, currentUserID]
  )

  return {
    isLoadingFavorites,
    favorites,
    isFavArtist,
    handleAddToFav,
    handleRemoveFromFav,
    isRemovedFav,
    // add fav
    isLoadingAddFav,
  }
}

export default useFavorites
