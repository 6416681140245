import { Button } from "@mui/material"

const CustomButton = ({
  btnText,
  className,
  onClick,
  startIcon,
  endIcon,
  variant,
  disabled,
  fullWidth,
  disableElevation,
  type,
  size,
}) => {
  return (
    <Button
      type={type}
      className={`${className} font-bold text-transform-none `}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      variant={variant || "outlined"}
      disabled={disabled || false}
      fullWidth={fullWidth || false}
      disableElevation={disableElevation || false}
      color="inherit"
      size={size ?? "medium"}
    >
      {btnText}
    </Button>
  )
}

export default CustomButton
