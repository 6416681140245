import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  eventTypesSelector,
  eventTypesStateSelector,
  fetchEventTypes,
  fetchServices,
  loadingEventTypes,
  loadingServices,
  servicesSelector,
} from "redux/actions"
import { useIsMounted } from "hooks"

const useEventTypes = () => {
  const isMounted = useIsMounted()
  const dispatch = useDispatch()
  const eventTypesState = useSelector((state) => eventTypesStateSelector(state))
  const allEventTypes = eventTypesSelector(eventTypesState)
  const allServices = servicesSelector(eventTypesState)
  const isLoadingEventTypes = loadingEventTypes(eventTypesState)
  const isLoadingServices = loadingServices(eventTypesState)

  useEffect(() => {
    if (isMounted()) {
      dispatch(fetchEventTypes())
    }
  }, [isMounted, dispatch])

  useEffect(() => {
    if (isMounted()) {
      dispatch(fetchServices())
    }
  }, [isMounted, dispatch])

  return {
    allEventTypes,
    allServices,
    isLoadingEventTypes,
    isLoadingServices,
  }
}

export default useEventTypes
