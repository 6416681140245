import {
  EventOutlined,
  MiscellaneousServicesOutlined,
} from "@mui/icons-material"
import { Typography, Grid } from "@mui/material"
import { useEffect } from "react"
// import from other files
import {
  useApi,
  useEventTypes,
  useIsMounted,
  useSnackbar,
  useUsers,
} from "hooks"
import { setArtistEvents, setArtistServices } from "redux/actions"
import { ManageProfileSaveBtn } from "."
import { CustomSnackbar, Loader } from "components/core"

const ArtistManageEvent = ({ artistProfileState, dispatch, currentUserID }) => {
  const { userDetails, handleGetUserDetails } = useUsers()
  const isMounted = useIsMounted()
  const { allEventTypes, allServices, isLoadingEventTypes, isLoadingServices } =
    useEventTypes()
  const {
    postData,
    isPosting,
    isPosted,
    handleResetPostApi,
    postStatus,
    isPostErr,
  } = useApi()
  const { snackbar, handleOpenSnackbar, handleCloseSnackbar } = useSnackbar()
  // update profile picture
  const handleUpdateEvents = () => {
    postData(
      `user/account-update/${currentUserID}`,
      {
        events: artistProfileState?.selectEvents,
        services: artistProfileState?.selectServices,
      },
      "PUT"
    )
  }

  useEffect(() => {
    if (isMounted() && userDetails?._id) {
      dispatch(setArtistEvents(userDetails?.events?.map((i) => i?._id)))
      dispatch(setArtistServices(userDetails?.services?.map((i) => i?._id)))
    }
  }, [dispatch, isMounted, userDetails])

  useEffect(() => {
    if (isMounted() && isPosted) {
      handleGetUserDetails(currentUserID)
      handleOpenSnackbar({
        message: "Updated successfully",
        variant: "success",
      })
    }
  }, [
    handleGetUserDetails,
    isMounted,
    isPosted,
    currentUserID,
    handleOpenSnackbar,
  ])

  useEffect(() => {
    if (isMounted() && isPostErr) {
      handleOpenSnackbar({
        message: postStatus?.message,
        variant: "error",
      })
    }
  }, [handleOpenSnackbar, isMounted, isPostErr, postStatus])

  return (
    <div className="artist-manage-event-root">
      <CustomSnackbar
        open={snackbar?.open}
        handleClose={() => {
          handleCloseSnackbar()
        }}
        message={snackbar?.message}
        variant={snackbar?.variant}
        autoHideDuration={3000}
        resetFunction={handleResetPostApi}
      />
      {/* manage event */}
      <div className="artist-manage-event">
        <div className="artist-manage-event-title">
          <Typography variant="h6" className="text-primary-8">
            Select Events
          </Typography>
        </div>
        <div className="artist-manage-event-body m-1-0">
          {!isLoadingEventTypes && allEventTypes?.length > 0 && (
            <Grid container spacing={2}>
              {allEventTypes?.map((event) => (
                <Grid item xs={12} sm={6} lg={4} key={event?._id}>
                  <div
                    className={`manage-event-card p-1em position-center flex-column cursor-pointer border-5 w-100 ${
                      artistProfileState?.selectEvents?.includes(event?._id)
                        ? "text-primary-3 font-bold"
                        : "text-primary-7"
                    }`}
                    role={"button"}
                    onClick={() => {
                      dispatch(
                        setArtistEvents(
                          artistProfileState?.selectEvents,
                          event?._id
                        )
                      )
                    }}
                  >
                    <EventOutlined />
                    <Typography
                      variant="body1"
                      className={`m-0_5em-0 text-ellipses font-bold`}
                    >
                      {event?.eventName}
                    </Typography>
                  </div>
                </Grid>
              ))}
            </Grid>
          )}
          {isLoadingEventTypes && (
            <div className="h-50vh">
              <Loader isCircularLoader={true} />
            </div>
          )}
        </div>
      </div>
      {/* manage services */}
      <div className="artist-manage-service">
        <div className="artist-manage-service-title">
          <Typography variant="h6" className="text-primary-8">
            Select Services
          </Typography>
        </div>
        <div className="artist-manage-service-body m-1-0">
          {!isLoadingServices && allServices?.length > 0 && (
            <Grid container spacing={2}>
              {allServices?.map((service) => (
                <Grid item xs={12} sm={6} lg={4} key={service?._id}>
                  <div
                    className={`artist-manage-service-card p-1em position-center flex-column cursor-pointer border-5 h-100 text-center w-100 ${
                      artistProfileState?.selectServices?.includes(service?._id)
                        ? "text-primary-3 font-bold"
                        : "text-primary-7"
                    }`}
                    role={"button"}
                    onClick={() => {
                      dispatch(
                        setArtistServices(
                          artistProfileState?.selectServices,
                          service?._id
                        )
                      )
                    }}
                  >
                    <MiscellaneousServicesOutlined />
                    <Typography
                      variant="body1"
                      className="m-0_5em-0 text-ellipses"
                    >
                      {service?.serviceName}
                    </Typography>
                  </div>
                </Grid>
              ))}
            </Grid>
          )}
          {isLoadingServices && (
            <div className="h-50vh">
              <Loader isCircularLoader={true} />
            </div>
          )}
        </div>
      </div>
      {/* action button */}
      <Grid item xs={12}>
        <ManageProfileSaveBtn
          onClick={() => {
            handleUpdateEvents()
          }}
          disabled={isPosting}
        />
      </Grid>
    </div>
  )
}

export default ArtistManageEvent
