import produce from "immer"

const mailActionState = {
  success: false,
  error: false,
  successMsg: null,
  errorMsg: null,
}
const initialState = {
  removeManagerByArtistMail: Object.assign({}, mailActionState),
}
const MailReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action
    const { removeManagerByArtistMail } = draft
    switch (type) {
      // -------------------------------------------------- remove manager by artist mail --------------------------------------------------
      case "SEND_MAIL_SUCCESS":
        removeManagerByArtistMail.success = true
        removeManagerByArtistMail.successMsg = payload
        break
      case "SEND_MAIL_ERROR":
        removeManagerByArtistMail.error = true
        removeManagerByArtistMail.errorMsg = payload
        break
      case "SEND_MAIL_RESET":
        removeManagerByArtistMail.success = false
        removeManagerByArtistMail.error = false
        removeManagerByArtistMail.successMsg = null
        removeManagerByArtistMail.errorMsg = null
        break
      default:
        return draft
    }
  })

export default MailReducer
