import produce from "immer"

const actionState = {
  isActionSuccess: false,
  isActionError: false,
  successMsg: "",
  errorMsg: "",
}

const initialState = {
  isLoading: true,
  error: null,
  data: [],
  removeAction: actionState,
}
const PersonalizedMessageReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action
    const { removeAction } = draft
    switch (type) {
      case "PERSONALIZED_MESSAGE_LOADING":
        draft.isLoading = true
        draft.error = null
        draft.data = []
        break
      case "PERSONALIZED_MESSAGE_SUCCESS":
        draft.isLoading = false
        draft.error = null
        draft.data = payload
        break
      case "PERSONALIZED_MESSAGE_FAILURE":
        draft.isLoading = false
        draft.error = payload
        draft.data = []
        break
      // remove action
      case "PERSONALIZED_MESSAGE_REMOVE_ACTION_SUCCESS":
        removeAction.isActionSuccess = true
        removeAction.isActionError = false
        removeAction.successMsg = payload
        removeAction.errorMsg = ""
        break
      case "PERSONALIZED_MESSAGE_REMOVE_ACTION_FAILURE":
        removeAction.isActionSuccess = false
        removeAction.isActionError = true
        removeAction.successMsg = ""
        removeAction.errorMsg = payload
        break
      case "PERSONALIZED_MESSAGE_REMOVE_ACTION_RESET":
        removeAction.isActionSuccess = false
        removeAction.isActionError = false
        removeAction.successMsg = ""
        removeAction.errorMsg = ""
        break
      default:
        return draft
    }
  })

export default PersonalizedMessageReducer
