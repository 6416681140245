import { useCallback, useState } from "react"

const useSnackbar = () => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    variant: "success",
  })
  const handleOpenSnackbar = useCallback(({ message, variant }) => {
    setSnackbar({
      open: true,
      message: message,
      variant: variant,
    })
  }, [])
  const handleCloseSnackbar = useCallback(() => {
    setSnackbar((pre) => {
      return {
        ...pre,
        open: false,
      }
    })
  }, [])
  return {
    snackbar,
    handleOpenSnackbar,
    handleCloseSnackbar,
  }
}

export default useSnackbar
