import { Component } from "react"

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    //    error info
    console.log(error, errorInfo)
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      //  custom fallback UI
      return (
        <div className="flex flex-column flex-justify-center flex-align-center h-100vh w-100 row-gap-1em">
          <h2 className="font-bold text-primary-8">
            Something went wrong. Please reload the page.
          </h2>
          <button
            className="border-5 bg-primary-2 text-white p-0_5em-2em font-bold border-none cursor-pointer font-bold font-size-1em"
            onClick={() => window.location.reload()}
          >
            Reload
          </button>
        </div>
      )
    }

    return <>{this.props.children}</>
  }
}
