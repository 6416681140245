import {
  AddAPhotoOutlined,
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  CloseOutlined,
  DeleteOutline,
  DeleteOutlined,
  DeleteOutlineOutlined,
} from "@mui/icons-material"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material"
import { useEffect, useMemo, useRef, useState } from "react"
import { CustomButton, CustomLightBox, EmptyData } from "components"
import { getSelectedItems } from "helpers"
import {
  useArtistMedias,
  useIsMounted,
  useLoader,
  useSnackbar,
  useWindow,
} from "hooks"
import {
  CustomConfirmationDialog,
  CustomSnackbar,
  Loader,
} from "components/core"

const ArtistManagePhotos = ({ currentUserID }) => {
  const { namesInLoaderActions } = useLoader()
  const isMounted = useIsMounted()
  const { snackbar, handleOpenSnackbar, handleCloseSnackbar } = useSnackbar()
  const {
    artistPhotos,
    isLoadingArtistPhotos,
    removeArtistPhotos,
    isRemoveArtistPhotosSuccess,
    handleRemoveArtistPhotosReset,
    handleFetchArtistPhotos,
    handleAddArtistPhotos,
    isAddArtistPhotosSuccess,
    handleAddArtistPhotosReset,
  } = useArtistMedias(currentUserID)
  const { isMobileView } = useWindow().windowData
  const uploadRef = useRef(null)
  const [images, setImages] = useState([])
  const [isShowPreview, setIsShowPreview] = useState(false)
  const [previewImages, setPreviewImages] = useState([])
  const [selectedImages, setSelectedImages] = useState([])
  const [isOpenLightBox, setIsOpenLightBox] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)

  // delete images dialog
  const [deleteDialog, setDeleteDialog] = useState(false)

  //   handle onChange event for file upload
  const handleUpload = (e) => {
    const files = e.target.files
    const images = []
    for (let i = 0; i < files?.length; i++) {
      // check for file type is image or not
      if (files[i].type.match(/image.*/)) {
        images?.push(files?.[i])
      }
    }
    setPreviewImages([...previewImages, ...images])
  }

  //   upload multiple images
  const uploadMultiImages = (items) => {
    handleAddArtistPhotos(items)
    setIsShowPreview(false)
    setPreviewImages([])
  }

  //   select multiple images
  const handleSelectImages = (resArr, selectedImg) => {
    const result = getSelectedItems(resArr, selectedImg)
    setSelectedImages(result)
  }

  //   handle remove selected images
  const handleRemoveSelectedImages = (img) => {
    const res = previewImages?.filter((item) => item !== img)
    setPreviewImages(res)
  }

  // remove photos
  const removingArtistPhotos = useMemo(() => {
    return namesInLoaderActions?.includes(
      `REMOVE_ARTIST_PHOTOS_${currentUserID}`
    )
  }, [namesInLoaderActions, currentUserID])

  // add photos
  const isAddingArtistPhotos = useMemo(() => {
    return namesInLoaderActions?.includes(`ADD_ARTIST_PHOTOS_${currentUserID}`)
  }, [namesInLoaderActions, currentUserID])

  // snackbar open when remove photos or add photos
  useEffect(() => {
    if (isMounted() && isRemoveArtistPhotosSuccess) {
      handleOpenSnackbar({
        message: "Photos removed successfully",
        variant: "success",
      })
    }
  }, [handleOpenSnackbar, isRemoveArtistPhotosSuccess, isMounted])

  useEffect(() => {
    if (isMounted() && isAddArtistPhotosSuccess) {
      handleOpenSnackbar({
        message: "Photos added successfully",
        variant: "success",
      })
    }
  }, [handleOpenSnackbar, isAddArtistPhotosSuccess, isMounted])

  // if images present fetch images
  useEffect(() => {
    if (isMounted()) {
      handleFetchArtistPhotos(currentUserID)
    }
  }, [
    isMounted,
    isRemoveArtistPhotosSuccess,
    currentUserID,
    handleFetchArtistPhotos,
    isAddArtistPhotosSuccess,
  ])

  useEffect(() => {
    if (isMounted()) {
      setImages(artistPhotos)
    }
  }, [
    isMounted,
    artistPhotos,
    isRemoveArtistPhotosSuccess,
    isAddArtistPhotosSuccess,
  ])

  return (
    <div>
      {/* snack bar  */}
      {(removingArtistPhotos || isAddingArtistPhotos) && (
        <Loader
          isBackDrop
          fullScreen
          isCircularLoader={true}
          className="zIndex-9999"
        />
      )}
      <CustomSnackbar
        open={snackbar.open}
        handleClose={() => {
          handleCloseSnackbar()
          handleRemoveArtistPhotosReset()
          handleAddArtistPhotosReset()
        }}
        message={snackbar.message}
        variant={snackbar.variant}
      />
      {/* light box */}
      <CustomLightBox
        imgData={images?.map((item) => item?.imageUrl)}
        openLightBox={isOpenLightBox}
        setOpenLightBox={setIsOpenLightBox}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />
      {/* Images preview */}
      <Dialog
        maxWidth="sm"
        fullWidth
        open={isShowPreview}
        fullScreen={isMobileView}
      >
        <DialogContent className="position-relative">
          <div className="flex-row flex-justify-between m-b-1em flex-align-center">
            <Typography variant="h6" className="text-primary-2 font-bold">
              Add Photos Now
            </Typography>
            <IconButton
              onClick={() => {
                setIsShowPreview(false)
              }}
            >
              <CloseOutlined />
            </IconButton>
          </div>
          <Grid container spacing={2}>
            {previewImages?.map((item, index) => (
              <Grid item xs={6} sm={6} lg={4} key={index}>
                <div className="artist-manage-photos-add-action position-center position-relative flex-column cursor-pointer box-shadow-1 border-5 m-b-1em h-100">
                  <img
                    src={URL.createObjectURL(item)}
                    alt=""
                    height={"130px"}
                    className="object-fit-cover border-5"
                    width={"100%"}
                  />
                  {/* select multiple images */}
                  <div className="remove-artist-added-image position-absolute box-shadow-1 border-circle">
                    <IconButton
                      onClick={() => handleRemoveSelectedImages(item)}
                      size="small"
                      className="text-primary-1"
                    >
                      <DeleteOutline />
                    </IconButton>
                  </div>
                </div>
              </Grid>
            ))}
            {/* add image in dialog */}
            <Grid item xs={6} sm={4} lg={3}>
              <input
                type="file"
                accept="image/*"
                onChange={(ev) => {
                  handleUpload(ev)
                }}
                multiple
                ref={uploadRef}
                hidden
                onError={() => {
                  return false
                }}
              />
              <div
                className="artist-manage-photos-add-action position-center position-relative box-shadow-1 p-1em flex-column cursor-pointer border-5 h-100 w-100"
                role={"button"}
                onClick={() => {
                  uploadRef.current.click()
                }}
              >
                <div className="overlay zIndex-0 border-5"></div>
                <AddAPhotoOutlined className="zIndex-1 text-white m-b-0_5em" />
                <Typography className="zIndex-1 text-white">
                  Add Photos
                </Typography>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div className="upload-dialog-action-btn m-1-0 text-left w-100 p-0-1em">
            <CustomButton
              btnText="Upload Now"
              className={`${
                previewImages?.length > 0
                  ? "border-primary-2 text-primary-2"
                  : "disabled-btn"
              }`}
              disabled={!previewImages?.length > 0}
              onClick={() => {
                uploadMultiImages(previewImages)
              }}
              fullWidth={isMobileView}
            />
          </div>
        </DialogActions>
      </Dialog>

      {/* delete confirm dialog */}
      <CustomConfirmationDialog
        open={deleteDialog}
        headerTitle={`Delete Photos`}
        description={`Are you sure you want to delete?`}
        headerIcon={<DeleteOutlined />}
        handleAction={() => {
          selectedImages?.length > 0 &&
            removeArtistPhotos(selectedImages?.map((i) => i?._id))
          setSelectedImages([])
          setDeleteDialog(false)
        }}
        handleClose={() => {
          setDeleteDialog(false)
        }}
      />
      {/* all photos */}
      <div className="artist-manage-photos-body">
        {/* remove images button */}

        {/* add photo action */}
        <div
          className="artist-manage-photos-add-action position-center position-relative box-shadow-normal p-1em flex-column cursor-pointer border-5 h-100 w-100"
          role={"button"}
          onClick={() => {
            setIsShowPreview(true)
          }}
        >
          <Typography className="zIndex-1" variant="h6">
            Upload photos so that your fans can see your work
          </Typography>
          <Button
            endIcon={<AddAPhotoOutlined />}
            variant="outlined"
            className="text-primary-2 border-primary-2 text-transform-none m-1-0"
          >
            Upload Photos
          </Button>
        </div>
        {!isLoadingArtistPhotos && (
          <>
            <div className="artist-manage-photos-title m-1-0 flex-row flex-align-center flex-justify-between">
              <Typography variant="h6" className="text-primary-8 " gutterBottom>
                All Photos
              </Typography>
              <div
                className={`remove-artist-manage-images-btn m-b-1em ${
                  selectedImages?.length > 0 ? "" : "display-none"
                }`}
              >
                <CustomButton
                  btnText="Remove"
                  className="border-primary-1 text-primary-1"
                  endIcon={<DeleteOutlineOutlined />}
                  onClick={() => {
                    setDeleteDialog(true)
                  }}
                />
              </div>
            </div>
            {/* no images */}
            {images?.length === 0 && (
              <>
                <EmptyData />
              </>
            )}
            {images?.length > 0 && (
              <Grid container spacing={2}>
                {/* show all added images */}
                {images?.map((item, index) => (
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    lg={3}
                    key={index}
                    className="m-b-1em"
                  >
                    <div className="artist-manage-photos-add-action position-center position-relative flex-column cursor-pointer box-shadow-1 border-5">
                      <img
                        src={item?.imageUrl}
                        alt=""
                        height={"130px"}
                        className="object-fit-cover border-5"
                        width={"100%"}
                        onClick={() => {
                          setCurrentIndex(index)
                          setIsOpenLightBox(true)
                        }}
                      />
                      {/* select multiple images */}
                      <div className="remove-artist-added-image position-absolute box-shadow-1 border-circle">
                        <IconButton
                          onClick={() =>
                            handleSelectImages(selectedImages, item)
                          }
                          size="small"
                          className="text-primary-2"
                        >
                          {selectedImages?.includes(item) ? (
                            <CheckBoxOutlined />
                          ) : (
                            <CheckBoxOutlineBlank />
                          )}
                        </IconButton>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            )}
          </>
        )}
        {isLoadingArtistPhotos && (
          <div className="h-50vh">
            <Loader isCircularLoader={true} />
          </div>
        )}
      </div>
    </div>
  )
}

export default ArtistManagePhotos
