import { Grid, TextField, Typography } from "@mui/material"
import { CustomSnackbar, Loader } from "components/core"
import { useApi, useIsMounted, useSnackbar } from "hooks"
import { useEffect } from "react"
import { useState } from "react"
import { ManageProfileSaveBtn } from "."

const ArtistManageBankAccount = ({ currentUserID }) => {
  const isMounted = useIsMounted()
  const {
    postData,
    isPosting,
    isPosted,
    postStatus,
    handleResetPostApi,
    isPostErr,
    apiGetData,
    getData,
    isGetting,
  } = useApi()
  const { snackbar, handleOpenSnackbar, handleCloseSnackbar } = useSnackbar()
  const [bank, setBank] = useState({
    holderName: "",
    accountNumber: "",
    bankName: "",
    bankAddress: "",
  })

  const onChange = (e) => {
    setBank({
      ...bank,
      [e.target.name]: e.target.value,
    })
  }

  const handlePostBankAccount = () => {
    const data = {
      artistId: currentUserID,
      bankAccountNumber: bank.accountNumber,
      bankName: bank.bankName,
      bankAccountHolderName: bank.holderName,
      bankAddress: bank.bankAddress,
    }
    postData("bank-account/create", data, "POST")
  }

  // success snackbar
  useEffect(() => {
    if (isMounted() && isPosted) {
      handleOpenSnackbar({
        message: postStatus.message,
        variant: "success",
      })
    }
  }, [postStatus, isMounted, isPosted, handleOpenSnackbar])

  // error snackbar
  useEffect(() => {
    if (isMounted() && isPostErr) {
      handleOpenSnackbar({
        message: postStatus.message,
        variant: "error",
      })
    }
  }, [isMounted, postStatus, handleOpenSnackbar, isPostErr])

  // fetch bank account
  useEffect(() => {
    if (isMounted() && currentUserID) {
      getData(`bank-account/all-bank-account/artist/${currentUserID}`)
    }
  }, [currentUserID, isMounted, getData])

  // set bank account data
  useEffect(() => {
    if (isMounted() && apiGetData.data) {
      const { data } = apiGetData
      setBank({
        holderName: data?.bankAccountHolderName,
        accountNumber: data?.bankAccountNumber,
        bankName: data?.bankName,
        bankAddress: data?.bankAddress,
      })
    }
  }, [apiGetData, isMounted])

  return (
    <div>
      <CustomSnackbar
        open={snackbar.open}
        handleClose={handleCloseSnackbar}
        message={snackbar.message}
        variant={snackbar.variant}
        autoHideDuration={1000}
        resetFunction={handleResetPostApi}
      />
      <Typography variant="h6" className="text-primary-8">
        Bank Account
      </Typography>
      <Typography variant="body2" className="text-primary-1">
        Please provide your bank account details & all * fields are mandatory to
        be filled.
      </Typography>
      {isGetting && (
        <div className="h-50vh position-center">
          <Loader isCircularLoader={true} bgNone />
        </div>
      )}
      {!isGetting && (
        <div className="artist-bank-account-body m-2em-0">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom>
                Account Holder Name
                <sup className="text-primary-1">*</sup>
              </Typography>
              <TextField
                fullWidth
                name="holderName"
                value={bank.holderName}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom>
                Bank Name <sup className="text-primary-1">*</sup>
              </Typography>
              <TextField
                fullWidth
                name="bankName"
                value={bank.bankName}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom>
                Account Number <sup className="text-primary-1">*</sup>
              </Typography>
              <TextField
                fullWidth
                name="accountNumber"
                value={bank.accountNumber}
                onChange={onChange}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "")
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom>
                Bank Branch Name & Address{" "}
                <sup className="text-primary-1">*</sup>
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={5}
                name="bankAddress"
                value={bank.bankAddress}
                onChange={onChange}
              />
            </Grid>
            {/* action button */}
            <Grid item xs={12}>
              <ManageProfileSaveBtn
                onClick={handlePostBankAccount}
                disabled={isPosting}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  )
}

export default ArtistManageBankAccount
