import { createSelector } from "reselect"
import moment from "moment"
import { apiInstance } from "configs"
import { endAction, startAction } from "./LoaderAction"

// selector functions
export const selectAllReviews = createSelector(
  (state) => state?.reviewsState?.reviews,
  (reviews) => reviews?.data
)

export const sortedAllReviewsSelector = createSelector(
  [selectAllReviews],
  ([...reviews]) =>
    reviews?.sort((a, b) => moment(b?.timestamp) - moment(a?.timestamp))
)
export const sortOldestReviewsSelector = createSelector(
  [selectAllReviews],
  ([...reviews]) =>
    reviews?.sort((a, b) => moment(a?.timestamp) - moment(b?.timestamp))
)
export const loadingReviewsSelector = createSelector(
  (state) => state?.reviewsState?.reviews,
  (reviews) => reviews?.loading
)

// reviews
// fetch all reviews
export const reviewsSuccess = (reviews) => {
  return {
    type: "REVIEWS_SUCCESS",
    payload: reviews,
  }
}
export const reviewsFailure = (error) => {
  return {
    type: "REVIEWS_FAILURE",
    payload: error,
  }
}

export const fetchAllReviews = () => async (dispatch) => {
  try {
    await apiInstance
      .get("review/all-review")
      .then(({ data }) => {
        dispatch(reviewsSuccess(data?.success?.data))
      })
      .catch(({ response }) => {
        const errMessage = response.data.error.message
        dispatch(reviewsFailure(errMessage))
      })
  } catch (error) {
    dispatch(reviewsFailure(error.message))
  }
}

// fetch reviews by artist id
export const reviewsByArtistSelector = createSelector(
  (state) => state?.reviewsState?.reviewsByArtist,
  (reviewsByArtist) => reviewsByArtist?.data
)
export const reviewsByArtistLoadingSelector = createSelector(
  (state) => state?.reviewsState?.reviewsByArtist,
  (reviewsByArtist) => reviewsByArtist?.loading
)
export const totalReviewsOfArtistSelector = createSelector(
  [reviewsByArtistSelector],
  (reviews) => reviews?.length
)
export const ratingsOfArtistSelector = createSelector(
  [reviewsByArtistSelector],
  (reviews) => {
    const ratings = reviews?.map((review) => review?.ratings)
    return (ratings?.reduce((a, b) => a + b, 0) / ratings?.length).toFixed(1)
  }
)
export const sortedReviewsByArtistSelector = createSelector(
  [reviewsByArtistSelector],
  ([...reviews]) =>
    reviews?.sort((a, b) => moment(b?.timestamp) - moment(a?.timestamp))
)
export const reviewsByArtistLoading = () => {
  return {
    type: "REVIEWS_BY_ARTIST_LOADING",
  }
}
export const reviewsByArtistSuccess = (reviews) => {
  return {
    type: "REVIEWS_BY_ARTIST_SUCCESS",
    payload: reviews,
  }
}
export const reviewsByArtistFailure = (error) => {
  return {
    type: "REVIEWS_BY_ARTIST_FAILURE",
    payload: error,
  }
}
export const fetchReviewsByArtistId = (artistID) => {
  return async (dispatch) => {
    try {
      dispatch(reviewsByArtistLoading())
      await apiInstance
        .get(`review/all-review/${artistID}`)
        .then(({ data }) => {
          dispatch(reviewsByArtistSuccess(data?.success?.data))
        })
        .catch(({ response }) => {
          const errMessage = response.data.error.message
          dispatch(reviewsByArtistFailure(errMessage))
        })
    } catch (error) {
      dispatch(reviewsByArtistFailure(error.message))
    }
  }
}

// give review for artist
export const giveReviewForArtist =
  (artistID, userID, title, desc, ratings) => async (dispatch) => {
    try {
      dispatch({
        type: "REVIEW_GIVEN_RESET",
      })
      dispatch(startAction(artistID))
      await apiInstance
        .post(`review/create`, {
          artistId: artistID,
          userId: userID,
          title: title,
          description: desc,
          ratings: +ratings,
        })
        .then(({ data }) => {
          data?.success?.message &&
            dispatch({
              type: "REVIEW_GIVEN_SUCCESS",
            })
        })
        .catch(({ response }) => {
          const errMessage = response.data.error.message
          errMessage &&
            dispatch({
              type: "REVIEW_GIVEN_FAILURE",
            })
        })
        .finally(() => {
          dispatch(endAction(artistID))
        })
    } catch (error) {
      dispatch({
        type: "REVIEW_GIVEN_FAILURE",
      })
    }
  }
