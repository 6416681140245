import produce from "immer"

const State = {
  isLoading: true,
  data: [],
  error: null,
}
const actionState = {
  isSuccess: false,
  isError: false,
  successMsg: null,
  errorMsg: null,
}
const initialState = {
  assignedManagers: State,
  managedArtists: State,
  removeManager: actionState,
}

const AssignedUsersReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action
    const { assignedManagers, managedArtists, removeManager } = draft
    switch (type) {
      // all assigned managers
      case "ASSIGNED_MANAGERS_LOADING":
        assignedManagers.isLoading = true
        assignedManagers.error = null
        assignedManagers.data = []
        break
      case "ASSIGNED_MANAGERS_SUCCESS":
        assignedManagers.isLoading = false
        assignedManagers.error = null
        assignedManagers.data = payload
        break
      case "ASSIGNED_MANAGERS_ERROR":
        assignedManagers.isLoading = false
        assignedManagers.error = payload
        assignedManagers.data = []
        break
      // all managed artists
      case "MANAGED_ARTISTS_LOADING":
        managedArtists.isLoading = true
        managedArtists.error = null
        managedArtists.data = []
        break
      case "MANAGED_ARTISTS_SUCCESS":
        managedArtists.isLoading = false
        managedArtists.error = null
        managedArtists.data = payload
        break
      case "MANAGED_ARTISTS_ERROR":
        managedArtists.isLoading = false
        managedArtists.error = payload
        managedArtists.data = []
        break
      // remove manager
      case "REMOVE_MANAGER_SUCCESS":
        removeManager.isSuccess = true
        removeManager.isError = false
        removeManager.successMsg = payload
        break
      case "REMOVE_MANAGER_ERROR":
        removeManager.isSuccess = false
        removeManager.isError = true
        removeManager.errorMsg = payload
        break
      case "REMOVE_MANAGER_RESET":
        removeManager.isSuccess = false
        removeManager.isError = false
        removeManager.successMsg = null
        removeManager.errorMsg = null
        break
      default:
        return draft
    }
  })

export default AssignedUsersReducer
