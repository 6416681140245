import { GTranslateOutlined } from "@mui/icons-material"
import { Grid, ListItemIcon, ListItemText, Typography } from "@mui/material"
import { NoDataAvailable } from "components"

const ArtistLanguagesTabItem = ({ languages }) => {
  return (
    <div>
      <Typography variant="h5" gutterBottom className="text-primary-2">
        Languages
      </Typography>
      {languages?.length > 0 && (
        <Grid container spacing={2}>
          {languages?.map((language) => (
            <Grid item xs={12} sm={6} md={4} lg={4} key={language?._id}>
              <div className="artist-event-tab-item border-5">
                <div className="p-1em cursor-default flex-row flex-align-center">
                  <ListItemIcon>
                    <GTranslateOutlined htmlColor="var(--col-primary-3)" />
                  </ListItemIcon>
                  <ListItemText
                    className="text-ellipses"
                    primary={
                      <Typography variant="h6" color="var(--col-primary-8)">
                        {language?.languageName}
                      </Typography>
                    }
                  />
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      )}
      {!languages?.length > 0 && <NoDataAvailable />}
    </div>
  )
}

export default ArtistLanguagesTabItem
