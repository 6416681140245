import { Button, Typography } from "@mui/material"

const CouponCard = ({ coupon, handleApplyCouponCode, handleClose }) => {
  return (
    <div className={"coupon-card-root"}>
      <div className="coupon-card-header">
        <div className="coupon-value bg-white w-fit">
          <Typography variant="body1" className="text-primary-9 font-bold">
            {coupon?.secretString}
          </Typography>
        </div>
      </div>
      <div className="coupon-card-body m-1-0">
        <Typography variant="body1" className="font-bold" gutterBottom>
          Get {coupon?.percentage}% off
        </Typography>
        <Typography variant="body2">
          Use code <span className="font-bold">{coupon?.secretString}</span> &
          get {coupon?.percentage}% off
          {+coupon?.maxCashBack > 0 ? ` up to $${coupon?.maxCashBack}` : ""} on
          your next booking.
        </Typography>
      </div>

      <div className="coupon-card-footer">
        <Button
          className="bg-whitesmoke font-size-medium font-bold text-primary-2"
          onClick={() => {
            handleApplyCouponCode(
              coupon?._id,
              coupon?.secretString,
              +coupon?.percentage,
              coupon
            )
            handleClose()
          }}
        >
          Apply Coupon
        </Button>
      </div>
    </div>
  )
}

export default CouponCard
