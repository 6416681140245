import { createSelector } from "reselect"
import { apiInstance } from "configs"
import { endAction, startAction } from "./LoaderAction"
import { DataURIToBlob, sortNewToOld } from "helpers"

export const artistMediasStateSelector = (state) => state?.artistMediasState
// videos
export const artistVideosStateSelector = createSelector(
  [artistMediasStateSelector],
  (artistMediasState) => artistMediasState?.artistVideos
)
export const artistVideosSelector = createSelector(
  [artistVideosStateSelector],
  (artistVideosState) => artistVideosState?.data
)
export const loadingArtistVideosSelector = createSelector(
  [artistVideosStateSelector],
  (artistVideosState) => artistVideosState?.isLoading
)
export const fetchArtistVideos = (artistID) => async (dispatch) => {
  try {
    dispatch({
      type: "ARTIST_VIDEOS_LOADING",
    })
    await apiInstance
      .get(`artist-media/videos/${artistID}`)
      .then(({ data }) => {
        dispatch({
          type: "ARTIST_VIDEOS_SUCCESS",
          payload: data?.success?.data,
        })
      })
      .catch(({ response }) => {
        dispatch({
          type: "ARTIST_VIDEOS_ERROR",
          payload: response?.data?.error?.message,
        })
      })
  } catch (error) {
    dispatch({
      type: "ARTIST_VIDEOS_ERROR",
      payload: error?.message,
    })
  }
}

// photos
export const artistPhotosStateSelector = createSelector(
  [artistMediasStateSelector],
  (artistMediasState) => artistMediasState?.artistPhotos
)
export const artistPhotosSelector = createSelector(
  [artistPhotosStateSelector],
  (artistPhotosState) =>
    [...artistPhotosState?.data]?.sort((a, b) =>
      sortNewToOld(a, b, "timestamp")
    )
)
export const loadingArtistPhotosSelector = createSelector(
  [artistPhotosStateSelector],
  (artistPhotosState) => artistPhotosState?.isLoading
)
export const fetchArtistPhotos = (artistID) => async (dispatch) => {
  try {
    await apiInstance
      .get(`artist-media/photos/${artistID}`)
      .then(({ data }) => {
        dispatch({
          type: "ARTIST_PHOTOS_SUCCESS",
          payload: data?.success?.data,
        })
      })
      .catch(({ response }) => {
        dispatch({
          type: "ARTIST_PHOTOS_ERROR",
          payload: response?.data?.error?.message,
        })
      })
  } catch (error) {
    dispatch({
      type: "ARTIST_PHOTOS_ERROR",
      payload: error?.message,
    })
  }
}

// remove all artist medias
// remove videos
export const removeArtistVideosSuccessSelector = createSelector(
  [artistMediasStateSelector],
  (artistMediasState) => artistMediasState?.removeVideos?.message
)
export const removeArtistVideosErrorSelector = createSelector(
  [artistMediasStateSelector],
  (artistMediasState) => artistMediasState?.removeVideos?.error
)
export const removeArtistVideosInProgressSelector = createSelector(
  [artistMediasStateSelector],
  (artistMediasState) => artistMediasState?.removeVideos?.isRemoving
)
export const removeArtistVideosReset = () => {
  return {
    type: "REMOVE_ARTIST_VIDEOS_RESET",
  }
}
export const removeArtistVideos = (artistID, videosIDs) => async (dispatch) => {
  try {
    dispatch({
      type: "REMOVE_ARTIST_VIDEOS_LOADING",
    })
    await apiInstance
      .post(`artist-media/delete-video`, {
        artistId: artistID,
        videoIds: videosIDs,
      })
      .then(({ data }) => {
        dispatch({
          type: "REMOVE_ARTIST_VIDEOS_SUCCESS",
          payload: data?.success?.message,
        })
      })
      .catch(({ response }) => {
        dispatch({
          type: "REMOVE_ARTIST_VIDEOS_ERROR",
          payload: response?.data?.error?.message,
        })
      })
  } catch (error) {
    dispatch({
      type: "REMOVE_ARTIST_VIDEOS_ERROR",
      payload: error?.message,
    })
  }
}

// remove photos
export const isRemoveArtistPhotosSuccessSelector = createSelector(
  [artistMediasStateSelector],
  (artistMediasState) => artistMediasState?.removePhotos?.message?.length > 0
)
export const removeArtistPhotosReset = () => {
  return {
    type: "REMOVE_ARTIST_PHOTOS_RESET",
  }
}
export const removeArtistPhotosAction =
  (artistID, photosIDs) => async (dispatch) => {
    try {
      dispatch(startAction(`REMOVE_ARTIST_PHOTOS_${artistID}`))
      await apiInstance
        .post(`artist-media/delete-photo`, {
          artistId: artistID,
          imageDataIds: photosIDs,
        })
        .then(({ data }) => {
          dispatch({
            type: "REMOVE_ARTIST_PHOTOS_SUCCESS",
            payload: data?.success?.message,
          })
        })
        .catch(({ response }) => {
          dispatch({
            type: "REMOVE_ARTIST_PHOTOS_ERROR",
            payload: response?.data?.error?.message,
          })
        })
        .finally(() => {
          dispatch(endAction(`REMOVE_ARTIST_PHOTOS_${artistID}`))
        })
    } catch (error) {
      dispatch({
        type: "REMOVE_ARTIST_PHOTOS_ERROR",
        payload: error?.message,
      })
    }
  }

// add photos
export const addArtistPhotosSuccessSelector = createSelector(
  [artistMediasStateSelector],
  (artistMediasState) => artistMediasState?.addPhotos?.isAdded
)
export const addArtistPhotosResetAction = () => {
  return {
    type: "ADD_ARTIST_PHOTOS_RESET",
  }
}
export const addArtistPhotosAction = (artistID, photos) => async (dispatch) => {
  try {
    dispatch(startAction(`ADD_ARTIST_PHOTOS_${artistID}`))
    const formData = new FormData()
    photos?.forEach((photo) => {
      formData.append("images", photo, photo?.name)
    })
    formData.append("artistId", artistID)
    await apiInstance
      .post(`artist-media/create-photo`, formData)
      .then(({ data }) => {
        dispatch({
          type: "ADD_ARTIST_PHOTOS_SUCCESS",
          payload: data?.success?.message,
        })
      })
      .catch(({ response }) => {
        dispatch({
          type: "ADD_ARTIST_PHOTOS_ERROR",
          payload: response?.data?.error?.message,
        })
      })
      .finally(() => {
        dispatch(endAction(`ADD_ARTIST_PHOTOS_${artistID}`))
      })
  } catch (error) {
    dispatch({
      type: "ADD_ARTIST_PHOTOS_ERROR",
      payload: error?.message,
    })
  }
}

// add videos
export const addArtistVideosSuccessSelector = createSelector(
  [artistMediasStateSelector],
  (artistMediasState) => artistMediasState?.addVideos?.isAdded
)
export const addArtistVideosErrorSelector = createSelector(
  [artistMediasStateSelector],
  (artistMediasState) => artistMediasState?.addVideos?.isError
)
export const addArtistVideosResetAction = () => {
  return {
    type: "ADD_ARTIST_VIDEOS_RESET",
  }
}

export const addArtistVideosAction =
  (artistID, videos, links, thumbImages) => async (dispatch) => {
    try {
      dispatch(startAction(`ADD_ARTIST_VIDEOS_${artistID}`))
      const formData = new FormData()
      videos?.forEach((video, ind) => {
        formData.append("video", video, video?.name)
        formData.append("thumbnail", DataURIToBlob(thumbImages?.[ind]), "")
      })
      links?.forEach((link) => {
        formData.append("links", link)
      })
      formData.append("artistId", artistID)
      await apiInstance
        .post(`artist-media/create-video`, formData)
        .then(({ data }) => {
          console.log({ data })
          dispatch({
            type: "ADD_ARTIST_VIDEOS_SUCCESS",
            payload: data?.success?.message,
          })
        })
        .catch(({ response }) => {
          console.log({ response })
          dispatch({
            type: "ADD_ARTIST_VIDEOS_ERROR",
            payload: response?.data?.error?.message,
          })
        })
        .finally(() => {
          dispatch(endAction(`ADD_ARTIST_VIDEOS_${artistID}`))
        })
    } catch (error) {
      dispatch({
        type: "ADD_ARTIST_VIDEOS_ERROR",
        payload: error?.message,
      })
    }
  }
