import * as YUP from "yup"
export const supportValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  countryCode: "+27",
  description: "",
}

export const supportErrSchema = YUP.object().shape({
  firstName: YUP.string()
    .required("First name is required")
    .min(3, "First name must be at least 3 characters")
    .trim()
    .matches(/^[a-zA-Z\s]*$/, "First name must contain only letters"),
  lastName: YUP.string()
    .required("Last name is required")
    .min(3, "Last name must be at least 3 characters")
    .trim()
    .matches(/^[a-zA-Z\s]*$/, "Last name must contain only letters"),
  email: YUP.string()
    .email("Please enter a valid email address")
    .required("Email is required"),
  phoneNumber: YUP.string()
    .required("Phone number is required")
    .matches(/^[0-9]{8,16}$/, "Please enter a valid phone number"),
  countryCode: YUP.string().required("Country code is required"),
  description: YUP.string()
    .required("Description is required")
    .min(10, "Description must be at least 10 characters")
    .trim(),
})
