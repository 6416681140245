import { apiInstance } from "configs"
import { lowerCaseText, sortNewToOld } from "helpers"
import { createSelector } from "reselect"
import { endAction, startAction } from "./LoaderAction"

export const bookingStateSelector = (state) => state?.bookingsState
export const userBookingsSelector = createSelector(
  [bookingStateSelector],
  (bookingState) => bookingState?.userBookings?.bookings
)
export const loadingUserBookingsSelector = createSelector(
  [bookingStateSelector],
  (bookingState) => bookingState?.userBookings?.isLoading
)
export const pendingUserBookingsSelector = createSelector(
  [userBookingsSelector],
  ([...userBookings]) =>
    userBookings
      ?.sort((a, b) => sortNewToOld(a, b, "timestamp"))
      ?.filter((booking) => lowerCaseText(booking?.status) === "pending")
)
export const pastUserBookingsSelector = createSelector(
  [userBookingsSelector],
  ([...userBookings]) =>
    userBookings
      ?.sort((a, b) => sortNewToOld(a, b, "timestamp"))
      ?.filter((booking) => lowerCaseText(booking?.status) === "past")
)
export const confirmedUserBookingsSelector = createSelector(
  [userBookingsSelector],
  ([...userBookings]) =>
    userBookings
      ?.sort((a, b) => sortNewToOld(a, b, "timestamp"))
      ?.filter((booking) => lowerCaseText(booking?.status) === "confirm")
)
export const canceledUserBookingsSelector = createSelector(
  [userBookingsSelector],
  ([...userBookings]) =>
    userBookings
      ?.sort((a, b) => sortNewToOld(a, b, "timestamp"))
      ?.filter((booking) => lowerCaseText(booking?.status) === "cancel")
)

// check if artist is present on user bookings
export const isArtistOnUserBookingsSelector = createSelector(
  [bookingStateSelector],
  (bookingState) => bookingState?.isArtistOnUserBookings
)
export const getArtistOnUserBookings =
  (artistID, userBookings) => (dispatch) => {
    if (!userBookings?.length > 0) return false
    const bookingsLength = userBookings?.filter(
      (booking) =>
        booking?.artist?._id === artistID && booking?.eventStatus === "complete"
    )?.length
    dispatch({
      type: "IS_ARTIST_ON_USER_BOOKINGS",
      payload: bookingsLength > 0,
    })
  }

export const fetchUserBookings = (userID) => async (dispatch) => {
  try {
    await apiInstance
      .get(`booking/all-booking/user/${userID}`)
      .then(({ data }) => {
        const { success } = data
        dispatch({
          type: "USER_BOOKINGS_SUCCESS",
          payload: success?.data,
        })
      })
      .catch(({ response }) => {
        const errMsg = response?.data?.error?.message
        dispatch({
          type: "USER_BOOKINGS_ERROR",
          payload: errMsg,
        })
      })
  } catch (error) {
    dispatch({
      type: "USER_BOOKINGS_ERROR",
      payload: error.message,
    })
  }
}

// artist bookings selector
export const artistBookingsSelector = createSelector(
  [bookingStateSelector],
  (bookingState) =>
    [...bookingState?.artistBookings?.bookings]?.sort((a, b) =>
      sortNewToOld(a, b, "timestamp")
    )
)
export const loadingArtistBookingsSelector = createSelector(
  [bookingStateSelector],
  (bookingState) => bookingState?.artistBookings?.isLoading
)
export const pendingArtistBookingsSelector = createSelector(
  [artistBookingsSelector],
  (artistBookings) =>
    artistBookings?.filter(
      (booking) => lowerCaseText(booking?.status) === "pending"
    )
)
export const confirmedArtistBookingsSelector = createSelector(
  [artistBookingsSelector],
  (artistBookings) =>
    artistBookings?.filter(
      (booking) => lowerCaseText(booking?.status) === "confirm"
    )
)
export const pastArtistBookingsSelector = createSelector(
  [artistBookingsSelector],
  (artistBookings) =>
    artistBookings?.filter(
      (booking) => lowerCaseText(booking?.status) === "past"
    )
)
export const cancelArtistBookingsSelector = createSelector(
  [artistBookingsSelector],
  (artistBookings) =>
    artistBookings?.filter(
      (booking) => lowerCaseText(booking?.status) === "cancel"
    )
)
export const fetchArtistBookings = (artistID) => async (dispatch) => {
  try {
    await apiInstance
      .get(`booking/all-booking/artist/${artistID}`)
      .then(({ data }) => {
        const { success } = data
        dispatch({
          type: "ARTIST_BOOKINGS_SUCCESS",
          payload: success?.data,
        })
      })
      .catch(({ response }) => {
        const errMsg = response?.data?.error?.message
        dispatch({
          type: "ARTIST_BOOKINGS_ERROR",
          payload: errMsg,
        })
      })
  } catch (error) {
    dispatch({
      type: "ARTIST_BOOKINGS_ERROR",
      payload: error?.message,
    })
  }
}

// remove bookings
export const removeBookingState = createSelector(
  [bookingStateSelector],
  (bookingState) => bookingState?.removeBookings
)
export const removeBookingLoadingSelector = createSelector(
  [removeBookingState],
  (removeBookingState) => removeBookingState?.isRemoving
)
export const removeBookingEndSelector = createSelector(
  [removeBookingState],
  (removeBookingState) => removeBookingState?.isRemoved
)
export const removeBookingsData = (userID, bookingIDs) => async (dispatch) => {
  dispatch({
    type: "REMOVE_BOOKINGS_LOADING",
  })
  await apiInstance
    .put(`booking/delete`, {
      userId: userID,
      bookingsIds: [bookingIDs],
    })
    .then(({ data }) => {
      dispatch({
        type: "REMOVE_BOOKINGS_SUCCESS",
        payload: data?.success?.data,
      })
    })
    .catch(({ response }) => {
      dispatch({
        type: "REMOVE_BOOKINGS_ERROR",
        payload: response?.data?.error?.message,
      })
    })
    .finally(() => {
      dispatch({
        type: "REMOVE_BOOKINGS_RESET",
      })
    })
}

// individual booking details
export const individualBookingSelector = createSelector(
  [bookingStateSelector],
  (bookingState) => bookingState?.bookingDetails
)
export const openBookingDetailsAction = (booking) => {
  return {
    type: "OPEN_BOOKING_DETAILS",
    payload: booking,
  }
}
export const closeBookingDetailsAction = () => {
  return {
    type: "CLOSE_BOOKING_DETAILS",
  }
}

// cancel booking
export const isBookingCancelSuccessSelector = createSelector(
  [bookingStateSelector],
  (bookingState) => bookingState?.cancelBooking?.isActionSuccess
)
export const isBookingCancelErrorSelector = createSelector(
  [bookingStateSelector],
  (bookingState) => bookingState?.cancelBooking?.isActionError
)
export const cancelBookingAction =
  (bookingID, walletVal, bankVal, actionBy) => async (dispatch) => {
    try {
      dispatch(startAction(`CANCEL_BOOKING_${bookingID}`))
      await apiInstance
        .post(`booking/booking-cancel`, {
          bookingId: bookingID,
          walletRefund: +walletVal,
          bankRefund: +bankVal,
          cancelBy: actionBy,
          walletHistoryTitle:
            lowerCaseText(actionBy) === "artist"
              ? "Artist Refund"
              : "Customer Refund",
          walletHistoryDescription:
            lowerCaseText(actionBy) === "artist"
              ? "Refund due to cancellation by artist"
              : "Refund due to cancellation by customer",
          OtherDetails: "message send",
        })
        .then(({ data }) => {
          dispatch({
            type: "CANCEL_BOOKING_SUCCESS",
            payload: data?.success?.data,
          })
        })
        .catch(({ response }) => {
          dispatch({
            type: "CANCEL_BOOKING_ERROR",
            payload: response?.data?.error?.message,
          })
        })
        .finally(() => {
          dispatch(endAction(`CANCEL_BOOKING_${bookingID}`))
        })
    } catch (error) {
      dispatch({
        type: "CANCEL_BOOKING_ERROR",
        payload: error?.message,
      })
    }
  }

// confirm event done or not
export const isBookingConfirmSuccessSelector = createSelector(
  [bookingStateSelector],
  (bookingState) => bookingState?.confirmEventDone?.isActionSuccess
)
export const confirmEventDoneAction =
  (bookingID, isComplete) => async (dispatch) => {
    dispatch(startAction(`CONFIRM_EVENT_DONE_${bookingID}`))
    dispatch({
      type: "CONFIRM_EVENT_DONE_RESET",
    })
    await apiInstance
      .post(`booking/booking-past-confirmation`, {
        bookingId: bookingID,
        isComplete: isComplete,
      })
      .then((data) => {
        dispatch({
          type: "CONFIRM_EVENT_DONE_SUCCESS",
          payload: data?.success?.data,
        })
      })
      .catch(({ response }) => {
        dispatch({
          type: "CONFIRM_EVENT_DONE_ERROR",
          payload: response?.data?.error?.message,
        })
      })
      .finally(() => {
        dispatch(endAction(`CONFIRM_EVENT_DONE_${bookingID}`))
      })
  }

// --------------------------------unused------------------------------------------
export const isValidBookingDateSelector = createSelector(
  [bookingStateSelector],
  (bookingState) => bookingState?.validateBooking?.actionSuccessMsg
)
export const validateBookingAction =
  (artistID, eventDate) => async (dispatch) => {
    try {
      dispatch(startAction(`VALIDATE_BOOKING_${artistID}`))
      dispatch({
        type: "VALIDATE_BOOKING_RESET",
      })
      await apiInstance
        .post(`artist-block-date/block-date-check`, {
          artistId: artistID,
          eventDate: eventDate,
        })
        .then(({ data }) => {
          if (data?.success?.data?.isBlock === false) {
            dispatch({
              type: "VALIDATE_BOOKING_SUCCESS",
              payload: true,
            })
            return
          }
          dispatch({
            type: "VALIDATE_BOOKING_SUCCESS",
            payload: false,
          })
        })
        .catch(({ response }) => {
          dispatch({
            type: "VALIDATE_BOOKING_ERROR",
            payload: response?.data?.error?.message,
          })
        })
        .finally(() => {
          dispatch(endAction(`VALIDATE_BOOKING_${artistID}`))
        })
    } catch (error) {
      dispatch({
        type: "VALIDATE_BOOKING_ERROR",
        payload: error?.message,
      })
    }
  }
// --------------------------------------------------------------------------------
