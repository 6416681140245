import { useIsMounted } from "hooks"
import { useCallback, useEffect, useRef } from "react"

export const SuggestionContainer = ({ children, suggestionRef }) => {
  return (
    <div
      className="suggestions-container position-relative "
      suggestionRef={suggestionRef}
    >
      <div className="suggestion-items position-absolute zIndex-1000 w-100">
        {children}
      </div>
    </div>
  )
}

const CustomSearchBar = ({
  value,
  onChange,
  placeholder,
  startIcon,
  endIcon,
  isOpen,
  setIsOpen,
  children,
  isAutoSuggestion,
  isMobileViewSearchBar,
  suggestionRef,
}) => {
  const isMounted = useIsMounted()
  const currentRef = useRef(null)
  const closeCallBack = useCallback(() => {
    const handleClickOutside = (event) => {
      if (
        currentRef.current &&
        !currentRef.current.contains(event.target) &&
        !suggestionRef?.current?.contains(event.target)
      ) {
        if (!isMobileViewSearchBar) {
          setIsOpen(false)
          return
        }
        setIsOpen(true)
      }
    }
    document.addEventListener("click", handleClickOutside, false)
    return () => {
      document.removeEventListener("click", handleClickOutside, false)
    }
  }, [setIsOpen, isMobileViewSearchBar, suggestionRef])

  useEffect(() => {
    if (isMounted()) {
      closeCallBack()
    }
  }, [closeCallBack, isMounted])

  return (
    <div ref={currentRef}>
      <div className="custom-search-bar border-25 flex-row flex-align-center flex-justify-between text-center">
        <div>{startIcon}</div>
        <input
          className="custom-search-input w-100 font-size-1_1em border-none"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
        <div>{endIcon}</div>
      </div>
      <div className="suggestion-container bg-primary-2">
        {isAutoSuggestion && isOpen ? children : null}
      </div>
    </div>
  )
}

export default CustomSearchBar
