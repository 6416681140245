import { apiInstance } from "configs"
import { createSelector } from "reselect"

export const getCategoriesState = (state) => state?.categoriesState
export const categoriesSelector = createSelector(
  (categoriesState) => categoriesState,
  (state) => state?.categories
)
export const isLoadingCategories = createSelector(
  (categoriesState) => categoriesState,
  (state) => state?.isLoading
)

// fetch categories
export const fetchingCategories = () => {
  return {
    type: "LOADING_CATEGORIES",
  }
}

// set categories
export const setCategories = (categories) => {
  return {
    type: "SET_CATEGORIES",
    payload: categories,
  }
}
// set subcategories
export const setSubCategories = (subCategories) => {
  return {
    type: "SET_SUB_CATEGORIES",
    payload: subCategories,
  }
}
// set genres
export const setGenres = (genres) => {
  return {
    type: "SET_GENRES",
    payload: genres,
  }
}

// fetch categories error
export const fetchingCategoriesError = (error) => {
  return {
    type: "FETCHING_CATEGORIES_ERROR",
    payload: error,
  }
}

// reset subcategories & genres
export const resetSubCategories = () => {
  return {
    type: "RESET_SUB_CATEGORIES",
  }
}
export const resetGenres = () => {
  return {
    type: "RESET_GENRES",
  }
}

// fetch all categories
export const fetchCategories = () => async (dispatch) => {
  try {
    dispatch(fetchingCategories())
    await apiInstance
      .get("category/all-category")
      .then(({ data }) => {
        dispatch(setCategories(data.success.data))
      })
      .catch(({ response }) => {
        const errMessage = response.data.error.message
        dispatch(fetchingCategoriesError(errMessage))
      })
  } catch (error) {
    dispatch(fetchingCategoriesError(error?.message))
  }
}

// fetch subcategories by category id
export const subcategoriesByCategoryIDSelector = createSelector(
  [getCategoriesState],
  (categoriesState) => categoriesState?.subCategories
)
export const fetchSubcategories = (categoryId) => async (dispatch) => {
  try {
    dispatch(fetchingCategories())
    await apiInstance
      .get(`sub-category/category-sub-category/${categoryId}`)
      .then(({ data }) => {
        const successData = data.success.data
        dispatch(setSubCategories(successData))
      })
      .catch(({ response }) => {
        dispatch(fetchingCategoriesError(response.data.error.message))
      })
  } catch (error) {
    dispatch(fetchingCategoriesError(error?.message))
  }
}

// fetch genres by subcategory id or category id
export const genresBySubcategoryIDSelector = createSelector(
  [getCategoriesState],
  (categoriesState) => categoriesState?.genres
)
export const fetchGenres = (reqIds) => async (dispatch) => {
  try {
    dispatch(fetchingCategories())
    await apiInstance
      .post(`genres/sub-category-genres`, {
        subCategoryIds: reqIds,
      })
      .then(({ data }) => {
        const successData = data.success.data
        dispatch(setGenres(successData))
      })
      .catch(({ response }) => {
        dispatch(fetchingCategoriesError(response?.data?.err?.message))
      })
  } catch (error) {
    dispatch(fetchingCategoriesError(error?.message))
  }
}
