const currencies = [
  {
    "Currency Name": "United States dollar",
    code: "USD",
    symbol: "$",
    countryName: "United States",
    alpha2Code: "US",
    alpha3Code: "USA",
    callingCodes: "1",
    latlng: [38, -97],
    timezones: [
      "UTC−05:00",
      "UTC−06:00",
      "UTC−07:00",
      "UTC−08:00",
      "UTC−09:00",
      "UTC−10:00",
      "UTC−11:00",
      "UTC−12:00",
      "UTC−01:00",
      "UTC+00:00",
      "UTC+01:00",
      "UTC+02:00",
      "UTC+03:00",
      "UTC+04:00",
      "UTC+05:00",
      "UTC+06:00",
      "UTC+07:00",
      "UTC+08:00",
      "UTC+09:00",
      "UTC+10:00",
      "UTC+11:00",
      "UTC+12:00",
    ],
  },
  {
    "Currency Name": "South African rand",
    code: "ZAR",
    symbol: "R",
    countryName: "South Africa",
    alpha2Code: "ZA",
    alpha3Code: "ZAF",
    callingCodes: "27",
    latlng: [-29, 24],
    timezones: ["UTC+02:00"],
  },
]

export default currencies
