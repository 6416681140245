import { CustomCarousel } from "components/core"
import { CommonHeader } from "."
import ArtistCard from "./ArtistCard"

const TopSearches = ({ isTopTrending, route, artistsData }) => {
  return (
    <div>
      <CommonHeader
        title={isTopTrending ? `Top Trending` : `Top Searches`}
        subtitle={
          isTopTrending
            ? `See what's trending in the world right now`
            : ` See what other people are looking for`
        }
        route={route ? route : `/artists/all`}
        isShowBtn={true}
        btnText={`View All`}
      />
      <div className="flex-row overflow-auto hide-scrollbar">
        <CustomCarousel
          id="top-search"
          columnGap={"1em"}
          hideArrow={artistsData?.length < 4}
        >
          {artistsData?.map((res, index) => (
            <ArtistCard data={res} key={index} />
          ))}
        </CustomCarousel>
      </div>
    </div>
  )
}

export default TopSearches
