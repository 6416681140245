import {
  CalendarTodayOutlined,
  CallOutlined,
  ChatOutlined,
  CloseOutlined,
  DateRangeOutlined,
  EmailOutlined,
  EventOutlined,
  LocationOnOutlined,
  MiscellaneousServicesOutlined,
  PeopleOutlined,
  PersonOutline,
  TimelapseOutlined,
  UpdateOutlined,
  WatchLaterOutlined,
  WcOutlined,
} from "@mui/icons-material"
import { Divider, IconButton, Typography } from "@mui/material"
import { useMemo } from "react"

// import from other files
import {
  hideDivider,
  lowerCaseText,
  primaryDateFormat,
  primaryTimeFormat,
} from "helpers"
import { useCurrencyContext } from "hooks"

const ViewRequestDetail = ({ reqDetail, handleClose }) => {
  const { currencyWiseValue } = useCurrencyContext()
  const isManagerRelatedRequest = useMemo(
    () =>
      ["manager", "managerremove"]?.includes(
        lowerCaseText(reqDetail?.requestType)
      ),
    [reqDetail]
  )
  const REQUEST_DETAIL = [
    // event type
    {
      id: "eventType",
      label: "Event Type",
      value: reqDetail?.booking?.eventType?.eventName,
      icon: <EventOutlined className="text-secondary" />,
    },
    // service type
    {
      id: "serviceType",
      label: "Service Type",
      value: reqDetail?.booking?.serviceType?.serviceName,
      icon: <MiscellaneousServicesOutlined className="text-secondary" />,
    },
    // event date
    {
      id: "date",
      label: "Event Date",
      value:
        lowerCaseText(reqDetail?.booking?.bookingType) !== "other"
          ? reqDetail?.booking?.personalizedMsgDate &&
            primaryDateFormat(reqDetail?.booking?.personalizedMsgDate)
          : reqDetail?.booking?.eventDate?.start &&
            `${primaryDateFormat(reqDetail?.booking?.eventDate?.start)}`,
      icon: <DateRangeOutlined className="text-secondary" />,
    },
    // rescheduleDate
    {
      id: "rescheduleDate",
      label: "Reschedule Date",
      value:
        lowerCaseText(reqDetail?.booking?.bookingType) !== "other"
          ? reqDetail?.booking?.reschedulePersonalizedDate &&
            primaryDateFormat(reqDetail?.booking?.reschedulePersonalizedDate)
          : reqDetail?.booking?.rescheduleDate?.start &&
            `${primaryDateFormat(reqDetail?.booking?.rescheduleDate?.start)} `,
      icon: <UpdateOutlined className="text-secondary" />,
    },
    // Event location
    {
      id: "eventLocation",
      label: "Event Location",
      value:
        lowerCaseText(reqDetail?.booking?.eventLocation) === "other"
          ? reqDetail?.booking?.cityName
          : reqDetail?.booking?.eventLocation,
      icon: <LocationOnOutlined className="text-secondary" />,
    },
    // Booking price
    {
      id: "bookingPrice",
      label: "Booking Price",
      value: reqDetail?.booking?.bookingPrice
        ? `${currencyWiseValue(reqDetail?.booking?.bookingPrice)}`
        : 0,
      icon: <LocationOnOutlined className="text-secondary" />,
    },
    // Crowd size
    {
      id: "crowdSize",
      label: "Crowd Size",
      value: reqDetail?.booking?.crowdSize,
      icon: <PeopleOutlined className="text-secondary" />,
    },
    // personalized message
    {
      id: "personalizedMessage",
      label: "Personalized Message",
      value: reqDetail?.booking?.personalizedMessage,
      icon: <ChatOutlined className="text-secondary" />,
    },
    // Event time
    {
      id: "eventTime",
      label: "Event Time",
      value:
        reqDetail?.booking?.eventTime &&
        primaryTimeFormat(reqDetail?.booking?.eventTime),
      icon: <WatchLaterOutlined className="text-secondary" />,
    },
    // Event duration
    {
      id: "eventDuration",
      label: "Event Duration",
      value:
        reqDetail?.booking?.eventDuration &&
        `${reqDetail?.booking?.eventDuration} hours`,
      icon: <TimelapseOutlined className="text-secondary" />,
    },
    // Other Details
    {
      id: "otherDetails",
      label: "Other Details",
      value: reqDetail?.booking?.OtherDetails,
      icon: <ChatOutlined className="text-secondary" />,
    },
    // manager name
    {
      id: "managerName",
      label: "Name",
      value: reqDetail?.senderUser?.firstName
        ? `${reqDetail?.senderUser?.firstName} ${reqDetail?.senderUser?.lastName}`
        : "",
      icon: <PersonOutline className="text-secondary" />,
    },
    // manager email
    {
      id: "managerEmail",
      label: "Email",
      value: isManagerRelatedRequest ? reqDetail?.senderUser?.email : "",
      icon: <EmailOutlined className="text-secondary" />,
    },
    // manager phone
    {
      id: "managerPhone",
      label: "Phone",
      value: isManagerRelatedRequest ? reqDetail?.senderUser?.phoneNumber : "",
      icon: <CallOutlined className="text-secondary" />,
    },
    // manager location
    {
      id: "managerLocation",
      label: "Location",
      value: reqDetail?.senderUser?.location?.address,
      icon: <LocationOnOutlined className="text-secondary" />,
    },
    // gender
    {
      id: "gender",
      label: "Gender",
      value: reqDetail?.senderUser?.gender,
      icon: <WcOutlined className="text-secondary" />,
    },
    // years of experience
    {
      id: "yearsOfExperience",
      label: "Years Of Experience",
      value: ["manager", "managerremove"]?.includes(
        lowerCaseText(reqDetail?.requestType)
      )
        ? reqDetail?.senderUser?.yearsOfExperience
        : "",
      icon: <CalendarTodayOutlined className="text-secondary" />,
    },
  ]


  return (
    <div className="view-request-detail-root">
      {/* header */}
      <div className="view-request-detail-header flex-row flex-justify-between flex-align-center">
        <Typography variant="h5" className="text-primary-2 font-bold">
          Request Details
        </Typography>
        <IconButton onClick={() => handleClose()}>
          <CloseOutlined />
        </IconButton>
      </div>
      <div className="m-0_5em-0">
        <Divider />
      </div>
      {/* body */}
      <div className="view-request-detail-body">
        {REQUEST_DETAIL?.map((req, reqIndex) => (
          <div key={req?.id}>
            {(req?.value?.length > 0 || req?.value > 0) && (
              <>
                <div className="view-request-detail-item flex-row flex-align-start">
                  <div>{req?.icon}</div>
                  <div className="m-0-1em">
                    <Typography variant="body1" className="font-bold">
                      {req?.label}
                    </Typography>
                    <Typography variant="body2">{req?.value}</Typography>
                  </div>
                </div>
                <div className="m-0_5em-0">
                  {hideDivider(reqIndex, REQUEST_DETAIL?.length)}
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ViewRequestDetail
