import { apiInstance } from "configs"
import { createSelector } from "reselect"
import { startAction, endAction } from "."

export const favoriteStateSelector = (state) => state.favoriteState
export const loadingFavoritesSelector = createSelector(
  (state) => state,
  (favoriteState) => favoriteState?.isLoading
)
export const favoritesSelector = createSelector(
  (state) => state,
  (favoriteState) => favoriteState?.favorites
)

export const fetchingFavorite = () => {
  return {
    type: "LOADING_FAVORITE",
  }
}

export const getFavoriteSuccess = (favoritesData) => {
  return {
    type: "GET_FAVORITE_SUCCESS",
    payload: favoritesData,
  }
}

export const getFavoriteFailure = (error) => {
  return {
    type: "GET_FAVORITE_FAILURE",
    payload: error,
  }
}

export const fetchingUserFavoritesData = (userId) => {
  return async (dispatch) => {
    try {
      await apiInstance
        .get(`favorite/all-favorite-user/${userId}`)
        .then(({ data }) => {
          dispatch(getFavoriteSuccess(data?.success?.data))
        })
        .catch(({ response }) => {
          const errMsg = response?.data?.error?.message
          dispatch(getFavoriteFailure(errMsg))
        })
    } catch (error) {
      dispatch(getFavoriteFailure(error?.message))
    }
  }
}

// add favorite
export const addFavLoadingSelector = createSelector(
  [favoriteStateSelector],
  (favoriteState) => favoriteState?.addFav?.loading
)
export const addToFavorite = (artistId, userId) => async (dispatch) => {
  try {
    dispatch({
      type: "ADD_FAVORITE_LOADING",
    })
    dispatch(startAction(artistId))
    await apiInstance
      .post(`favorite/add`, {
        artistId,
        userId,
      })
      .then(({ data }) => {
        dispatch({
          type: "ADD_FAVORITE_SUCCESS",
          payload: data?.success?.message,
        })
      })
      .catch(({ response }) => {
        const errMsg = response?.data?.error?.message
        dispatch({
          type: "ADD_FAVORITE_FAILURE",
          payload: errMsg,
        })
      })
      .finally(() => {
        dispatch(endAction(artistId))
      })
  } catch (error) {
    dispatch({
      type: "ADD_FAVORITE_FAILURE",
      payload: error?.message,
    })
  }
}

// remove favorite

export const removeFavLoadingSelector = createSelector(
  [favoriteStateSelector],
  (favoriteState) => favoriteState?.removeFav?.loading
)
export const removedFavSelector = createSelector(
  [favoriteStateSelector],
  (favoriteState) => favoriteState?.removeFav?.isRemoved
)
export const removeFromFavorite = (artistId, userId) => async (dispatch) => {
  try {
    dispatch({
      type: "REMOVE_FAVORITE_LOADING",
    })
    dispatch(startAction(artistId))
    await apiInstance
      .post(`favorite/remove`, {
        artistId,
        userId,
      })
      .then(({ data }) => {
        dispatch({
          type: "REMOVE_FAVORITE_SUCCESS",
          payload: data?.success?.message,
        })
      })
      .catch(({ response }) => {
        const errMsg = response?.data?.error?.message
        dispatch({
          type: "REMOVE_FAVORITE_FAILURE",
          payload: errMsg,
        })
      })
      .finally(() => {
        dispatch(endAction(artistId))
      })
  } catch (error) {
    dispatch({
      type: "REMOVE_FAVORITE_FAILURE",
      payload: error?.message,
    })
  }
}
