import { useMemo } from "react"
import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import { getAuthState } from "redux/actions"
import { Loader } from "components"

const UnAuthUserRoutes = ({ component: Component }) => {
  const auth = useSelector((state) => getAuthState(state))
  const isUserOrUnAuth = useMemo(
    () =>
      auth?.userRole?.toLowerCase() === "user" ||
      auth?.userRole?.toLowerCase() === "manager" ||
      auth?.userRole === "",
    [auth]
  )
  return auth?.isLoading ? (
    <Loader />
  ) : isUserOrUnAuth ? (
    <Component />
  ) : (
    <Navigate to="/login" replace />
  )
}

export default UnAuthUserRoutes
