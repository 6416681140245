import { produce } from "immer";
const headerSearchInitialState = {
  searchValue: "",
  location: {
    lat: 0,
    lng: 0,
  },
  address: "",
  range: { id: null, title: "", distance: null },
  country: "",
  city: "",
  currentLocation: {
    lat: null,
    lng: null,
    address: "",
    isChosen: false,
  },
};

const HeaderSearchReducer = (state = headerSearchInitialState, action) => {
  // produce function of immer use to update the state immutably but in mutate way
  // produce function takes two arguments 1st is the state and 2nd is the callback function.
  // callback function contains the draft state and we can immutably update the state inside the callback function safely.
  // All the mutate action is handled by immer itself.
  return produce(state, (draft) => {
    const { location, currentLocation } = draft;
    switch (action.type) {
      case "SET_SEARCH_VALUE":
        draft.searchValue = action.payload;
        break;
      case "SET_ADDRESS":
        draft.address = action.payload;
        break;
      case "SET_LOCATION":
        location.lat = action?.payload?.lat;
        location.lng = action?.payload?.lng;
        break;
      case "SET_RANGE":
        draft.range = action.payload;
        break;
      case "SET_CITY":
        draft.city = action.payload;
        break;
      case "SET_COUNTRY":
        draft.country = action.payload;
        break;
      case "SET_CURRENT_LOCATION":
        currentLocation.lat = action.payload.lat;
        currentLocation.lng = action.payload.lng;
        currentLocation.address = action.payload.address;
        currentLocation.isChosen = action.payload.isChosen;
        break;
      // reset value
      case "RESET_HEADER_SEARCH":
        draft.searchValue = headerSearchInitialState.searchValue;
        break;
      case "RESET_HEADER_SEARCH_LOCATION":
        draft.location = headerSearchInitialState.location;
        break;
      case "RESET_HEADER_SEARCH_RANGE":
        draft.range = headerSearchInitialState.range;
        break;
      case "RESET_HEADER_SEARCH_CITY":
        draft.city = headerSearchInitialState.city;
        break;
      case "RESET_HEADER_SEARCH_CURRENT_LOCATION":
        draft.currentLocation = headerSearchInitialState.currentLocation;
        break;
      default:
        return state;
    }
  });
};
export default HeaderSearchReducer;
