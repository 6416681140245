import {
  CloseOutlined,
  PhotoSizeSelectActualOutlined,
  VideoCameraBackOutlined,
} from "@mui/icons-material"
import {
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Typography,
} from "@mui/material"
import { useCallback, useEffect, useRef, useState } from "react"
import { CustomButton } from "components"
import { useApi, useIsMounted } from "hooks"

const ArtistAddPersonalizedVideos = ({
  open,
  handleClose,
  bookingID,
  getArtistBookingsData,
  artistID,
}) => {
  const isMounted = useIsMounted()
  const [msgVideo, setMsgVideo] = useState({
    objUrl: "",
    file: "",
    error: null,
  })
  const videoRef = useRef(null)
  const [msgThumb, setMsgThumb] = useState({
    objUrl: "",
    file: "",
    error: null,
  })
  const thumbRef = useRef(null)
  //upload video action
  const { postData, isPosting, isPosted } = useApi()

  // close and clear all data
  const handleCloseAll = useCallback(() => {
    handleClose()
    setMsgVideo({
      objUrl: "",
      file: "",
      error: null,
    })
    setMsgThumb({
      objUrl: "",
      file: "",
      error: null,
    })
  }, [handleClose])

  // upload message
  const handleUploadMessage = (vid, img) => {
    const formData = new FormData()
    formData.append("video", vid?.file, vid?.name)
    formData.append("bookingId", bookingID)
    formData.append("thumbImage", img?.file, img?.name)
    postData("/personalize-video/create", formData)
  }

  useEffect(() => {
    if (isMounted() && isPosted) {
      handleCloseAll()
    }
  }, [isMounted, isPosted, handleCloseAll])

  useEffect(() => {
    if (isMounted()) {
      getArtistBookingsData(artistID)
    }
  }, [artistID, isMounted, getArtistBookingsData, isPosted])

  return (
    <Dialog fullScreen fullWidth open={open}>
      <DialogContent>
        {/* message  */}
        <Container maxWidth="md">
          {/* header */}
          <div className="flex-row flex-align-center flex-justify-between m-1-0">
            <Typography variant="h4" className="font-bold text-primary-2">
              Upload Message
            </Typography>
            <IconButton
              onClick={() => {
                handleCloseAll()
              }}
            >
              <CloseOutlined />
            </IconButton>
          </div>
          <Divider />
          {/* image */}
          <div className="add-personalized-video-body p-2em-1em position-center flex-column box-shadow-normal m-1-0 border-5">
            {msgThumb?.objUrl ? (
              <div className="flex-column">
                <img
                  src={msgThumb?.objUrl}
                  controls
                  autoPlay
                  onError={(e) => {
                    setMsgThumb({
                      ...msgThumb,
                      error: e.target.error,
                    })
                  }}
                  height="200px"
                  className="border-5 box-shadow-1"
                  alt="thumbnail"
                />
                <div className="flex-row flex-align-center flex-justify-center m-t-1em">
                  <CustomButton
                    btnText={"Remove"}
                    variant="contained"
                    size={"small"}
                    disableElevation
                    className={`bg-primary-1 text-white`}
                    onClick={() => {
                      setMsgThumb({
                        objUrl: "",
                        file: "",
                        error: null,
                      })
                    }}
                  />
                </div>
              </div>
            ) : (
              <IconButton
                className="text-primary-2 bg-whitesmoke p-1em"
                disabled
              >
                <PhotoSizeSelectActualOutlined
                  fontSize="large"
                  className="font-size-3em"
                />
              </IconButton>
            )}
            <div className="m-0_5em-0 flex-column text-center">
              <Typography variant="h6" className="text-primary-9">
                Upload thumbnail image by selecting file
              </Typography>
              <Typography variant="body2" className="text-primary-1 font-bold">
                You can upload only one image at a time.
              </Typography>
            </div>
            {/* select files for upload */}
            <input
              type="file"
              accept="image/*"
              multiple={false}
              hidden
              onChange={(e) => {
                const file = e.target.files[0]
                const objUrl = URL.createObjectURL(file)
                setMsgThumb({
                  objUrl,
                  file,
                  error: null,
                })
              }}
              ref={thumbRef}
            />
            <CustomButton
              btnText={"Select File"}
              variant="contained"
              size={"small"}
              disableElevation
              className={
                !msgThumb?.objUrl ? "bg-primary-2 text-white" : "disabled-btn"
              }
              onClick={() => {
                thumbRef.current.click()
              }}
              disabled={msgThumb?.objUrl ?? false}
            />
          </div>
          {/* video */}
          <div className="add-personalized-video-body p-2em-1em position-center flex-column box-shadow-normal m-1-0 border-5">
            {msgVideo?.objUrl ? (
              <div className="flex-column">
                <video
                  src={msgVideo?.objUrl}
                  controls
                  autoPlay
                  onError={(e) => {
                    setMsgVideo({
                      ...msgVideo,
                      error: e.target.error,
                    })
                  }}
                  height="200px"
                  className="border-5 box-shadow-1"
                />
                <div className="flex-row flex-align-center flex-justify-center m-t-1em">
                  <CustomButton
                    btnText={"Remove"}
                    variant="contained"
                    size={"small"}
                    disableElevation
                    className={`bg-primary-1 text-white`}
                    onClick={() => {
                      setMsgVideo({
                        objUrl: "",
                        file: "",
                        error: null,
                      })
                    }}
                  />
                </div>
              </div>
            ) : (
              <IconButton
                className="text-primary-2 bg-whitesmoke p-1em"
                disabled
              >
                <VideoCameraBackOutlined
                  fontSize="large"
                  className="font-size-3em"
                />
              </IconButton>
            )}
            <div className="m-0_5em-0 flex-column text-center">
              <Typography variant="h6" className="text-primary-9">
                Upload your personalized video by selecting file
              </Typography>
              <Typography variant="body2" className="text-primary-1 font-bold">
                You can upload only one video at a time.
              </Typography>
            </div>
            {/* select files for upload */}
            <input
              type="file"
              accept="video/*"
              multiple={false}
              hidden
              onChange={(e) => {
                const file = e.target.files[0]
                const objUrl = URL.createObjectURL(file)
                setMsgVideo({
                  objUrl,
                  file,
                  error: null,
                })
              }}
              ref={videoRef}
            />
            <CustomButton
              btnText={"Select File"}
              variant="contained"
              size={"small"}
              disableElevation
              className={
                !msgVideo?.objUrl ? "bg-primary-2 text-white" : "disabled-btn"
              }
              onClick={() => {
                videoRef.current.click()
              }}
              disabled={msgVideo?.objUrl ?? false}
            />
          </div>
          {/* upload whole message */}
          <div className="text-center">
            {isPosting ? (
              <CustomButton
                btnText={"Uploading..."}
                variant="contained"
                size={"small"}
                disableElevation
                className={`bg-primary-2 text-white m-t-1em`}
                disabled={true}
                startIcon={
                  <CircularProgress size={20} className="text-white" />
                }
              />
            ) : (
              <CustomButton
                btnText={"Upload Message"}
                className={`${
                  msgVideo?.file && msgThumb?.file
                    ? "btn-gradient"
                    : "disabled-btn"
                }`}
                variant="contained"
                disableElevation
                disabled={!(msgVideo?.file && msgThumb?.file) ? true : false}
                onClick={() => {
                  handleUploadMessage(msgVideo, msgThumb)
                }}
              />
            )}
          </div>
        </Container>
      </DialogContent>
    </Dialog>
  )
}

export default ArtistAddPersonalizedVideos
