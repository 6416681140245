import produce from "immer";

const filterInitialState = {
  categories: [],
  events: [],
  services: [],
  ratings: [],
  gender: "",
  languages: [],
  location: {},
  priceRange: [],
};

const ArtistsFilterReducer = (state = filterInitialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "SET_FILTER_CATEGORIES":
        draft.categories = action.payload;
        break;
      case "SET_FILTER_EVENTS":
        draft.events = action.payload;
        break;
      case "SET_FILTER_SERVICES":
        draft.services = action.payload;
        break;
      case "SET_FILTER_RATINGS":
        draft.ratings = action.payload;
        break;
      case "SET_FILTER_GENDER":
        draft.gender = action.payload;
        break;
      case "SET_FILTER_LANGUAGES":
        draft.languages = action.payload;
        break;
      case "SET_FILTER_LOCATION":
        draft.location = action.payload;
        break;
      case "SET_FILTER_PRICE_RANGE":
        draft.priceRange = action.payload;
        break;
      case "RESET_FILTER":
        return filterInitialState;
      default:
        return state;
    }
  });
};

export default ArtistsFilterReducer;
