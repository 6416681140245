import { Star } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { useState } from "react"
const StarRating = ({ ratings, setRatings }) => {
  const [ratingsNumbers] = useState([1, 2, 3, 4, 5])

  //   set ratings
  const handleSetRatings = (rating) => {
    if (ratings.includes(rating)) {
      if (ratings.length === rating) {
        setRatings(ratings?.filter((num) => num !== rating))
      } else {
        setRatings(ratingsNumbers?.filter((r) => r <= rating))
      }
    } else {
      setRatings(ratingsNumbers?.filter((r) => r <= rating))
    }
  }
  return (
    <div className="ratings-star">
      {ratingsNumbers?.map((item) => (
        <IconButton
          onClick={() => handleSetRatings(item)}
          className="p-0_2em"
          key={item}
        >
          <Star
            fontSize="large"
            className={`cursor-pointer ${
              ratings?.includes(item) ? "text-primary-3" : "text-whitesmoke"
            }`}
          />
        </IconButton>
      ))}
    </div>
  )
}

export default StarRating
