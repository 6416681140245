import {
  Home,
  NotFound,
  ArtistDetails,
  EventTypesAll,
  AllArtists,
  MyBookings,
  BookingArtist,
  ArtistBookingPayment,
  Notifications,
  MyFavorites,
  AllReviews,
  UserAccount,
  UserPersonalizedMessage,
  UserSupport,
  Registration,
  MyWallet,
  RescheduledEventRequests,
  ArtistManageProfile,
  ArtistBookings,
  ArtistManagers,
  ArtistRequests,
  ArtistPricing,
  ArtistCalendar,
  ManagerProfile,
  ManagedArtists,
  ManagerRequests,
  SetPassword,
  ArtistReviews,
  WhySkyrise,
  FAQ,
  ContactUs,
  AboutUs,
  TermAnConditions,
  PrivacyPolicy,
  BookingSuccess,
  ArtistProfileViews,
  ArtistShortListedViews,
  ArtistRevenue,
} from "pages"
import { useRoutes } from "react-router-dom"
import {
  ArtistRoutes,
  ManagerRoutes,
  UnAuthUserRoutes,
  UserRoutes,
  ManagedArtistRoutes,
} from "routers"

const PublicRoutes = () => {
  const publicElements = useRoutes([
    {
      path: "/",
      element: <Home />,
    },

    {
      path: "artist/:id",
      element: <UnAuthUserRoutes component={ArtistDetails} />,
    },
    {
      path: "events",
      element: <UnAuthUserRoutes component={EventTypesAll} />,
    },
    {
      path: "artists/all",
      element: <UnAuthUserRoutes component={AllArtists} />,
    },
    {
      path: "artists/:category",
      element: <UnAuthUserRoutes component={AllArtists} />,
    },
    {
      path: "reviews/all",
      element: <UnAuthUserRoutes component={AllReviews} />,
    },
    {
      path: "login",
      element: <Registration />,
    },
    {
      path: "login/:type",
      element: <Registration />,
    },
    {
      path: "login",
      element: <Registration />,
    },
    {
      path: "/why-skyrise-celebrity",
      element: <WhySkyrise />,
    },
    {
      path: "/faq",
      element: <FAQ />,
    },
    {
      path: "/contact",
      element: <ContactUs />,
    },
    {
      path: "/about",
      element: <AboutUs />,
    },
    {
      path: "/terms-and-conditions",
      element: <TermAnConditions />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: `artist-reviews/:artistID`,
      element: <ArtistReviews />,
    },
    // authenticated user routes
    {
      path: "my-bookings",
      element: <UserRoutes component={MyBookings} />,
    },
    {
      path: "book-artist/:artistID",
      element: <UserRoutes component={BookingArtist} />,
    },
    {
      path: "payment/:artistID",
      element: <UserRoutes component={ArtistBookingPayment} />,
    },
    {
      path: "notifications",
      element: <UserRoutes component={Notifications} />,
    },
    {
      path: "my-favorites",
      element: <UserRoutes component={MyFavorites} />,
    },
    {
      path: "my-profile",
      element: <UserRoutes component={UserAccount} />,
    },
    {
      path: "personalized-messages",
      element: <UserRoutes component={UserPersonalizedMessage} />,
    },
    {
      path: "support",
      element: <UserRoutes component={UserSupport} />,
    },
    {
      path: "my-wallet",
      element: <UserRoutes component={MyWallet} />,
    },
    {
      path: "requests",
      element: <UserRoutes component={RescheduledEventRequests} />,
    },

    // payment routes
    {
      path: `booking-success/:artistID`,
      element: <UserRoutes component={BookingSuccess} />,
    },
    // artist routes
    {
      path: "/artist-dashboard",
      element: <ArtistRoutes component={Home} />,
    },
    {
      path: "/artist-profile",
      element: <ArtistRoutes component={ArtistManageProfile} />,
    },
    {
      path: "/artist-bookings",
      element: <ArtistRoutes component={ArtistBookings} />,
    },
    {
      path: "/artist-managers",
      element: <ArtistRoutes component={ArtistManagers} />,
    },
    {
      path: "/artist-requests",
      element: <ArtistRoutes component={ArtistRequests} />,
    },
    {
      path: "/artist-pricing",
      element: <ArtistRoutes component={ArtistPricing} />,
    },
    {
      path: "artist-support",
      element: <ArtistRoutes component={UserSupport} />,
    },
    {
      path: "artist-calendar",
      element: <ArtistRoutes component={ArtistCalendar} />,
    },
    {
      path: "artist/notifications",
      element: <ArtistRoutes component={Notifications} />,
    },
    {
      path: "artist/profile-views",
      element: <ArtistRoutes component={ArtistProfileViews} />,
    },
    {
      path: "artist/shortlisted-counts",
      element: <ArtistRoutes component={ArtistShortListedViews} />,
    },
    {
      path: "artist/revenue",
      element: <ArtistRoutes component={ArtistRevenue} />,
    },
    // manager routes
    {
      path: "manager-profile",
      element: <ManagerRoutes component={ManagerProfile} />,
    },
    {
      path: "managed-artists",
      element: <ManagerRoutes component={ManagedArtists} />,
    },
    {
      path: `artist-reviews-all/:artistID`,
      element: <ManagerRoutes component={ArtistReviews} />,
    },
    {
      path: "manager-requests",
      element: <ManagerRoutes component={ManagerRequests} />,
    },
    {
      path: "manager/favorites",
      element: <ManagerRoutes component={MyFavorites} />,
    },
    {
      path: "manager/notifications",
      element: <ManagerRoutes component={Notifications} />,
    },
    {
      path: "manager/support",
      element: <ManagerRoutes component={UserSupport} />,
    },
    // managed artist routes
    {
      path: "/artist-dashboard/:artistID",
      element: <ManagedArtistRoutes component={Home} />,
    },
    {
      path: "/artist-profile/:artistID",
      element: <ManagedArtistRoutes component={ArtistManageProfile} />,
    },
    {
      path: "/artist-calendar/:artistID",
      element: <ManagedArtistRoutes component={ArtistCalendar} />,
    },
    {
      path: "/artist-pricing/:artistID",
      element: <ManagedArtistRoutes component={ArtistPricing} />,
    },
    {
      path: "/artist-bookings/:artistID",
      element: <ManagedArtistRoutes component={ArtistBookings} />,
    },
    {
      path: "/artist-requests/:artistID",
      element: <ManagedArtistRoutes component={ArtistRequests} />,
    },
    {
      path: "artist/profile-views/:artistID",
      element: <ManagedArtistRoutes component={ArtistProfileViews} />,
    },
    {
      path: "artist/shortlisted-counts/:artistID",
      element: <ManagedArtistRoutes component={ArtistShortListedViews} />,
    },
    {
      path: "artist/revenue/:artistID",
      element: <ManagedArtistRoutes component={ArtistRevenue} />,
    },
    // password reset
    {
      path: "password-reset/:token",
      element: <SetPassword />,
    },
    // not found route
    {
      path: "*",
      element: <NotFound />,
    },
  ])

  return {
    ...publicElements,
  }
}

export default PublicRoutes
