import { Divider, IconButton, Typography } from "@mui/material"
import {
  PersonOutlined,
  EmailOutlined,
  LocationOnOutlined,
  CategoryOutlined,
  DeleteOutlined,
} from "@mui/icons-material"
import { CustomButton } from "components"
import { getTimeFromNow, lowerCaseText } from "helpers"
import { Loader } from "components/core"
import { useLoader } from "hooks"

const ManagerRequestCard = ({
  request,
  handleNavigate,
  handleRemoveRequest,
}) => {
  const { namesInLoaderActions } = useLoader()
  const OPTIONS = [
    {
      id: "name",
      label: "Name",
      value: request?.receiverUser?.firstName
        ? `${request?.receiverUser?.firstName} ${request?.receiverUser?.lastName}`
        : "N/A",
      icon: PersonOutlined,
    },
    {
      id: "email",
      label: "Email",
      value: request?.receiverUser?.email || "N/A",
      icon: EmailOutlined,
    },
    {
      id: "location",
      label: "Location",
      value: request?.receiverUser?.location?.address || "N/A",
      icon: LocationOnOutlined,
    },
    {
      id: "category",
      label: "Category",
      value: request?.receiverUser?.category?.title || "N/A",
      icon: CategoryOutlined,
    },
  ]

  const renderBtnByStatus = (status) => {
    switch (status?.toLowerCase()) {
      case "accept":
        return {
          btnText: "Accepted",
          className: "accept-btn",
        }
      case "reject":
        return {
          btnText: "Rejected",
          className: "reject-btn",
        }
      default:
        return {
          btnText: "Pending",
          className: "pending-btn",
        }
    }
  }

  return (
    <div className="manager-request-card-root p-1em h-100 box-shadow-normal border-5 w-100">
      {/* header */}
      <div className="manager-request-card-header flex-row flex-align-center flex-justify-between">
        <div>
          <Typography variant="h6">Artist Request</Typography>
          {lowerCaseText(request?.requestType) !== "manager" && (
            <Typography className="text-primary-1 font-bold font-size-small">
              Remove
            </Typography>
          )}
          <Typography
            gutterBottom
            className="text-primary-2 font-bold font-size-small"
          >
            {getTimeFromNow(request?.timestamp)}
          </Typography>
        </div>
        {lowerCaseText(request?.status) !== "pending" && (
          <IconButton
            className="border-10 bg-whitesmoke"
            disabled={namesInLoaderActions.includes(
              `REMOVE_REQUEST_${request?._id}`
            )}
            onClick={() => {
              handleRemoveRequest()
            }}
          >
            {namesInLoaderActions?.includes(
              `REMOVE_REQUEST_${request?._id}`
            ) ? (
              <Loader
                isCircularLoader={true}
                circularProgressSize={22}
                bgNone
              />
            ) : (
              <DeleteOutlined className="text-primary-1" />
            )}
          </IconButton>
        )}
      </div>
      <Divider />
      {/* body */}
      <div className="manager-request-card-body m-0_5em-0 ">
        {OPTIONS?.map((opt) => (
          <div key={opt?.id} className="flex-row flex-align-center m-0_5em-0">
            <opt.icon className="text-secondary" />
            <div className="flex-column m-0-0_5em w-90">
              <Typography variant="body1" className="font-bold">
                {opt?.label}
              </Typography>
              <Typography
                variant="body2"
                className="text-ellipses text-secondary"
              >
                {opt?.value}
              </Typography>
            </div>
          </div>
        ))}
      </div>
      <Divider />
      {/* actions */}
      <div className="manager-request-card-actions m-t-1em flex-row flex-justify-between flex-align-center">
        <CustomButton
          btnText="View Profile"
          className="bg-whitesmoke text-primary-3 font-bold"
          variant="contained"
          disableElevation
          onClick={() => handleNavigate(request?.receiverUser?._id)}
        />
        <CustomButton
          btnText={renderBtnByStatus(request?.status)?.btnText}
          className={renderBtnByStatus(request?.status)?.className}
          variant="contained"
          disableElevation
          disabled={true}
        />
      </div>
    </div>
  )
}

export default ManagerRequestCard
