import { Container, Typography } from "@mui/material"

const ArtistDetailsTab = ({ tabIndex, setTabIndex }) => {
  const tabs = [
    {
      value: 1,
      label: "Biography",
    },
    {
      value: 2,
      label: "Photos",
    },
    {
      value: 3,
      label: "Videos",
    },
    {
      value: 4,
      label: "Events",
    },
    {
      value: 5,
      label: "Languages",
    },
    {
      value: 6,
      label: "Charges",
    },
    {
      value: 7,
      label: "Reviews",
    },
    {
      value: 8,
      label: "Social Links",
    },
  ]
  return (
    <div>
      <div className="artist-tab-container">
        {/* tab header with tab option */}
        <div className="artist-tab-header box-shadow-1 overflow-x-auto">
          <Container className="artist-tab-header-container">
            {tabs?.map((tab, index) => (
              <div
                key={index}
                className={`text-black text-transform-none ${
                  tabIndex === tab?.value
                    ? "artist-tab-btn-active"
                    : "artist-tab-btn"
                }`}
                onClick={() => setTabIndex(tab?.value)}
                role="button"
              >
                <Typography className="font-size-1_1em">
                  {tab?.label}
                </Typography>
              </div>
            ))}
          </Container>
        </div>
        <div className="artist tab-item"></div>
      </div>
    </div>
  )
}

export default ArtistDetailsTab
