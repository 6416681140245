import { currencies } from "configs"
import { getCurrencyRate, getFromLocalStorage } from "helpers"
import React, { useCallback, useMemo } from "react"
import { useEffect } from "react"
import { useState } from "react"
import { createContext } from "react"

const CurrencyContext = createContext()

export const CurrencyContextProvider = ({ children }) => {
  const [selectedCurrency, setSelectedCurrency] = useState({})
  const [openCurrencyPicker, setOpenCurrencyPicker] = useState(false)

  const currencyCodeFromStorage = useMemo(
    () => getFromLocalStorage("skyriseCurrencyCode"),
    []
  )
  const handleSelectCurrency = useCallback((currency) => {
    if (!currency?.code) {
      return true
    }
    setSelectedCurrency(currency)
    localStorage.setItem("skyriseCurrencyCode", currency?.code)
  }, [])

  const onOpenCurrencyPicker = useCallback(() => {
    setOpenCurrencyPicker(true)
  }, [])

  const onCloseCurrencyPicker = useCallback(() => {
    setOpenCurrencyPicker(false)
  }, [])

  const setCurrency = useCallback(() => {
    if (currencyCodeFromStorage) {
      const currency = currencies?.find(
        (item) => item?.code === currencyCodeFromStorage
      )
      setSelectedCurrency(currency)
      return
    }
    const currency = currencies?.find((item) => item?.code === "ZAR")
    setSelectedCurrency(currency)
  }, [currencyCodeFromStorage])

  useEffect(() => {
    let isMount = true
    if (isMount) {
      setCurrency()
    }
    return () => {
      isMount = false
    }
  }, [setCurrency])

  const currencyCode = useMemo(() => {
    if (selectedCurrency?.code) {
      return selectedCurrency?.code
    }
    return "ZAR"
  }, [selectedCurrency])

  const currencyWiseValue = useCallback(
    (amount) => {
      if (currencyCode) {
        return getCurrencyRate(amount, currencyCode)
      }
      return getCurrencyRate(amount, "ZAR")
    },
    [currencyCode]
  )

  const value = {
    selectedCurrency,
    handleSelectCurrency,
    openCurrencyPicker,
    onOpenCurrencyPicker,
    onCloseCurrencyPicker,
    currencyCodeFromStorage,
    currencyCode,
    currencyWiseValue,
  }
  return (
    <CurrencyContext.Provider value={value}>
      {children}
    </CurrencyContext.Provider>
  )
}
const useCurrencyContext = () => {
  const {
    selectedCurrency,
    handleSelectCurrency,
    openCurrencyPicker,
    onOpenCurrencyPicker,
    onCloseCurrencyPicker,
    currencyCodeFromStorage,
    currencyCode,
    currencyWiseValue,
  } = React.useContext(CurrencyContext)
  return {
    selectedCurrency,
    handleSelectCurrency,
    openCurrencyPicker,
    onOpenCurrencyPicker,
    onCloseCurrencyPicker,
    currencyCodeFromStorage,
    currencyCode,
    currencyWiseValue,
  }
}

export default useCurrencyContext
