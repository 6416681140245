import { apiInstance } from "configs"
import { sortNewToOld } from "helpers"
import { createSelector } from "reselect"
import { endAction, startAction } from "./LoaderAction"

// selector for wallet balance & wallet history
export const walletStateSelector = (state) => state.walletState
export const totalBalanceSelector = createSelector(
  [walletStateSelector],
  (state) => state?.wallet?.balance
)
export const totalSpentSelector = createSelector(
  [walletStateSelector],
  (state) => state?.wallet?.totalSpent
)

export const loadingWalletSelector = createSelector(
  [walletStateSelector],
  (state) => state?.wallet?.isLoading
)
// wallet history
export const walletHistorySelector = createSelector(
  [walletStateSelector],
  (state) =>
    [...state?.walletHistory?.history]?.sort((a, b) =>
      sortNewToOld(a, b, "timestamp")
    )
)
// wallet history loading
export const walletHistoryLoadingSelector = createSelector(
  [walletStateSelector],
  (state) => state?.walletHistory?.isLoading
)
// credited wallet history
export const getCreditedWalletHistory = createSelector(
  [walletHistorySelector],
  (history) => history?.filter((item) => item?.type?.toLowerCase() === "credit")
)
// debited wallet history
export const getDebitedWalletHistory = createSelector(
  [walletHistorySelector],
  (history) => history?.filter((item) => item?.type?.toLowerCase() === "debit")
)

// recent credited wallet history
export const recentCreditWalletHistorySelector = createSelector(
  [getCreditedWalletHistory],
  ([...history]) =>
    history?.sort((a, b) => sortNewToOld(a, b, "timestamp"))?.[0]
)
// recent debited wallet history
export const recentDebitWalletHistorySelector = createSelector(
  [getDebitedWalletHistory],
  ([...history]) =>
    history?.sort((a, b) => sortNewToOld(a, b, "timestamp"))?.[0]
)

// wallet balance
export const loadWalletBalance = () => {
  return {
    type: "LOAD_WALLET_BALANCE",
  }
}
export const loadWalletBalanceSuccess = (balance, totalSpent) => {
  return {
    type: "LOAD_WALLET_BALANCE_SUCCESS",
    payload: {
      balance,
      totalSpent,
    },
  }
}
export const loadWalletBalanceFailure = (error) => {
  return {
    type: "LOAD_WALLET_BALANCE_FAILURE",
    payload: error,
  }
}
export const fetchWalletBalance = (userID) => async (dispatch) => {
  try {
    dispatch(loadWalletBalance())
    await apiInstance
      .get(`wallet/get-wallet-balance/${userID}`)
      .then(({ data }) => {
        const { balance, spent } = data?.success?.data
        dispatch(loadWalletBalanceSuccess(balance, spent))
      })
      .catch(({ response }) => {
        const errMsg = response?.data?.error?.message
        dispatch(loadWalletBalanceFailure(errMsg))
      })
  } catch (error) {
    dispatch(loadWalletBalanceFailure(error))
  }
}

// wallet history
export const loadingWalletHistory = () => {
  return {
    type: "LOAD_WALLET_HISTORY",
  }
}
export const loadWalletHistorySuccess = (historyData) => {
  return {
    type: "LOAD_WALLET_HISTORY_SUCCESS",
    payload: historyData,
  }
}
export const loadWalletHistoryFailure = (error) => {
  return {
    type: "LOAD_WALLET_HISTORY_FAILURE",
    payload: error,
  }
}

export const fetchWalletHistory = (userID) => async (dispatch) => {
  try {
    dispatch(loadingWalletHistory())
    await apiInstance
      .get(`wallet-history/all-wallet-history/${userID}`)
      .then(({ data }) => {
        dispatch(loadWalletHistorySuccess(data?.success?.data))
      })
      .catch(({ response }) => {
        const errMsg = response?.data?.error?.message
        dispatch(loadWalletHistoryFailure(errMsg))
      })
  } catch (error) {
    dispatch(loadWalletHistoryFailure(error?.message))
  }
}

// update wallet balance
export const updateWalletBalance =
  (userID, amount, type, title, desc, balance, spent) => async (dispatch) => {
    try {
      dispatch(startAction(`UPDATE_WALLET_${userID}`))
      dispatch({
        type: "UPDATE_WALLET_BALANCE_CLEAR",
      })
      await apiInstance
        .put(`wallet/update`, {
          userId: userID,
          balance: +balance - +amount,
          transactionAmount: amount,
          spent: +spent + +amount,
          transactionType: type,
          title: title,
          description: desc,
        })
        .then(({ data }) => {
          dispatch({
            type: "UPDATE_WALLET_BALANCE_SUCCESS",
            payload: data?.success?.message,
          })
        })
        .catch(({ response }) => {
          const errMsg = response?.data?.error?.message
          dispatch({
            type: "UPDATE_WALLET_BALANCE_FAILURE",
            payload: errMsg,
          })
        })
        .finally(() => {
          dispatch(endAction(`UPDATE_WALLET_${userID}`))
        })
    } catch (error) {
      dispatch({
        type: "UPDATE_WALLET_BALANCE_FAILURE",
        payload: error?.message,
      })
    }
  }
