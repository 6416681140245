import {
  Button,
  Dialog,
  DialogContent,
  ThemeProvider,
  Typography,
} from "@mui/material"
import { Suspense, useEffect } from "react"
import { BrowserRouter } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Cookies from "js-cookie"
// import from other files
import { Loader, ScrollToTop } from "components"
import { useIsMounted, useFCMToken, CurrencyContextProvider } from "hooks"
import {
  getCurrentUserDetails,
  logoutUser,
  setAuthToken,
  userBlockedSelector,
} from "redux/actions"
import { PublicRoutes } from "routers"
import CustomTheme from "themes"
import { Home } from "pages"
import { BlockOutlined } from "@mui/icons-material"

const App = () => {
  const auth = useSelector((state) => state.authState)
  // is user blocked
  const isUserBlocked = useSelector((state) => userBlockedSelector(state))
  // isMounted is a hook to check if the component is mounted
  const isMounted = useIsMounted()
  // dispatch is a hook to dispatch actions
  const dispatch = useDispatch()

  // getCurrentUserDetails used to get the user details and roles also
  // According to the roles, the user is redirected to the respective pages

  const token = Cookies.get("skyRiseToken")
  // set auth token
  useEffect(() => {
    if (isMounted() && token) {
      dispatch(setAuthToken(token))
    }
  }, [isMounted, token, dispatch])
  // get user details
  useEffect(() => {
    if (isMounted()) {
      dispatch(getCurrentUserDetails(token))
    }
  }, [isMounted, dispatch, token])

  // fcm token
  useFCMToken(auth?.currentUser?._id)

  return (
    <Suspense fallback={<Loader />}>
      <CurrencyContextProvider>
        <ThemeProvider theme={CustomTheme}>
          <BrowserRouter>
            <ScrollToTop>
              {auth?.isLoading ? (
                <Loader />
              ) : isUserBlocked ? (
                <div>
                  <Dialog maxWidth="xs" fullWidth open={true}>
                    <DialogContent>
                      <div className="text-center">
                        <BlockOutlined
                          fontSize="large"
                          className="text-primary-1"
                        />
                        <Typography
                          variant="h5"
                          className="text-primary-1 font-bold"
                        >
                          You are blocked
                        </Typography>
                        <Typography>
                          You are blocked by the admin. Please contact the admin
                          to unblock and access the site.
                        </Typography>
                      </div>
                    </DialogContent>
                    <div className="text-center">
                      <Button
                        variant="contained"
                        color="error"
                        className="m-1-0"
                        onClick={() => dispatch(logoutUser())}
                      >
                        Log Out
                      </Button>
                    </div>
                  </Dialog>
                  <Home />
                </div>
              ) : (
                <PublicRoutes />
              )}
            </ScrollToTop>
          </BrowserRouter>
        </ThemeProvider>
      </CurrencyContextProvider>
    </Suspense>
  )
}

export default App
