import {
  LocationOnOutlined,
  MyLocationOutlined,
  SearchOutlined,
  CloseOutlined,
  CheckCircleOutline,
  ArrowForwardOutlined,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import {
  Avatar,
  Button,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material"
import { Fragment, useCallback, useEffect, useRef, useState } from "react"
// import from other files
import {
  CustomButton,
  CustomSearchBar,
  PlaceAutoComplete,
  SuggestionContainer,
} from "components"
import { hideDivider } from "helpers"
import { useIsMounted } from "hooks"
// header actions
import {
  setSearchValue,
  resetSearchValue,
  setSearchLocation,
  setSearchAddress,
  setHeaderCountry,
  setHeaderSearchRange,
  resetHeaderSearchRange,
  setHeaderCurrentLocation,
} from "redux/actions"
import { resetHeaderCurrentLocation } from "redux/actions/HeaderSearchActions"
import { RANGE_ARR, TOP_CITIES } from "configs"
import { Link } from "react-router-dom"

const HeaderSearch = ({
  searchValue,
  dispatch,
  filteredRes,
  isMobileViewSearchBar,
  setIsFullSearchBar,
  isBelow950,
  isFullSearchBar,
  address,
  range,
  currentLocation,
}) => {
  const suggestionRef = useRef(null)
  const [isSuggestionOpen, setIsSuggestionOpen] = useState(false)
  const [isChoseForLocation, setIsChoseForLocation] = useState(false)

  // mounted custom hook
  const isMounted = useIsMounted()
  const navigate = useNavigate()

  const handleFullSearchBarOpen = useCallback(() => {
    if (searchValue.length > 0) {
      isBelow950 && setIsFullSearchBar(true)
    } else {
      setIsFullSearchBar(false)
    }
  }, [searchValue, setIsFullSearchBar, isBelow950])

  useEffect(() => {
    if (isMounted()) {
      handleFullSearchBarOpen()
    }
  }, [handleFullSearchBarOpen, isMounted])

  const [userCurrentLocation, setUserCurrentLocation] = useState({})
  useEffect(() => {
    isChoseForLocation &&
      navigator?.geolocation?.getCurrentPosition(
        ({ coords }) => {
          isMounted() &&
            setUserCurrentLocation({
              lat: coords?.latitude,
              lng: coords?.longitude,
            })
        },
        (error) => {
          console.log(error)
          alert("Please enable your location")
        },
        []
      )
  }, [isMounted, isChoseForLocation])

  return (
    <>
      <CustomSearchBar
        suggestionRef={suggestionRef}
        value={searchValue}
        placeholder="Search artists by name or category"
        // if search value is there then show clear icon else show search icon
        startIcon={
          (searchValue?.length > 0 || isChoseForLocation || isFullSearchBar) &&
          isBelow950 ? (
            <IconButton
              onClick={() => {
                dispatch(resetSearchValue())
                setIsChoseForLocation(false)
                setIsFullSearchBar(false)
                setIsSuggestionOpen(false)
              }}
            >
              <CloseOutlined />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                isBelow950 && !isMobileViewSearchBar && setIsFullSearchBar(true)
              }}
            >
              <SearchOutlined />
            </IconButton>
          )
        }
        endIcon={
          <IconButton
            onClick={() => {
              isChoseForLocation && !isMobileViewSearchBar && !isBelow950
                ? setIsSuggestionOpen(!isSuggestionOpen)
                : setIsSuggestionOpen(true)
              setIsChoseForLocation(true)
              isBelow950 && setIsFullSearchBar(true)
            }}
          >
            <MyLocationOutlined />
          </IconButton>
        }
        isOpen={isSuggestionOpen}
        setIsOpen={setIsSuggestionOpen}
        onChange={(ev) => {
          dispatch(setSearchValue(ev.target.value))
          ev.target.value.length > 0
            ? setIsSuggestionOpen(true)
            : setIsSuggestionOpen(false)
          setIsChoseForLocation(false)
        }}
        isAutoSuggestion={true}
        isMobileViewSearchBar={isMobileViewSearchBar}
      >
        {/* suggestions container */}
        {/* search artists by name or category name */}
        {/* show UI by location also */}
        <SuggestionContainer suggestionRef={suggestionRef}>
          <div className={"suggestion-container-root"} ref={suggestionRef}>
            {isChoseForLocation ? (
              <div className="location-search-root p-0_5em-1em">
                {/*-------------------------- google autocomplete to chose popular city------------------------- */}
                <div className="google-autocomplete-input position-relative">
                  <Typography gutterBottom className="font-size-1_1em">
                    Chose City
                  </Typography>
                  {/* place autocomplete */}
                  <PlaceAutoComplete
                    handleOpenSuggestion={() => {
                      setIsSuggestionOpen(true)
                    }}
                    address={address}
                    handleLocationAddress={(add) => {
                      dispatch(setSearchAddress(add))
                    }}
                    handleLocation={(lat, lng) => {
                      dispatch(
                        setHeaderCurrentLocation({
                          lat,
                          lng,
                        })
                      )
                      dispatch(setSearchLocation({ lat, lng }))
                    }}
                    handleCountry={(country) => {
                      dispatch(setHeaderCountry(country))
                    }}
                  />
                </div>
                {/* location by range */}
                <div className="location-by-range m-1-0 ">
                  <Typography gutterBottom className="font-size-1_1em">
                    Chose Range
                  </Typography>
                  <div className="use-current-location flex-row flex-align-center border-25 w-fit m-0_5em-0">
                    <div
                      className="bg-whitesmoke w-fit border-circle p-0_5em m-r-0_5em position-center cursor-pointer text-primary-3"
                      role="button"
                      onClick={() => {
                        setIsSuggestionOpen(true)
                        if (currentLocation?.isChosen)
                          return dispatch(resetHeaderCurrentLocation())

                        dispatch(setSearchAddress("Your Location"))
                        dispatch(
                          setHeaderCurrentLocation({
                            ...userCurrentLocation,
                            address: "Your Location",
                            isChosen: true,
                          })
                        )
                      }}
                    >
                      {currentLocation?.isChosen ? (
                        <CheckCircleOutline className="pointer-none" />
                      ) : (
                        <RadioButtonUncheckedOutlined className="pointer-none" />
                      )}
                    </div>
                    <div>
                      <Typography className="font-size-1em text-transform-none m-0_5em-0 font-bold text-primary-3">
                        Use Current Location
                      </Typography>
                    </div>
                  </div>
                  <div className="location-range-btn flex-row flex-align-center flex-wrap ">
                    {RANGE_ARR?.map((rangeItem) => (
                      <CustomButton
                        key={rangeItem.id}
                        variant="outlined"
                        className="text-primary-2 border-primary-2 m-r-0_5em m-b-0_5em"
                        btnText={`${rangeItem.title}`}
                        onClick={() => {
                          if (range.id === rangeItem.id) {
                            dispatch(resetHeaderSearchRange())
                            return
                          }
                          dispatch(setHeaderSearchRange(rangeItem))
                        }}
                        endIcon={
                          range.id === rangeItem.id ? (
                            <CheckCircleOutline className="pointer-none" />
                          ) : (
                            <></>
                          )
                        }
                      />
                    ))}
                  </div>
                </div>
                {/* popular cities */}
                <div className="search-popular-cities">
                  <Typography gutterBottom className="font-size-1_1em">
                    Popular Cities
                  </Typography>
                  <div className="flex-row flex-align-center flex-wrap m-1-0">
                    {TOP_CITIES?.map((city) => (
                      <div
                        key={city.id}
                        className="search-popular-city flex-row flex-align-center p-0_5em-1em border-10 bg-whitesmoke m-r-0_5em m-b-0_5em"
                        onClick={() => {
                          dispatch(setSearchAddress(city.name))
                          dispatch(
                            setHeaderCurrentLocation({
                              ...city.location,
                              address: city.name,
                              isChosen: false,
                            })
                          )
                        }}
                      >
                        <IconButton
                          disableTouchRipple
                          className="cursor-default"
                        >
                          <LocationOnOutlined htmlColor="var(--col-primary-2)" />
                        </IconButton>
                        <Typography>{city.name}</Typography>
                      </div>
                    ))}
                  </div>
                </div>
                {/* view result btn */}
                <div className="view-search-res-btn">
                  <Button
                    variant="contained"
                    className="btn-gradient text-transform-none m-0_5em-0 font-size-1em"
                    fullWidth={isMobileViewSearchBar}
                    size="large"
                    endIcon={<ArrowForwardOutlined />}
                    component={Link}
                    to={`/artists/all`}
                  >
                    Let's Start
                  </Button>
                </div>
              </div>
            ) : (
              <>
                {filteredRes?.length > 0 ? (
                  <>
                    {filteredRes?.map((item, index) => (
                      <Fragment key={index}>
                        <div className="header-suggestion-item cursor-pointer">
                          <ListItem
                            className="w-100"
                            onClick={() => {
                           
                              navigate(`/artist/${item?._id}`)
                            }}
                          >
                            <ListItemAvatar>
                              {item?.profileImageUrl ? (
                                <div className="artist-avatar img-loading border-circle w-fit">
                                  <Avatar src={item?.profileImageUrl} />
                                </div>
                              ) : (
                                <div>
                                  <Avatar className="bg-primary-2">
                                    {item?.firstName?.slice(0, 1)}
                                  </Avatar>
                                </div>
                              )}
                            </ListItemAvatar>
                            <ListItemText
                              className="w-100"
                              primary={
                                <Typography className="text-ellipses">
                                  {`${item?.firstName} ${item?.lastName}`}
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  className="text-ellipses"
                                  color={"GrayText"}
                                  variant="body2"
                                >
                                  {item?.category?.title}
                                </Typography>
                              }
                            />
                          </ListItem>
                        </div>
                        {hideDivider(index, filteredRes?.length)}
                      </Fragment>
                    ))}
                  </>
                ) : (
                  <div className="header-suggestion-item">
                    <Typography className="font-size-1_2em">{`No Results For "${searchValue}"`}</Typography>
                  </div>
                )}
              </>
            )}
          </div>
        </SuggestionContainer>
      </CustomSearchBar>
    </>
  )
}

export default HeaderSearch
