import produce from "immer"

const State = {
  data: [],
  loading: true,
  error: null,
}

const initialState = {
  reviews: State,
  reviewsByArtist: State,
  giveReview: {
    isReviewDone: false,
    isReviewError: false,
  },
}

const ReviewsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type } = action
    const { reviews, reviewsByArtist, giveReview } = draft
    switch (type) {
      // all reviews
      case "REVIEWS_LOADING":
        reviews.loading = true
        reviews.error = null
        reviews.data = []
        break
      case "REVIEWS_SUCCESS":
        reviews.loading = false
        reviews.error = null
        reviews.data = action.payload
        break
      case "REVIEWS_FAILURE":
        reviews.loading = false
        reviews.error = action.payload
        reviews.data = []
        break
      // reviews by artist
      case "REVIEWS_BY_ARTIST_LOADING":
        reviewsByArtist.loading = true
        reviewsByArtist.error = null
        reviewsByArtist.data = []
        break
      case "REVIEWS_BY_ARTIST_SUCCESS":
        reviewsByArtist.loading = false
        reviewsByArtist.error = null
        reviewsByArtist.data = action.payload
        break
      case "REVIEWS_BY_ARTIST_FAILURE":
        reviewsByArtist.loading = false
        reviewsByArtist.error = action.payload
        reviewsByArtist.data = []
        break
      // give review
      case "REVIEW_GIVEN_SUCCESS":
        giveReview.isReviewDone = true
        giveReview.isReviewError = false
        break
      case "REVIEW_GIVEN_FAILURE":
        giveReview.isReviewDone = false
        giveReview.isReviewError = true
        break
      case "REVIEW_GIVEN_RESET":
        giveReview.isReviewDone = false
        giveReview.isReviewError = false
        break
      default:
        break
    }
  })

export default ReviewsReducer
