import produce from "immer"

const initialState = {
  blockedDates: [],
  isLoading: true,
  error: null,
  addBlockDates: {
    isDone: false,
    isErr: false,
  },
  removeBlockDates: {
    isRemoved: false,
    isErr: false,
    errMsg: null,
  },
}
const BlockedDatesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action
    const { addBlockDates, removeBlockDates } = draft
    switch (type) {
      case "BLOCKED_DATES_LOADING":
        draft.isLoading = true
        draft.error = null
        break
      case "BLOCKED_DATES_SUCCESS":
        draft.blockedDates = payload
        draft.isLoading = false
        draft.error = null
        break
      case "BLOCKED_DATES_ERROR":
        draft.error = payload
        draft.isLoading = false
        break
      // Add Block Dates
      case "ADD_BLOCKED_DATES_SUCCESS":
        addBlockDates.isDone = true
        addBlockDates.isErr = false
        break
      case "ADD_BLOCKED_DATES_ERROR":
        addBlockDates.isDone = false
        addBlockDates.isErr = true
        break
      case "ADD_BLOCKED_DATES_RESET":
        addBlockDates.isDone = false
        addBlockDates.isErr = false
        break
      // Remove Block Dates
      case "REMOVE_BLOCKED_DATES_SUCCESS":
        removeBlockDates.isRemoved = action.payload
        removeBlockDates.isErr = false

        break
      case "REMOVE_BLOCKED_DATES_ERROR":
        removeBlockDates.isRemoved = false
        removeBlockDates.isErr = action.payload.isErr
        removeBlockDates.errMsg = action.payload.errMsg
        break
      case "REMOVE_BLOCKED_DATES_RESET":
        removeBlockDates.isRemoved = false
        removeBlockDates.isErr = false
        removeBlockDates.errMsg = null
        break
      default:
        return draft
    }
  })
export default BlockedDatesReducer
