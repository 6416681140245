import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { CustomButton, PlaceAutoComplete } from "components";
import { Formik } from "formik";
import { useEffect, useMemo, useRef } from "react";
import { lowerCaseText } from "helpers";
import { useAdmin, useIsMounted, useLoader } from "hooks";
import { artistPricingErrSchemas } from "schemas/artistPricingSchemas";
import { SERVICE_TYPE } from "constant";
import { useSelector } from "react-redux";
const ArtistPricingForm = ({
  pricingState,
  handleAddPricingList,
  addPricingErrMsg,
  artistID,
  // handle change
  handleSelectCrowdSize,
  handleSelectOtherCrowdSize,
  handleServiceType,
  handleSelectDay,
  handleSelectOtherDay,
  handleSelectLocation,
  handleSelectOtherLocation,
  handleSelectLocLatLng,
  handleSelectPricePerHour,
  isUpdateForm,
  // update
  handleUpdatePricingList,
  pricingID,
  isUpdatingPricing,
  updatePricingOpen,
}) => {
  const formikRef = useRef();
  const isMounted = useIsMounted();
  const { namesInLoaderActions } = useLoader();
  const { crowdSizes, days } = useAdmin();
  const auth = useSelector((state) => state.authState);
  const CROWD_SIZES = useMemo(
    () =>
      crowdSizes?.map((i) => {
        return {
          max: i.max,
          min: i.min,
        };
      }),
    [crowdSizes]
  );
  const DAYS = useMemo(
    () => [
      {
        day: "Other",
        _id: "Other",
      },
      ...days,
    ],
    [days]
  );

  // add pricing list
  const handleSubmitPricing = (values, actions) => {
    const data = {
      type: values?.serviceType?.toUpperCase(),
      artistId: auth?.currentUser?._id,
      personalizePrice: values.pricePerHour,
      numberOfDays: values?.otherDay ? values?.otherDay : values?.day,
      pricePerHour: values.pricePerHour,
      maxCrowdSize: values?.otherCrowdSize?.max
        ? values?.otherCrowdSize?.max
        : values?.crowdSize?.max,
      minCrowdSize: values?.otherCrowdSize?.min
        ? values?.otherCrowdSize?.min
        : values?.crowdSize?.min,

      otherCrowdSize: Boolean(values?.otherCrowdSize?.max),
      otherLocation: Boolean(values?.otherLocation),
      otherDay: Boolean(values?.otherDay),
      address: values?.otherLocation ? values?.otherLocation : values?.location,
      lat: values?.lat,
      lng: values?.lng,
      country: values?.country,
    };
    if(values?.serviceType?.toUpperCase() === "NORMAL"){

      delete data.personalizePrice
    }else{
      delete data.pricePerHour
    }
 
    handleAddPricingList(data);
    actions.resetForm({ values: "" });
  };

  // update pricing list
  const handleUpdatePricing = (values, id) => {
    const data = {
      crowdSize: values?.crowdSize,
      otherCrowdSize: {
        min: values?.otherCrowdSizeMin,
        max: values?.otherCrowdSizeMax,
      },
      location: pricingState?.location,
      otherLocation: values?.otherLocation,
      day: values?.day,
      otherDay: values?.otherDay,
      pricePerHour: values?.pricePerHour,
      personalizePrice: values.pricePerHour,

      latLng: pricingState?.latLng,
      country: values?.country,
      serviceType: values?.serviceType,
    };

    handleUpdatePricingList(id, data);
  };

  useEffect(() => {
    if (isMounted() && updatePricingOpen) {
      formikRef.current.resetForm({
        crowdSize: "",
        otherCrowdSizeMin: "",
        otherCrowdSizeMax: "",
        day: "",
        otherDay: "",
        pricePerHour: "",
        lat: "",
        lng: "",
        country: "",
        otherLocation: "",
        serviceType: "Normal",
      });
    }
  }, [isMounted, updatePricingOpen]);
  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={{
          serviceType: pricingState?.serviceType || "Normal",
          crowdSize: pricingState?.crowdSize || "",
          otherCrowdSizeMin: pricingState?.otherCrowdSize?.min || "",
          otherCrowdSizeMax: pricingState?.otherCrowdSize?.max || "",
          location: pricingState?.location || "",
          otherLocation: pricingState?.otherLocation || "",
          day: pricingState?.day || "",
          otherDay: pricingState?.otherDay || "",
          pricePerHour: pricingState?.pricePerHour || "",
          lat: pricingState?.latLng?.lat || "",
          lng: pricingState?.latLng?.lng || "",
          country: pricingState?.country || "",
        }}
        validationSchema={artistPricingErrSchemas}
        onSubmit={(values, actions) => {
          isUpdateForm
            ? handleUpdatePricing(values, pricingID)
            : handleSubmitPricing(values, actions);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          touched,
          setFieldTouched,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} noValidate>
          
            <div className="artist-pricing-form w-100 h-fit p-0_5em-1em">
              {/* crowd size */}
              <div className="artist-pricing-form-input">
                <Typography
                  className="text-primary-8 m-0_5em-0"
                  variant="body1"
                >
                  Service Type
                  <sup className="font-size-small text-red">*</sup>
                </Typography>
                <FormControl fullWidth>
                  <Select
                    disabled={isUpdateForm}
                    name="serviceType"
                    variant="outlined"
                    fullWidth
                    value={values?.serviceType}
                    defaultValue={values?.serviceType}
                    onChange={(e) => {
                      handleServiceType(e.target.value);
                      setFieldTouched("serviceType", true);
                      setFieldValue("serviceType", e.target.value);
                    }}
                    error={Boolean(errors.serviceType && touched.serviceType)}
                  >
                    {values?.serviceType &&
                      SERVICE_TYPE?.map((option) => {
                        return (
                          <MenuItem value={option?.type} key={option?.type}>
                            {option?.title}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <FormHelperText error={true}>
                    {/* {errors.serviceType && touched.serviceType && errors.serviceType} */}
                  </FormHelperText>
                </FormControl>
              </div>

              {values?.serviceType === "Normal" ? (
                <>
                  <div className="artist-pricing-form-input">
                    <Typography
                      className="text-primary-8 m-0_5em-0"
                      variant="body1"
                    >
                      Crowd Size
                      <sup className="font-size-small text-red">*</sup>
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        name="crowdSize"
                        variant="outlined"
                        fullWidth
                        value={values?.crowdSize}
                        defaultValue={{
                          max: values?.crowdSize?.max || "",
                          min: values?.crowdSize?.min || "",
                        }}
                        onChange={(e) => {
                          if (lowerCaseText(e.target.value) !== "other") {
                            setFieldValue("otherCrowdSizeMin", "");
                            setFieldValue("otherCrowdSizeMax", "");
                            handleSelectOtherCrowdSize({
                              min: "",
                              max: "",
                            });
                          }
                          handleSelectCrowdSize(e.target.value);
                          setFieldTouched("crowdSize", true);
                          setFieldValue("crowdSize", e.target.value);
                        }}
                        error={Boolean(errors.crowdSize && touched.crowdSize)}
                        renderValue={(selected) => {
                          if (
                            selected?.max &&
                            (selected?.min || selected?.min === 0)
                          ) {
                            return `${selected?.min} - ${selected?.max}`;
                          }
                          if (lowerCaseText(selected) === "other") {
                            return `${"Other"}`;
                          }
                        }}
                      >
                        <MenuItem value={"Other"}>Other</MenuItem>
                        {CROWD_SIZES?.map((option, index) => {
                          return (
                            <MenuItem value={option} key={index}>
                              {`${option?.min} - ${option?.max}`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={true}>
                        {errors.crowdSize &&
                          touched.crowdSize &&
                          errors.crowdSize}
                      </FormHelperText>
                    </FormControl>

                    {/* enter crowd size manually */}
                    {lowerCaseText(pricingState?.crowdSize) === "other" && (
                      <>
                        <Typography
                          className="text-primary-8 m-0_5em-0"
                          variant="body1"
                        >
                          Enter Crowd Size
                          <sup className="font-size-small text-red">*</sup>
                        </Typography>
                        <div className="flex-row  col-gap-0_5em row-gap-0_5em">
                          <TextField
                            fullWidth
                            variant="outlined"
                            value={pricingState?.otherCrowdSize?.min}
                            onChange={(e) => {
                              handleSelectOtherCrowdSize({
                                min: e.target.value,
                                max: pricingState?.otherCrowdSize?.max,
                              });
                              setFieldTouched("otherCrowdSizeMin", true);
                              handleChange(e);
                            }}
                            placeholder="Minimum Crowd"
                            name="otherCrowdSizeMin"
                            error={Boolean(
                              errors.otherCrowdSizeMin &&
                                touched.otherCrowdSizeMin
                            )}
                            helperText={
                              touched.otherCrowdSizeMin &&
                              errors.otherCrowdSizeMin &&
                              errors.otherCrowdSizeMin
                            }
                          />
                          <TextField
                            fullWidth
                            variant="outlined"
                            value={pricingState?.otherCrowdSize?.max}
                            onChange={(e) => {
                              handleSelectOtherCrowdSize({
                                min: pricingState?.otherCrowdSize?.min,
                                max: e.target.value,
                              });
                              setFieldTouched("otherCrowdSizeMax", true);
                              handleChange(e);
                            }}
                            placeholder="Maximum Crowd"
                            name="otherCrowdSizeMax"
                            error={Boolean(
                              errors.otherCrowdSizeMax &&
                                touched.otherCrowdSizeMax
                            )}
                            helperText={
                              touched.otherCrowdSizeMax &&
                              errors.otherCrowdSizeMax &&
                              errors.otherCrowdSizeMax
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                  {/* location */}
                  <div className="artist-pricing-form-input">
                    <Typography
                      className="text-primary-8 m-0_5em-0"
                      variant="body1"
                    >
                      Location
                      <sup className="font-size-small text-red">*</sup>
                    </Typography>
                    <div>
                      <PlaceAutoComplete
                        address={values?.location}
                        className="border-5"
                        handleLocationAddress={(address) => {
                          setFieldValue("location", address);
                          handleSelectLocation(address);
                        }}
                        handleLocation={(lat, lng) => {
                          handleSelectLocLatLng(lat, lng);
                          setFieldValue("lat", lat);
                          setFieldValue("lng", lng);
                        }}
                        handleCountry={(country) => {
                          setFieldValue("country", country);
                        }}
                        name="location"
                      />
                      <FormHelperText error={true} className="p-0-1em">
                        {errors.location && touched.location && errors.location}
                      </FormHelperText>
                    </div>

                    {/* enter location manually */}
                    {lowerCaseText(pricingState?.location?.address) ===
                      "other" && (
                      <>
                        <Typography
                          className="text-primary-8 m-0_5em-0"
                          variant="body1"
                        >
                          Enter Location
                          <sup className="font-size-small text-red">*</sup>
                        </Typography>
                        <TextField
                          variant="outlined"
                          fullWidth
                          value={values?.otherLocation}
                          onChange={(e) => {
                            setFieldValue("otherLocation", e.target.value);
                            handleSelectOtherLocation(e.target.value);
                          }}
                          placeholder="Enter Location"
                        />
                      </>
                    )}
                  </div>

                  {/* day */}
                  <div className="artist-pricing-form-input">
                    <Typography
                      className="text-primary-8 m-0_5em-0"
                      variant="body1"
                    >
                      Day
                      <sup className="font-size-small text-red">*</sup>
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        variant="outlined"
                        fullWidth
                        value={values.day}
                        defaultValue={""}
                        onChange={(e) => {
                          if (lowerCaseText(e.target.value) !== "other") {
                            setFieldValue("otherDay", "");
                            handleSelectOtherDay("");
                          }
                          handleSelectDay(e.target.value);
                          setFieldTouched("day", true);
                          handleChange(e);
                        }}
                        name="day"
                        error={Boolean(errors.day && touched.day)}
                        renderValue={(selected) => {
                          if (lowerCaseText(selected) === "other") {
                            return "Other";
                          }
                          if (selected) {
                            return `${selected} day(s)`;
                          }
                          return "No day selected";
                        }}
                      >
                        {DAYS?.map((option) => (
                          <MenuItem value={option?.day} key={`${option?._id}`}>
                            {lowerCaseText(option?.day) !== "other"
                              ? `${option?.day} day(s)`
                              : option?.day}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={true}>
                        {errors.day && touched.day && errors.day}
                      </FormHelperText>
                    </FormControl>
                    {/* enter day manually */}
                    {lowerCaseText(pricingState?.day) === "other" && (
                      <>
                        <Typography
                          className="text-primary-8 m-0_5em-0"
                          variant="body1"
                        >
                          Enter Day
                          <sup className="font-size-small text-red">*</sup>
                        </Typography>
                        <TextField
                          variant="outlined"
                          fullWidth
                          value={values.otherDay}
                          onChange={(e) => {
                            handleSelectOtherDay(e.target.value);
                            handleChange(e);
                            setFieldTouched("otherDay", true);
                          }}
                          placeholder="Numbers only"
                          InputProps={{
                            endAdornment: <span>Day(s)</span>,
                          }}
                          name="otherDay"
                          error={Boolean(errors.otherDay && touched.otherDay)}
                          helperText={
                            touched.otherDay && errors.otherDay
                              ? errors.otherDay
                              : ""
                          }
                        />
                      </>
                    )}
                  </div>
                  {/* set price */}
                  <div className="artist-pricing-form-input">
                    <div className="m-0_5em-0">
                      <Typography className="text-primary-8" variant="body1">
                        Set Price
                        <sup className="font-size-small text-red">*</sup>
                      </Typography>
                    </div>
                    <TextField
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        endAdornment: <span>/hr</span>,
                      }}
                      placeholder="Numbers only"
                      value={values?.pricePerHour}
                      onChange={(e) => {
                        handleSelectPricePerHour(e.target.value);
                        setFieldTouched("pricePerHour", true);
                        handleChange(e);
                      }}
                      name="pricePerHour"
                      error={Boolean(
                        errors.pricePerHour && touched.pricePerHour
                      )}
                      helperText={
                        touched.pricePerHour && errors.pricePerHour
                          ? errors.pricePerHour
                          : ""
                      }
                    />
                  </div>
                  {/* add pricing btn */}
                  {addPricingErrMsg?.length > 0 && (
                    <div className="add-pricing-err-msg m-1-0 text-center font-bold font-size-small text-primary-1">
                      <span>{addPricingErrMsg}</span>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="artist-pricing-form-input">
                    <div className="m-0_5em-0">
                      <Typography className="text-primary-8" variant="body1">
                        Price
                        <sup className="font-size-small text-red">*</sup>
                      </Typography>
                    </div>
                    <TextField
                      variant="outlined"
                      fullWidth
                      // InputProps={{
                      //   endAdornment: <span>/hr</span>,
                      // }}
                      placeholder="Numbers only"
                      value={values?.pricePerHour}
                      onChange={(e) => {
                        handleSelectPricePerHour(e.target.value);
                        setFieldTouched("pricePerHour", true);
                        handleChange(e);
                      }}
                      name="pricePerHour"
                      error={Boolean(
                        errors.pricePerHour && touched.pricePerHour
                      )}
                      helperText={
                        touched.pricePerHour && errors.pricePerHour
                          ? errors.pricePerHour
                          : ""
                      }
                    />
                  </div>
                  {/* add pricing btn */}
                  {addPricingErrMsg?.length > 0 && (
                    <div className="add-pricing-err-msg m-1-0 text-center font-bold font-size-small text-primary-1">
                      <span>{addPricingErrMsg}</span>
                    </div>
                  )}
                </>
              )}

              <div className="artist-pricing-add-btn m-1-0">
                {isUpdateForm && (
                  <CustomButton
                    btnText={"Update Pricing"}
                    variant="contained"
                    className={
                      isUpdatingPricing ? "disabled-btn" : "btn-gradient"
                    }
                    fullWidth
                    disableElevation
                    disabled={isUpdatingPricing}
                    type="submit"
                  />
                )}
                {!isUpdateForm && (
                  <CustomButton
                    btnText={"Add Pricing Now"}
                    variant="contained"
                    className={
                      namesInLoaderActions?.includes(
                        `ADD_PRICING_LIST_${artistID}`
                      )
                        ? "disabled-btn"
                        : "btn-gradient"
                    }
                    fullWidth
                    disableElevation
                    disabled={namesInLoaderActions?.includes(
                      `ADD_PRICING_LIST_${artistID}`
                    )}
                    type="submit"
                  />
                )}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default ArtistPricingForm;
