import {
  AccountBoxOutlined,
  DashboardOutlined,
  DateRangeOutlined,
  HomeOutlined,
  ListAltOutlined,
  PeopleAltOutlined,
} from "@mui/icons-material"
import { IconButton, Typography } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
// hooks import
import { useIsMounted } from "hooks"

const BaseBottomTab = () => {
  // role from auth redux
  const auth = useSelector((state) => state.authState)
  const [tabIndex, setTabIndex] = useState(null)
  // navigation
  const navigate = useNavigate()
  // get current path name
  const { pathname } = useLocation()
  const isMounted = useIsMounted()

  // use useMemo to memoize the returned value and prevent unnecessary re-renders

  const TAB_ITEMS = useMemo(() => {
    return [
      {
        label: "Home",
        icon: <HomeOutlined className="font-size-1_2em" />,
        path: "/",
      },
      {
        label: "Bookings",
        icon: <ListAltOutlined className="font-size-1_2em" />,
        path: "/my-bookings",
      },
      {
        label: "Profile",
        icon: <AccountBoxOutlined className="font-size-1_2em" />,
        path: "/my-profile",
      },
    ]
  }, [])

  const ARTIST_TAB_ITEMS = useMemo(() => {
    return [
      {
        label: "Dashboard",
        icon: <DashboardOutlined className="font-size-1_2em" />,
        path: "/artist-dashboard",
      },
      {
        label: "Bookings",
        icon: <ListAltOutlined className="font-size-1_2em" />,
        path: "/artist-bookings",
      },
      {
        label: "Calendar",
        icon: <DateRangeOutlined className="font-size-1_2em" />,
        path: "/artist-calendar",
      },
    ]
  }, [])

  const MANAGER_TAB_ITEMS = useMemo(() => {
    return [
      {
        label: "Home",
        icon: <HomeOutlined className="font-size-1_2em" />,
        path: "/",
      },
      {
        label: "Artists",
        icon: <PeopleAltOutlined className="font-size-1_2em" />,
        path: "/managed-artists",
      },
      {
        label: "Profile",
        icon: <AccountBoxOutlined className="font-size-1_2em" />,
        path: "/manager-profile",
      },
    ]
  }, [])

  // set tab index using pathname and useCallback to prevent re-render
  const handleSetTabIndex = useCallback(() => {
    if (pathname === "/artist-dashboard" || pathname === "/") {
      setTabIndex(0)
      return
    }
    if (
      pathname === "/artist-bookings" ||
      pathname === "/my-bookings" ||
      pathname === "/managed-artists"
    ) {
      setTabIndex(1)
      return
    }
    if (
      pathname === "/artist-calendar" ||
      pathname === "/my-profile" ||
      pathname === "/manager-profile"
    ) {
      setTabIndex(2)
      return
    }
  }, [pathname])

  // set tab index on mount
  useEffect(() => {
    if (isMounted()) {
      handleSetTabIndex()
    }
  }, [handleSetTabIndex, isMounted])

  const SELECT_TABS = useMemo(() => {
    if (auth?.userRole === "artist") {
      return ARTIST_TAB_ITEMS
    }
    if (auth?.userRole === "manager") {
      return MANAGER_TAB_ITEMS
    }
    return TAB_ITEMS
  }, [auth, ARTIST_TAB_ITEMS, TAB_ITEMS, MANAGER_TAB_ITEMS])

  return (
    <footer>
      <div className="base-bottom-tab-root-down border-10"></div>
      <div className="base-bottom-tab-root position-fixed border-10">
        <div className="base-bottom-tab-content flex-row flex-justify-between p-0-1em flex-align-center">
          {SELECT_TABS?.map((item, tab_index) => (
            <div
              className={`flex-column flex-align-center ${
                tabIndex === tab_index
                  ? "base-bottom-tab-item-active"
                  : "base-bottom-tab-item"
              } `}
              role={"button"}
              key={tab_index}
              onClick={() => {
                navigate(item.path)
              }}
            >
              <div
                className={
                  tabIndex === tab_index
                    ? "base-bottom-tab-icon-root-active"
                    : "base-bottom-tab-icon-root"
                }
              >
                <IconButton
                  className={
                    tabIndex === tab_index
                      ? "base-bottom-tab-icon-active"
                      : "base-bottom-tab-icon"
                  }
                >
                  {item.icon}
                </IconButton>
              </div>
              {tabIndex === tab_index ? (
                <Typography color={"var(--col-primary-3)"}>
                  {item?.label}
                </Typography>
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
      </div>
    </footer>
  )
}

export default BaseBottomTab
