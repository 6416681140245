import {
  EmailOutlined,
  LocationOnOutlined,
  Password,
  PersonOutlined,
  VpnKey,
} from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Formik } from "formik";
// import from other files
import { CustomSnackbar } from "components";
import { authErrSchema, authValues } from "schemas";
import { useApi, useIsMounted } from "hooks";
import { PhoneNumber, PlaceAutoComplete } from "components/core";

const SignUp = ({ setIsChoseSignUp }) => {
  // postData from useApi

  const { postData, postStatus, handleResetPostApi } = useApi();
  const { postData:authPostDate, isPosting:authIsPosting, postStatus:authPostStatus} = useApi();
  const [snackbar, setSnackbar] = useState({
    open: false,
    variant: "",
    message: "",
  });
  const isMounted = useIsMounted();
  const authIsMounted = useIsMounted();
  // location popper
  const [isCheckMark, setIsCheckMark] = useState(true);
  const [location, setLocation] = useState({
    lat: 0,
    lng: 0,
  });
  const [country, setCountry] = useState("");
  const [phoneNumber, setPhoneNumber] = useState('')
  const [countryCode, setCountryCode] = useState('')
  const [otpLoader, setOtpLoader] = useState(true)

  // submit values

  const onSubmitUserValues = (values,formik) => {
    const userDetails = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      password:values?.password,
      countryCode:values?.countryCode,
      phoneNumber:values?.phoneNumber,
      otp:values?.otp,
      email: values?.email,
      role: values?.userRole,
      lat: +location?.lat,
      lng: +location?.lng,
      address: values?.location,
      country: country,
      baseUrl: window.location.origin,
    };
   
    authPostDate("auth/otp-register", userDetails);
    formik.resetForm()
  };

  useEffect(() => {
    if (isMounted()) {
      if (postStatus?.status) {
        setSnackbar({
          open: true,
          variant: postStatus?.status === 200 ? "success" : "error",
          message: postStatus?.message,
        });
        setOtpLoader(false)
        return;
      }
    }
  }, [isMounted, postStatus]);
  useEffect(() => {
    if (authIsMounted()) {
      if (authPostStatus?.status) {
        setSnackbar({
          open: true,
          variant: authPostStatus?.status === 200 ? "success" : "error",
          message: authPostStatus?.message,
        });
      
        
        // setIsChoseSignUp(prev=>!prev)

        return;
      }
    }
  }, [authIsMounted, authPostStatus]);

  const sendOtpFn = ()=>{
      const body = {
        "phoneNumber":phoneNumber,
        "countryCode":`+${countryCode}`
      }
    postData(`auth/otp-send`,body)   
  
  }


  

  return (
    <div className="sign-up-form-root">
      {/* snackbar */}
      <CustomSnackbar
        open={snackbar.open}
        variant={snackbar.variant}
        message={snackbar.message}
        handleClose={() => {
          setSnackbar({
            ...snackbar,
            open: false,
          });
          handleResetPostApi();
        }}
        autoHideDuration={5000}
      />
      <Typography
        variant="h4"
        className="font-bold text-primary-2"
        gutterBottom
      >
        Create Account
      </Typography>
      <div className="registration-body flex-column row-gap-1em">
        <Formik
          initialValues={authValues}
          onSubmit={onSubmitUserValues}
          validationSchema={authErrSchema}
          // enableReinitialize
        >
          {({
            values,
            handleChange,
            handleSubmit,
            errors,
            setFieldValue,
            touched,
            setFieldTouched,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Grid
                container
                spacing={1}
                style={{
                  paddingLeft: 5,
                  paddingRight: 20,
                }}
              >
                <Grid item xs={12} md={6} 
                // lg={6}
                >
                  <TextField
                    fullWidth
                    classes={{
                      root: "registration-text-field-input border-25",
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: "border-none",
                        input: "border-25",
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <div className="registration-text-field-adornment position-center">
                            <PersonOutlined fontSize="medium" />
                          </div>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="First Name"
                    name="firstName"
                    onChange={(e) => {
                      setFieldTouched("firstName", true, false);
                      handleChange(e);
                    }}
                    value={values.firstName}
                  />
                  {touched?.firstName && errors.firstName && (
                    <div className="p-0-1em">
                      <span className="text-primary-1">{errors.firstName}</span>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    classes={{
                      root: "registration-text-field-input border-25",
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: "border-none",
                        input: "border-25",
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <div className="registration-text-field-adornment position-center">
                            <PersonOutlined fontSize="medium" />
                          </div>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Last Name"
                    name="lastName"
                    onChange={(e) => {
                      setFieldTouched("lastName", true, false);
                      handleChange(e);
                    }}
                    value={values.lastName}
                  />
                  {touched?.lastName && errors.lastName && (
                    <div className="p-0-1em">
                      <span className="text-primary-1">{errors.lastName}</span>
                    </div>
                  )}
                </Grid>
                {/* email */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    classes={{
                      root: "registration-text-field-input border-25",
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: "border-none",
                        input: "border-25",
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <div className="registration-text-field-adornment">
                            <EmailOutlined fontSize="medium" />
                          </div>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Enter Email"
                    name="email"
                    onChange={(e) => {
                      setFieldTouched("email", true, false);
                      handleChange(e);
                    }}
                    value={values.email}
                  />
                  {touched?.email && errors.email && (
                    <div className="p-0-1em">
                      <span className="text-primary-1">{errors.email}</span>
                    </div>
                  )}
                </Grid>
{/* password */}
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    classes={{
                      root: "registration-text-field-input border-25",
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: "border-none",
                        input: "border-25",
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <div className="registration-text-field-adornment position-center">
                            <VpnKey fontSize="medium" />
                          </div>
                        </InputAdornment>
                      ),
                    }}
                    type="password"
                    placeholder="Password"
                    name="password"
                    onChange={(e) => {
                      setFieldTouched("password", true, false);
                      handleChange(e);
                    }}
                    value={values.password}
                  />
                  {touched?.password && errors.password && (
                    <div className="p-0-1em">
                      <span className="text-primary-1">{errors.password}</span>
                    </div>
                  )}
                </Grid>
                {/* conform password */}

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    type="password"
                    classes={{
                      root: "registration-text-field-input border-25",
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: "border-none",
                        input: "border-25",
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <div className="registration-text-field-adornment position-center">
                            <VpnKey fontSize="medium" />
                          </div>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Confirm password"
                    name="confirmPassword"
                    onChange={(e) => {
                      setFieldTouched("confirmPassword", true, false);
                      handleChange(e);
                    }}
                    value={values.confirmPassword}
                  />
                  {touched?.confirmPassword && errors.confirmPassword && (
                    <div className="p-0-1em">
                      <span className="text-primary-1">
                        {errors.confirmPassword}
                      </span>
                    </div>
                  )}
                </Grid>

                <Grid item xs={12} md={6}>
                  <PhoneNumber
                
                    values={values}
                    sendOtpFn={sendOtpFn}
                    setFieldTouched={setFieldTouched}
                    handleChange={handleChange}
                    setCountryCode={setCountryCode}
                    setPhoneNumber={setPhoneNumber}
                  />

                  {((touched?.countryCode && errors.countryCode) ||
                    (touched?.phoneNumber && errors.phoneNumber)) && (
                    <div className="p-0-1em">
                      <span className="text-primary-1">
                        {errors.countryCode || errors.phoneNumber}
                      </span>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    classes={{
                      root: "registration-text-field-input border-25",
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: "border-none",
                        input: "border-25",
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <div className="registration-text-field-adornment position-center">
                            <Password fontSize="medium" />
                          </div>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="OTP"
                    name="otp"
                    disabled={otpLoader}
                    onChange={(e) => {
                      setFieldTouched("otp", true, false);
                      handleChange(e);
                    }}
                    value={values.otp}
                  />
                  {touched?.otp && errors.otp && (
                    <div className="p-0-1em">
                      <span className="text-primary-1">{errors.otp}</span>
                    </div>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <PlaceAutoComplete
                    classes={{
                      root: "registration-text-field-input border-25 position-center",
                    }}
                    className="border-none"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div className="registration-text-field-adornment">
                            <LocationOnOutlined fontSize="medium" />
                          </div>
                        </InputAdornment>
                      ),
                      root: "p-0-1em",
                    }}
                    address={values?.location}
                    handleLocation={(lat, lng) => {
                      setLocation({ lat, lng });
                    }}
                    handleCountry={(country) => {
                      setCountry(country);
                    }}
                    handleLocationAddress={(locationAddress) => {
                      setFieldValue("location", locationAddress);
                    }}
                    name="location"
                  />
                  {touched?.location && errors.location && (
                    <div className="p-0-1em">
                      <span className="text-primary-1">{errors.location}</span>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography className="font-bold" variant="h6">
                    Register as
                  </Typography>

                  <RadioGroup
                    row
                    name="userRole"
                    value={values?.userRole}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldTouched("userRole", true, false);
                    }}
                  >
                    {["User", "Artist", "Manager"]?.map((role) => (
                      <FormControlLabel
                        key={role}
                        value={role}
                        control={
                          <Radio
                            className="text-primary-2"
                            checked={values?.userRole === role}
                          />
                        }
                        label={role}
                      />
                    ))}
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox defaultChecked className="text-primary-3" />
                    }
                    label={
                      <span className="font-bold">
                        Accept Terms & Conditions
                      </span>
                    }
                    value={isCheckMark}
                    onChange={(e) => {
                      setIsCheckMark(e.target.checked);
                    }}
                  />
                </Grid>
             
                <Grid item xs={12}>
                  <Button
                    className={`border-25 text-transform-none font-size-1_1em font-bold ${
                      authIsPosting || !isCheckMark
                        ? "disabled-btn"
                        : "btn-gradient"
                    }`}
                    fullWidth
                    size="large"
                    type="submit"
                    disabled={authIsPosting || !isCheckMark}
                  >
                    Register
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
        {/* sign in link */}
        <div className="link-to-sign-in p-1em flex-row flex-align-center flex-justify-center">
          <Typography className="font-bold" variant="body1">
            {` Already have an account? `}
          </Typography>
          <Button
            className="text-transform-none bg-none"
            variant="text"
            disableRipple
            onClick={() => {
              setIsChoseSignUp(false);
            }}
          >
            <Typography className="font-bold text-primary-3" variant="body1">
              Sign In
            </Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
