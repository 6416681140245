import produce from "immer"

const defaultState = {
  data: [],
  isLoading: true,
  error: null,
}
const removeState = {
  isRemoving: false,
  error: null,
  message: null,
}
const addState = {
  isAdded: false,
  isError: false,
  successMsg: null,
  errorMsg: null,
}
const initialState = {
  artistVideos: defaultState,
  artistPhotos: defaultState,
  removeVideos: removeState,
  removePhotos: removeState,
  addPhotos: addState,
  addVideos: addState,
}
export default function ArtistMediasReducer(state = initialState, action) {
  return produce(state, (draft) => {
    const { type, payload } = action
    const {
      artistVideos,
      artistPhotos,
      removeVideos,
      removePhotos,
      addPhotos,
      addVideos,
    } = draft
    switch (type) {
      // artist videos
      case "ARTIST_VIDEOS_LOADING":
        artistVideos.isLoading = true
        artistVideos.error = null
        break
      case "ARTIST_VIDEOS_SUCCESS":
        artistVideos.data = payload
        artistVideos.isLoading = false
        artistVideos.error = null
        break
      case "ARTIST_VIDEOS_ERROR":
        artistVideos.isLoading = false
        artistVideos.error = payload
        break
      // artist photos
      case "ARTIST_PHOTOS_LOADING":
        artistPhotos.isLoading = true
        artistPhotos.error = null
        break
      case "ARTIST_PHOTOS_SUCCESS":
        artistPhotos.data = payload
        artistPhotos.isLoading = false
        artistPhotos.error = null
        break
      case "ARTIST_PHOTOS_ERROR":
        artistPhotos.isLoading = false
        artistPhotos.error = payload
        break
      // remove videos
      case "REMOVE_ARTIST_VIDEOS_LOADING":
        removeVideos.isRemoving = true
        removeVideos.error = null
        break
      case "REMOVE_ARTIST_VIDEOS_SUCCESS":
        removeVideos.isRemoving = false
        removeVideos.error = null
        removeVideos.message = payload
        break
      case "REMOVE_ARTIST_VIDEOS_ERROR":
        removeVideos.isRemoving = false
        removeVideos.error = payload
        break
      case "REMOVE_ARTIST_VIDEOS_RESET":
        removeVideos.isRemoving = false
        removeVideos.error = null
        removeVideos.message = null
        break
      // remove photos
      case "REMOVE_ARTIST_PHOTOS_SUCCESS":
        removePhotos.isRemoving = false
        removePhotos.error = null
        removePhotos.message = payload
        break
      case "REMOVE_ARTIST_PHOTOS_ERROR":
        removePhotos.isRemoving = false
        removePhotos.error = payload
        break
      case "REMOVE_ARTIST_PHOTOS_RESET":
        removePhotos.isRemoving = false
        removePhotos.error = null
        removePhotos.message = null
        break
      // add photos
      case "ADD_ARTIST_PHOTOS_SUCCESS":
        addPhotos.isAdded = true
        addPhotos.isError = false
        addPhotos.successMsg = payload
        addPhotos.errorMsg = null
        break
      case "ADD_ARTIST_PHOTOS_ERROR":
        addPhotos.isAdded = false
        addPhotos.isError = true
        addPhotos.successMsg = null
        addPhotos.errorMsg = payload
        break
      case "ADD_ARTIST_PHOTOS_RESET":
        addPhotos.isAdded = false
        addPhotos.isError = false
        addPhotos.successMsg = null
        addPhotos.errorMsg = null
        break
      // add videos
      case "ADD_ARTIST_VIDEOS_SUCCESS":
        addVideos.isAdded = true
        addVideos.isError = false
        addVideos.successMsg = payload
        addVideos.errorMsg = null
        break
      case "ADD_ARTIST_VIDEOS_ERROR":
        addVideos.isAdded = false
        addVideos.isError = true
        addVideos.successMsg = null
        addVideos.errorMsg = payload
        break
      case "ADD_ARTIST_VIDEOS_RESET":
        addVideos.isAdded = false
        addVideos.isError = false
        addVideos.successMsg = null
        addVideos.errorMsg = null
        break
      default:
        return draft
    }
  })
}
