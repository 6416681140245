import moment from "moment"
import {
  Avatar,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material"
import { NotificationsOutlined } from "@mui/icons-material"

const NotificationBody = ({ notification }) => {
  return (
    <div className="notifications-details-root p-2em-1em h-75vh overflow-auto">
      <ListItem className="w-100">
        <ListItemAvatar>
          <Avatar className="bg-primary-2 text-white font-bold">
            <NotificationsOutlined />
          </Avatar>
        </ListItemAvatar>
        <div className="flex-row flex-justify-between flex-align-center flex-wrap w-100">
          <ListItemText
            primary={
              <Typography variant="h6" className="font-bold p-0">
                {notification?.title}
              </Typography>
            }
          />
          <Typography variant="body2" className="font-bold text-primary-6">
            {moment(notification?.timestamp).format("Do MMM YYYY")}
          </Typography>
        </div>
      </ListItem>
      <Divider />
      <div className="notification-description">
        <Typography variant="body1" className="p-1em m-1-0 text-align-justify">
          {notification?.description}
        </Typography>
      </div>
    </div>
  )
}

export default NotificationBody
