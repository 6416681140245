import { Star } from "@mui/icons-material"
import { Button, Typography } from "@mui/material"
import { QUOTE_ICON, USER_PLACEHOLDER } from "assets"
import { getText, primaryDateFormat } from "helpers"
import { useState } from "react"

const ReviewsCard = ({ review }) => {
  const [reviewActions, setReviewActions] = useState({
    isViewMore: false,
  })

  return (
    <div className="reviews-card-root position-relative border-10">
      <div className="review-user-root">
        {/* quote icon from assets */}
        <div className="quote-icon-root flex-row flex-align-center flex-justify-between">
          <div className="review-quote-img w-fit border-10 bg-primary-5">
            <img
              src={QUOTE_ICON}
              alt=""
              height={"25px"}
              width={"25px"}
              className="object-fit-cover"
            />
          </div>
          <div className="w-fit border-10 bg-primary-5">
            <Button
              variant="contained"
              className="font-bold p-0_1em text-transform-none bg-primary-3 font-size-small cursor-default"
              endIcon={<Star className="p-0 m-0" />}
              disableElevation
            >
              {review?.ratings}
            </Button>
          </div>
        </div>
        <div className="reviews-card-container">
          <div
            className={`h-100 ${
              reviewActions?.isViewMore ? "reviews-card-body" : ""
            }`}
          >
            <Typography
              variant="body1"
              gutterBottom
              className="font-bold text-ellipse-vertical line-clamp-2"
            >
              {review?.title}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {reviewActions?.isViewMore
                ? review?.description
                : getText(review?.description, 97)}
            </Typography>
            {review?.description?.length > 97 ? (
              <Button
                variant="text"
                className="font-bold p-0 text-transform-none text-primary-2 font-size-small"
                onClick={() =>
                  setReviewActions({
                    ...reviewActions,
                    isViewMore: !reviewActions.isViewMore,
                  })
                }
              >
                {reviewActions.isViewMore ? "Read Less" : "Read More"}
              </Button>
            ) : (
              ""
            )}
          </div>
          <div className="reviews-card-user-details flex-row flex-align-center col-gap-0_5em">
            <div className="review-user-img border-circle">
              <img
                src={review?.user?.profileImageUrl || USER_PLACEHOLDER}
                alt=""
                width={"50px"}
                height={"50px"}
                className="border-circle object-fit-cover"
                onError={(e) => {
                  e.target.src = USER_PLACEHOLDER
                }}
              />
            </div>
            <div className="review-user-details">
              <Typography variant="body1" className="font-bold text-ellipses">
                {`${review?.user?.firstName} ${review?.user?.lastName}`}
              </Typography>
              <div className="review-date border-5">
                <Typography variant="body2" color="var(--col-secondary)">
                  {primaryDateFormat(review?.timestamp)}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReviewsCard
