import { apiInstance } from "configs"
import { createSelector } from "reselect"
// crowd sizes
export const adminStateSelector = (state) => state?.adminState
export const crowdSizesStateSelector = createSelector(
  [adminStateSelector],
  (admin) => admin?.crowdSizes
)
export const crowdSizesSelector = createSelector(
  [crowdSizesStateSelector],
  (crowdSizes) => crowdSizes?.data
)
export const loadingCrowdSizesSelector = createSelector(
  [crowdSizesStateSelector],
  (crowdSizes) => crowdSizes?.isLoading
)
export const fetchCrowdSizes = () => async (dispatch) => {
  try {
    dispatch({
      type: "CROWD_SIZES_LOADING",
    })
    await apiInstance
      .get(`crowd/all-crowd`)
      .then(({ data }) => {
        const { success } = data
        dispatch({
          type: "CROWD_SIZES_SUCCESS",
          payload: success?.data,
        })
      })
      .catch(({ response }) => {
        dispatch({
          type: "CROWD_SIZES_FAILURE",
          payload: response.data.error?.message,
        })
      })
  } catch (error) {
    dispatch({
      type: "CROWD_SIZES_FAILURE",
      payload: error.message,
    })
  }
}

// days
export const daysStateSelector = createSelector(
  [adminStateSelector],
  (admin) => admin?.days
)
export const daysSelector = createSelector(
  [daysStateSelector],
  (days) => days?.data
)
export const fetchDays = () => async (dispatch) => {
  try {
    dispatch({
      type: "DAYS_LOADING",
    })
    await apiInstance
      .get(`day/all-day`)
      .then(({ data }) => {
        const { success } = data
        dispatch({
          type: "DAYS_SUCCESS",
          payload: success?.data,
        })
      })
      .catch(({ response }) => {
        dispatch({
          type: "DAYS_FAILURE",
          payload: response?.data.error?.message,
        })
      })
  } catch (error) {
    dispatch({
      type: "DAYS_FAILURE",
      payload: error?.message,
    })
  }
}

// event durations
export const eventDurationsStateSelector = createSelector(
  [adminStateSelector],
  (admin) => admin?.eventDurations
)
export const eventDurationsSelector = createSelector(
  [eventDurationsStateSelector],
  (eventDurations) => eventDurations?.data
)
export const loadingEventDurationsSelector = createSelector(
  [eventDurationsStateSelector],
  (eventDurations) => eventDurations?.isLoading
)
export const fetchEventDurationsAction = () => async (dispatch) => {
  try {
    dispatch({
      type: "EVENT_DURATIONS_LOADING",
    })
    await apiInstance
      .get(`event-duration/all-event-duration`)
      .then(({ data }) => {
        const { success } = data
        dispatch({
          type: "EVENT_DURATIONS_SUCCESS",
          payload: success?.data,
        })
      })
      .catch(({ response }) => {
        dispatch({
          type: "EVENT_DURATIONS_FAILURE",
          payload: response?.data.error?.message,
        })
      })
  } catch (error) {
    dispatch({
      type: "EVENT_DURATIONS_FAILURE",
      payload: error?.message,
    })
  }
}
