// import from packages

import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import {
  MobileDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import { TextField, Typography } from "@mui/material";
import moment from "moment";
// other files import
import {
  setCrowedSize,
  setEventDate,
  setPersonalizedMessageDate,
} from "redux/actions";

const ChoseEventDate = ({
  bookingArtistState,
  dispatch,
  isPersonalizedMessage,
  blockedDates,
}) => {
  return (
    <div className="booking-artist-date-root w-100 flex-column position-center m-1-0">
      {!isPersonalizedMessage ? (
        <>
          <div className="booking-artist-date p-0_5em-0 booking-artist-input-root-width">
            <Typography variant="body1" gutterBottom>
              Choose Event Date
            </Typography>
            {/* event date range */}
            {/* <LocalizationProvider dateAdapter={DateAdapter}>
              <TextField variant="outlined" label="Event Date" type="date" />
            </LocalizationProvider> */}

            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileDatePicker
                shouldDisableDate={(date) => {
                  return blockedDates
                    ?.map((bd) => moment(bd?.date).format("YYYY-MM-DD"))
                    ?.includes(moment(date).format("YYYY-MM-DD"));
                }}
                showToolbar={false}
                label="Event Date"
                inputFormat={"Do MMM YY"}
                value={bookingArtistState?.eventDate?.start}
                onChange={(value) => {
                  dispatch(
                    setEventDate({
                      start: moment(value).toLocaleString(),
                      end: moment(value).toLocaleString(),
                    })
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className="border-10 m-0_5em-0"
                    error={false}
                  />
                )}
                InputProps={{
                  classes: {
                    root: "border-10",
                  },
                }}
                minDate={moment()}
                className="text-primary-3 font-bold"
              />
            </LocalizationProvider>
          </div>
          {/* crowed size */}
          <div className="booking-artist-crowed-size p-0_5em-0 booking-artist-input-root-width">
            <Typography variant="body1">Crowd Size</Typography>
            <span className="font-bold text-primary-1 font-size-small">
              Enter only numbers
            </span>
            <TextField
              fullWidth
              InputProps={{
                classes: {
                  root: "border-10",
                },
                inputProps: {
                  maxLength: 9,
                },
              }}
              placeholder="Enter crowd size"
              onChange={(e) => {
                const res = e.target.value?.split(" ").join("");
                dispatch(setCrowedSize(res));
              }}
              value={bookingArtistState?.crowdSize}
            />
          </div>
        </>
      ) : (
        <div className="booking-artist-date p-0_5em-0 booking-artist-input-root-width">
          <Typography variant="body1" gutterBottom>
            Choose Event Date
          </Typography>
          {/* event date range */}
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MobileDatePicker
              shouldDisableDate={(date) => {
                return blockedDates
                  ?.map((bd) => moment(bd?.date).format("YYYY-MM-DD"))
                  ?.includes(moment(date).format("YYYY-MM-DD"));
              }}
              showToolbar={false}
              label="Event Date"
              inputFormat="DD/MM/YYYY"
              value={bookingArtistState?.personalizedMsgDate}
              onChange={(value) => {
                dispatch(
                  setPersonalizedMessageDate(moment(value).toLocaleString())
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  className="border-10 m-0_5em-0"
                  error={false}
                />
              )}
              InputProps={{
                classes: {
                  root: "border-10",
                },
              }}
              minDate={moment()}
              className="text-primary-3 font-bold"
            />
          </LocalizationProvider>
        </div>
      )}
    </div>
  );
};

export default ChoseEventDate;
