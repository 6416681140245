// imports from packages
import {
  CheckCircleOutlined,
  Favorite,
  FavoriteBorder,
  Share,
  Star,
} from "@mui/icons-material"
import {
  Button,
  Chip,
  CircularProgress,
  Container,
  IconButton,
  Typography,
} from "@mui/material"
import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
// other imports
import { USER_PLACEHOLDER } from "assets"
import { getAuthState, resetBookingArtist } from "redux/actions"
import { useApi, useIsMounted, useSnackbar } from "hooks"
import { CustomSnackbar, Loader } from "components"

const WebViewArtistHero = ({
  isMobileView,
  artist,
  totalReviewsOfArtist,
  avgRatingOfArtist,
  isFavArtist,
  handleFavorite,
  isLoadingAddFav,
  handleOpenShareDialog,
  resetBookingDetailsFromLocalStorage,
  isRequestSentAndPending,
  currentUserID,
  handleFetchSentRequests,
  isManagedArtist,
}) => {
  const isMounted = useIsMounted()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // get tha auth state and check for manager role
  const authState = useSelector((state) => getAuthState(state))
  const isManager = useMemo(() => {
    return authState?.userRole?.toLowerCase() === "manager"
  }, [authState])
  const { postData, handleResetPostApi, isPosted, isPosting } = useApi()
  const { snackbar, handleOpenSnackbar, handleCloseSnackbar } = useSnackbar()

  // open snackbar when sent requests
  const handleSendRequest = (senderID, receiverID) => {
    postData(`request/create`, {
      requestType: "manager",
      receiverUserId: receiverID,
      senderUserId: senderID,
    })
  }
  useEffect(() => {
    if (isMounted() && isPosted) {
      handleOpenSnackbar({
        message: "Request sent successfully",
        variant: "success",
      })
    }
  }, [handleOpenSnackbar, isMounted, isPosted])

  // fetch sent requests after sent request
  useEffect(() => {
    if (isMounted()) {
      handleFetchSentRequests(currentUserID)
    }
  }, [isMounted, currentUserID, handleFetchSentRequests, isPosted])

  return (
    <div className="web-view-artist-header">
      {isPosting && (
        <Loader
          isCircularLoader={true}
          isBackDrop
          fullScreen={true}
          className="zIndex-9999"
        />
      )}
      {/* sent request snackbar */}
      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        variant={snackbar.variant}
        handleClose={() => {
          handleCloseSnackbar()
        }}
        resetFunction={handleResetPostApi}
      />
      <Container className="zIndex-0_5">
        <div
          className={`artist-img-section flex-row flex-align-center col-gap-1em ${
            isMobileView ? "flex-wrap" : "flex-nowrap"
          }`}
        >
          {/* artist image */}
          <div className="artist-img p-1em border-circle zIndex-0_5 box-shadow-1 position-center">
            <img
              src={artist?.profileImageUrl || USER_PLACEHOLDER}
              alt="artist-img"
              height={"130px"}
              width={"130px"}
              className="object-fit-cover border-circle"
            />
          </div>
          {/* artist name,review and share section */}
          <div className="artist-details-section zIndex-0_5 flex-row flex-align-end flex-justify-between flex-wrap w-100 row-gap-1em p-0-1em">
            {/* artist name,reviews section */}
            <div className="artist-name-section">
              <Typography variant="h4" className="text-white font-bold">
                {`${artist?.firstName} ${artist?.lastName}`}
              </Typography>
              <Button
                startIcon={<Star />}
                className="text-white bg-primary-3 cursor-default p-0_1em m-0_5em-0"
                disableElevation
                disableTouchRipple
              >
                {avgRatingOfArtist}
              </Button>
              <Typography variant="body1" className="text-white font-bold">
                {totalReviewsOfArtist > 0
                  ? `${totalReviewsOfArtist} reviews`
                  : "No reviews"}
              </Typography>
              <div className="flex-row flex-align-center col-gap-0_5em">
                <Typography variant="h6" className="text-white font-bold">
                  {artist?.location?.address}
                </Typography>
                <Chip
                  label={`${artist?.category?.title ?? "Artist"}`}
                  className="text-primary-3 p-0_5em bg-col-white font-bold box-shadow-1"
                  variant="contained"
                />
              </div>
            </div>
            {/* artists share section */}
            <div className="artist-share-section flex-row flex-align-center col-gap-1em ">
              <IconButton
                className="bg-col-white text-primary-3"
                onClick={() => {
                  handleOpenShareDialog(artist?._id)
                }}
              >
                <Share />
              </IconButton>
              <IconButton
                className={`bg-col-white text-primary-3`}
                onClick={() => {
                  currentUserID ? handleFavorite() : navigate("/login")
                }}
              >
                {isLoadingAddFav ? (
                  <CircularProgress
                    style={{ height: "1em", width: "1em" }}
                    className="text-primary-3"
                  />
                ) : isFavArtist ? (
                  <Favorite />
                ) : (
                  <FavoriteBorder />
                )}
              </IconButton>
              {/* if manager then send request to the artist otherwise navigate to the booking section */}
              {isManager ? (
                <>
                  {/* is request sent */}
                  {isRequestSentAndPending && (
                    <Button
                      className="book-now-btn btn-gradient border-25 text-transform-none font-size-1em"
                      size="large"
                      endIcon={<CheckCircleOutlined />}
                      disabled={true}
                    >
                      Request Sent
                    </Button>
                  )}
                  {/* is managed  */}
                  {isManagedArtist && (
                    <Button
                      className="book-now-btn btn-gradient border-25 text-transform-none font-size-1em"
                      size="large"
                      endIcon={<CheckCircleOutlined />}
                      disabled={true}
                    >
                      Managed
                    </Button>
                  )}
                  {/* send request action */}
                  {!isRequestSentAndPending && !isManagedArtist && (
                    <Button
                      className="book-now-btn btn-gradient border-25 text-transform-none font-size-1em"
                      size="large"
                      onClick={() => {
                        handleSendRequest(currentUserID, artist?._id)
                      }}
                    >
                      Send Request
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  className="book-now-btn btn-gradient border-25 text-transform-none font-size-1em"
                  size="large"
                  onClick={() => {
                    navigate(`/book-artist/${artist?._id}`)
                    dispatch(resetBookingArtist())
                    resetBookingDetailsFromLocalStorage()
                    localStorage.removeItem("indexes")
                  }}
                >
                  Book Now
                </Button>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default WebViewArtistHero
