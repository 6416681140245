import { useEffect, useState } from "react"

const useWindow = () => {
  // get width and height and top position on window scroll or resize event and set it to state
  const [windowData, setWindowData] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    top: window.pageYOffset,
    isMobileView: window.innerWidth < 650,
  })
  // set window width and height on window scroll or resize event
  useEffect(() => {
    const handleResize = () => {
      setWindowData((pre) => {
        return {
          ...pre,
          width: window.innerWidth,
          height: window.innerHeight,
          isMobileView: window.innerWidth < 650,
        }
      })
    }
    const handleScroll = () => {
      setWindowData((pre) => {
        return {
          ...pre,
          top: window.pageYOffset,
        }
      })
    }
    // add event listener on window scroll and resize event
    window.addEventListener("resize", handleResize)
    window.addEventListener("scroll", handleScroll)
    return () => {
      // remove event listener on unmount
      window.removeEventListener("resize", handleResize)
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])
  // return window width and height and top position
  return {
    windowData,
  }
}

export default useWindow
