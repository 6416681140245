import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Close,
} from "@mui/icons-material"
import { Dialog, DialogContent, IconButton } from "@mui/material"
import { useWindow } from "hooks"

const CustomLightBox = ({
  imgData,
  openLightBox,
  setOpenLightBox,
  currentIndex,
  setCurrentIndex,
}) => {
  const { isMobileView } = useWindow().windowData
  const handlePrev = () => {
    if (currentIndex === 0) {
      setCurrentIndex(imgData?.length - 1)
    } else {
      setCurrentIndex(currentIndex - 1)
    }
  }
  const handleNext = () => {
    if (currentIndex === imgData?.length - 1) {
      setCurrentIndex(0)
    } else {
      setCurrentIndex(currentIndex + 1)
    }
  }
  return (
    <Dialog
      fullWidth
      fullScreen
      maxWidth="xl"
      open={openLightBox}
      PaperProps={{
        style: {
          backgroundColor: isMobileView ? "" : "transparent",
        },
      }}
    >
      <div className="h-100 position-relative">
        <div className="overlay zIndex-0"></div>
        <DialogContent
          className={`position-relative h-100 p-0 overflow-hidden`}
        >
          <div className="light-box-header p-1em text-right w-fit">
            <IconButton
              onClick={() => {
                setOpenLightBox(false)
              }}
            >
              <Close className="text-white" />
            </IconButton>
          </div>

          {/* data to be shown  */}
          <div className="light-box-img-root h-100 position-center position-relative w-100">
            {/* previous arrow */}
            <div className="light-box-prev-arrow">
              <IconButton
                className="light-box-prev-icon bg-primary-2 text-white light-box-action-icon"
                onClick={handlePrev}
              >
                <ArrowBackIosOutlined />
              </IconButton>
            </div>
            <img
              src={imgData?.[currentIndex]}
              alt=""
              className="object-fit-contain light-box-img"
              height={"95%"}
            />
            {/* next arrow */}
            <div className="light-box-next-arrow">
              <IconButton
                className="light-box-next-icon bg-primary-2 text-white light-box-action-icon"
                onClick={handleNext}
              >
                <ArrowForwardIosOutlined />
              </IconButton>
            </div>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  )
}

export default CustomLightBox
