import produce from "immer"

const State = {
  data: [],
  isLoading: false,
  error: null,
}
const initialState = {
  crowdSizes: State,
  days: State,
  eventDurations: State,
}

const AdminReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action
    const { crowdSizes, days, eventDurations } = draft
    switch (type) {
      case "CROWD_SIZES_LOADING":
        crowdSizes.isLoading = true
        crowdSizes.error = null
        break
      case "CROWD_SIZES_SUCCESS":
        crowdSizes.data = payload
        crowdSizes.isLoading = false
        crowdSizes.error = null
        break
      case "CROWD_SIZES_FAILURE":
        crowdSizes.isLoading = false
        crowdSizes.error = payload
        break
      // days
      case "DAYS_LOADING":
        days.isLoading = true
        days.error = null
        break
      case "DAYS_SUCCESS":
        days.data = payload
        days.isLoading = false
        days.error = null
        break
      case "DAYS_FAILURE":
        days.isLoading = false
        days.error = payload
        break
      // event durations
      case "EVENT_DURATIONS_LOADING":
        eventDurations.isLoading = true
        eventDurations.error = null
        break
      case "EVENT_DURATIONS_SUCCESS":
        eventDurations.data = payload
        eventDurations.isLoading = false
        eventDurations.error = null
        break
      case "EVENT_DURATIONS_FAILURE":
        eventDurations.isLoading = false
        eventDurations.error = payload
        break
      default:
        return draft
    }
  })

export default AdminReducer
