import { getSelectedItems } from "helpers"
import { createSelector } from "reselect"

export const getArtistProfileInfo = (state) =>
  state.artistProfileState.profileInfo
export const artistPhoneSelector = createSelector(
  (state) => state.artistProfileState.profileInfo,
  (profileInfo) => profileInfo.phone
)

export const setArtistProfileInfo = (data) => {
  return {
    type: "SET_ARTIST_PROFILE_INFO",
    payload: data,
  }
}

export const setArtistCategory = (data) => {
  return {
    type: "SET_ARTIST_CATEGORY",
    payload: data,
  }
}

export const setArtistSubCategory = (items) => {
  return {
    type: "SET_ARTIST_SUBCATEGORY",
    payload: items,
  }
}

export const setArtistGenresAction = (items) => {
  return {
    type: "SET_ARTIST_GENRES",
    payload: items,
  }
}

export const setArtistLanguages = (items, selectedItem) => {
  return {
    type: "SET_ARTIST_LANGUAGES",
    payload: getSelectedItems(items, selectedItem),
  }
}

// artist events
export const setArtistEvents = (items, selectedItem) => {
  return {
    type: "SET_ARTIST_EVENTS",
    payload: getSelectedItems(items, selectedItem),
  }
}
// services
export const setArtistServices = (items, selectedItem) => {
  return {
    type: "SET_ARTIST_SERVICES",
    payload: getSelectedItems(items, selectedItem),
  }
}
