import { apiInstance } from "configs"
import { lowerCaseText } from "helpers"
import { createSelector } from "reselect"

// use of createSelector
export const getUsers = (state) => state?.usersState
export const getAllUsers = createSelector(
  [getUsers],
  (usersState) => usersState?.users
)
export const getArtists = createSelector([getAllUsers], (users) =>
  users?.filter(
    (user) =>
      user?.role?.toLowerCase() === "artist" &&
      lowerCaseText(user?.status) === "active"
  )
)
export const getManagers = createSelector([getAllUsers], (users) =>
  users?.filter((user) => user?.role?.toLowerCase() === "manager")
)

export const topTrendingArtistsSelector = createSelector(
  [getArtists],
  (artists) => {
    return artists?.filter((artist) => artist?.inTopTrending)
  }
)

export const fetchingUsers = () => {
  return {
    type: "FETCH_USERS_START",
  }
}

export const fetchingUsersSuccess = (usersData) => {
  return {
    type: "FETCH_USERS_SUCCESS",
    payload: usersData,
  }
}

export const fetchingUsersErr = (err) => {
  return {
    type: "FETCH_USERS_FAILURE",
    payload: err,
  }
}

// fetch all users data
export const fetchUsers = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchingUsers())
      apiInstance
        .get(`user/accounts`)
        .then(({ data }) => {
          dispatch(fetchingUsersSuccess(data?.success?.data))
        })
        .catch((err) => {
          dispatch(fetchingUsersErr(err.response?.data?.error?.message))
        })
    } catch (error) {
      dispatch(fetchingUsersErr(error?.message))
    }
  }
}

//  user details
export const userDetailsSelector = createSelector(
  [getUsers],
  (usersState) => usersState?.userDetails?.user
)
export const loadingUserDetailsSelector = createSelector(
  [getUsers],
  (usersState) => usersState?.userDetails?.isLoading
)
// loading user details
export const loadingUserDetailsAction = () => {
  return {
    type: "USER_DETAILS_LOADING",
  }
}
export const getUserDetails = (userID) => async (dispatch) => {
  try {
    await apiInstance
      .get(`user/accounts/${userID}`)
      .then(({ data }) => {
        dispatch({
          type: "USER_DETAILS_SUCCESS",
          payload: data?.success?.data,
        })
      })
      .catch(({ response }) => {
        dispatch({
          type: "USER_DETAILS_FAILURE",
          payload: response?.data?.error?.message,
        })
      })
  } catch (error) {
    dispatch({
      type: "USER_DETAILS_FAILURE",
      payload: error?.message,
    })
  }
}

// top search artists
export const topSearchesArtistsSelector = createSelector(
  [getUsers],
  (usersState) => usersState?.topSearch?.data
)
export const loadingTopSearchesArtistsSelector = createSelector(
  [getUsers],
  (usersState) => usersState?.topSearch?.isLoading
)

export const fetchTopSearchesArtistsAction = () => async (dispatch) => {
  try {
    await apiInstance
      .get(`user/top-search/artists/${10}`)
      .then(({ data }) => {
        dispatch({
          type: "TOP_SEARCH_SUCCESS",
          payload: data?.success?.data,
        })
      })
      .catch(({ response }) => {
        dispatch({
          type: "TOP_SEARCH_FAILURE",
          payload: response?.data?.error?.message,
        })
      })
  } catch (error) {
    dispatch({
      type: "TOP_SEARCH_FAILURE",
      payload: error?.message,
    })
  }
}
