import axios from "axios"

// export const BASE_URL = "https://sky-rise-api.herokuapp.com/api/v1"
export const BASE_URL = "https://server.skyrisecelebrity.com/api/v2"
export const apiInstance = axios.create({ baseURL: BASE_URL })

// firebase related configs
export { messaging, app, analytics } from "./firebaseConfig"
export const VAPID_KEY =
  "BKTAeY1o46deDNbWPSOU6K36ozPy6FFhUs9bUv6VrA3D9nc7MDmE3Hl1dr8ygJc_nJCvpUWWUdENqoaZrpKzsTM"
export const FIREBASE_SERVER_KEY =
  "AAAAaDR_V5c:APA91bGqc-urCWbGOgw9h67Atas4v-hyjI9BLb2GsgJzSWcQc-DZjBb2kMV3vAWAXHBoo_VjRVmXaslAl-ldaLOsHeyYGJhXrhsgnszhQFekF_jRUj29In_dVcL0iFGU37yZa7xrtucr"
export const API_KEY = `AIzaSyDHaHQs5WffujJi9uLzjoimI0RXVWRKhqY`
export const TOP_CITIES = [
  {
    id: `1`,
    name: "New York ",
    location: { lat: 40.7127753, lng: -74.0059728 },
  },
  {
    id: `2`,
    name: "Los Angeles",
    location: { lat: 34.0522342, lng: -118.2436849 },
  },
  {
    id: `3`,
    name: "Chicago",
    location: { lat: 41.8781136, lng: -87.6297982 },
  },
  {
    id: `4`,
    name: "Houston",
    location: { lat: 29.7604267, lng: -95.3698028 },
  },
]
export const RANGE_ARR = [
  {
    id: 1,
    title: "150 KM",
    distance: 150,
  },
  {
    id: 2,
    title: "250 KM",
    distance: 250,
  },
  {
    id: 3,
    title: "500 KM",
    distance: 500,
  },
  {
    id: 4,
    title: "1000 KM",
    distance: 1000,
  },
  {
    id: 5,
    title: "1500 KM",
    distance: 1500,
  },
]

export { default as currencies } from "./currencies"
