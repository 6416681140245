import { Close, EditOutlined } from "@mui/icons-material"
import {
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
// import from other files
import {
  CustomCarousel,
  Loader,
  NoDataAvailable,
  ReviewsCard,
  StarRating,
} from "components"
import { useLoader, useWindow, useIsMounted } from "hooks"
import { USER_PLACEHOLDER } from "assets"

const ArtistReviewsTabItem = ({
  reviewsByArtist,
  isLoadingReviewsByArtist,
  artistID,
  isArtistOnUserBookings,
  handleGiveReviewForArtist,
  currentUserID,
  userDetails,
}) => {
  const isMounted = useIsMounted()
  const { namesInLoaderActions } = useLoader()
  const isSmallScreen = useWindow().windowData.width < 600
  //   navigate
  const navigate = useNavigate()
  //   open add review dialog
  const [openAddReviewDialog, setOpenAddReviewDialog] = useState(false)
  //   star ratings
  const [ratings, setRatings] = useState([])
  //   review text
  const [reviewTitle, setReviewTitle] = useState("")
  const [reviewDesc, setReviewDesc] = useState("")

  useEffect(() => {
    if (isMounted() && !namesInLoaderActions?.includes(artistID)) {
      setOpenAddReviewDialog(false)
      setRatings([])
      setReviewTitle("")
      setReviewDesc("")
    }
  }, [isMounted, namesInLoaderActions, artistID])

  return (
    <div>
      {/* add review dialog */}
      {namesInLoaderActions?.includes(artistID) && (
        <Loader isCircularLoader={true} isBackDrop />
      )}
      <Dialog
        maxWidth="sm"
        fullWidth
        open={openAddReviewDialog}
        fullScreen={isSmallScreen}
      >
        <div className="add-review-dialog-content p-0-1em hide-scrollbar">
          {/* dialog header */}
          <div className="close-add-review-dialog-header w-100 flex-row flex-align-center position-relative">
            <IconButton
              className="position-absolute"
              onClick={() => {
                setOpenAddReviewDialog(false)
              }}
            >
              <Close />
            </IconButton>
            <div className="add-review-dialog-header-text w-100 text-center p-1em-0">
              <Typography variant="h5" className="font-bold text-primary-2">
                Add Review
              </Typography>
            </div>
          </div>
          <Divider />
          {/* dialog body */}
          <div className="add-review-dialog-body m-1-0">
            <div className="add-review-dialog-body-artist text-center">
              {/* artist image */}
              <div className="add-review-dialog-body-artist-img ">
                <img
                  src={userDetails?.profileImageUrl || USER_PLACEHOLDER}
                  alt=""
                  height={"150px"}
                  width={"150px"}
                  className="border-circle object-fit-cover"
                />
              </div>
              {/* artist name */}
              <div className="add-review-dialog-body-artist-name">
                <Typography variant="h6" className="text-primary-8">
                  {`${userDetails?.firstName} ${userDetails?.lastName}`}
                </Typography>
              </div>
              {/* star ratings */}
              <div className="add-review-dialog-star-ratings ">
                <StarRating ratings={ratings} setRatings={setRatings} />
              </div>
              {/* title & description */}
              <div className="add-review-title-desc-root p-1em flex-column row-gap-1em">
                {/* title */}
                <div className="add-review-title-desc text-left">
                  <TextField
                    fullWidth
                    label="Title"
                    value={reviewTitle}
                    onChange={(e) => setReviewTitle(e.target.value)}
                  />
                </div>
                {/* description */}
                <div className="add-review-title-desc text-left">
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    label="Additional Comments"
                    value={reviewDesc}
                    onChange={(e) => setReviewDesc(e.target.value)}
                  />
                </div>
              </div>
              {/* review action buttons */}
              <div className="add-review-action-buttons flex-row flex-justify-between flex-align-center p-0-1em col-gap-1em">
                <Button
                  variant="contained"
                  className={`font-bold text-transform-none ${
                    namesInLoaderActions?.includes(artistID) ||
                    !ratings?.length ||
                    !reviewTitle ||
                    !reviewDesc
                      ? "disabled-btn"
                      : "btn-gradient"
                  }`}
                  fullWidth
                  size="large"
                  disableElevation
                  onClick={() => {
                    handleGiveReviewForArtist(
                      currentUserID,
                      reviewTitle,
                      reviewDesc,
                      ratings?.length
                    )
                  }}
                  disabled={
                    namesInLoaderActions?.includes(artistID) ||
                    !ratings?.length ||
                    !reviewTitle ||
                    !reviewDesc
                      ? true
                      : false
                  }
                  color="inherit"
                >
                  Submit Review
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      {/* review tab item body */}
      <div className="m-1-0">
        <div className="view-add-review flex-row flex-align-end flex-justify-between">
          <div>
            <Typography variant="h5" gutterBottom className="text-primary-2">
              Client Reviews
            </Typography>
            {isArtistOnUserBookings && currentUserID && (
              <Button
                className="text-primary-3 bg-col-white text-transform-none border-5 border-primary-3 font-bold font-size-small"
                variant="outlined"
                disableElevation
                endIcon={<EditOutlined />}
                onClick={() => setOpenAddReviewDialog(true)}
              >
                Add Review
              </Button>
            )}
          </div>
          {reviewsByArtist?.length > 0 && !isLoadingReviewsByArtist && (
            <Button
              className="text-primary-2 bg-col-white text-transform-none border-5 border-primary-2 font-size-small font-bold"
              variant="outlined"
              disableElevation
              onClick={() => {
                navigate(`/artist-reviews/${artistID}`)
              }}
            >
              View All
            </Button>
          )}
        </div>
      </div>
      {!isLoadingReviewsByArtist && (
        <>
          {reviewsByArtist?.length > 0 ? (
            <Grid container spacing={2} justifyContent={"center"}>
              {reviewsByArtist?.length > 4 || isSmallScreen ? (
                <CustomCarousel>
                  {reviewsByArtist?.map((review, re_index) => (
                    <ReviewsCard key={re_index} review={review} />
                  ))}
                </CustomCarousel>
              ) : (
                <Grid container spacing={2}>
                  {reviewsByArtist?.map((review, re_index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={re_index}>
                      <ReviewsCard review={review} />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          ) : (
            <NoDataAvailable />
          )}
        </>
      )}
      {isLoadingReviewsByArtist && (
        <div>
          <Loader bgNone isCircularLoader={true} />
        </div>
      )}
    </div>
  )
}

export default ArtistReviewsTabItem
