import { apiInstance } from "configs"
import { createSelector } from "reselect"
import { startAction } from "."
import { endAction } from "./LoaderAction"
// ----------------------------------------SELECTORS----------------------------------------
export const artistVisitorsStateSelector = (state) =>
  state?.artistDashboardState?.visitors
export const artistVisitorsSelector = createSelector(
  [artistVisitorsStateSelector],
  (visitors) => visitors?.data
)
export const artistsVisitorsLengthSelector = createSelector(
  [artistVisitorsStateSelector],
  (visitors) => visitors?.wholeLength
)
export const artistVisitorsLoadingSelector = createSelector(
  [artistVisitorsStateSelector],
  (visitors) => visitors?.loading
)
export const noOfTimesVisitedSelector = createSelector(
  [artistVisitorsSelector],
  (visitors) => visitors?.reduce((acc, curr) => acc + curr?.count, 0)
)
export const noOfMinutesSpentSelector = createSelector(
  [artistVisitorsSelector],
  (visitors) =>
    (visitors?.reduce((acc, curr) => acc + curr?.spentTime, 0) / 60).toFixed(0)
)
// -----------------------------------------ACTIONS-----------------------------------------
// visitors action
export const fetchArtistVisitorsAction =
  (artistID, pageLength) => async (dispatch) => {
    try {
      dispatch(startAction(`LOADING_PAGE_${pageLength}`))
      await apiInstance
        .get(`visitor/all-visitor-artist/${artistID}/${pageLength ?? 0}`)
        .then(({ data }) => {
          const { success } = data
          dispatch({
            type: "VISITORS_SUCCESS",
            payload: {
              data: success?.data,
              length: success?.length,
            },
          })
        })
        .catch(({ response }) => {
          const errMsg = response?.data?.error?.message
          dispatch({
            type: "VISITORS_ERROR",
            payload: errMsg,
          })
        })
        .finally(() => {
          dispatch(endAction(`LOADING_PAGE_${pageLength}`))
        })
    } catch (error) {
      dispatch({
        type: "VISITORS_ERROR",
        payload: error?.message,
      })
    }
  }
// favorite artists action
export const favByUsersStateSelector = (state) =>
  state?.artistDashboardState?.favByUsers
export const favByUsersSelector = createSelector(
  [favByUsersStateSelector],
  (favByUsers) => favByUsers?.data
)
export const favByUsersLengthSelector = createSelector(
  [favByUsersStateSelector],
  (favByUsers) => favByUsers?.wholeLength
)
export const favByUsersLoadingSelector = createSelector(
  [favByUsersStateSelector],
  (favByUsers) => favByUsers?.loading
)
export const fetchFavByUsersAction =
  (artistID, pageCount) => async (dispatch) => {
    try {
      dispatch({
        type: "FAV_BY_USERS_LOADING",
      })
      await apiInstance
        .get(`favorite/all-favorite-artist/${artistID}/${pageCount ?? 0}`)
        .then(({ data }) => {
          dispatch({
            type: "FAV_BY_USERS_SUCCESS",
            payload: {
              data: data?.success?.data,
              length: data?.success?.length,
            },
          })
        })
        .catch(({ response }) => {
          dispatch({
            type: "FAV_BY_USERS_ERROR",
            payload: response?.data?.error?.message,
          })
        })
    } catch (error) {
      dispatch({
        type: "FAV_BY_USERS_ERROR",
        payload: error?.message,
      })
    }
  }

// artist revenue
export const artistRevenueStateSelector = (state) =>
  state?.artistDashboardState?.revenues

export const artistRevenueLoadingSelector = createSelector(
  [artistRevenueStateSelector],
  (revenues) => revenues?.loading
)

export const artistRevenueSelector = createSelector(
  [artistRevenueStateSelector],
  (revenues) => revenues?.data
)
export const artistTotalRevenueSelector = createSelector(
  [artistRevenueStateSelector],
  (state) => state?.totalRevenue
)
export const artistLastRevenueSelector = createSelector(
  [artistRevenueStateSelector],
  (revenues) => revenues?.recentPayment
)
export const artistRevenueLengthSelector = createSelector(
  [artistRevenueStateSelector],
  (revenues) => revenues?.wholeLength
)

export const fetchArtistRevenueAction = (artistID, count) => (dispatch) => {
  try {
    dispatch({
      type: "ARTIST_REVENUE_LOADING",
    })
    dispatch(startAction(`LOADING_PAGE_${count}`))
    apiInstance
      .get(`booking/booking-revenue/${artistID}/${count}`)
      .then(({ data }) => {
        const res = data?.success?.data
        dispatch({
          type: "REVENUES_SUCCESS",
          payload: {
            data: res?.bookings,
            totalRevenue: res?.revenue,
            recentPayment: res?.lastPayment,
            length: res?.bookingLength,
          },
        })
      })
      .catch(({ response }) => {
        dispatch({
          type: "REVENUES_ERROR",
          payload: response?.data?.error?.message,
        })
      })
      .finally(() => {
        dispatch(endAction(`LOADING_PAGE_${count}`))
      })
  } catch (error) {
    dispatch({
      type: "REVENUES_ERROR",
      payload: error?.message,
    })
  }
}
