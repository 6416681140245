import { ArrowForwardOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import from other files
import {
  clearPromoCode,
  clearWalletAmount,
  resetOtherDetails,
  setPersonalizedMessage,
  setPersonalizedMessageDate,
} from "redux/actions";
import { useMemo } from "react";

const PrevNextAction = ({
  compIndex,
  setCompIndex,
  steps,
  bookingArtistState,
  isPersonalizedMessage,
  handleNextWithStorage,
  artistID,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClickNext = () => {
    if (compIndex?.length === steps) {
      navigate(`/payment/${artistID}`);
      dispatch(clearWalletAmount());
      dispatch(clearPromoCode());
      return;
    }
    if (compIndex?.length < steps && isPersonalizedMessage) {
      dispatch(resetOtherDetails());
      setCompIndex((pre) => [...pre, pre.length + 2]);
    }
    if (compIndex?.length < steps) {
      !isPersonalizedMessage && dispatch(setPersonalizedMessage(""));
      !isPersonalizedMessage && dispatch(setPersonalizedMessageDate(""));
      setCompIndex((pre) => [...pre, pre.length + 1]);
    }
  };

  // check for disabled next btn
  const isDisabledBtn = useMemo(() => {
    return (
      (compIndex?.length === 1 &&
        (bookingArtistState?.eventTypeName === "" ||
          bookingArtistState?.serviceTypeName === "")) ||
      (compIndex?.length === 2 &&
        (bookingArtistState?.eventTime === "Invalid date" ||
          bookingArtistState?.eventDuration === "")) ||
      (compIndex?.length === 3 &&
        (!isPersonalizedMessage
          ? bookingArtistState?.eventDate?.start === "" ||
            bookingArtistState?.eventDate?.start === "Invalid date" ||
            bookingArtistState?.eventDate?.end === "" ||
            bookingArtistState?.eventDate?.end === "Invalid date" ||
            bookingArtistState?.crowdSize === "" ||
            (bookingArtistState?.crowdSize?.length > 0 &&
              isNaN(+bookingArtistState?.crowdSize))
          : bookingArtistState?.personalizedMsgDate === "Invalid date" ||
            bookingArtistState?.personalizedMsgDate === "")) ||
      (compIndex?.length === 4 && bookingArtistState?.eventLocation === "") ||
      (compIndex?.length === 5 &&
        isPersonalizedMessage &&
        bookingArtistState?.personalizedMessage === "")
    );
  }, [compIndex, bookingArtistState, isPersonalizedMessage]);

  return (
    <div className="booking-artist-action-root flex-row flex-align-center flex-justify-between p-0-1em">
      <div className="text-left w-100">
        <Button
          className={`border-5 bg-primary-7 text-white font-bold text-transform-none p-0_5em-1em ${
            compIndex?.length === 1 ? "display-none" : ""
          }`}
          size="large"
          onClick={() => {
            if (compIndex?.length > 1) {
              setCompIndex((pre) => pre.filter((i) => i !== pre?.length));
            }
            return;
          }}
        >
          Go Back
        </Button>
      </div>
      <div className="text-right w-100">
        <Button
          className={`border-5 font-bold text-transform-none p-0_5em-1em ${
            isDisabledBtn ? "disabled-btn" : "bg-primary-3 text-white"
          }`}
          size="large"
          endIcon={<ArrowForwardOutlined />}
          onClick={() => {
            handleClickNext();
            handleNextWithStorage();
          }}
          disabled={isDisabledBtn}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default PrevNextAction;
