import { useDispatch, useSelector } from "react-redux"
import {
  Container,
  Typography,
  IconButton,
  MenuItem,
  ListItemIcon,
  Badge,
  ListItemText,
  Divider,
  Dialog,
  Tooltip,
} from "@mui/material"
import {
  AccountBalanceWalletOutlined,
  AdminPanelSettingsOutlined,
  AttachMoneyOutlined,
  CalendarTodayOutlined,
  ChatOutlined,
  Close,
  ContactMailOutlined,
  DashboardOutlined,
  EventOutlined,
  FavoriteBorder,
  HomeOutlined,
  InfoOutlined,
  KeyboardArrowDown,
  // Language,
  LibraryMusicOutlined,
  LogoutOutlined,
  MenuOpen,
  NotificationsOutlined,
  PeopleOutline,
  PersonOutline,
  PolicyOutlined,
  PrivacyTipOutlined,
  QuizOutlined,
  SearchOutlined,
  SupportAgentOutlined,
  UpdateOutlined,
  WidgetsOutlined,
} from "@mui/icons-material"
import { useLocation, useNavigate } from "react-router-dom"
import { LOGO, USER_PLACEHOLDER } from "assets"
import {
  useApi,
  // useCurrencyContext,
  useIsMounted,
  useNotifications,
  useUsers,
  useWindow,
} from "hooks"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { CustomDrawer, CustomPopper, CustomPopperBody } from "components"
import HeaderSearch from "./HeaderSearch"
import {
  getAuthState,
  headerAddressSelector,
  headerCountrySelector,
  headerSearchLocationSelector,
  headerSearchStateSelector,
  headerSearchValueSelector,
  isUserLoggedIn,
  logoutUser,
  resetSearchValue,
} from "redux/actions"
import { getText } from "helpers"
import {
  headerCurrentLocationSelector,
  headerRangeSelector,
} from "redux/actions/HeaderSearchActions"
import { CurrencyPicker, CustomButton } from "components/core"

const BaseHeader = ({ isShowSearchBar }) => {
  const isMounted = useIsMounted()
  const { postData } = useApi()
  // const { selectedCurrency, onOpenCurrencyPicker } = useCurrencyContext()
  // get auth state
  const { currentUser, artistsData, currentUserID } = useUsers()
  const allStates = useSelector((state) => state)
  const auth = useSelector((state) => getAuthState(state))
  const isUserLogin = useMemo(() => isUserLoggedIn(allStates), [allStates])
  const isArtist = useMemo(
    () => auth?.userRole?.toLowerCase() === "artist",
    [auth]
  )
  const isManager = useMemo(
    () => auth?.userRole?.toLowerCase() === "manager",
    [auth]
  )
  // redux state for header search bar
  useSelector((state) => headerSearchStateSelector(state))
  const searchValue = useSelector((state) => headerSearchValueSelector(state))
  const location = useSelector((state) => headerSearchLocationSelector(state))
  const address = useSelector((state) => headerAddressSelector(state))
  const country = useSelector((state) => headerCountrySelector(state))
  const range = useSelector((state) => headerRangeSelector(state))
  const currentLocation = useSelector((state) =>
    headerCurrentLocationSelector(state)
  )
  const dispatch = useDispatch()

  // form useWindow hook
  const scrollFromTop = useWindow().windowData?.top
  const isSmallScreen = useWindow().windowData?.width < 800
  const isMobileViewSearchBar = useWindow().windowData?.width < 600
  const isBelow950 = useWindow().windowData?.width < 1050
  // navigate from react-router-dom
  const navigate = useNavigate()
  // drawer open
  const [drawerOpen, setDrawerOpen] = useState(false)
  // popper open
  const [popperOpen, setPopperOpen] = useState(false)
  const mainRef = useRef(null)
  // search dialog open
  const [searchDialogOpen, setSearchDialogOpen] = useState(false)
  // full search bar open
  const [isFullSearchBar, setIsFullSearchBar] = useState(false)
  // side drawer open for web view
  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false)
  // notifications
  const { unreadNotifications, handleFetchNotifications } =
    useNotifications(currentUserID)

  // ------------other functions------------
  const filteredRes = useMemo(() => {
    const filteredArtists = artistsData?.filter(
      (artist) =>
        artist?.firstName
          ?.toLowerCase()
          ?.includes(searchValue?.toLowerCase()) ||
        artist?.lastName?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
        artist?.category?.title
          ?.toLowerCase()
          ?.includes(searchValue?.toLowerCase())
    )
    if (searchValue?.length > 0) {
      return filteredArtists
    }
    return artistsData
  }, [searchValue, artistsData])

  // logout user
  const logOutUser = useCallback(() => {
    dispatch(logoutUser())
    postData(
      `user/account-update/${currentUserID}`,
      {
        fcmToken: "",
      },
      "PUT"
    )
    navigate("/")
  }, [dispatch, navigate, postData, currentUserID])

  // get notifications
  useEffect(() => {
    if (isMounted()) {
      handleFetchNotifications()
    }
  }, [isMounted, handleFetchNotifications])

  // ------------Popper Menus And Drawer Menus------------

  // menus for header
  const MENUS = [
    {
      name: "Artist Login",
      handleClick: () => navigate("/login/artist"),
    },
    {
      name: "User Login",
      handleClick: () => navigate("/login/user"),
    },
    {
      name: "Contact",
      handleClick: () => navigate("/contact"),
    },
  ]
  // side drawer menus
  const SIDE_MENUS = [
    {
      name: "Manager Login",
      handleClick: () => {
        navigate("/login/manager")
        setDrawerOpen(false)
        setIsSideDrawerOpen(false)
      },
      icon: <AdminPanelSettingsOutlined htmlColor="var(--col-primary-5)" />,
      isLoggedIn: false,
    },
    {
      name: "Why Skyrise Connect?",
      handleClick: () => navigate("/why-skyrise-celebrity"),
      icon: <QuizOutlined htmlColor="var(--col-primary-5)" />,
      isLoggedIn: false,
    },
  ]

  // user profile popper
  const userActionMenus = [
    {
      label: "My Account",
      icon: <PersonOutline />,
      handleClick: () => {
        navigate("/my-profile")
      },
    },
    {
      label: "Personalized Messages",
      icon: <ChatOutlined />,
      handleClick: () => {
        navigate("/personalized-messages")
      },
    },
    {
      label: "My Bookings",
      icon: <EventOutlined />,
      handleClick: () => {
        navigate("/my-bookings")
      },
    },
    {
      label: "My Favorites",
      icon: <FavoriteBorder />,
      handleClick: () => {
        navigate("/my-favorites")
      },
    },
    {
      label: "My Wallet",
      icon: <AccountBalanceWalletOutlined />,
      handleClick: () => {
        navigate("/my-wallet")
      },
    },
    {
      label: "Notifications",
      icon: <NotificationsOutlined />,
      handleClick: () => {
        navigate("/notifications")
      },
    },
    {
      label: "Requests",
      icon: <UpdateOutlined />,
      handleClick: () => {
        navigate("/requests")
      },
    },
    {
      label: "Support",
      icon: <SupportAgentOutlined />,
      handleClick: () => {
        navigate("/support")
      },
    },
    {
      label: "Logout",
      icon: <LogoutOutlined />,
      handleClick: () => {
        logOutUser()
      },
    },
  ]

  // drawer contents
  const DRAWER_CONTENTS = {
    loggedInUserContents: userActionMenus?.filter(
      (m) => m.label !== "My Account"
    ),
    loggedOutUserContents: [
      {
        name: "Home",
        handleClick: () => navigate("/"),
        icon: <HomeOutlined htmlColor="var(--col-primary-5)" />,
      },
      {
        name: "Artist Login",
        handleClick: () => navigate("/login/artist"),
        icon: <LibraryMusicOutlined htmlColor="var(--col-primary-5)" />,
        isLoggedIn: false,
      },
      {
        name: "User Login",
        handleClick: () => navigate("/login/user"),
        icon: <PersonOutline htmlColor="var(--col-primary-5)" />,
        isLoggedIn: false,
      },
      {
        name: "Manager Login",
        handleClick: () => navigate("/login/manager"),
        icon: <AdminPanelSettingsOutlined htmlColor="var(--col-primary-5)" />,
        isLoggedIn: false,
      },
    ],
    bothUserContents: [
      {
        name: "Contact Us",
        handleClick: () => navigate("/contact"),
        icon: <ContactMailOutlined htmlColor="var(--col-primary-5)" />,
      },
      {
        name: "About Us",
        handleClick: () => navigate("/about"),
        icon: <InfoOutlined htmlColor="var(--col-primary-5)" />,
      },
      {
        name: "Terms & Conditions",
        handleClick: () => navigate("/terms-and-conditions"),
        icon: <PrivacyTipOutlined htmlColor="var(--col-primary-5)" />,
      },
      {
        name: "Privacy Policy",
        handleClick: () => navigate("/privacy-policy"),
        icon: <PolicyOutlined htmlColor="var(--col-primary-5)" />,
      },
    ],
  }
  // artist profile popper
  const artistActionMenus = [
    {
      label: "Dashboard",
      icon: <DashboardOutlined />,
      handleClick: () => {
        navigate("/artist-dashboard")
      },
    },
    {
      label: "Manage Profile",
      icon: <PersonOutline />,
      handleClick: () => {
        navigate("/artist-profile")
      },
    },
    {
      label: "Bookings",
      icon: <EventOutlined />,
      handleClick: () => {
        navigate("/artist-bookings")
      },
    },
    {
      label: "Calendar",
      icon: <CalendarTodayOutlined />,
      handleClick: () => {
        navigate("/artist-calendar")
      },
    },
    {
      label: "Pricing",
      icon: <AttachMoneyOutlined />,
      handleClick: () => {
        navigate("/artist-pricing")
      },
    },
    {
      label: "Requests",
      icon: <UpdateOutlined />,
      handleClick: () => {
        navigate("/artist-requests")
      },
    },
    {
      label: "Managers",
      icon: <PeopleOutline />,
      handleClick: () => {
        navigate("/artist-managers")
      },
    },
    {
      label: "Notifications",
      icon: <NotificationsOutlined />,
      handleClick: () => {
        navigate("/artist/notifications")
      },
    },
    {
      label: "Support",
      icon: <SupportAgentOutlined />,
      handleClick: () => {
        navigate("/artist-support")
      },
    },
    {
      label: "Logout",
      icon: <LogoutOutlined />,
      handleClick: () => {
        logOutUser()
      },
    },
  ]
  // artist frequent access menus
  const { pathname } = useLocation()
  const [selectFQBtn, setSelectFQBtn] = useState(null)
  const artistFrequentAccessMenus = [
    {
      name: "Calendar",
      handleClick: () => navigate("/artist-calendar"),
    },
    {
      name: "Pricing",
      handleClick: () => navigate("/artist-pricing"),
    },
    {
      name: "Requests",
      handleClick: () => navigate("/artist-requests"),
    },
  ]
  // manager profile popper

  const managerActionMenus = [
    {
      label: "My Account",
      icon: <PersonOutline />,
      handleClick: () => {
        navigate("/manager-profile")
      },
    },
    {
      label: "Artists",
      icon: <PeopleOutline />,
      handleClick: () => {
        navigate("/managed-artists")
      },
    },
    {
      label: "My Favorites",
      icon: <FavoriteBorder />,
      handleClick: () => {
        navigate("/manager/favorites")
      },
    },
    {
      label: "Notifications",
      icon: <NotificationsOutlined />,
      handleClick: () => {
        navigate("/manager/notifications")
      },
    },
    {
      label: "Requests",
      icon: <UpdateOutlined />,
      handleClick: () => {
        navigate("/manager-requests")
      },
    },
    {
      label: "Support",
      icon: <SupportAgentOutlined />,
      handleClick: () => {
        navigate("/manager/support")
      },
    },
    {
      label: "Logout",
      icon: <LogoutOutlined />,
      handleClick: () => {
        logOutUser()
      },
    },
  ]

  // get the action menus according to the role
  const LOGGED_IN_ACTION_MENUS = isArtist
    ? artistActionMenus
    : isManager
    ? managerActionMenus
    : DRAWER_CONTENTS?.loggedInUserContents
  // component for popper menus
  const RenderActionPopper = ({ actionMenus }) => {
    return (
      <>
        {actionMenus?.map((actionMenu, a_index) => (
          <div
            className="userActionMenu-root flex-row flex-align-center cursor-pointer"
            key={a_index}
            role="button"
            onClick={() => actionMenu?.handleClick()}
          >
            <div className="userActionMenu-icon">
              <IconButton disableRipple>{actionMenu?.icon}</IconButton>
            </div>
            <div className="userActionMenu-name">
              <Typography className="text-primary-2" noWrap>
                {actionMenu?.label}
              </Typography>
            </div>
          </div>
        ))}
      </>
    )
  }

  const handleNavigateNotification = () => {
    if (isArtist) {
      navigate("/artist/notifications")
      return
    }
    if (isManager) {
      navigate("/manager/notifications")
      return
    }
    navigate("/notifications")
  }
  const handleNavigateFavorites = () => {
    if (isManager) {
      navigate("/manager/favorites")
      return
    }
    navigate("/my-favorites")
  }

  const handleNavigateProfile = () => {
    if (isArtist) {
      navigate("/artist-profile")
      return
    }
    if (isManager) {
      navigate("/manager-profile")
      return
    }
    navigate("/user-profile")
  }

  const setFrequentMenu = useCallback(() => {
    switch (pathname) {
      case "/artist-calendar":
        setSelectFQBtn(0)
        break
      case "/artist-pricing":
        setSelectFQBtn(1)
        break
      case "/artist-requests":
        setSelectFQBtn(2)
        break
      default:
        setSelectFQBtn(null)
    }
  }, [pathname])

  useEffect(() => {
    if (isMounted()) {
      setFrequentMenu()
    }
  }, [setFrequentMenu, isMounted])

  const handleNavigateToUserBookings = () => {
    navigate("/my-bookings")
  }

  return (
    <>
      {/* currency picker */}
      <CurrencyPicker />
      {/* side drawer for logged out user in web */}
      <CustomDrawer
        drawerOpen={isSideDrawerOpen}
        setDrawerOpen={setIsSideDrawerOpen}
        position="right"
      >
        <div className="side-drawer-content bg-white h-100 box-shadow-x-10-y-0 bg-black-0_7">
          <div className="drawer-close-icon text-right">
            <IconButton onClick={() => setIsSideDrawerOpen(false)}>
              <Close className="font-size-1em" htmlColor="var(--col-white)" />
            </IconButton>
          </div>
          {SIDE_MENUS?.map((item, d_index) => (
            <MenuItem
              key={d_index}
              className="p-1em header-drawer-menu-item"
              onClick={() => {
                item?.handleClick()
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <Typography className="text-white">{item?.name}</Typography>
            </MenuItem>
          ))}
          {DRAWER_CONTENTS?.bothUserContents.map((item, d_index) => (
            <MenuItem
              key={d_index}
              className="p-1em header-drawer-menu-item"
              onClick={() => {
                item?.handleClick()
              }}
            >
              <>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <Typography className="text-white">{item?.name}</Typography>
              </>
            </MenuItem>
          ))}
        </div>
      </CustomDrawer>
      {/* side drawer for mobile view */}
      {isSmallScreen ? (
        <CustomDrawer
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          position="right"
        >
          <div className="drawer-content bg-white box-shadow-x-10-y-0 bg-black-0_7">
            <div className="drawer-close-icon text-right">
              <IconButton onClick={() => setDrawerOpen(false)}>
                <Close className="font-size-1em" htmlColor="var(--col-white)" />
              </IconButton>
            </div>
            {/* check for user is logged in or not and show ui according to this */}
            {isUserLogin ? (
              <>
                <MenuItem
                  className="p-1em logged-in-user cursor-pointer"
                  disableRipple
                  onClick={() => {
                    handleNavigateProfile()
                    setDrawerOpen(false)
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={currentUser?.profileImageUrl || USER_PLACEHOLDER}
                      alt=""
                      className="object-fit-cover border-circle"
                      height={"60px"}
                      width={"60px"}
                      onError={(ev) => {
                        ev.target.src = USER_PLACEHOLDER
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    className="m-0_5em w-200px"
                    primary={
                      <>
                        <Typography className="text-white text-ellipses font-size-1_1em">
                          {getText(currentUser?.firstName, 15)}
                        </Typography>
                        <Typography
                          className="text-white text-ellipses"
                          variant="body2"
                        >
                          {currentUser?.email}
                        </Typography>
                      </>
                    }
                    secondary={
                      <Typography
                        className="text-white text-ellipses view-profile-link font-size-small"
                        variant="body2"
                        onClick={() => {
                          handleNavigateProfile()
                          setDrawerOpen(false)
                        }}
                      >
                        Go to Profile
                      </Typography>
                    }
                  />
                </MenuItem>
                {LOGGED_IN_ACTION_MENUS?.map((item, d_index) => (
                  <MenuItem
                    key={d_index}
                    className="p-1em header-drawer-menu-item"
                    onClick={() => {
                      item.handleClick()
                      setDrawerOpen(false)
                    }}
                  >
                    <>
                      <ListItemIcon
                        classes={{
                          root: "text-primary-5",
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <Typography className="text-white">
                        {item?.name || item?.label}
                      </Typography>
                    </>
                  </MenuItem>
                ))}
                <Divider className="bg-primary-5" />
              </>
            ) : (
              <>
                {DRAWER_CONTENTS?.loggedOutUserContents.map((item, d_index) => (
                  <MenuItem
                    key={d_index}
                    className="p-1em header-drawer-menu-item"
                    onClick={() => {
                      item?.handleClick()
                    }}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <Typography className="text-white">{item?.name}</Typography>
                  </MenuItem>
                ))}
              </>
            )}
            {/* <MenuItem
              onClick={() => {
                onOpenCurrencyPicker()
              }}
              className="p-1em header-drawer-menu-item"
            >
              <ListItemIcon>
                <Language htmlColor="white" />
              </ListItemIcon>
              <div className="flex flex-row flex-justify-between w-100">
                <Typography className="text-white">Currency</Typography>
                <Typography noWrap color="#FFB6C1" className="text">
                  {selectedCurrency?.code}
                </Typography>
              </div>
            </MenuItem> */}
            {DRAWER_CONTENTS?.bothUserContents.map((item, d_index) => (
              <MenuItem
                key={d_index}
                className="p-1em header-drawer-menu-item"
                onClick={() => {
                  item?.handleClick()
                  setDrawerOpen(false)
                }}
              >
                <>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <Typography className="text-white">{item?.name}</Typography>
                </>
              </MenuItem>
            ))}
          </div>
        </CustomDrawer>
      ) : (
        <></>
      )}
      <header
        className={`${
          scrollFromTop > 200
            ? "base-navbar-sticky"
            : "base-navbar box-shadow-1"
        }`}
      >
        <Container>
          <div className="header-container p-0_5em-0 text-white flex-row flex-align-center flex-justify-between">
            <div className="logo">
              <img
                src={LOGO}
                alt="logo"
                width={180}
                height={"100%"}
                className="cursor-pointer"
                onClick={() => navigate("/")}
              />
            </div>
            {/* header search bar web view & mobile view*/}
            {!isArtist && (
              <>
                {isMobileViewSearchBar ? (
                  <Dialog open={searchDialogOpen} fullScreen>
                    <div className="header-search-bar-dialog-header p-0_5em-1em text-right">
                      <IconButton
                        onClick={() => {
                          setSearchDialogOpen(false)
                          dispatch(resetSearchValue())
                          setIsFullSearchBar(false)
                        }}
                        className="bg-whitesmoke font-bold text-primary-1 text-transform-none "
                      >
                        <Close />
                      </IconButton>
                    </div>
                    <div className={`home-header-search-bar-mobile-view p-1em`}>
                      <HeaderSearch
                        searchValue={searchValue}
                        dispatch={dispatch}
                        filteredRes={filteredRes}
                        isMobileViewSearchBar={isMobileViewSearchBar}
                        setIsFullSearchBar={setIsFullSearchBar}
                        isFullSearchBar={isFullSearchBar}
                        isBelow950={isBelow950}
                        address={address}
                        country={country}
                        location={location}
                        range={range}
                        currentLocation={currentLocation}
                      />
                    </div>
                  </Dialog>
                ) : (
                  <>
                    <div className={`home-header-search-bar`}>
                      <HeaderSearch
                        searchValue={searchValue}
                        dispatch={dispatch}
                        filteredRes={filteredRes}
                        setIsFullSearchBar={setIsFullSearchBar}
                        isFullSearchBar={isFullSearchBar}
                        isBelow950={isBelow950}
                        address={address}
                        country={country}
                        location={location}
                        range={range}
                        currentLocation={currentLocation}
                      />
                    </div>
                  </>
                )}
              </>
            )}
            {isSmallScreen ? (
              <>
                <div className="menuIcon">
                  {!drawerOpen && !isFullSearchBar ? (
                    <IconButton onClick={() => setDrawerOpen(true)}>
                      <MenuOpen fontSize="large" className="text-primary-2" />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : (
              <div className="header-menus-root flex-row flex-align-center">
                {!isUserLogin ? (
                  <>
                    {!isFullSearchBar && (
                      <div className="menus flex-row flex-align-center col-gap-1em">
                        {MENUS?.map((menu, index) => (
                          <div
                            key={index}
                            onClick={() => menu.handleClick()}
                            className="menu-btn heading-text-color p-0_5em cursor-pointer border-5"
                          >
                            <Typography variant="body1" noWrap>
                              {menu?.name}
                            </Typography>
                          </div>
                        ))}

                        {/* sidebar menu */}
                        <IconButton
                          onClick={() => {
                            setIsSideDrawerOpen(true)
                          }}
                          className="text-primary-2 p-0_5em bg-whitesmoke menu-widget"
                        >
                          <WidgetsOutlined />
                        </IconButton>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {/* user profile start*/}
                    {!isFullSearchBar && (
                      <div className="user-profile-menus flex-row flex-align-center col-gap-1em">
                        {/* bookings icon for user only */}
                        {!isArtist && !isManager && (
                          <Tooltip title="Bookings">
                            <IconButton
                              className="bg-black-0_1 header-fav-icon"
                              onClick={handleNavigateToUserBookings}
                            >
                              <EventOutlined />
                            </IconButton>
                          </Tooltip>
                        )}
                        {/* favorite with badge */}
                        {!isArtist && (
                          <IconButton
                            className="bg-black-0_1 header-fav-icon"
                            onClick={() => {
                              handleNavigateFavorites()
                            }}
                          >
                            <FavoriteBorder />
                          </IconButton>
                        )}
                        {/* artist frequent menu */}
                        {isArtist && (
                          <div className="flex-row flex-align-center">
                            {artistFrequentAccessMenus?.map((item, ind) => (
                              <CustomButton
                                btnText={item?.name}
                                key={ind}
                                variant="contained"
                                className={`text-primary-8 m-0-0_5em bg-col-white box-shadow-normal border-25 font-size-1em frequent-access-btn ${
                                  selectFQBtn === ind ? "active" : ""
                                }`}
                                disableElevation
                                onClick={() => {
                                  item?.handleClick()
                                }}
                              />
                            ))}
                          </div>
                        )}
                        {/* notification with badge */}
                        <Badge
                          variant="dot"
                          badgeContent={unreadNotifications?.length}
                          classes={{
                            badge: "bg-primary-1",
                          }}
                        >
                          <IconButton
                            className="bg-black-0_1 header-notification-icon"
                            onClick={() => {
                              handleNavigateNotification()
                            }}
                          >
                            <NotificationsOutlined />
                          </IconButton>
                        </Badge>
                        {/* profile section with popper */}
                        <CustomPopper mainRef={mainRef}>
                          <div
                            className="user-profile-root flex-row flex-align-end border-25 cursor-pointer bg-black-0_1"
                            role="button"
                            onClick={() => {
                              setPopperOpen(!popperOpen)
                            }}
                          >
                            {/* logged in user image or start character */}
                            <div className="user-profile-icon bg-primary-2 position-center">
                              <Typography className="text-primary-5 font-size-1_5em">
                                {currentUser?.firstName
                                  ?.charAt(0)
                                  ?.toUpperCase()}
                              </Typography>
                            </div>
                            <KeyboardArrowDown className="text-primary-2" />
                          </div>
                          {/* custom popper body */}
                          <CustomPopperBody
                            open={popperOpen}
                            position="right"
                            setOpen={setPopperOpen}
                            mainRef={mainRef}
                          >
                            {/* check user role and according to the role display the action menus */}
                            <div className="profile-popper-content">
                              {isArtist ? (
                                <RenderActionPopper
                                  actionMenus={artistActionMenus}
                                />
                              ) : isManager ? (
                                <RenderActionPopper
                                  actionMenus={managerActionMenus}
                                />
                              ) : (
                                <RenderActionPopper
                                  actionMenus={userActionMenus}
                                />
                              )}
                            </div>
                          </CustomPopperBody>
                        </CustomPopper>
                      </div>
                    )}
                    {/* user profile end */}
                  </>
                )}
                {/* currency switcher */}
                {/* <IconButton
                  onClick={() => {
                    onOpenCurrencyPicker()
                  }}
                  className="text-primary-2 p-0_5em bg-whitesmoke menu-widget currency-switcher"
                >
                  <div className="position-absolute currency-text">
                    <Typography
                      variant="body2"
                      noWrap
                      color="red"
                      className="text"
                    >
                      {selectedCurrency?.code}
                    </Typography>
                  </div>
                  <Language />
                </IconButton> */}
              </div>
            )}
          </div>
        </Container>
      </header>
      {isMobileViewSearchBar && isShowSearchBar && !isArtist ? (
        <div
          className="header-search-bar-mobile border-25 flex-row flex-align-center"
          role={"button"}
          onClick={() => {
            setSearchDialogOpen(true)
          }}
        >
          <IconButton className="p-0 cursor-default m-0-0_5em">
            <SearchOutlined />
          </IconButton>
          <input
            type="text"
            className="w-100 font-size-1em border-none outline-none"
            placeholder="Search artists"
            readOnly
          />
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default BaseHeader
