import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  applyCouponCodeAction,
  expiredPromoCodesSelector,
  fetchPromoCodesData,
  promoCodesLoadingSelector,
  promoCodesSelector,
  promoCodeStateSelector,
  upcomingPromoCodesSelector,
} from "redux/actions"
import useIsMounted from "./useIsMounted"

const usePromoCodes = () => {
  const isMounted = useIsMounted()
  const dispatch = useDispatch()
  const promoCodeState = useSelector((state) => promoCodeStateSelector(state))
  const promoCodes = useSelector((state) => promoCodesSelector(state))
  const isLoadingPromoCodes = useSelector((state) =>
    promoCodesLoadingSelector(state)
  )
  const expiredPromoCodes = useSelector((state) =>
    expiredPromoCodesSelector(state)
  )
  const upcomingPromoCodes = useSelector((state) =>
    upcomingPromoCodesSelector(state)
  )

  const handleApplyPromoCode = useCallback(
    (secString, uID, amount) => {
      dispatch(applyCouponCodeAction(secString, uID, amount))
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch(fetchPromoCodesData())
  }, [dispatch, isMounted])
  return {
    promoCodeState,
    promoCodes,
    isLoadingPromoCodes,
    expiredPromoCodes,
    upcomingPromoCodes,
    handleApplyPromoCode,
  }
}

export default usePromoCodes
