import { DeleteOutlined, InfoOutlined } from "@mui/icons-material"
import { Button, Divider, IconButton, Tooltip, Typography } from "@mui/material"
// import from files
import {
  getText,
  getTimeFromNow,
  lowerCaseText,
  primaryDateFormat,
} from "helpers"
import { useCurrencyContext, useLoader } from "hooks"
import { Loader } from "components"

const RescheduleEventRequestCard = ({
  reqData,
  onClick,
  handleOpenReasonDialog,
  isShowAction,
  loaderActions,
  handleAcceptRequest,
  handleRemoveRequest,
}) => {
  const { currencyWiseValue } = useCurrencyContext()
  const { namesInLoaderActions } = useLoader()
  const renderByStatus = (status) => {
    switch (status?.toUpperCase()) {
      case "ACCEPT":
        return (
          <Button
            className="event-accept-btn font-bold p-0_5em-1em border-5"
            fullWidth
            disabled
          >
            Accepted
          </Button>
        )
      case "REJECT":
        return (
          <Button
            className="event-reject-btn font-bold p-0_5em-1em border-5"
            fullWidth
            disabled
          >
            Rejected
          </Button>
        )
      default:
        return (
          <Button
            className="event-pending-btn font-bold p-0_5em-1em border-5"
            fullWidth
            disabled
          >
            Pending
          </Button>
        )
    }
  }

  return (
    <div className={"reschedule-event-card-root p-1em bg-white border-5"}>
      {/* request info button */}
      <>
        <div className="request-details flex-row flex-align-center flex-justify-between p-0-1em">
          <span className="font-size-small text-primary-1 font-bold">
            {getTimeFromNow(reqData?.timestamp)}
          </span>
          <div>
            {lowerCaseText(reqData?.status) !== "pending" && (
              <>
                {namesInLoaderActions?.includes(
                  `REMOVE_REQUEST_${reqData?._id}`
                ) ? (
                  <IconButton className="border-10 bg-whitesmoke m-r-0_5em">
                    <Loader isCircularLoader bgNone circularProgressSize={22} />
                  </IconButton>
                ) : (
                  <Tooltip title="Remove">
                    <IconButton
                      onClick={handleRemoveRequest}
                      className="border-10 bg-whitesmoke m-r-0_5em"
                    >
                      <DeleteOutlined className="text-primary-1" />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
            <Tooltip title="View Details">
              <IconButton
                onClick={() => onClick(reqData)}
                className="border-10 bg-whitesmoke"
              >
                <InfoOutlined className="text-primary-9" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </>
      {/* requests body and action button */}
      <div className="reschedule-event-card">
        {/* pricing request */}
        {lowerCaseText(reqData?.requestType) === "pricing" && (
          <div className="p-0-1em">
            <Typography className="font-bold text-primary-2 font-size-1em">
              Pricing Request
            </Typography>
            <Divider className="m-0_5em-0" />
            <div className="schedule-date m-0_5em-0">
              <Typography variant="body1" className="font-bold text-ellipses">
                {reqData?.booking?.eventType?.eventName}
              </Typography>
              <Typography
                variant="body2"
                className="text-primary-8 text-ellipses"
              >
                {reqData?.booking?.serviceType?.serviceName}
              </Typography>
            </div>
            <div className="schedule-date m-0_5em-0">
              <Typography variant="body1" className="font-bold text-primary-2">
                Event Date
              </Typography>
              <div className="schedule-date">
                <Typography
                  variant="body2"
                  className="font-bold text-primary-6 text-ellipses"
                >{`${primaryDateFormat(
                  reqData?.booking?.eventDate?.start
                )}`}</Typography>
              </div>
            </div>
            <div className="schedule-date m-0_5em-0">
              <Typography variant="body1" className="font-bold text-primary-3">
                Crowd Size
              </Typography>
              <Typography
                variant="body2"
                className="font-bold text-primary-6 text-ellipses"
              >
                {`${reqData?.booking?.crowdSize}`}
              </Typography>
            </div>
          </div>
        )}
        {/* payment request */}
        {lowerCaseText(reqData?.requestType) === "payment" && (
          <div className="p-0-1em">
            <Typography className="font-bold text-primary-2 font-size-1em">
              Payment Request
            </Typography>
            <Divider className="m-0_5em-0" />
            <div className="schedule-date m-0_5em-0">
              <Typography variant="body1" className="font-bold text-ellipses">
                {reqData?.booking?.eventType?.eventName}
              </Typography>
              <Typography
                variant="body2"
                className="text-primary-8 text-ellipses"
              >
                {reqData?.booking?.serviceType?.serviceName}
              </Typography>
            </div>
            <div className="schedule-date m-0_5em-0">
              <Typography variant="body1" className="font-bold text-primary-2">
                Event Date
              </Typography>
              <div className="schedule-date">
                {reqData?.booking?.personalizedMsgDate ? (
                  <Typography
                    variant="body2"
                    className="font-bold text-primary-6 text-ellipses"
                  >{`${primaryDateFormat(
                    reqData?.booking?.personalizedMsgDate
                  )}`}</Typography>
                ) : (
                  <Typography
                    variant="body2"
                    className="font-bold text-primary-6 text-ellipses"
                  >{`${primaryDateFormat(
                    reqData?.booking?.eventDate?.start
                  )}`}</Typography>
                )}
              </div>
            </div>
            <div className="schedule-date m-0_5em-0">
              <Typography variant="body1" className="font-bold text-primary-3">
                Booking Price
              </Typography>
              <Typography
                variant="body2"
                className="font-bold text-primary-6 text-ellipses"
              >
                {`${currencyWiseValue(reqData?.booking?.bookingPrice)}`}
              </Typography>
            </div>
          </div>
        )}
        {/* request for manager */}
        <>
          {["managerremove", "manager"]?.includes(
            lowerCaseText(reqData?.requestType)
          ) && (
            <div className="p-0-1em">
              <Typography className="font-bold text-primary-2 font-size-1em">
                Manager Request
              </Typography>
              {lowerCaseText(reqData?.requestType) === "managerremove" && (
                <Typography className="font-bold font-size-small text-primary-1">
                  Remove Request
                </Typography>
              )}
              <Divider className="m-0_5em-0" />
              <div className="schedule-date m-0_5em-0">
                <Typography variant="body1" className="font-bold text-ellipses">
                  {`${reqData?.senderUser?.firstName} ${reqData?.senderUser?.lastName}`}
                </Typography>
                <Typography
                  variant="body2"
                  className="text-primary-8 text-ellipses"
                >
                  {reqData?.senderUser?.email}
                </Typography>
              </div>
              <div className="schedule-date m-0_5em-0">
                <Typography
                  variant="body1"
                  className="font-bold text-primary-2"
                >
                  Location
                </Typography>
                <Typography
                  variant="body2"
                  className="font-bold text-primary-6 text-ellipses"
                >
                  {reqData?.senderUser?.location?.address}
                </Typography>
              </div>
              {reqData?.senderUser?.yearsOfExperience && (
                <div className="schedule-date m-0_5em-0">
                  <Typography
                    variant="body1"
                    className="font-bold text-primary-2"
                  >
                    Years of Experience
                  </Typography>
                  <Typography
                    variant="body2"
                    className="font-bold text-primary-6 text-ellipses"
                  >
                    {reqData?.senderUser?.yearsOfExperience}
                  </Typography>
                </div>
              )}
            </div>
          )}
        </>
        {/* request for reschedule event */}
        {["rescheduledcustomer", "rescheduledartist"]?.includes(
          lowerCaseText(reqData?.requestType)
        ) && (
          <>
            <div className="p-0-1em">
              <Typography className="font-bold text-primary-2 font-size-1em">
                Reschedule Request
              </Typography>
              <Divider className="m-0_5em-0" />
            </div>
            <div className="schedule-date m-0_5em-0 p-0-1em">
              <Typography variant="body1" className="font-bold text-ellipses">
                {reqData?.booking?.eventType?.eventName}
              </Typography>
              <Typography
                variant="body2"
                className="text-primary-8 text-ellipses"
              >
                {reqData?.booking?.serviceType?.serviceName}
              </Typography>
            </div>
            <div className="schedule-date p-0-1em">
              <Typography variant="body1" className="font-bold text-primary-2">
                Schedule Date
              </Typography>
              {lowerCaseText(reqData?.booking?.bookingType) !== "other" ? (
                <Typography
                  variant="body2"
                  className="font-bold text-primary-6"
                >{`${primaryDateFormat(reqData?.booking?.personalizedMsgDate)}
            `}</Typography>
              ) : (
                <Typography
                  variant="body2"
                  className="font-bold text-primary-6"
                >{`${primaryDateFormat(
                  reqData?.booking?.eventDate?.start
                )}`}</Typography>
              )}
            </div>
            <div className="reschedule-date p-0-1em m-0_5em-0">
              <Typography variant="body1" className="font-bold text-primary-3">
                Reschedule Date
              </Typography>
              <Typography variant="body2" className="font-bold text-primary-6">
                {`${primaryDateFormat(
                  reqData?.reschedule
                    ? reqData?.reschedule?.rescheduleDate?.start
                    : reqData?.booking?.eventDate?.start
                )}`}
              </Typography>
            </div>
          </>
        )}
        {/* request for personalized message */}
        {lowerCaseText(reqData?.requestType) === "personalize" && (
          <div className="p-0-1em">
            <Typography className="font-bold text-primary-2 font-size-1em">
              Personalized Message Request
            </Typography>
            <Divider className="m-0_5em-0" />
            <div className="schedule-date m-0_5em-0">
              <Typography variant="body1" className="font-bold text-ellipses">
                {reqData?.booking?.eventType?.eventName}
              </Typography>
              <Typography
                variant="body2"
                className="text-primary-8 text-ellipses"
              >
                {reqData?.booking?.serviceType?.serviceName}
              </Typography>
            </div>
            <div className="schedule-date m-0_5em-0">
              <Typography variant="body1" className="font-bold text-primary-2">
                Event Date
              </Typography>
              <Typography
                variant="body2"
                className="font-bold text-primary-6 text-ellipses"
              >
                {primaryDateFormat(reqData?.booking?.personalizedMsgDate)}
              </Typography>
            </div>
            <div className="schedule-date m-0_5em-0">
              <Typography variant="body1" className="font-bold text-primary-2">
                Personalized Message
              </Typography>
              <Typography variant="body2" className="font-bold text-primary-6 ">
                {getText(reqData?.booking?.personalizedMessage, 33)}
              </Typography>
            </div>
          </div>
        )}
        {/* request for other location */}
        {reqData?.requestType?.toLowerCase() === "location" && (
          <div className="p-0-1em">
            <Typography className="font-bold text-primary-2 font-size-1em">
              Other Location Request
            </Typography>
            <Divider className="m-0_5em-0" />
            <div className="schedule-date m-0_5em-0">
              <Typography variant="body1" className="font-bold text-ellipses">
                {reqData?.eventType}
              </Typography>
              <Typography
                variant="body2"
                className="text-primary-8 text-ellipses"
              >
                {reqData?.serviceType}
              </Typography>
            </div>
            <div className="schedule-date m-0_5em-0">
              <Typography variant="body1" className="font-bold text-primary-2">
                Event Date
              </Typography>
              <Typography variant="body2" className="font-bold text-primary-6">
                {`${primaryDateFormat(reqData?.eventDate?.start)} `}
              </Typography>
            </div>
            <div className="schedule-date m-0_5em-0">
              <Typography variant="body1" className="font-bold text-primary-2">
                Event Location
              </Typography>
              <Typography variant="body2" className="font-bold text-primary-6 ">
                {reqData?.cityName}
              </Typography>
            </div>
          </div>
        )}
      </div>
      {!isShowAction || lowerCaseText(reqData?.status) !== "pending" ? (
        <div className="p-0_5em">{renderByStatus(reqData?.status)}</div>
      ) : (
        <>
          {loaderActions && <div className="p-0_5em-1em">Loading...</div>}
          {!loaderActions && (
            <div className="reschedule-event-card-action p-0_5em flex-row flex-align-center flex-justify-between">
              <Button
                className="event-accept-btn font-bold p-0_5em-1em border-5"
                onClick={() => handleAcceptRequest()}
              >
                Accept
              </Button>
              <Button
                className="event-reject-btn font-bold p-0_5em-1em border-5"
                onClick={() => handleOpenReasonDialog(reqData)}
              >
                Reject
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default RescheduleEventRequestCard
