// import from packages
import { Button, Dialog, DialogContent, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"

const CustomDialogBox = ({
  open,
  IconName,
  title,
  description,
  btnText,
  route,
  handleClose,
  rootClass,
  fullScreen,
  paperClass,
  dialogContentClass,
}) => {
  const navigate = useNavigate()
  return (
    <div>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        classes={{
          paper: `border-10 ${paperClass}`,
          root: rootClass || "",
        }}
        fullScreen={fullScreen || false}
      >
        <DialogContent className={dialogContentClass || ""}>
          <div className="custom-dialog-header text-center">
            {IconName && (
              <IconName
                className="text-primary-3 font-size-100px"
                fontSize="large"
              />
            )}
            <Typography variant="h4" gutterBottom>
              {title}
            </Typography>
          </div>
          <div className="custom-dialog-body text-center">
            <Typography variant="body1">{description}</Typography>
          </div>
          <div className="custom-dialog-action text-center m-1-0">
            <Button
              className="bg-primary-2 text-white text-transform-none"
              size="large"
              onClick={() => {
                route && navigate(route)
                handleClose()
              }}
            >
              {btnText}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default CustomDialogBox
