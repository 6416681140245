import { LocationOnOutlined } from "@mui/icons-material"
import { TextField, Typography } from "@mui/material"
import { useState } from "react"
import PlacesAutocomplete, {
  getLatLng,
  geocodeByAddress,
} from "react-places-autocomplete"
const PlaceAutoComplete = ({
  handleOpenSuggestion,
  handleLocationAddress,
  handleLocation,
  handleCountry,
  address,
  className,
  classes,
  InputProps,
  name,
}) => {
  const [isHide, setIsHide] = useState(false)
  const handleChange = (address) => {
    setIsHide(false)
    handleLocationAddress(address)
  }

  const handleSelect = async (address) => {
    //get result array
    const results = await geocodeByAddress(address)
    const latLng = await getLatLng(results?.[0])
    handleCountry &&
      handleCountry(
        results[0]?.address_components?.find((item) =>
          item?.types?.includes("country")
        )?.long_name
      )
    handleLocation && handleLocation(latLng.lat, latLng.lng)
    handleLocationAddress && handleLocationAddress(address)
  }
  return (
    <div>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="position-relative ">
            <TextField
              fullWidth
              name={name}
              classes={classes}
              InputProps={{
                ...InputProps,
                inputProps: {
                  style: {
                    fontSize: "1em",
                  },
                },
                classes: {
                  notchedOutline: `${
                    className
                      ? className
                      : "border-10 border-primary-2 border-1px"
                  }`,
                },
              }}
              {...getInputProps({
                placeholder: "Enter your location",
                className: "location-search-input",
              })}
            />
            <div
              className={`${
                !suggestions?.length > 0 || isHide
                  ? "display-none"
                  : "display-block autocomplete-dropdown-container box-shadow-1 p-1em"
              }`}
            >
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, index) => {
                const className = suggestion.active
                  ? "suggestion-item active"
                  : "suggestion-item"
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" }
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={index}
                    className="p-0_5em-0 flex-row flex-align-center cursor-pointer"
                    onClick={() => {
                      handleChange(suggestion?.description)
                      handleSelect(suggestion?.description)
                      setIsHide(true)
                      handleOpenSuggestion && handleOpenSuggestion()
                    }}
                  >
                    <LocationOnOutlined className="text-primary-9" />
                    <Typography variant="body1" className="p-0-0_5em">
                      {suggestion?.description}
                    </Typography>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  )
}

export default PlaceAutoComplete
