import {
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  CloseOutlined,
  DeleteOutline,
  DeleteOutlined,
  FileUploadOutlined,
} from "@mui/icons-material"
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material"
import { useCallback, useEffect, useRef, useState } from "react"
// import from other files
import { CustomButton } from "components"
import {
  useArtistMedias,
  useIsMounted,
  useLoader,
  useSnackbar,
  useWindow,
} from "hooks"
import { getSelectedItems, handleFileUpload, matchYoutubeUrl } from "helpers"
import {
  CustomConfirmationDialog,
  CustomSnackbar,
  EmptyData,
  Loader,
} from "components/core"

const ArtistManageVideos = ({ currentUserID }) => {
  const { namesInLoaderActions } = useLoader()
  const isMounted = useIsMounted()
  const {
    artistVideos,
    isLoadingArtistVideos,
    removeVideosCallback,
    isRemovingArtistVideos,
    isRemoveArtistVideosSuccess,
    isRemoveArtistVideosError,
    handleRemoveArtistVideosReset,
    handleAddArtistVideos,
    handleAddArtistVideosReset,
    isAddArtistVideosSuccess,
    isAddArtistVideosError,
  } = useArtistMedias(currentUserID)
  const { isMobileView } = useWindow().windowData
  const [isVideoUploadDialogOpen, setIsVideoUploadDialogOpen] = useState(false)
  const { snackbar, handleOpenSnackbar, handleCloseSnackbar } = useSnackbar()
  // videos upload and view preview
  const selectVideoRef = useRef(null)
  const [videoLink, setVideoLink] = useState("")
  const [videoLinkErr, setVideoLinkErr] = useState(false)
  const [previewLinkVideos, setPreviewLinkVideos] = useState([])
  const [previewVideos, setPreviewVideos] = useState([])
  const [allVideos, setAllVideos] = useState([])
  const [allLinkVideos, setAllLinkVideos] = useState([])
  const [selectedVideos, setSelectedVideos] = useState([])

  const [previewImages, setPreviewImages] = useState([])

  // delete images dialog
  const [deleteDialog, setDeleteDialog] = useState(false)

  // handle select multiple videos files and set to previews
  const handleSelectMultipleVideos = async (e) => {
    const { files } = e.target
    const videos = Array.from(files)
    for (const v of videos) {
      const data = await handleFileUpload(v)
      setPreviewImages((prev) => [...prev, data])
    }
    setPreviewVideos([...previewVideos, ...videos])
  }
  // handle remove video from preview
  const handleRemoveVideo = (videoUrl) => {
    const newPreviewVideos = previewVideos?.filter(
      (previewVideo) => previewVideo !== videoUrl
    )
    setPreviewVideos(newPreviewVideos)
  }

  // add video link to preview
  const addVideoLink = (link) => {
    if (matchYoutubeUrl(link)) {
      setPreviewLinkVideos([...previewLinkVideos, link])
      setVideoLinkErr(false)
      setVideoLink("")
      return
    }
    setVideoLinkErr(true)
  }
  // remove video link from preview
  const removeVideoLink = (link) => {
    const newPreviewLinkVideos = previewLinkVideos?.filter(
      (previewLinkVideo) => previewLinkVideo !== link
    )
    setPreviewLinkVideos(newPreviewLinkVideos)
  }

  // get id of youtube video
  function getId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
    const match = url?.match(regExp)

    return match && match[2].length === 11 ? match[2] : null
  }

  // upload all videos
  const uploadAllVideos = (videos, links, thumbnails) => {
    handleAddArtistVideos(videos, links, thumbnails)
    setPreviewVideos([])
    setPreviewLinkVideos([])
    setIsVideoUploadDialogOpen(false)
  }
  // fetch videos and set to own state
  useEffect(() => {
    if (
      isMounted() &&
      (artistVideos?.artistVideos?.length > 0 ||
        artistVideos?.youtubeVideos?.length > 0)
    ) {
      setAllVideos(artistVideos?.artistVideos)
      setAllLinkVideos(artistVideos?.youtubeVideos)
    }
  }, [artistVideos, isMounted])

  // open snackbar according to the conditions
  // remove success
  useEffect(() => {
    if (isMounted() && isRemoveArtistVideosSuccess) {
      handleOpenSnackbar({
        message: "Videos removed successfully",
        variant: "success",
      })
    }
  }, [isMounted, isRemoveArtistVideosSuccess, handleOpenSnackbar])
  // remove error
  useEffect(() => {
    if (isMounted() && isRemoveArtistVideosError) {
      handleOpenSnackbar({
        message: "Error removing videos",
        variant: "error",
      })
    }
  }, [isMounted, isRemoveArtistVideosError, handleOpenSnackbar])
  // add success
  useEffect(() => {
    if (isMounted() && isAddArtistVideosSuccess) {
      handleOpenSnackbar({
        message: "Videos added successfully",
        variant: "success",
      })
    }
  }, [isMounted, isAddArtistVideosSuccess, handleOpenSnackbar])
  // add error
  useEffect(() => {
    if (isMounted() && isAddArtistVideosError) {
      handleOpenSnackbar({
        message: "Error adding videos",
        variant: "error",
      })
    }
  }, [isMounted, isAddArtistVideosError, handleOpenSnackbar])

  const resetAddRemoveVideos = useCallback(() => {
    handleRemoveArtistVideosReset()
    handleAddArtistVideosReset()
  }, [handleRemoveArtistVideosReset, handleAddArtistVideosReset])

  return (
    <div className="artist-manage-videos-root">
      {(isRemovingArtistVideos ||
        namesInLoaderActions?.includes(
          `ADD_ARTIST_VIDEOS_${currentUserID}`
        )) && (
        <Loader
          isBackDrop={true}
          isCircularLoader
          fullScreen={true}
          className="zIndex-9999"
        />
      )}
      <CustomSnackbar
        open={snackbar.open}
        handleClose={() => {
          handleCloseSnackbar()
          handleRemoveArtistVideosReset()
          handleAddArtistVideosReset()
        }}
        message={snackbar.message}
        variant={snackbar.variant}
        resetFunction={resetAddRemoveVideos}
      />
      {/* delete confirm dialog */}
      <CustomConfirmationDialog
        open={deleteDialog}
        headerTitle={`Delete Videos`}
        description={`Are you sure you want to delete?`}
        headerIcon={<DeleteOutlined />}
        handleAction={() => {
          removeVideosCallback(selectedVideos)
          setSelectedVideos([])
          setDeleteDialog(false)
        }}
        handleClose={() => {
          setDeleteDialog(false)
        }}
      />
      {/* upload video action */}
      <div className="select-files position-center flex-column p-1em border-5 m-b-1em box-shadow-normal m-t-1em">
        <Typography variant="h6" className="text-center">
          Select files or add Youtube link to upload videos
        </Typography>
        <CustomButton
          btnText="Upload Videos"
          className="m-b-1em border-primary-2 text-primary-2 m-0_5em-0"
          endIcon={<FileUploadOutlined />}
          onClick={() => {
            setIsVideoUploadDialogOpen(true)
          }}
        />
      </div>
      {/* title */}
      {!isLoadingArtistVideos && (
        <div className="artist-manage-photos-title flex-row flex-align-center flex-justify-between">
          <div>
            <Typography variant="h6" className="text-primary-8 " gutterBottom>
              All Videos
            </Typography>
          </div>
          {selectedVideos?.length > 0 && (
            <div className="remove-artist-added-videos">
              <CustomButton
                btnText="Remove"
                className="m-b-1em border-primary-1 text-primary-1"
                endIcon={<DeleteOutline />}
                onClick={() => {
                  setDeleteDialog(true)
                }}
              />
            </div>
          )}
        </div>
      )}
      {/* body */}
      <div className="artist-manage-photos-body m-t-2em">
        {/* dialog box to upload videos */}
        <Dialog
          open={isVideoUploadDialogOpen}
          maxWidth="sm"
          fullWidth
          fullScreen={isMobileView}
        >
          <DialogContent>
            {/* dialog header */}
            <div className="upload-videos-dialog-header flex-row flex-align-center flex-justify-between">
              <Typography
                variant="h6"
                className="text-primary-2 font-bold "
                gutterBottom
              >
                Upload Videos
              </Typography>
              <IconButton
                onClick={() => {
                  setIsVideoUploadDialogOpen(false)
                }}
                className="bg-whitesmoke"
              >
                <CloseOutlined />
              </IconButton>
            </div>
            <Divider />
            {/* dialog body */}
            <div className="upload-videos-dialog-body m-1-0">
              {/* upload video url */}
              <div className="artist-upload-video-link w-100 m-b-1em">
                <Typography gutterBottom>Video URL</Typography>
                <TextField
                  placeholder="Enter Video Link Here"
                  fullWidth
                  variant="outlined"
                  value={videoLink}
                  onChange={(e) => {
                    setVideoLink(e.target.value)
                    setVideoLinkErr(false)
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: "border-none",
                    },
                    endAdornment: (
                      <IconButton
                        className={`text-primary-2 bg-whitesmoke font-bold`}
                        onClick={() => {
                          addVideoLink(videoLink)
                        }}
                      >
                        <FileUploadOutlined />
                      </IconButton>
                    ),
                  }}
                  className="box-shadow-1 border-5"
                  required
                  error={videoLinkErr ? true : false}
                  helperText={
                    videoLinkErr ? "Please enter correct youtube url" : ""
                  }
                />
              </div>
              {/* upload video from own machines */}
              <Typography gutterBottom>All Videos</Typography>
              <Grid container spacing={2}>
                {/* preview for videos added using link */}
                {previewLinkVideos?.map((linkVideo, index) => (
                  <Grid item xs={6} sm={4} lg={4} key={index} className="h-100">
                    <div className="position-relative">
                      <iframe
                        height={"130px"}
                        className="object-fit-cover box-shadow-1 border-5 w-100 "
                        src={`https://www.youtube.com/embed/${getId(
                          linkVideo
                        )}`}
                        title="video"
                        frameBorder="0"
                        allowFullScreen
                      ></iframe>
                      {/* select added videos */}
                      <div className="artist-added-video position-absolute box-shadow-1 border-circle">
                        <IconButton
                          size="small"
                          className="text-primary-1"
                          onClick={() => {
                            removeVideoLink(linkVideo)
                          }}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </div>
                    </div>
                  </Grid>
                ))}
                {/* preview for selected videos from machine */}
                {previewVideos?.map((previewVid, index) => (
                  <Grid item xs={6} sm={4} lg={4} key={index} className="h-100">
                    <div className="position-relative">
                      <iframe
                        height={"130px"}
                        className="object-fit-cover box-shadow-1 border-5 w-100 "
                        src={URL.createObjectURL(previewVid)}
                        title="video"
                        frameBorder="0"
                        allowFullScreen
                      ></iframe>
                      {/* select added videos */}
                      <div className="artist-added-video position-absolute box-shadow-1 border-circle">
                        <IconButton
                          size="small"
                          className="text-primary-1"
                          onClick={() => {
                            handleRemoveVideo(previewVid)
                          }}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </div>
                    </div>
                  </Grid>
                ))}
                <Grid item xs={6} sm={4} lg={4}>
                  <input
                    type="file"
                    multiple
                    onChange={(ev) => {
                      handleSelectMultipleVideos(ev)
                    }}
                    ref={selectVideoRef}
                    hidden
                    accept="video/*"
                  />
                  <div
                    className="artist-manage-photos-add-action position-center position-relative p-1em flex-column cursor-pointer border-5 h-100 w-100"
                    role={"button"}
                    onClick={() => {
                      selectVideoRef.current.click()
                    }}
                  >
                    <div className="overlay zIndex-0 border-5"></div>
                    <FileUploadOutlined
                      className="zIndex-1 text-white"
                      fontSize="large"
                    />
                    <Typography className="zIndex-1 text-white">
                      Select Files
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
          <DialogActions className="p-0_5em-1em">
            <div className="upload-dialog-action-btn m-1-0 text-left w-100 p-0-1em flex-row flex-justify-center">
              <CustomButton
                btnText="Upload Now"
                className={`${
                  true ? "border-primary-2 text-primary-2" : "disabled-btn "
                }`}
                disabled={false}
                fullWidth={isMobileView}
                onClick={() => {
                  uploadAllVideos(
                    previewVideos,
                    previewLinkVideos,
                    previewImages
                  )
                }}
              />
            </div>
          </DialogActions>
        </Dialog>
        {/* add videos */}
        {(allVideos?.length > 0 || allLinkVideos?.length > 0) &&
          !isLoadingArtistVideos && (
            <Grid container spacing={2} alignItems="center">
              {/* show all added videos */}
              {allLinkVideos?.map((vid) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  lg={4}
                  key={vid?._id}
                  className="h-100"
                >
                  <div className="position-relative">
                    <iframe
                      height={"200px"}
                      className="object-fit-cover box-shadow-1 border-5 w-100 "
                      src={`https://www.youtube.com/embed/${getId(
                        vid?.youtubeUrl
                      )}`}
                      title="video"
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>

                    {/* select added videos */}
                    <div className="artist-added-video position-absolute box-shadow-1 border-circle">
                      <IconButton
                        size="small"
                        className="text-primary-2"
                        onClick={() => {
                          setSelectedVideos(
                            getSelectedItems(selectedVideos, vid?._id)
                          )
                        }}
                      >
                        {selectedVideos?.includes(vid?._id) ? (
                          <CheckBoxOutlined />
                        ) : (
                          <CheckBoxOutlineBlank />
                        )}
                      </IconButton>
                    </div>
                  </div>
                </Grid>
              ))}
              {allVideos?.map((vid) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  lg={4}
                  key={vid?._id}
                  className="h-100"
                >
                  <div className="position-relative">
                    <iframe
                      height={"200px"}
                      className="object-fit-cover box-shadow-1 border-5 w-100 "
                      src={vid?.videoUrl}
                      title="video"
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>

                    {/* select added videos */}
                    <div className="artist-added-video position-absolute box-shadow-1 border-circle">
                      <IconButton
                        size="small"
                        className="text-primary-2"
                        onClick={() => {
                          setSelectedVideos(
                            getSelectedItems(selectedVideos, vid?._id)
                          )
                        }}
                      >
                        {selectedVideos?.includes(vid?._id) ? (
                          <CheckBoxOutlined />
                        ) : (
                          <CheckBoxOutlineBlank />
                        )}
                      </IconButton>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          )}
        {allVideos?.length === 0 &&
          allLinkVideos?.length === 0 &&
          !isLoadingArtistVideos && <EmptyData />}
        {isLoadingArtistVideos && (
          <div className="h-50vh">
            <Loader isCircularLoader={true} />
          </div>
        )}
      </div>
    </div>
  )
}

export default ArtistManageVideos
