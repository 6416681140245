import {
  CloseOutlined,
  FacebookOutlined,
  IosShareOutlined,
  Twitter,
  WhatsappOutlined,
} from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { CustomButton } from "components";

const ShareDialog = ({ open, handleClose, shareUrl }) => {
  const SHARE_ICONS = [
    {
      icon: FacebookOutlined,
      name: "Facebook",
      handleClick: () => {
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
          "_blank"
        );
      },
      color: "#3b5998",
    },
    {
      icon: Twitter,
      name: "Twitter",
      handleClick: () => {
        window.open(
          `https://twitter.com/intent/tweet?url=${shareUrl}`,
          "_blank"
        );
      },
      color: "#1DA1F2",
    },
    {
      icon: WhatsappOutlined,
      name: "WhatsApp",
      handleClick: () => {
        window.open(
          `https://api.whatsapp.com/send?&text=${shareUrl}`,
          "_blank"
        );
      },
      color: "#25d366",
    },
    {
      icon: IosShareOutlined,
      name: "Share",
      handleClick: () => {
        navigator
          ?.share({
            title: "Skyrise",
            text: "Share Now",
            url: shareUrl,
          })
          .then(() => {
            console.log("Share");
          })
          .catch((err) => {
            console.log("err", err);
          });
      },
      color: "var(--col-primary-9)",
    },
  ];
  const isSmall = useMediaQuery("(max-width:600px)");

  return (
    <Dialog maxWidth="sm" fullWidth open={open}>
      <DialogContent>
        <div className="flex-row flex-align-center flex-justify-between">
          <Typography variant="h5" className="text-primary-2 font-bold">
            Share
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseOutlined />
          </IconButton>
        </div>
        <Divider className="m-0_5em-0" />
        {/* share icon & link */}
        <div>
          {/* share icon */}
          <div className="m-0_5em-0 text-center">
            {SHARE_ICONS.map((item, index) => (
              <IconButton
                key={index}
                className="border-10 p-0_5em box-shadow-normal m-r-0_5em m-t-1em"
                onClick={item.handleClick}
              >
                <item.icon
                  fontSize={isSmall ? "medium" : "large"}
                  htmlColor={item?.color}
                />
              </IconButton>
            ))}
          </div>
          <Typography
            className="text-primary-2 font-bold text-center m-1-0"
            variant="body1"
          >
            Or
          </Typography>
          {/* share link */}
          <div className="flex-row flex-align-center flex-justify-between p-0_5em-1em border-25 box-shadow-normal">
            <Typography className="text-ellipses">{shareUrl}</Typography>
            <CustomButton
              btnText={"Copy"}
              variant="text"
              className={"bg-none font-bold font-size-1em text-primary-2"}
              disableElevation
              onClick={() => {
                // Copy shareUrl into clipboard
                navigator?.clipboard
                  ?.writeText(shareUrl)
                  .then(() => console.log("Copied to clipboard"))
                  .catch((err) => console.log(err));
              }}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ShareDialog;
