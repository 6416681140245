import { useIsMounted } from "hooks"
import { useCallback, useEffect, useRef } from "react"

export const CustomPopper = ({ children, mainRef }) => {
  return (
    <div className="custom-popper-root flex-column">
      <div className="main-child" id="main-child" ref={mainRef}>
        {children}
      </div>
    </div>
  )
}
export const CustomPopperBody = ({
  children,
  open,
  position,
  setOpen,
  mainRef,
}) => {
  const isMounted = useIsMounted()
  const customPopperRef = useRef(null)
  const closeCallBack = useCallback(() => {
    const handleClickOutside = (event) => {
      if (
        open &&
        !mainRef?.current?.contains(event.target) &&
        !customPopperRef?.current?.contains(event.target)
      ) {
        setOpen(false)
        return
      }
    }
    document.addEventListener("click", handleClickOutside, false)
    return () => {
      document.removeEventListener("click", handleClickOutside, false)
    }
  }, [setOpen, mainRef, open])
  useEffect(() => {
    if (isMounted()) {
      closeCallBack()
    }
  }, [closeCallBack, isMounted])
  return (
    <div
      className="custom-popper-item-root position-relative"
      id="custom-popper"
      ref={customPopperRef}
    >
      {open ? (
        <div
          className={`custom-popper-item position-absolute m-0_5em-0 ${
            position === "right"
              ? "custom-popper-item-right-0"
              : "custom-popper-item-left-0"
          }`}
        >
          {children}
        </div>
      ) : null}
    </div>
  )
}
