import produce from "immer"

const initialState = {
  isLoading: true,
  error: null,
  eventTypes: [],
  services: [],
  servicesLoading: true,
  servicesError: null,
}

const EventTypesReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    const { type } = action
    switch (type) {
      case "EVENT_TYPES_LOADING":
        draft.isLoading = true
        draft.error = null
        break
      case "EVENT_TYPES_SUCCESS":
        draft.isLoading = false
        draft.error = null
        draft.eventTypes = action.payload
        break
      case "EVENT_TYPES_ERROR":
        draft.isLoading = false
        draft.error = action.payload
        break
      case "SERVICES_LOADING":
        draft.servicesLoading = true
        draft.servicesError = null
        break
      case "SERVICES_SUCCESS":
        draft.servicesLoading = false
        draft.servicesError = null
        draft.services = action.payload
        break
      case "SERVICES_ERROR":
        draft.servicesLoading = false
        draft.servicesError = action.payload
        break
      default:
        return state
    }
  })
}

export default EventTypesReducer
