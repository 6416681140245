import { Grid, Typography } from "@mui/material"
import { useMemo, useState } from "react"
import { CustomCarousel } from "components"
import { useWindow } from "hooks"
import { getYoutubeID } from "helpers"
import { NoDataAvailable } from "components"
import ReactImageVideoLightbox from "react-image-video-lightbox"

const ArtistVideosTabItem = ({ artistVideos }) => {
  const { isMobileView } = useWindow().windowData
  // combined videos
  const VIDEOS = useMemo(() => {
    return [...artistVideos?.artistVideos, ...artistVideos?.youtubeVideos]
  }, [artistVideos])
  const [startIndex, setStartIndex] = useState(null)
  return (
    <div>
      <Typography variant="h5" gutterBottom className="text-primary-2">
        All Videos
      </Typography>

      {VIDEOS?.length > 0 && (
        <>
          {isMobileView ? (
            <div className="artist-photos-container">
              {/* Carousel for mobile view */}
              <CustomCarousel>
                {VIDEOS?.map((vid, i) => (
                  <div
                    className="artist-videos-root cursor-pointer box-shadow-normal bg-whitesmoke m-r-1em"
                    key={vid?._id}
                    onClick={() => setStartIndex(i)}
                  >
                    {vid?.youtubeUrl ? (
                      <img
                        src={`https://img.youtube.com/vi/${getYoutubeID(
                          vid?.youtubeUrl
                        )}/0.jpg`}
                        alt=""
                        style={{
                          height: 200,
                          cursor: "pointer",
                        }}
                        className="border-5 object-fit-cover"
                      />
                    ) : (
                      <video
                        style={{
                          height: 200,
                          cursor: "pointer",
                        }}
                        className="border-5 object-fit-cover"
                        src={vid?.videoUrl}
                      ></video>
                    )}
                  </div>
                ))}
              </CustomCarousel>
            </div>
          ) : (
            <>
              {/*grid container web view videos */}
              <Grid container spacing={2} alignItems={"center"}>
                {VIDEOS?.map((vid, i) => (
                  <Grid item xs={12} md={4} sm={6} lg={4} key={vid?._id}>
                    <div
                      className="artist-videos-root cursor-pointer box-shadow-normal bg-whitesmoke"
                      onClick={() => setStartIndex(i)}
                      style={{
                        zIndex: 4000,
                        background: "#f6f6f6",
                        height: 200,
                      }}
                    >
                      {vid?.youtubeUrl ? (
                        <img
                          src={`https://img.youtube.com/vi/${getYoutubeID(
                            vid?.youtubeUrl
                          )}/0.jpg`}
                          alt=""
                          style={{
                            width: "100%",
                            height: 200,
                            cursor: "pointer",
                          }}
                          className="border-5 object-fit-cover"
                        />
                      ) : (
                        <video
                          style={{
                            width: "100%",
                            height: 200,
                            cursor: "pointer",
                          }}
                          className="border-5 object-fit-cover"
                          src={vid?.videoUrl}
                        ></video>
                      )}
                    </div>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </>
      )}
      {VIDEOS?.length === 0 && <NoDataAvailable />}

      {startIndex !== null && (
        <div className="zIndex-1000 position-absolute h-100 w-100">
          <ReactImageVideoLightbox
            data={VIDEOS?.map((vid) => ({
              url: vid?.youtubeUrl
                ? `https://www.youtube.com/embed/${getYoutubeID(
                    vid?.youtubeUrl
                  )}`
                : vid?.videoUrl,
              type: "video",
              title: "some video",
            }))}
            startIndex={startIndex}
            showResourceCount={true}
            onCloseCallback={() => setStartIndex(null)}
            onNavigationCallback={(currentIndex) =>
              console.log(`Current index: ${currentIndex}`)
            }
          />
        </div>
      )}
    </div>
  )
}

export default ArtistVideosTabItem
