import {
  CalendarTodayOutlined,
  CloseOutlined,
  EmailOutlined,
  LocationOnOutlined,
  PeopleAltOutlined,
  PhoneOutlined,
  WcOutlined,
} from "@mui/icons-material"
import { IconButton, Typography } from "@mui/material"

const ArtistViewManagerDetails = ({
  managerDetail,
  handleClose,
  managedArtists,
}) => {
  const MANAGER_DETAILS = [
    {
      id: "email",
      label: "Email",
      value: managerDetail?.email,
      icon: <EmailOutlined className="text-secondary" />,
    },
    {
      id: "phone",
      label: "Phone Number",
      value: managerDetail?.phoneNumber,
      icon: <PhoneOutlined className="text-secondary" />,
    },
    {
      id: "location",
      label: "Location",
      value: managerDetail?.location?.address,
      icon: <LocationOnOutlined className="text-secondary" />,
    },
    {
      id: "yearsOfExperience",
      label: "Years of Experience",
      value: managerDetail?.yearsOfExperience,
      icon: <CalendarTodayOutlined className="text-secondary" />,
    },
    {
      id: "gender",
      label: "Gender",
      value: managerDetail?.gender,
      icon: <WcOutlined className="text-secondary" />,
    },
    {
      id: "noOfArtistsManaged",
      label: "Number of Artists Managed",
      value: managedArtists?.length || "N/A",
      icon: <PeopleAltOutlined className="text-secondary" />,
    },
  ]
  return (
    <div className="artist-view-manager-detail-root h-100 bg-white w-100">
      <IconButton
        className="bg-whitesmoke"
        onClick={() => {
          handleClose()
        }}
      >
        <CloseOutlined />
      </IconButton>
      {/* header */}
      <div className="artist-view-manager-detail-header position-center flex-column">
        <div className="manager-avatar border-10 p-1em bg-primary-2 text-white position-center min-h-100px w-100px box-shadow-1">
          <Typography variant="h2" className="font-bold text-center">
            {managerDetail?.firstName?.slice(0, 1)?.toUpperCase()}
          </Typography>
        </div>
        <Typography variant="h6" className="font-bold text-center p-0_5em">
          {`${managerDetail?.firstName} ${managerDetail?.lastName}`}
        </Typography>
      </div>
      {/* body */}
      <div className="artist-view-manager-detail-body">
        {MANAGER_DETAILS?.map((detail) => (
          <div
            className="manager-detail-list-item p-1em bg-whitesmoke flex-row flex-align-start m-b-0_5em border-5"
            key={detail?.id}
          >
            <div className="manager-detail-list-item-icon">{detail?.icon}</div>
            <div className="manager-detail-list-item-content m-0-1em">
              <Typography variant="body1" className="font-bold">
                {detail?.label}
              </Typography>
              <Typography variant="body2" className="text-secondary">
                {detail?.value}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ArtistViewManagerDetails
