// selector & createSelector
// import from 'reselect'
import { lowerCaseText } from "helpers"
import { createSelector } from "reselect"
// export selector using createSelector & normal selector
export const getBookingArtist = (state) => state?.bookingArtistState
export const isChosePersonalizedMessage = createSelector(
  [getBookingArtist],
  (state) =>
    lowerCaseText(state?.serviceTypeName) === "personalized video message"
)
export const isChoseOtherCity = createSelector(
  [getBookingArtist],
  (state) => lowerCaseText(state?.eventLocation) === "other"
)

export const promoCodeAppliedUsersSelector = createSelector(
  [getBookingArtist],
  (state) => state?.promoCode?.appliedUsers
)

// all actions
export const setEventType = (eventType) => ({
  type: "SET_EVENT_TYPE",
  payload: eventType,
})
export const setServiceType = (serviceType) => ({
  type: "SET_SERVICE_TYPE",
  payload: serviceType,
})
export const setEventTypeName = (event) => ({
  type: "SET_EVENT_TYPE_NAME",
  payload: event,
})
export const setServiceTypeName = (service) => ({
  type: "SET_SERVICE_TYPE_NAME",
  payload: service,
})
export const setEventDate = (eventDate) => ({
  type: "SET_EVENT_DATE",
  payload: eventDate,
})
export const setEventTime = (eventTime) => ({
  type: "SET_EVENT_TIME",
  payload: eventTime,
})
export const setEventDuration = (eventDuration) => ({
  type: "SET_EVENT_DURATION",
  payload: eventDuration,
})
export const setCrowedSize = (crowedSize) => ({
  type: "SET_CROWED_SIZE",
  payload: crowedSize,
})
export const setEventLocation = (eventLocation) => ({
  type: "SET_EVENT_LOCATION",
  payload: eventLocation,
})
export const setLocDetailsAction = (locDetails) => ({
  type: "SET_LOC_DETAILS",
  payload: {
    lat: locDetails.lat,
    lng: locDetails.lng,
  },
})
export const setLocCountryAction = (country) => ({
  type: "SET_LOC_COUNTRY",
  payload: country,
})
export const setCityName = (cityName) => ({
  type: "SET_CITY_NAME",
  payload: cityName,
})
export const setIsPricingMatchAction = (isPricingMatch) => ({
  type: "SET_IS_PRICING_MATCH",
  payload: isPricingMatch,
})
export const setOtherDetails = (otherDetails) => ({
  type: "SET_OTHER_DETAILS",
  payload: otherDetails,
})
export const setPersonalizedMessage = (personalizedMessage) => ({
  type: "SET_PERSONALIZED_MESSAGE",
  payload: personalizedMessage,
})
export const setPersonalizedMessageDate = (personalizedMessageDate) => ({
  type: "SET_PERSONALIZED_MESSAGE_DATE",
  payload: personalizedMessageDate,
})
export const setArtistID = (id) => {
  return {
    type: "SET_ARTIST_ID",
    payload: id,
  }
}
// promo code
export const setPromoCodeAmount = (amount) => {
  return {
    type: "SET_PROMO_CODE_AMOUNT",
    payload: Math.floor(amount),
  }
}
export const setPromoCodeID = (id) => {
  return {
    type: "SET_PROMO_CODE_ID",
    payload: id,
  }
}
export const setPromoCode = (code, percentage, coupon) => {
  return {
    type: "SET_PROMO_CODE",
    payload: {
      code,
      percentage,
      maxCashBack: coupon?.maxCashBack,
      maxUse: coupon?.maxUse,
      appliedUsers: coupon?.appliedUser,
    },
  }
}
export const clearPromoCode = () => {
  return {
    type: "CLEAR_PROMO_CODE",
  }
}
// wallet amount
export const setWalletAmount = (walletAmount) => {
  return {
    type: "SET_WALLET_AMOUNT",
    payload: walletAmount,
  }
}
export const clearWalletAmount = () => {
  return {
    type: "CLEAR_WALLET_AMOUNT",
  }
}
// booking price
export const setBookingPrice = (price) => {

  return {
    type: "SET_BOOKING_PRICE",
    payload: price,
  }
}

// bank amount
export const setBankAmount = (amount) => {
  return {
    type: "SET_BANK_AMOUNT",
    payload: Math.floor(amount),
  }
}

// reset all details
export const resetBookingArtist = () => ({
  type: "RESET_BOOKING_ARTIST",
})

// reset other details which is not required after choosing event type "personalized message"
export const resetOtherDetails = () => ({
  type: "RESET_OTHER_DETAILS",
})

// pricing
export const setPricing = (pricing) => ({
  type: "SET_SELECTED_PRICING",
  payload: pricing,
})
