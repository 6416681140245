import { apiInstance } from "configs"
import { createSelector } from "reselect"
import { endAction, startAction } from "./LoaderAction"

export const personalizedMsgStateSelector = (state) =>
  state?.personalizedMessageState
export const getPersonalizedMessage = createSelector(
  [personalizedMsgStateSelector],
  (state) => state?.data
)

export const getLoadingPersonalizedMessage = createSelector(
  [personalizedMsgStateSelector],
  (personalizedMessageState) => personalizedMessageState?.isLoading
)

export const fetchPersonalizedMessages = (userID, type) => {
  return async (dispatch) => {
    try {
      await apiInstance
        .get(
          `personalize-video/all-personalize-video/${
            type ? type : "user"
          }/${userID}`
        )
        .then(({ data }) => {
          const { success } = data
          dispatch({
            type: "PERSONALIZED_MESSAGE_SUCCESS",
            payload: success?.data,
          })
        })
        .catch(({ response }) => {
          dispatch({
            type: "PERSONALIZED_MESSAGE_FAILURE",
            payload: response?.data?.error?.message,
          })
        })
    } catch (error) {
      dispatch({
        type: "PERSONALIZED_MESSAGE_FAILURE",
        payload: error.message,
      })
    }
  }
}

// remove action
export const removePersonalizedMessageSuccessSelector = createSelector(
  [personalizedMsgStateSelector],
  (state) => state?.removeAction?.isActionSuccess
)
export const removePersonalizedMessageErrorSelector = createSelector(
  [personalizedMsgStateSelector],
  (state) => state?.removeAction?.isActionError
)
export const resetRemovePersonalizedMessageAction = () => {
  return {
    type: "PERSONALIZED_MESSAGE_REMOVE_ACTION_RESET",
  }
}
export const removePersonalizedMessageAction =
  (userID, videoID) => async (dispatch) => {
    try {
      dispatch(startAction(`REMOVE_PERSONALIZED_MESSAGE_${videoID}`))
      dispatch(resetRemovePersonalizedMessageAction())
      await apiInstance
        .put(`personalize-video/delete`, {
          personalizeId: videoID,
          userId: userID,
        })
        .then(({ data }) => {
          dispatch({
            type: "PERSONALIZED_MESSAGE_REMOVE_ACTION_SUCCESS",
            payload: data?.success?.message,
          })
        })
        .catch(({ response }) => {
          dispatch({
            type: "PERSONALIZED_MESSAGE_REMOVE_ACTION_FAILURE",
            payload: response?.data?.error?.message,
          })
        })
        .finally(() => {
          dispatch(endAction(`REMOVE_PERSONALIZED_MESSAGE_${videoID}`))
        })
    } catch (error) {
      dispatch({
        type: "PERSONALIZED_MESSAGE_REMOVE_ACTION_FAILURE",
        payload: error.message,
      })
    }
  }
