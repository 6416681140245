import { apiInstance } from "configs"
import { endAction, startAction } from "./LoaderAction"

// remove manager by artist email
export const sendMail = (email, subject, message) => async (dispatch) => {
  dispatch(startAction(`SEND_EMAIL_${email}`))
  await apiInstance
    .post(`email/email`, {
      email,
      subject,
      text: message,
    })
    .then(({ data }) => {
      dispatch({
        type: "SEND_MAIL_SUCCESS",
        payload: data?.success?.message,
      })
    })
    .catch(({ response }) => {
      dispatch({
        type: "SEND_MAIL_ERROR",
        payload: response?.data?.error?.message,
      })
    })
    .finally(() => {
      dispatch(endAction(`SEND_EMAIL_${email}`))
      dispatch({
        type: "SEND_MAIL_RESET",
      })
    })
}
