import { Typography, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { HeroBgImg1 } from "assets";
import { HeroBgImg2, HeroBgImg3 } from "assets/heroBgImg";
import { useEffect, useMemo, useState } from "react";
const HeroSection = () => {
  const navigate = useNavigate();
  const [imgInd, setImgInd] = useState(0);

  const bgImages = useMemo(
    () => [
      {
        id: "1",
        src: HeroBgImg1,
      },
      {
        id: "2",
        src: HeroBgImg2,
      },
      {
        id: "3",
        src: HeroBgImg3,
      },
    ],
    []
  );

  useEffect(() => {
    let isMounted = true;
    const interval = setInterval(() => {
      if (imgInd === bgImages?.length - 1) {
        isMounted && setImgInd(0);
      } else {
        isMounted && setImgInd(imgInd + 1);
      }
    }, 7000);
    return () => {
      isMounted = false;
      clearInterval(interval);
    };
  }, [bgImages, imgInd]);

  return (
    <>
      <div className="hero-section-root position-relative">
        <div className="position-relative">
          <div className="hero-section-img-root h-85vh text-center overflow-hidden flex-row">
            <img
              src={bgImages?.[imgInd].src}
              alt="hero-section-img"
              width={"100%"}
              className={`object-fit-cover`}
            />
          </div>
          <div className="hero-section-img-overlay"></div>
        </div>
        <Container>
          <div className="hero-search-bar-section flex-column flex-justify-start flex-align-start">
            <div>
              <Typography
                variant="h3"
                className="font-bold"
                color="var(--col-white)"
                component="h1"
              >
                Book Your Best Artists
              </Typography>
              <Typography
                variant="h6"
                color="var(--col-whitesmoke)"
                component="h4"
              >
                Let's Make Your Events, Celebrations More Amazing
              </Typography>
            </div>
            <div className="hero-search-field border-25">
              <input
                variant="contained"
                className="bg-whitesmoke font-size-1_2em text-black w-100 p-1em border-25 outline-none border-none"
                placeholder="Search for a live performer"
                readOnly
                onClick={() => {
                  navigate("/artists/all");
                }}
              />
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default HeroSection;
