import { CheckCircle, Close } from "@mui/icons-material"
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material"
import { currencies } from "configs"
import { useCurrencyContext } from "hooks"
import React from "react"

const CurrencyPicker = () => {
  const {
    selectedCurrency,
    handleSelectCurrency,
    openCurrencyPicker,
    onCloseCurrencyPicker,
  } = useCurrencyContext()

  return (
    <Dialog
      open={openCurrencyPicker}
      fullWidth
      maxWidth="sm"
      className="currency-dialog-wrapper"
    >
      <DialogContent>
        <div className="currency-dialog-header flex">
          <Typography variant="h5" color="initial">
            Currency
          </Typography>
          <IconButton
            onClick={() => onCloseCurrencyPicker()}
            className="float-right"
          >
            <Close />
          </IconButton>
        </div>
        {currencies?.length > 0 ? (
          <Grid container spacing={2}>
            {currencies?.map((currency) => (
              <Grid item xs={12} sm={6} key={currency?.alpha2Code}>
                <div
                  className={
                    selectedCurrency?.alpha2Code === currency?.alpha2Code
                      ? "currency-card active-color"
                      : "currency-card"
                  }
                  role="button"
                  onClick={() => handleSelectCurrency(currency)}
                >
                  <div className="currency-list-item">
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${currency?.alpha2Code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${currency?.alpha2Code.toLowerCase()}.png 20x`}
                      alt=""
                    />
                    <Typography variant="body1" color="initial">
                      {currency?.["Currency Name"]}
                    </Typography>
                  </div>
                  {currency?.code && currency?.symbol && (
                    <div>
                      <Typography
                        variant="body1"
                        color="initial"
                        className="font-bold"
                      >
                        {`${currency?.code} - ${currency?.symbol}`}
                      </Typography>
                    </div>
                  )}
                  {selectedCurrency?.alpha2Code === currency?.alpha2Code && (
                    <IconButton className="currency-checked">
                      <CheckCircle />
                    </IconButton>
                  )}
                </div>
              </Grid>
            ))}
          </Grid>
        ) : (
          <div className="empty-result-currency">
            <h4 style={{ color: "grey", fontWeight: 200 }}>
              No results found.
            </h4>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default CurrencyPicker
