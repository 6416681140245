import { useCallback, useEffect, useRef } from "react"

const useIsMounted = () => {
  // Create a ref that stores your value
  const isMounted = useRef(false)
  //   useEffect fro mounted and unmounted
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [isMounted])
  //   return callback
  return useCallback(() => isMounted.current, [isMounted])
}

export default useIsMounted
