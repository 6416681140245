import { Star } from "@mui/icons-material"
import { Avatar, Button, Grid, Typography } from "@mui/material"
// import from other files
import { CustomPagination, Loader } from "components"
import { getText, hideDivider, getTimeFromNow } from "helpers"
import { NO_DATA_IMG } from "assets"
const UserReviewsBody = ({
  reviewsData,
  isLoadingReviews,
  currentData,
  choseReviews,
  handleViewMore,
  setCurrentData,
  itemsPerPage,
}) => {
  return (
    <div className="reviews-body-root">
      {reviewsData?.length > 0 && !isLoadingReviews && (
        <>
          {currentData?.map((review, r_index) => (
            <div className="m-2em-0" key={review?._id}>
              <Grid container spacing={2} alignItems={"flex-start"}>
                {/* list item for user and given star */}
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <div className="review-given-user-root flex-row flex-align-start col-gap-0_5em w-100">
                    <div className="review-given-user-root-img">
                      <Avatar
                        className="bg-primary-8 text-col-white"
                        src={review?.user?.profileImageUrl}
                      >
                        {review?.user?.firstName?.charAt(0)}
                      </Avatar>
                    </div>
                    <div className="review-given-user-root-name ">
                      <Typography variant="body1" className="font-bold">
                        {getText(
                          `${review?.user?.firstName} ${review?.user?.lastName}`,
                          35
                        )}
                      </Typography>
                      <Button
                        className="bg-whitesmoke text-primary-3 p-0 font-bold"
                        startIcon={<Star />}
                      >
                        {review?.ratings}
                      </Button>
                    </div>
                  </div>
                </Grid>
                {/* review body */}
                <Grid item xs={12} sm={12} md={7} lg={7} className="w-100">
                  <div className="review-body">
                    <Typography
                      variant="body1"
                      className="font-bold"
                      gutterBottom
                    >
                      {review?.title}
                    </Typography>
                    <div className="review-all-description">
                      <Typography variant="body2">
                        {choseReviews?.includes(review)
                          ? review?.description
                          : getText(review?.description, 150)}
                      </Typography>
                      {review?.description?.length > 150 && (
                        <Button
                          onClick={() => {
                            handleViewMore(review)
                          }}
                          variant="text"
                          className="text-primary-2 font-bold text-transform-none p-0 font-size-small"
                        >
                          {choseReviews?.includes(review)
                            ? "View Less"
                            : "View More"}
                        </Button>
                      )}
                    </div>
                  </div>
                </Grid>
                {/* review date */}
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <div className="review-date bg-whitesmoke p-0_5em border-5">
                    <Typography
                      variant="body2"
                      className="font-bold text-primary-2 "
                    >
                      {getTimeFromNow(review?.timestamp)}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <div className="divider m-1-0">
                {hideDivider(r_index, reviewsData?.length)}
              </div>
            </div>
          ))}
          {/* pagination for reviews */}
          <div
            className={`review-pagination ${
              reviewsData?.length > itemsPerPage ? "" : "display-none"
            }`}
          >
            <CustomPagination
              data={reviewsData}
              setCurrentPageData={setCurrentData}
              itemsPerPage={itemsPerPage}
            />
          </div>
        </>
      )}
      {isLoadingReviews && <Loader />}
      {!reviewsData?.length > 0 && !isLoadingReviews && (
        <div className="position-center flex-column text-center">
          <img
            src={NO_DATA_IMG}
            alt=""
            height={"250px"}
            className="max-width-100 object-fit-cover"
          />
          <Typography variant="h5">No reviews yet</Typography>
        </div>
      )}
    </div>
  )
}

export default UserReviewsBody
