import { getSelectedItems } from "helpers"

// get the filter state
export const getFilterState = (state) => state?.artistFilterState

// select multiple filter categories
export const setFilterCategories = (categories, category) => {
  return {
    type: "SET_FILTER_CATEGORIES",
    payload: getSelectedItems(categories, category),
  }
}
// select multiple events categories
export const setEvents = (events, event) => {
  return {
    type: "SET_FILTER_EVENTS",
    payload: getSelectedItems(events, event),
  }
}
// select multiple services
export const setServices = (services, service) => {
  return {
    type: "SET_FILTER_SERVICES",
    payload: getSelectedItems(services, service),
  }
}
// select multiple ratings
export const setRatings = (ratings, rating) => {
  return {
    type: "SET_FILTER_RATINGS",
    payload: getSelectedItems(ratings, rating),
  }
}

// select multiple languages

export const setLanguages = (languages, language) => {
  return {
    type: "SET_FILTER_LANGUAGES",
    payload: getSelectedItems(languages, language),
  }
}

//  set gender
export const setGender = (gender) => {
  return {
    type: "SET_FILTER_GENDER",
    payload: gender,
  }
}

//  set price range
export const setPriceRange = (min, max) => {
  return {
    type: "SET_FILTER_PRICE_RANGE",
    payload: [min, max],
  }
}

//  set location
export const setFilterLocation = (location) => {
  return {
    type: "SET_FILTER_LOCATION",
    payload: location,
  }
}

// reset filter
export const resetFilter = () => {
  return {
    type: "RESET_FILTER",
  }
}
