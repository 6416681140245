import { createSelector } from "reselect"

// selector
// -----------------------------------------------Selectors-------------------------------------------------------
export const headerSearchStateSelector = (state) => state?.headerSearchState
export const headerSearchValueSelector = createSelector(
  [headerSearchStateSelector],
  (headerSearchState) => headerSearchState?.searchValue
)
export const headerSearchLocationSelector = createSelector(
  [headerSearchStateSelector],
  (headerSearchState) => headerSearchState?.location
)
export const headerAddressSelector = createSelector(
  [headerSearchStateSelector],
  (headerSearchState) => headerSearchState?.address
)
export const headerCountrySelector = createSelector(
  [headerSearchStateSelector],
  (headerSearchState) => headerSearchState?.country
)
export const headerRangeSelector = createSelector(
  [headerSearchStateSelector],
  (headerSearchState) => headerSearchState?.range
)
export const headerCurrentLocationSelector = createSelector(
  [headerSearchStateSelector],
  (headerSearchState) => headerSearchState?.currentLocation
)

// ---------------------------------------------------Actions------------------------------------------------------
export const setSearchValue = (value) => {
  return {
    type: "SET_SEARCH_VALUE",
    payload: value,
  }
}
export const setSearchLocation = ({ lat, lng }) => {
  return {
    type: "SET_LOCATION",
    payload: {
      lat,
      lng,
    },
  }
}
export const setSearchAddress = (address) => {
  return {
    type: "SET_ADDRESS",
    payload: address,
  }
}
export const setHeaderCountry = (country) => {
  return {
    type: "SET_COUNTRY",
    payload: country,
  }
}
export const setHeaderSearchRange = (range) => {
  return {
    type: "SET_RANGE",
    payload: range,
  }
}
export const setHeaderCurrentLocation = ({ lat, lng, address, isChosen }) => {
  return {
    type: "SET_CURRENT_LOCATION",
    payload: {
      lat,
      lng,
      address,
      isChosen,
    },
  }
}
export const setHeaderCity = (city) => {
  return {
    type: "SET_CITY",
    payload: city,
  }
}
// -----------------------------------------------------------RESET_VALUES----------------------------------------------------
// reset search value
export const resetSearchValue = () => {
  return {
    type: "RESET_HEADER_SEARCH",
  }
}
// reset search location
export const resetSearchLocation = () => {
  return {
    type: "RESET_HEADER_SEARCH_LOCATION",
  }
}
export const resetHeaderSearchRange = () => {
  return {
    type: "RESET_HEADER_SEARCH_RANGE",
  }
}
export const resetHeaderCurrentLocation = () => {
  return {
    type: "RESET_HEADER_SEARCH_CURRENT_LOCATION",
  }
}

export const resetHeaderSearchCity = () => {
  return {
    type: "RESET_HEADER_SEARCH_CITY",
  }
}
