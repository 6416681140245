import { Grid, MenuItem, TextField, Typography } from "@mui/material"
import { Loader } from "components"
import { useEventTypes, useWindow } from "hooks"
import {
  setEventType,
  setEventTypeName,
  setServiceType,
  setServiceTypeName,
} from "redux/actions"

const ChoseEventType = ({ bookingArtistState, dispatch }) => {
  const { isMobileView } = useWindow().windowData
  const { allEventTypes, allServices, isLoadingEventTypes, isLoadingServices } =
    useEventTypes()

  return (
    <div className={"m-1-0"}>
      {(isLoadingEventTypes || isLoadingServices) && (
        <div className="booking-artist-event-type-select-box position-center h-50vh">
          <Loader isCircularLoader />
        </div>
      )}
      {!isLoadingEventTypes && !isLoadingServices && (
        <>
          {isMobileView ? (
            <>
              {/* event types */}
              <div className="booking-artist-event-type-select-box booking-artist-input-root-width">
                <Typography variant="body1" gutterBottom>
                  Choose Event Type
                </Typography>
                <TextField
                  select
                  value={bookingArtistState?.eventType || ""}
                  onChange={(e) => {
                    dispatch(setEventType(e.target.value))
                    dispatch(
                      setEventTypeName(
                        allEventTypes?.find((i) => i?._id === e.target.value)
                          ?.eventName
                      )
                    )
                  }}
                  fullWidth
                  InputProps={{
                    classes: {
                      root: "border-10",
                    },
                  }}
                  SelectProps={{
                    defaultValue: bookingArtistState?.eventType || "",
                  }}
                >
                  {allEventTypes?.map((event) => (
                    <MenuItem value={event?._id} key={event?._id}>
                      {event?.eventName}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              {/* service types */}
              <div className="booking-artist-event-type-select-box booking-artist-input-root-width m-1-0">
                <Typography variant="body1" gutterBottom>
                  Choose Service Type
                </Typography>
                <TextField
                  select
                  value={bookingArtistState?.serviceType || ""}
                  onChange={(e) => {
                    dispatch(setServiceType(e.target.value))
                    dispatch(
                      setServiceTypeName(
                        allServices?.find((i) => i?._id === e.target.value)
                          ?.serviceName
                      )
                    )
                  }}
                  fullWidth
                  InputProps={{
                    classes: {
                      root: "border-10",
                    },
                  }}
                  SelectProps={{
                    defaultValue: bookingArtistState?.serviceType || "",
                  }}
                >
                  {allServices?.map((service) => (
                    <MenuItem value={service?._id} key={service?._id}>
                      {service?.serviceName}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </>
          ) : (
            <>
              {/* event types */}
              <div>
                <Typography
                  variant={isMobileView ? "body1" : "h6"}
                  className="p-0_5em"
                >
                  Choose Event Type
                </Typography>
                <Grid
                  container
                  spacing={2}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  {allEventTypes?.map((event) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      key={event?._id}
                      className="h-100"
                    >
                      <div
                        className={`booking-artist-event-type-card text-center bg-whitesmoke w-100 p-2em-1em cursor-pointer border-10 ${
                          bookingArtistState?.eventType === event?._id
                            ? "booking-artist-event-type-card-active"
                            : ""
                        }`}
                        onClick={() => {
                          dispatch(setEventType(event?._id))
                          dispatch(
                            setEventTypeName(
                              allEventTypes?.find((i) => i?._id === event?._id)
                                ?.eventName
                            )
                          )
                        }}
                      >
                        <Typography variant="body1" className="text-ellipses">
                          {event?.eventName}
                        </Typography>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
              {/* services after event type */}
              <div className="booking-service-type">
                <Typography
                  variant={isMobileView ? "body1" : "h6"}
                  className="p-0_5em"
                >
                  Choose Service Type
                </Typography>
                <Grid container spacing={2} justifyContent={"center"}>
                  {allServices?.map((service) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={service?._id}>
                      <div
                        className={`booking-artist-event-type-card  bg-whitesmoke text-center w-100 p-1em cursor-pointer border-10 h-100 position-center ${
                          bookingArtistState?.serviceType === service?._id
                            ? "booking-artist-event-type-card-active"
                            : ""
                        }`}
                        onClick={() => {
                          dispatch(setServiceType(service?._id))
                          dispatch(
                            setServiceTypeName(
                              allServices?.find((i) => i?._id === service?._id)
                                ?.serviceName
                            )
                          )
                        }}
                      >
                        <Typography variant="body1">
                          {service?.serviceName}
                        </Typography>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default ChoseEventType
