import { Typography } from "@mui/material"
import { NO_DATA_IMG } from "assets"

const NoDataAvailable = () => {
  return (
    <div className="p-1em-0 w-100 position-center">
      <div className="flex-column flex-align-center text-center w-100 ">
        <img src={NO_DATA_IMG} alt="" height="190px" />
        <Typography className="font-size-1em">No Data Available</Typography>
      </div>
    </div>
  )
}

export default NoDataAvailable
