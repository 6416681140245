import produce from "immer"

const initialState = {
  promoCode: {
    isLoading: true,
    error: null,
    data: [],
  },
  applyPromoCode: {
    actionSuccessMsg: "",
    actionErrorMsg: "",
  },
}
const PromoCodeReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action
    const { promoCode, applyPromoCode } = draft
    switch (type) {
      case "PROMO_CODES_LOADING":
        promoCode.isLoading = true
        promoCode.error = null
        break
      case "PROMO_CODES_SUCCESS":
        promoCode.isLoading = false
        promoCode.error = null
        promoCode.data = payload
        break
      case "PROMO_CODES_ERROR":
        promoCode.isLoading = false
        promoCode.error = payload
        break
      // apply promo code
      case "APPLY_PROMO_CODE_SUCCESS":
        applyPromoCode.actionSuccessMsg = payload
        break
      case "APPLY_PROMO_CODE_ERROR":
        applyPromoCode.actionErrorMsg = payload
        break
      case "CLEAR_APPLY_PROMO_CODE_MSG":
        applyPromoCode.actionSuccessMsg = ""
        applyPromoCode.actionErrorMsg = ""
        break
      default:
        return draft
    }
  })

export default PromoCodeReducer
