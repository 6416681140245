import produce from "immer"

const initialState = {
  wallet: {
    balance: 0,
    totalSpent: 0,
    isLoading: true,
    error: null,
  },
  walletHistory: {
    history: [],
    isLoading: false,
    error: null,
  },
  updateWallet: {
    actionSuccessMsg: null,
    actionErrorMsg: null,
  },
}

const WalletReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action
    const { wallet, walletHistory, updateWallet } = draft
    switch (type) {
      case "LOAD_WALLET_BALANCE":
        wallet.isLoading = true
        wallet.error = null
        break
      case "LOAD_WALLET_BALANCE_SUCCESS":
        wallet.balance = payload.balance
        wallet.totalSpent = payload.totalSpent
        wallet.isLoading = false
        wallet.error = null
        break
      case "LOAD_WALLET_BALANCE_FAILURE":
        wallet.isLoading = false
        wallet.error = payload.error
        break
      case "LOAD_WALLET_HISTORY":
        walletHistory.isLoading = true
        walletHistory.error = null
        break
      case "LOAD_WALLET_HISTORY_SUCCESS":
        walletHistory.history = payload
        walletHistory.isLoading = false
        walletHistory.error = null
        break
      case "LOAD_WALLET_HISTORY_FAILURE":
        walletHistory.isLoading = false
        walletHistory.error = payload
        break
      // update wallet
      case "UPDATE_WALLET_BALANCE_SUCCESS":
        updateWallet.actionSuccessMsg = payload
        break
      case "UPDATE_WALLET_BALANCE_FAILURE":
        updateWallet.actionErrorMsg = payload
        break
      case "UPDATE_WALLET_BALANCE_CLEAR":
        updateWallet.actionSuccessMsg = null
        updateWallet.actionErrorMsg = null
        break
      default:
        return state
    }
  })
export default WalletReducer
