// import from packages
import produce from "immer"

// initial state fro booking artist
const bookingArtistInitialState = {
  eventType: "",
  eventTypeName: "",
  serviceType: "",
  serviceTypeName: "",
  eventDate: {
    start: "",
    end: "",
  },
  eventTime: "",
  eventDuration: "",
  crowdSize: "",
  eventLocation: "",
  cityName: "",
  locDetails: {
    lat: 0,
    lng: 0,
    country: "",
  },
  otherDetails: "",
  personalizedMessage: "",
  personalizedMsgDate: "",
  promoCodeAmount: 0,
  promoCodeID: "",
  promoCode: {
    percentage: 0,
    code: "",
    appliedUsers: [],
    maxUse: 0,
    maxCashBack: 0,
  },
  bankAmount: 0,
  walletAmount: 0,
  bookingPrice: 0,
  artistID: "",
  isPricingMatch: false,
  selectedPricing: {},
}

// reducer for booking artist
const BookingArtistReducer = (state = bookingArtistInitialState, action) => {
  return produce(state, (draft) => {
    const { locDetails } = draft
    const { payload } = action
    switch (action.type) {
      case "SET_EVENT_TYPE":
        draft.eventType = action.payload
        break
      case "SET_SERVICE_TYPE":
        draft.serviceType = action.payload
        break
      case "SET_EVENT_TYPE_NAME":
        draft.eventTypeName = action.payload
        break
      case "SET_SERVICE_TYPE_NAME":
        draft.serviceTypeName = action.payload
        break
      case "SET_EVENT_DATE":
        draft.eventDate = action.payload
        break
      case "SET_PERSONALIZED_MESSAGE_DATE":
        draft.personalizedMsgDate = action.payload
        break
      case "SET_EVENT_TIME":
        draft.eventTime = action.payload
        break
      case "SET_EVENT_DURATION":
        draft.eventDuration = action.payload
        break
      case "SET_CROWED_SIZE":
        draft.crowdSize = action.payload
        break
      case "SET_EVENT_LOCATION":
        draft.eventLocation = action.payload
        break
      case "SET_CITY_NAME":
        draft.cityName = action.payload
        break
      case "SET_LOC_DETAILS":
        locDetails.lat = payload.lat
        locDetails.lng = payload.lng
        break
      case "SET_LOC_COUNTRY":
        locDetails.country = payload
        break
      case "SET_IS_PRICING_MATCH":
        draft.isPricingMatch = action.payload
        break
      case "SET_OTHER_DETAILS":
        draft.otherDetails = action.payload
        break
      case "SET_PERSONALIZED_MESSAGE":
        draft.personalizedMessage = action.payload
        break

      // promo code
      case "SET_PROMO_CODE_AMOUNT":
        draft.promoCodeAmount = action.payload
        break
      case "SET_PROMO_CODE_ID":
        draft.promoCodeID = action.payload
        break
      case "SET_PROMO_CODE":
        draft.promoCode.percentage = action.payload.percentage
        draft.promoCode.code = action.payload.code
        draft.promoCode.appliedUsers = action.payload.appliedUsers
        draft.promoCode.maxUse = action.payload.maxUse
        draft.promoCode.maxCashBack = action.payload.maxCashBack
        break
      // wallet and bank
      case "SET_BANK_AMOUNT":
        draft.bankAmount = action.payload
        break
      case "SET_WALLET_AMOUNT":
        draft.walletAmount = action.payload
        break
      case "SET_BOOKING_PRICE":
        draft.bookingPrice = action.payload
        break
      case "SET_ARTIST_ID":
        draft.artistID = action.payload
        break
      // pricing
      case "SET_SELECTED_PRICING":
        draft.selectedPricing = action.payload
        break
      // reset or clear
      case "CLEAR_PROMO_CODE":
        draft.promoCode.percentage = 0
        draft.promoCode.code = ""
        draft.promoCodeAmount = 0
        draft.promoCodeID = ""
        draft.promoCode.appliedUsers = []
        draft.promoCode.maxUse = 0
        draft.promoCode.maxCashBack = 0
        break
      case "CLEAR_WALLET_AMOUNT":
        draft.walletAmount = 0
        break
      case "CLEAR_BOOKING_PRICE":
        draft.bookingPrice = 0
        break
      case "CLEAR_BOOKING_AMOUNT":
        draft.walletAmount = 0
        break
      case "CLEAR_BANK_AMOUNT":
        draft.bankAmount = 0
        break
      case "RESET_BOOKING_ARTIST":
        return bookingArtistInitialState
      case "RESET_OTHER_DETAILS":
        draft.cityName = ""
        draft.crowdSize = ""
        draft.eventDuration = ""
        draft.eventLocation = ""
        draft.eventTime = ""
        draft.otherDetails = ""
        draft.eventDate = {
          start: "",
          end: "",
        }
        locDetails.lat = 0
        locDetails.lng = 0
        locDetails.country = ""
        break
      default:
        return state
    }
  })
}

export default BookingArtistReducer
