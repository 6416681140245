import produce from "immer"

const initialState = {
  users: [],
  loading: true,
  error: null,
  userDetails: {
    isLoading: true,
    error: null,
    user: {},
  },
  topSearch: {
    isLoading: true,
    error: null,
    data: [],
  },
}

const UsersReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    const { userDetails, topSearch } = draft
    switch (action.type) {
      case "FETCH_USERS_START":
        draft.loading = true
        draft.error = null
        draft.users = []
        break
      case "FETCH_USERS_SUCCESS":
        draft.loading = false
        draft.error = null
        draft.users = action.payload
        break
      case "FETCH_USERS_FAILURE":
        draft.loading = false
        draft.error = action.payload
        draft.users = []
        break
      case "USER_DETAILS_LOADING":
        userDetails.isLoading = true
        userDetails.error = null
        userDetails.user = {}
        break
      case "USER_DETAILS_SUCCESS":
        userDetails.isLoading = false
        userDetails.error = null
        userDetails.user = action.payload
        break
      case "USER_DETAILS_FAILURE":
        userDetails.isLoading = false
        userDetails.error = action.payload
        userDetails.user = {}
        break
      case "USER_DETAILS_CLEAR":
        userDetails.isLoading = false
        userDetails.error = null
        userDetails.user = {}
        break
      // Top Search
      case "TOP_SEARCH_LOADING":
        topSearch.isLoading = true
        topSearch.error = null
        topSearch.data = []
        break
      case "TOP_SEARCH_SUCCESS":
        topSearch.isLoading = false
        topSearch.error = null
        topSearch.data = action.payload
        break
      case "TOP_SEARCH_FAILURE":
        topSearch.isLoading = false
        topSearch.error = action.payload
        topSearch.data = []
        break
      case "TOP_SEARCH_CLEAR":
        topSearch.isLoading = false
        topSearch.error = null
        topSearch.data = []
        break
      default:
        return state
    }
  })
}

export default UsersReducer
