import { apiInstance } from "configs"
import { createSelector } from "reselect"
import { endAction, startAction } from "./LoaderAction"

export const blockedDatesStateSelector = (state) => state?.blockedDatesState
export const blockedDatesSelector = createSelector(
  [blockedDatesStateSelector],
  (blockedDatesState) => blockedDatesState?.blockedDates
)
export const loadingBlockDatesSelector = createSelector(
  [blockedDatesStateSelector],
  (blockedDatesState) => blockedDatesState?.isLoading
)

export const fetchBlockedDates = (userID) => async (dispatch) => {
  try {
    // dispatch({
    //   type: "BLOCKED_DATES_LOADING",
    // })
    await apiInstance
      .get(`artist-block-date/all-block-date/${userID}`)
      .then(({ data }) => {
        const { success } = data
        dispatch({
          type: "BLOCKED_DATES_SUCCESS",
          payload: success?.data,
        })
      })
      .catch(({ response }) => {
        dispatch({
          type: "BLOCKED_DATES_ERROR",
          payload: response?.data?.error?.message,
        })
      })
  } catch (error) {
    dispatch({
      type: "BLOCKED_DATES_ERROR",
      payload: error.message,
    })
  }
}

// add blocked dates
export const blockedDatesSuccessSelector = createSelector(
  [blockedDatesStateSelector],
  (blockedDatesState) => blockedDatesState?.addBlockDates?.isDone
)
export const blockedDatesErrorSelector = createSelector(
  [blockedDatesStateSelector],
  (blockedDatesState) => blockedDatesState?.addBlockDates?.isErr
)
export const addBlockedDates = (artistID, blockedDates) => async (dispatch) => {
  dispatch(startAction(artistID))
  await apiInstance
    .post(`artist-block-date/create`, {
      artistId: artistID,
      datesArray: blockedDates,
    })
    .then(({ data }) => {
      const { success } = data
      success?.message &&
        dispatch({
          type: "ADD_BLOCKED_DATES_SUCCESS",
        })
    })
    .catch(({ response }) => {
      const errMsg = response?.data?.error?.message
      errMsg &&
        dispatch({
          type: "ADD_BLOCKED_DATES_ERROR",
        })
    })
    .finally(() => {
      dispatch(endAction(artistID))
    })
}

// remove block dates
export const removeBlockDatesSelector = createSelector(
  [blockedDatesStateSelector],
  (blockedDatesState) => blockedDatesState?.removeBlockDates?.isRemoved
)
export const removeBlockDatesErrorSelector = createSelector(
  [blockedDatesStateSelector],
  (blockedDatesState) => blockedDatesState?.removeBlockDates?.errMsg
)
export const removeBlockDates = (blockDates) => async (dispatch) => {
  for (let d of blockDates) {
    dispatch(startAction(d))
  }
  dispatch({
    type: "REMOVE_BLOCKED_DATES_SUCCESS",
    payload: false,
  })
  dispatch({
    type: "REMOVE_BLOCKED_DATES_ERROR",
    payload: false,
  })
  await apiInstance
    .put(`artist-block-date/delete`, {
      blockDateIds: blockDates,
    })
    .then(({ data }) => {
      const { success } = data
      success?.message &&
        dispatch({
          type: "REMOVE_BLOCKED_DATES_SUCCESS",
          payload: success?.message ?? true,
        })
    })
    .catch(({ response }) => {
      const errMsg = response?.data?.error?.message
      dispatch({
        type: "REMOVE_BLOCKED_DATES_ERROR",
        payload: {
          isErr: true,
          errMsg,
        },
      })
    })
    .finally(() => {
      for (let d of blockDates) {
        dispatch(endAction(d))
      }
    })
}
