import { createSelector } from "reselect"

export const uiActionStateSelector = (state) => state?.loaderState
export const loaderActionsSelector = createSelector(
  [uiActionStateSelector],
  (loaderState) => loaderState?.uiAction?.loader
)

export const startAction = (name) => {
  return {
    type: `START_ACTION`,
    payload: {
      name,
    },
  }
}

export const endAction = (name) => {
  return {
    type: `END_ACTION`,
    payload: {
      name,
    },
  }
}
