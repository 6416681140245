import produce from "immer"

const bookingStates = {
  isLoading: true,
  error: null,
  bookings: [],
}
const actionState = {
  isActionSuccess: false,
  isActionError: false,
  actionSuccessMsg: null,
  actionErrorMsg: null,
}
const initialState = {
  userBookings: Object.assign({}, bookingStates),
  artistBookings: Object.assign({}, bookingStates),
  removeBookings: {
    isRemoving: false,
    error: null,
    isRemoved: false,
  },
  isArtistOnUserBookings: false,
  bookingDetails: {
    open: false,
    booking: {},
  },
  cancelBooking: Object.assign({}, actionState),
  confirmEventDone: Object.assign({}, actionState),
  validateBooking: Object.assign({}, actionState),
}
const BookingsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action
    const {
      userBookings,
      artistBookings,
      removeBookings,
      bookingDetails,
      cancelBooking,
      confirmEventDone,
      validateBooking,
    } = draft
    switch (type) {
      case "USER_BOOKINGS_LOADING":
        userBookings.isLoading = true
        userBookings.error = null
        break
      case "USER_BOOKINGS_SUCCESS":
        userBookings.bookings = payload
        userBookings.isLoading = false
        userBookings.error = null
        break
      case "USER_BOOKINGS_ERROR":
        userBookings.isLoading = false
        userBookings.error = payload
        break
      case "ARTIST_BOOKINGS_LOADING":
        artistBookings.isLoading = true
        artistBookings.error = null
        break
      case "ARTIST_BOOKINGS_SUCCESS":
        artistBookings.bookings = payload
        artistBookings.isLoading = false
        artistBookings.error = null
        break
      case "ARTIST_BOOKINGS_ERROR":
        artistBookings.isLoading = false
        artistBookings.error = payload
        break

      // remove bookings
      case "REMOVE_BOOKINGS_LOADING":
        removeBookings.isRemoving = true
        break
      case "REMOVE_BOOKINGS_SUCCESS":
        removeBookings.isRemoving = false
        removeBookings.error = null
        removeBookings.isRemoved = true
        break
      case "REMOVE_BOOKINGS_ERROR":
        removeBookings.isRemoving = false
        removeBookings.error = payload
        removeBookings.isRemoved = false
        break
      case "REMOVE_BOOKINGS_RESET":
        removeBookings.isRemoving = false
        removeBookings.error = null
        removeBookings.isRemoved = false
        break
      // check if artist is on user bookings
      case "IS_ARTIST_ON_USER_BOOKINGS":
        draft.isArtistOnUserBookings = payload
        break
      // open booking details
      case "OPEN_BOOKING_DETAILS":
        bookingDetails.open = true
        bookingDetails.booking = payload
        break
      case "CLOSE_BOOKING_DETAILS":
        bookingDetails.open = false
        bookingDetails.booking = {}
        break

      // cancel booking
      case "CANCEL_BOOKING_SUCCESS":
        cancelBooking.isActionSuccess = true
        cancelBooking.actionSuccessMsg = payload
        break
      case "CANCEL_BOOKING_ERROR":
        cancelBooking.isActionError = true
        cancelBooking.actionErrorMsg = payload
        break
      case "CANCEL_BOOKING_RESET":
        cancelBooking.isActionSuccess = false
        cancelBooking.actionSuccessMsg = null
        cancelBooking.isActionError = false
        cancelBooking.actionErrorMsg = null
        break
      // confirm event done
      case "CONFIRM_EVENT_DONE_SUCCESS":
        confirmEventDone.isActionSuccess = true
        confirmEventDone.actionSuccessMsg = payload
        break
      case "CONFIRM_EVENT_DONE_ERROR":
        confirmEventDone.isActionError = true
        confirmEventDone.actionErrorMsg = payload
        break
      case "CONFIRM_EVENT_DONE_RESET":
        confirmEventDone.isActionSuccess = false
        confirmEventDone.actionSuccessMsg = null
        confirmEventDone.isActionError = false
        confirmEventDone.actionErrorMsg = null
        break

      // validate booking
      case "VALIDATE_BOOKING_SUCCESS":
        validateBooking.isActionSuccess = true
        validateBooking.actionSuccessMsg = payload
        break
      case "VALIDATE_BOOKING_ERROR":
        validateBooking.isActionError = true
        validateBooking.actionErrorMsg = payload
        break
      case "VALIDATE_BOOKING_RESET":
        validateBooking.isActionSuccess = false
        validateBooking.actionSuccessMsg = null
        validateBooking.isActionError = false
        validateBooking.actionErrorMsg = null
        break
      default:
        return draft
    }
  })

export default BookingsReducer
