import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
// import from other files
import { CustomDialogBox, Loader } from "components";
import { getAuthState } from "redux/actions";
import { useEffect } from "react";
import { Pending } from "@mui/icons-material";

const ArtistRoutes = ({ component: Component }) => {
  const auth = useSelector((state) => getAuthState(state));
  const isArtist = useMemo(
    () => auth?.userRole?.toLowerCase() === "artist",
    [auth?.userRole]
  );
  useEffect(() => {
    if (auth?.currentUser?.status === "pending") {
    }
  }, [auth?.currentUser?.status]);

  return auth?.isLoading ? (
    <Loader />
  ) : isArtist ? (
    <>
      <Component />
      <CustomDialogBox
        open={auth?.currentUser?.status === "pending"}
        IconName={Pending}
        title="Your account is pending"
        description="Please comeback after admin approval"
        btnText="OK"
        route="/"
        handleClose={() => {}}
      />
    </>
  ) : (
    <Navigate to="/login" replace />
  );
};

export default ArtistRoutes;
