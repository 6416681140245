import styled from "@emotion/styled"
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { useCallback, useRef, useState } from "react"

const CustomCarousel = ({ children, columnGap, id, hideArrow }) => {
  let carouselDiv = useRef()
  // hide right and left arrow

  const [currentPosition, setCurrentPosition] = useState(0)

  const scrollLeft = useCallback(() => {
    carouselDiv?.current?.scrollBy(-carouselDiv?.current?.offsetWidth, 0)
    setCurrentPosition((pre) => pre - 1)
  }, [carouselDiv])

  const scrollRight = useCallback(() => {
    carouselDiv?.current?.scrollBy(carouselDiv?.current?.offsetWidth, 0)
    setCurrentPosition((pre) => pre + 1)
  }, [carouselDiv])

  return (
    <CarouselWrapper id="wrapper">
      {currentPosition - carouselDiv?.current?.offsetWidth !== 0 &&
        currentPosition !== 0 &&
        !hideArrow && (
          <LeftArrow>
            <IconButton
              onClick={scrollLeft}
              className={`bg-whitesmoke box-shadow-1 ${
                currentPosition === 0 ? "display-none" : ""
              }`}
            >
              <ArrowBackIosNew fontSize="small" className="text-primary-2" />
            </IconButton>
          </LeftArrow>
        )}
      <Carousel
        columnGap={columnGap}
        id={id}
        ref={carouselDiv}
        className="text-center"
        onScroll={(e) => {
          setCurrentPosition(
            parseInt(e.target.scrollLeft + e.target.offsetWidth)
          )
        }}
      >
        {children}
      </Carousel>
      {currentPosition !== carouselDiv?.current?.scrollWidth && !hideArrow && (
        <RightArrow>
          <IconButton
            onClick={() => {
              scrollRight()
            }}
            className={`bg-whitesmoke box-shadow-1`}
          >
            <ArrowForwardIos fontSize="small" className="text-primary-2" />
          </IconButton>
        </RightArrow>
      )}
    </CarouselWrapper>
  )
}

const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  z-index: 0;
`
const Carousel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  column-gap: ${({ columnGap }) => columnGap};
  --webkit-column-gap: ${({ columnGap }) => columnGap};
  z-index: 0;
  margin: 0.5em auto;
  scroll-behavior: smooth;

  /* padding: 0 45px; */
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
`
const RightArrow = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: transparent;
  height: "100%";
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0.1em !important;
  width: 40px;
  @media (max-width: 768px) {
    display: none;
  }
`
const LeftArrow = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: transparent;
  height: "100%";
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0.1em !important;
  width: 40px;
  @media (max-width: 768px) {
    display: none;
  }
`

export default CustomCarousel
