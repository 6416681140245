import { calculateServiceCharge } from "helpers"
import { useAdmin } from "hooks"
import { useMemo } from "react"
import { usePaystackPayment } from "react-paystack"

export default function PayStack({ children, email, amount, handleSuccess }) {
  // const { currencyCode } = useCurrencyContext()
  const { serviceCharge } = useAdmin()

  const amountToPay = useMemo(() => {
    const sum = amount + calculateServiceCharge(amount, serviceCharge?.value)
    return sum * 100
  }, [amount, serviceCharge])

  const initializePayment = usePaystackPayment({
    reference: new Date().getTime().toString(),
    email: email,
    amount: +amountToPay,
    publicKey: "pk_live_c685b46f20bfc8f4a4ebcb93d1c6a2b903bb015b",
    currency: "ZAR",
  })
  const onSuccess = (reference) => {
   
    handleSuccess(reference)
  }

  const onClose = () => {
    console.log("closed")
  }

  return (
    <div
      onClick={() => {
        initializePayment(onSuccess, onClose)
      }}
    >
      {children}
    </div>
  )
}
