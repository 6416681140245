import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useIsMounted } from "hooks"
import {
  categoriesSelector,
  fetchCategories,
  fetchGenres,
  fetchSubcategories,
  genresBySubcategoryIDSelector,
  getCategoriesState,
  isLoadingCategories,
  subcategoriesByCategoryIDSelector,
} from "redux/actions"

const useCategories = () => {
  const isMounted = useIsMounted()
  const dispatch = useDispatch()
  const categoriesState = useSelector((state) => getCategoriesState(state))
  const categories = categoriesSelector(categoriesState)
  const loadingCategories = isLoadingCategories(categoriesState)
  // subcategories
  const subcategoriesByCategoryID = useSelector((state) =>
    subcategoriesByCategoryIDSelector(state)
  )
  // genres
  const genresBySubcategoryID = useSelector((state) =>
    genresBySubcategoryIDSelector(state)
  )

  useEffect(() => {
    if (isMounted()) {
      dispatch(fetchCategories())
    }
  }, [dispatch, isMounted])

  const fetchSubcategoriesByCategory = useCallback(
    (categoryId) => {
      dispatch(fetchSubcategories(categoryId))
    },
    [dispatch]
  )

  const fetchGenresData = useCallback(
    (reqID) => {
      dispatch(fetchGenres(reqID))
    },
    [dispatch]
  )

  return {
    categories,
    loadingCategories,
    // subcategories
    fetchSubcategoriesByCategory,
    subcategoriesByCategoryID,
    // genres
    fetchGenresData,
    genresBySubcategoryID,
  }
}

export default useCategories
