import { Dialog, DialogContent, Divider, Typography } from "@mui/material"
import { CustomButton } from "."

const CustomConfirmationDialog = ({
  open,
  handleClose,
  headerIcon,
  headerTitle,
  description,
  handleAction,
  children,
  fullScreen,
}) => {
  return (
    <>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={open}
        fullScreen={fullScreen ? fullScreen : false}
      >
        <DialogContent>
          {/* header */}
          <div className="confirmation-dialog-header text-center m-1-0">
            <div className="text-center text-primary-3">{headerIcon}</div>
            <div className="confirmation-dialog-header-title">
              <Typography variant="h6" className="font-bold text-primary-3">
                {headerTitle}
              </Typography>
            </div>
            <Divider className="m-0_5em-0" />
          </div>
          {/* body */}
          <div className="confirmation-dialog-body text-center">
            <Typography variant="body1">{description}</Typography>
            {children}
          </div>
          {/* action */}
          <div className="confirmation-dialog-action m-t-1em text-center">
            <CustomButton
              btnText="Cancel"
              onClick={() => {
                handleClose()
              }}
              className="m-0-0_5em border-primary-2 text-primary-2 bg-col-white"
              variant="outlined"
            />
            <CustomButton
              btnText="Yes,Confirm"
              onClick={() => {
                handleAction()
              }}
              className="m-0-0_5em bg-primary-3 text-white"
              variant="contained"
              disableElevation={true}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default CustomConfirmationDialog
