import produce from "immer"

const initialState = {
  languages: [],
  loading: false,
  error: null,
}

const LanguagesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type } = action
    switch (type) {
      case "LOADING_LANGUAGES":
        draft.loading = true
        draft.error = null
        break
      case "SET_LANGUAGES":
        draft.loading = false
        draft.error = null
        draft.languages = action.payload
        break
      case "FETCHING_LANGUAGES_ERROR":
        draft.loading = false
        draft.error = action.payload
        draft.languages = []
        break
      default:
        return state
    }
  })
export default LanguagesReducer
