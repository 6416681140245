import { TextField, Typography } from "@mui/material"
import { setOtherDetails, setPersonalizedMessage } from "redux/actions"

const ChoseOtherDetails = ({
  bookingArtistState,
  dispatch,
  isPersonalizedMessage,
}) => {
  return (
    <div>
      <div className="booking-artist-other-details-root w-100 flex-column position-center m-1-0">
        <div className="booking-artist-other-details p-0_5em-0 booking-artist-input-root-width">
          <Typography variant="body1" gutterBottom>
            {isPersonalizedMessage
              ? "Enter Your Personalized Message"
              : "Any Other Details (Optional)"}
          </Typography>
          {isPersonalizedMessage ? (
            <TextField
              fullWidth
              InputProps={{
                classes: {
                  root: "border-10",
                },
              }}
              multiline
              rows={5}
              placeholder="Enter your message here"
              value={bookingArtistState?.personalizedMessage}
              onChange={(e) => dispatch(setPersonalizedMessage(e.target.value))}
            />
          ) : (
            <TextField
              fullWidth
              InputProps={{
                classes: {
                  root: "border-10",
                },
              }}
              multiline
              rows={5}
              placeholder="Enter any other details"
              value={bookingArtistState?.otherDetails}
              onChange={(e) => dispatch(setOtherDetails(e.target.value))}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ChoseOtherDetails
