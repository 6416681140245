import { getToken } from "@firebase/messaging"
import { messaging, VAPID_KEY } from "configs"
import { useEffect } from "react"
import useApi from "./useApi"

const useFCMToken = (uid) => {
  const { postData } = useApi()
  useEffect(() => {
    "Notification" in window &&
      Notification.requestPermission(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          if (uid) {
            // Get FCM Token
            getToken(messaging, { vapidKey: VAPID_KEY })
              .then((fcmToken) => {
                if (fcmToken) {
                  postData(
                    `user/account-update/${uid}`,
                    {
                      fcmToken,
                    },
                    "PUT"
                  )
                } else console.log("No registration token available.")
              })
              .catch((err) => console.log("An error occurred ", err))
          }
        }
      })
  }, [uid, postData])
}

export default useFCMToken
