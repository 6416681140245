import { Grid, Typography } from "@mui/material"
import { LINK_LOGO } from "assets"
import { useIsMounted, useSocialLinks } from "hooks"
import React, { useEffect } from "react"

const ArtistSocialLinksTabItem = ({ artistID }) => {
  const isMounted = useIsMounted()
  const { getLinks, onGetLinks } = useSocialLinks()
  useEffect(() => {
    if (isMounted() && artistID) {
      onGetLinks(artistID)
    }
  }, [isMounted, artistID, onGetLinks])
  return (
    <>
      <Typography variant="h5" gutterBottom className="text-primary-2">
        Social Links
      </Typography>
      <Grid container spacing={2}>
        {getLinks?.data?.map((link) => (
          <Grid item xs={12} md={4} sm={6} lg={4} key={link?._id}>
            <a
              href={link?.link}
              target="_blank"
              rel="noopener noreferrer"
              title={link?.link}
            >
              <div
                className="artist-photos-root cursor-pointer p-1em overflow-hidden box-shadow-1 border-5 h-100 flex"
                role="button"
              >
                <img
                  src={LINK_LOGO}
                  alt=""
                  height={35}
                  width={35}
                  className="object-fit-cover"
                />
                <Typography className="w-90 text-ellipses-vertical line-clamp-3 m-auto">
                  {link?.link}
                </Typography>
              </div>
            </a>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default ArtistSocialLinksTabItem
