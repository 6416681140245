import produce from "immer"

const requestsData = {
  isLoading: true,
  error: null,
  requests: [],
}
const actionState = {
  isActionSuccess: false,
  isActionError: false,
  successMsg: "",
  errorMsg: "",
}
const initialState = {
  sentRequests: Object.assign({}, requestsData),
  receivedRequests: Object.assign({}, requestsData),
  acceptAction: Object.assign({}, actionState),
  rejectAction: Object.assign({}, actionState),
  removeAction: Object.assign({}, actionState),
  requestDetails: {
    isShow: false,
    data: {},
  },
  setPrice: Object.assign({}, actionState),
  managerRequest: Object.assign({}, actionState),
  paymentReqAccept: Object.assign({}, actionState),
}
const RequestsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action
    const {
      sentRequests,
      receivedRequests,
      acceptAction,
      rejectAction,
      requestDetails,
      removeAction,
      setPrice,
      managerRequest,
      paymentReqAccept,
    } = draft
    switch (type) {
      case "LOAD_SENT_REQUESTS":
        sentRequests.isLoading = true
        sentRequests.error = null
        break
      case "LOAD_SENT_REQUESTS_SUCCESS":
        sentRequests.requests = payload
        sentRequests.isLoading = false
        sentRequests.error = null
        break
      case "LOAD_SENT_REQUESTS_FAILURE":
        sentRequests.isLoading = false
        sentRequests.error = payload
        break
      case "LOAD_RECEIVED_REQUESTS":
        receivedRequests.isLoading = true
        receivedRequests.error = null
        break
      case "LOAD_RECEIVED_REQUESTS_SUCCESS":
        receivedRequests.requests = payload
        receivedRequests.isLoading = false
        receivedRequests.error = null
        break
      case "LOAD_RECEIVED_REQUESTS_FAILURE":
        receivedRequests.isLoading = false
        receivedRequests.error = payload
        break
      // accept action
      case "ACCEPT_REQUEST_SUCCESS":
        acceptAction.isActionSuccess = true
        acceptAction.isActionError = false
        break
      case "ACCEPT_REQUEST_FAILURE":
        acceptAction.isActionSuccess = false
        acceptAction.isActionError = true
        break
      case "ACCEPT_REQUEST_RESET":
        acceptAction.isActionSuccess = false
        acceptAction.isActionError = false
        break
      // reject action
      case "REJECT_REQUEST_ALL_SUCCESS":
        rejectAction.isActionSuccess = true
        rejectAction.isActionError = false
        rejectAction.successMsg = payload
        break
      case "REJECT_REQUEST_ALL_FAILURE":
        rejectAction.isActionSuccess = false
        rejectAction.isActionError = true
        rejectAction.errorMsg = payload
        break
      case "REJECT_REQUEST_ALL_RESET":
        rejectAction.isActionSuccess = false
        rejectAction.isActionError = false
        rejectAction.successMsg = ""
        rejectAction.errorMsg = ""
        break
      // individual request details
      case "SET_REQUEST_DETAILS":
        requestDetails.isShow = true
        requestDetails.data = payload
        break
      case "RESET_REQUEST_DETAILS":
        requestDetails.isShow = false
        requestDetails.data = {}
        break
      // remove action
      case "REMOVE_REQUEST_SUCCESS":
        removeAction.isActionSuccess = true
        removeAction.isActionError = false
        removeAction.successMsg = payload
        break
      case "REMOVE_REQUEST_FAILURE":
        removeAction.isActionSuccess = false
        removeAction.isActionError = true
        removeAction.errorMsg = payload
        break
      case "REMOVE_REQUEST_RESET":
        removeAction.isActionSuccess = false
        removeAction.isActionError = false
        removeAction.successMsg = ""
        removeAction.errorMsg = ""
        break
      // set price
      case "SET_PRICE_SUCCESS":
        setPrice.isActionSuccess = true
        setPrice.isActionError = false
        setPrice.successMsg = payload
        break
      case "SET_PRICE_FAILURE":
        setPrice.isActionSuccess = false
        setPrice.isActionError = true
        setPrice.errorMsg = payload
        break
      case "SET_PRICE_RESET":
        setPrice.isActionSuccess = false
        setPrice.isActionError = false
        setPrice.successMsg = ""
        setPrice.errorMsg = ""
        break
      // accept manager remove request
      case "ACCEPT_MANAGER_REQUEST_SUCCESS":
        managerRequest.isActionSuccess = true
        managerRequest.isActionError = false
        managerRequest.successMsg = payload
        break
      case "ACCEPT_MANAGER_REQUEST_FAILURE":
        managerRequest.isActionSuccess = false
        managerRequest.isActionError = true
        managerRequest.errorMsg = payload
        break
      case "ACCEPT_MANAGER_REQUEST_RESET":
        managerRequest.isActionSuccess = false
        managerRequest.isActionError = false
        managerRequest.successMsg = ""
        managerRequest.errorMsg = ""
        break
      // accept payment request
      case "ACCEPT_PAYMENT_REQUEST_SUCCESS":
        paymentReqAccept.isActionSuccess = true
        paymentReqAccept.isActionError = false
        paymentReqAccept.successMsg = payload
        break
      case "ACCEPT_PAYMENT_REQUEST_FAILURE":
        paymentReqAccept.isActionSuccess = false
        paymentReqAccept.isActionError = true
        paymentReqAccept.errorMsg = payload
        break
      case "ACCEPT_PAYMENT_REQUEST_RESET":
        paymentReqAccept.isActionSuccess = false
        paymentReqAccept.isActionError = false
        paymentReqAccept.successMsg = ""
        paymentReqAccept.errorMsg = ""
        break
      default: {
        return draft
      }
    }
  })

export default RequestsReducer
