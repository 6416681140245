import { useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  artistBookingsSelector,
  bookingStateSelector,
  confirmedUserBookingsSelector,
  fetchArtistBookings,
  fetchUserBookings,
  loadingArtistBookingsSelector,
  loadingUserBookingsSelector,
  pastUserBookingsSelector,
  pendingUserBookingsSelector,
  userBookingsSelector,
  // remove bookings
  removeBookingsData,
  removeBookingState,
  removeBookingLoadingSelector,
  removeBookingEndSelector,
  // booking artist
  setCityName,
  setCrowedSize,
  setEventDate,
  setEventDuration,
  setEventLocation,
  setEventTime,
  setEventType,
  setOtherDetails,
  setServiceType,
  setEventTypeName,
  setServiceTypeName,
  setBookingPrice,
  setPersonalizedMessageDate,
  setPersonalizedMessage,
  getArtistOnUserBookings,
  isArtistOnUserBookingsSelector,
  setArtistID,
  pendingArtistBookingsSelector,
  confirmedArtistBookingsSelector,
  pastArtistBookingsSelector,
  openBookingDetailsAction,
  individualBookingSelector,
  closeBookingDetailsAction,
  setLocDetailsAction,
  setLocCountryAction,
  setIsPricingMatchAction,
  setPricing,
  cancelBookingAction,
  isBookingCancelSuccessSelector,
  isBookingCancelErrorSelector,
  confirmEventDoneAction,
  isBookingConfirmSuccessSelector,
  cancelArtistBookingsSelector,
  canceledUserBookingsSelector,
  validateBookingAction,
  isValidBookingDateSelector,
} from "redux/actions"

const useBookings = () => {
  const dispatch = useDispatch()
  const userBookingsState = useSelector((state) => bookingStateSelector(state))
  // user
  const userBookings = useSelector((state) => userBookingsSelector(state))
  const isLoadingUserBookings = useSelector((state) =>
    loadingUserBookingsSelector(state)
  )
  const pendingUserBookings = useSelector((state) =>
    pendingUserBookingsSelector(state)
  )
  const pastUserBookings = useSelector((state) =>
    pastUserBookingsSelector(state)
  )
  const confirmedUserBookings = useSelector((state) =>
    confirmedUserBookingsSelector(state)
  )
  const canceledUserBookings = useSelector((state) =>
    canceledUserBookingsSelector(state)
  )
  // artist
  const artistBookings = useSelector((state) => artistBookingsSelector(state))
  const isLoadingArtistBookings = useSelector((state) =>
    loadingArtistBookingsSelector(state)
  )
  const pendingArtistBookings = useSelector((state) =>
    pendingArtistBookingsSelector(state)
  )
  const confirmedArtistBookings = useSelector((state) =>
    confirmedArtistBookingsSelector(state)
  )
  const pastArtistBookings = useSelector((state) =>
    pastArtistBookingsSelector(state)
  )
  const cancelArtistBookings = useSelector((state) =>
    cancelArtistBookingsSelector(state)
  )

  // fetch bookings
  const getUserBookingsData = useCallback(
    (userID) => {
      dispatch(fetchUserBookings(userID))
    },
    [dispatch]
  )

  const getArtistBookingsData = useCallback(
    (artistID) => {
      dispatch(fetchArtistBookings(artistID))
    },
    [dispatch]
  )

  // remove past bookings
  const removeBookingsStateData = useSelector((state) =>
    removeBookingState(state)
  )
  const isRemoveInProgress = useSelector((state) =>
    removeBookingLoadingSelector(state)
  )
  const isRemoveEnd = useSelector((state) => removeBookingEndSelector(state))
  const removePastBookings = useCallback(
    (uid, bookingIDs) => {
      dispatch(removeBookingsData(uid, bookingIDs))
    },
    [dispatch]
  )

  // booking artist
  const getBookingDetailsFromLocalStorage = useCallback(() => {
    const booking = JSON.parse(localStorage.getItem("bookingArtistState"))
    const pricing = JSON.parse(localStorage.getItem("selectedPricing"))
    if (booking) {
      dispatch(setEventType(booking?.eventType || ""))
      dispatch(setServiceType(booking?.serviceType || ""))
      dispatch(setEventTime(booking?.eventTime || ""))
      dispatch(setEventDuration(booking?.eventDuration || ""))
      dispatch(
        setEventDate({
          start: booking?.eventDate?.start || "",
          end: booking?.eventDate?.end || "",
        })
      )
      dispatch(setPersonalizedMessageDate(booking?.personalizedMsgDate || ""))
      dispatch(setPersonalizedMessage(booking?.personalizedMessage || ""))
      dispatch(setCrowedSize(booking?.crowdSize || ""))
      dispatch(setEventLocation(booking?.eventLocation || ""))
      dispatch(setCityName(booking?.cityName || ""))
      dispatch(setOtherDetails(booking?.otherDetails || ""))
      dispatch(setEventTypeName(booking?.eventTypeName))
      dispatch(setServiceTypeName(booking?.serviceTypeName))
      // dispatch(setPromoCodeAmount(booking?.promoCodeAmount))
      // dispatch(setPromoCodeID(booking?.promoCodeID))
      // dispatch(
      //   setPromoCode(booking?.promoCode?.code, booking?.promoCode?.percentage)
      // )
      // dispatch(setWalletAmount(booking?.walletAmount))
      dispatch(setBookingPrice(booking?.bookingPrice))
      dispatch(setArtistID(booking?.artistID))
      // dispatch(setBankAmount(booking?.bankAmount))
      dispatch(setLocDetailsAction(booking?.locDetails))
      dispatch(setLocCountryAction(booking?.locDetails?.country))
      dispatch(setIsPricingMatchAction(booking?.isPricingMatch))
      dispatch(setPricing(pricing))
    }
  }, [dispatch])

  const resetBookingDetailsFromLocalStorage = useCallback(() => {
    localStorage.removeItem("bookingArtistState")
    localStorage.removeItem("indexes")
    localStorage.removeItem("selectedPricing")
  }, [])

  // check if artist is on user bookings
  const isArtistOnUserBookings = useSelector((state) =>
    isArtistOnUserBookingsSelector(state)
  )
  const handleGetArtistOnUserBookings = useCallback(
    (artistID, uBookings) => {
      dispatch(getArtistOnUserBookings(artistID, uBookings))
    },
    [dispatch]
  )

  // open & close booking details
  const handleOpenBookingDetails = useCallback(
    (booking) => {
      dispatch(openBookingDetailsAction(booking))
    },
    [dispatch]
  )
  const bookingDetails = useSelector((state) =>
    individualBookingSelector(state)
  )
  const handleCloseBookingDetails = useCallback(() => {
    dispatch(closeBookingDetailsAction())
  }, [dispatch])

  // cancel booking
  const isCancelBookingSuccess = useSelector((state) =>
    isBookingCancelSuccessSelector(state)
  )
  const isCancelBookingError = useSelector((state) =>
    isBookingCancelErrorSelector(state)
  )
  const handleCancelBooking = useCallback(
    (bID, wV, bV, actionBy) => {
      dispatch(cancelBookingAction(bID, wV, bV, actionBy))
    },
    [dispatch]
  )

  // event done confirmation
  const isConfirmEventDoneSuccess = useSelector((state) =>
    isBookingConfirmSuccessSelector(state)
  )
  const handleConfirmEventDone = useCallback(
    (bID, isComplete) => {
      dispatch(confirmEventDoneAction(bID, isComplete))
    },
    [dispatch]
  )
  // ---------------------unused-------------------
  // validate booking
  const isValidBookingDate = useSelector((state) =>
    isValidBookingDateSelector(state)
  )
  const handleValidateBooking = useCallback(
    (aID, eventDate) => {
      dispatch(validateBookingAction(aID, eventDate))
    },
    [dispatch]
  )
  // --------------------------------------------------
  return {
    userBookingsState,
    userBookings,
    isLoadingUserBookings,
    getUserBookingsData,
    pendingUserBookings,
    pastUserBookings,
    confirmedUserBookings,
    canceledUserBookings,
    // remove bookings
    removePastBookings,
    removeBookingsStateData,
    isRemoveInProgress,
    isRemoveEnd,
    // booking artist
    getBookingDetailsFromLocalStorage,
    resetBookingDetailsFromLocalStorage,
    // check if artist is on user bookings
    handleGetArtistOnUserBookings,
    isArtistOnUserBookings,
    // artist bookings
    artistBookings,
    isLoadingArtistBookings,
    getArtistBookingsData,
    pendingArtistBookings,
    confirmedArtistBookings,
    pastArtistBookings,
    cancelArtistBookings,
    // open & close bookings
    handleOpenBookingDetails,
    bookingDetails,
    handleCloseBookingDetails,
    // cancel booking
    handleCancelBooking,
    isCancelBookingSuccess,
    isCancelBookingError,
    // event done confirmation
    handleConfirmEventDone,
    isConfirmEventDoneSuccess,
    // validate booking
    handleValidateBooking,
    isValidBookingDate,
  }
}

export default useBookings
