import { apiInstance } from "configs"
import { createSelector } from "reselect"

export const languageSelector = createSelector(
  (languagesState) => languagesState,
  (state) => state?.languages
)

export const languagesLoadingSelector = createSelector(
  (languagesState) => languagesState,
  (state) => state?.loading
)

export const loadingLanguages = () => {
  return {
    type: "LOADING_LANGUAGES",
  }
}

export const setLanguages = (languages) => {
  return {
    type: "SET_LANGUAGES",
    payload: languages,
  }
}

export const fetchLanguagesError = (error) => {
  return {
    type: "FETCHING_LANGUAGES_ERROR",
    payload: error,
  }
}

export const fetchLanguagesFromApi = () => async (dispatch) => {
  try {
    dispatch(loadingLanguages())
    await apiInstance
      .get("language/all-language")
      .then(({ data }) => {
        dispatch(setLanguages(data?.success?.data))
      })
      .catch(({ response }) => {
        const errMsg = response?.data?.error?.message
        dispatch(fetchLanguagesError(errMsg))
      })
  } catch (error) {
    dispatch(fetchLanguagesError(error))
  }
}
