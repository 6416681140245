import { useRef } from "react";

export const CustomDrawer = ({
  drawerOpen,
  setDrawerOpen,
  children,
  position,
  onClose,
}) => {
  const drawerContainerRef = useRef(null);
  const handleCloseDrawer = () => {
    document.addEventListener("click", (e) => {
      if (e.target === drawerContainerRef.current && drawerOpen) {
        setDrawerOpen(false);
        onClose && onClose();
      }
    });
  };

  return (
    <div
      className={`drawer-container ${
        drawerOpen
          ? position === "left"
            ? "open-left"
            : "open-right"
          : position === "left"
          ? "close-left"
          : "close-right"
      }`}
      role={"button"}
      onClick={() => {
        handleCloseDrawer();
      }}
      id={"drawer-container"}
      ref={drawerContainerRef}
    >
      <div
        id="drawer"
        className="drawer "
        style={{
          left: position === "left" ? "0" : "auto",
          right: position === "right" ? "0" : "auto",
        }}
      >
        {children}
      </div>
    </div>
  );
};
