import produce from "immer"
const defaultState = {
  isLoading: false,
  error: null,
  message: null,
}
const initialState = {
  notifications: [],
  notificationsLength: 0,
  isLoading: true,
  error: null,
  readAction: defaultState,
  removeAction: defaultState,
}
const NotificationsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action
    const { readAction, removeAction } = draft
    switch (type) {
      case "NOTIFICATIONS_LOADING":
        draft.isLoading = true
        draft.error = null
        break
      case "NOTIFICATIONS_SUCCESS":
        draft.isLoading = false
        draft.error = null
        draft.notifications = payload.data
        draft.notificationsLength = payload.length
        break
      case "NOTIFICATIONS_ERROR":
        draft.isLoading = false
        draft.error = payload
        draft.notifications = []
        draft.notificationsLength = 0
        break
      // read action
      case "READ_NOTIFICATION_LOADING":
        readAction.isLoading = true
        readAction.error = null
        break
      case "READ_NOTIFICATION_SUCCESS":
        readAction.isLoading = false
        readAction.error = null
        readAction.message = payload
        break
      case "READ_NOTIFICATION_ERROR":
        readAction.isLoading = false
        readAction.error = payload
        readAction.message = null
        break
      // remove action
      case "REMOVE_NOTIFICATION_LOADING":
        removeAction.isLoading = true
        removeAction.error = null
        break
      case "REMOVE_NOTIFICATION_SUCCESS":
        removeAction.isLoading = false
        removeAction.error = null
        removeAction.message = payload
        break
      case "REMOVE_NOTIFICATION_ERROR":
        removeAction.isLoading = false
        removeAction.error = payload
        removeAction.message = null
        break
      case "RESET_REMOVE_NOTIFICATION_ACTION":
        removeAction.isLoading = false
        removeAction.error = null
        removeAction.message = null
        break
      default:
        return draft
    }
  })

export default NotificationsReducer
