import { apiInstance } from "configs"
import { createSelector } from "reselect"
import { endAction, startAction } from "./LoaderAction"

// selector for all type of requests
export const requestStateSelector = (state) => state.requestsState
export const sentRequestsSelector = createSelector(
  [requestStateSelector],
  (state) => state?.sentRequests?.requests
)
export const loadingSentRequests = createSelector(
  [requestStateSelector],
  (state) => state?.sentRequests?.isLoading
)
export const receivedRequestsSelector = createSelector(
  [requestStateSelector],
  (state) => state?.receivedRequests?.requests
)
export const loadingReceivedRequests = createSelector(
  [requestStateSelector],
  (state) => state?.receivedRequests?.isLoading
)

// actions to fetch all the requests
// sent requests
export const fetchSentRequests = (userID) => async (dispatch) => {
  try {
    // dispatch({
    //   type: "LOAD_SENT_REQUESTS",
    // })
    await apiInstance
      .get(`request/all-request-sender/${userID}`)
      .then(({ data }) => {
        const { success } = data
        dispatch({
          type: "LOAD_SENT_REQUESTS_SUCCESS",
          payload: success?.data,
        })
      })
      .catch(({ response }) => {
        const errMsg = response?.data?.error?.message
        dispatch({
          type: "LOAD_SENT_REQUESTS_FAILURE",
          payload: errMsg,
        })
      })
  } catch (error) {
    dispatch({
      type: "LOAD_SENT_REQUESTS_FAILURE",
      payload: error?.message,
    })
  }
}

// received requests
export const fetchReceivedRequests = (userID) => async (dispatch) => {
  try {
    // dispatch({
    //   type: "LOAD_RECEIVED_REQUESTS",
    // })
    await apiInstance
      .get(`request/all-request-receiver/${userID}`)
      .then(({ data }) => {
        const { success } = data
        dispatch({
          type: "LOAD_RECEIVED_REQUESTS_SUCCESS",
          payload: success.data,
        })
      })
      .catch(({ response }) => {
        const errMsg = response?.data?.error?.message
        dispatch({ type: "LOAD_RECEIVED_REQUESTS_FAILURE", payload: errMsg })
      })
  } catch (error) {
    dispatch({
      type: "LOAD_RECEIVED_REQUESTS_FAILURE",
      payload: error?.message,
    })
  }
}

// accept request action
export const acceptRescheduleRequestSuccessSelector = createSelector(
  [requestStateSelector],
  (state) => state?.acceptAction?.isActionSuccess
)
export const acceptRequestAction =
  (requestID, permission, actionBy, reason) => async (dispatch) => {
    try {
      dispatch({
        type: "ACCEPT_REQUEST_RESET",
      })
      dispatch(startAction(requestID))
      await apiInstance
        .put(`request/reject-and-accept`, {
          requestId: requestID,
          isAccept: permission,
          actionBy: actionBy,
          reason: reason,
        })
        .then(({ data }) => {
          dispatch({
            type: "ACCEPT_REQUEST_SUCCESS",
          })
        })
        .catch(({ response }) => {
          const errMsg = response?.data?.error?.message
          errMsg &&
            dispatch({
              type: "ACCEPT_REQUEST_FAILURE",
            })
        })
        .finally(() => {
          dispatch(endAction(requestID))
        })
    } catch (error) {
      dispatch({
        type: "ACCEPT_REQUEST_FAILURE",
      })
    }
  }

// request details set & reset
export const requestDetailsSelector = createSelector(
  [requestStateSelector],
  (state) => state?.requestDetails
)

export const setRequestDetailsAction = (request) => {
  return {
    type: "SET_REQUEST_DETAILS",
    payload: request,
  }
}
export const resetRequestDetailsAction = () => {
  return {
    type: "RESET_REQUEST_DETAILS",
  }
}

// remove request action
export const removeRequestStateSelector = createSelector(
  [requestStateSelector],
  (state) => state?.removeAction
)
export const removeRequestResetAction = () => {
  return {
    type: "REMOVE_REQUEST_RESET",
  }
}
export const removeRequestAction = (requestID, userID) => async (dispatch) => {
  try {
    dispatch(startAction(`REMOVE_REQUEST_${requestID}`))
    await apiInstance
      .post(`request/delete`, {
        requestIds: [requestID],
        userId: userID,
      })
      .then(({ data }) => {
        const { success } = data
        dispatch({
          type: "REMOVE_REQUEST_SUCCESS",
          payload: success?.message,
        })
      })
      .catch(({ response }) => {
        const errMsg = response?.data?.error?.message
        dispatch({
          type: "REMOVE_REQUEST_FAILURE",
          payload: errMsg,
        })
      })
      .finally(() => {
        dispatch(endAction(`REMOVE_REQUEST_${requestID}`))
        dispatch({
          type: "REMOVE_REQUEST_RESET",
        })
      })
  } catch (error) {
    dispatch({
      type: "REMOVE_REQUEST_FAILURE",
      payload: error?.message,
    })
  }
}

// set price action
export const setPriceAction = (requestID, price) => async (dispatch) => {
  try {
    dispatch(startAction(`SET_PRICE_${requestID}`))
    await apiInstance
      .put(`request/reject-and-accept`, {
        requestId: requestID,
        price,
        isAccept: true,
      })
      .then(({ data }) => {
        const { success } = data
        dispatch({
          type: "SET_PRICE_SUCCESS",
          payload: success?.message,
        })
      })
      .catch(({ response }) => {
        const errMsg = response?.data?.error?.message
        dispatch({
          type: "SET_PRICE_FAILURE",
          payload: errMsg,
        })
      })
      .finally(() => {
        dispatch(endAction(`SET_PRICE_${requestID}`))
        dispatch({
          type: "SET_PRICE_RESET",
        })
      })
  } catch (error) {
    dispatch({
      type: "SET_PRICE_FAILURE",
      payload: error?.message,
    })
  }
}

// payment request accept and reject
export const paymentRequestAcceptAction = (requestID) => async (dispatch) => {
  try {
    dispatch(startAction(`ACCEPT_PAYMENT_REQUEST_${requestID}`))
    dispatch({
      type: "ACCEPT_PAYMENT_REQUEST_RESET",
    })
    await apiInstance
      .put(`request/reject-and-accept`, {
        requestId: requestID,
        isAccept: true,
      })
      .then(({ data }) => {
        dispatch({
          type: "ACCEPT_PAYMENT_REQUEST_SUCCESS",
          payload: data?.success?.message,
        })
      })
      .catch(({ response }) => {
        const errMsg = response?.data?.error?.message
        dispatch({
          type: "ACCEPT_PAYMENT_REQUEST_FAILURE",
          payload: errMsg,
        })
      })
      .finally(() => {
        dispatch(endAction(`ACCEPT_PAYMENT_REQUEST_${requestID}`))
      })
  } catch (error) {
    dispatch({
      type: "ACCEPT_PAYMENT_REQUEST_FAILURE",
      payload: error?.message,
    })
  }
}

// reject various type of request
export const rejectRequestAllAction =
  (requestID, reason, actionBy, type) => async (dispatch) => {
    const url = ["rescheduledCustomer", "rescheduledArtist"]?.includes(type)
      ? `request/reject-and-accept`
      : `request/reject-and-accept`
    try {
      dispatch(startAction(`REJECT_REQUEST_ALL_${requestID}`))
      await apiInstance
        .put(url, {
          requestId: requestID,
          isAccept: false,
          reason,
          actionBy,
        })
        .then(({ data }) => {
          dispatch({
            type: "REJECT_REQUEST_ALL_SUCCESS",
            payload: data?.success?.message,
          })
        })
        .catch(({ response }) => {
          const errMsg = response?.data?.error?.message
          dispatch({
            type: "REJECT_REQUEST_ALL_FAILURE",
            payload: errMsg,
          })
        })
        .finally(() => {
          dispatch(endAction(`REJECT_REQUEST_ALL_${requestID}`))
        })
    } catch (error) {
      dispatch({
        type: "REJECT_REQUEST_ALL_FAILURE",
        payload: error?.message,
      })
    }
  }

// accept manager remove request
export const acceptManagerRequestAction = (reqID) => async (dispatch) => {
  try {
    dispatch(startAction(`ACCEPT_MANAGER_REQUEST_${reqID}`))
    await apiInstance
      .put(`request/reject-and-accept`, {
        isAccept: true,
        requestId: reqID,
      })
      .then(({ data }) => {
        dispatch({
          type: "ACCEPT_MANAGER_REQUEST_SUCCESS",
          payload: data?.success?.message,
        })
      })
      .catch(({ response }) => {
        const errMsg = response?.data?.error?.message
        dispatch({
          type: "ACCEPT_MANAGER_REQUEST_FAILURE",
          payload: errMsg,
        })
      })
      .finally(() => {
        dispatch(endAction(`ACCEPT_MANAGER_REQUEST_${reqID}`))
      })
  } catch (error) {
    dispatch({
      type: "ACCEPT_MANAGER_REQUEST_FAILURE",
      payload: error?.message,
    })
  }
}
