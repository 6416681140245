import {
  CancelOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@mui/icons-material"
import {
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material"
import moment from "moment"
import { useState } from "react"
import { CustomButton } from "components"
import { useWindow } from "hooks"
import { lowerCaseText } from "helpers"

const UserBookingCancelForm = ({
  open,
  handleCloseCancelBookingDialog,
  bookingData,
  handleCancelBooking,
  isCancelBookingError,
  sentRequests,
}) => {
  const { isMobileView } = useWindow().windowData
  const [reason, setReason] = useState("")
  const handleCancelBookingByPolicy = () => {
    if (
      sentRequests?.find(
        (i) =>
          i?.requestType === "rescheduledCustomer" &&
          i?._id === bookingData?._id &&
          lowerCaseText(i?.status) === "reject"
      )
    ) {
      handleCancelBooking(
        bookingData?._id,
        bookingData?.payment?.walletAmount + bookingData?.payment?.bankAmount,
        0,
        "user"
      )
    } else {
      if (moment(bookingData?.eventDate?.start).diff(moment(), "days") >= 14) {
        handleCancelBooking(
          bookingData?._id,
          bookingData?.payment?.walletAmount ?? 0,
          bookingData?.payment?.bankAmount ?? 0,
          "user"
        )
        return
      }
      if (
        moment(bookingData?.eventDate?.start).diff(moment(), "days") < 14 &&
        moment(bookingData?.eventDate?.start).diff(moment(), "days") >= 7
      ) {
        handleCancelBooking(
          bookingData?._id,
          bookingData?.payment?.walletAmount ?? 0,
          bookingData?.payment?.bankAmount * 0.75,
          "user"
        )
        return
      }
      if (moment(bookingData?.eventDate?.start).diff(moment(), "days") < 7) {
        handleCancelBooking(bookingData?._id, 0, 0, "user")
        return
      }
    }
  }
  return (
    <Dialog open={open} maxWidth="sm" fullWidth fullScreen={isMobileView}>
      <DialogContent>
        <div className="flex-row flex-align-center flex-justify-between">
          <Typography variant="h5" className="font-bold text-primary-2">
            Cancel Booking
          </Typography>
          <IconButton
            onClick={() => {
              handleCloseCancelBookingDialog()
            }}
          >
            <CloseOutlined />
          </IconButton>
        </div>
        <Divider className="m-0_5em-0" />
        <Typography
          variant="body1"
          gutterBottom
          className="font-bold text-primary-1"
        >
          Please provide your reason for cancelling this booking.
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Write your reason here"
          multiline
          rows={5}
          InputProps={{
            classes: {
              notchedOutline: "border-black-1px border-5",
            },
          }}
          value={reason}
          onChange={(ev) => setReason(ev.target.value)}
        />
        {isCancelBookingError && (
          <div className="m-0_5em-0">
            <span className="font-bold text-primary-9 font-size-small">
              Something went wrong while cancelling your booking. Please try
              again.
            </span>
          </div>
        )}
        <div className="booking-cancel-action m-1-0">
          <CustomButton
            btnText={"Back"}
            disableElevation={true}
            className="text-primary-1"
            endIcon={<CancelOutlined />}
            onClick={() => {
              handleCloseCancelBookingDialog()
            }}
          />
          <CustomButton
            btnText={"Confirm Cancel"}
            className="m-0-0_5em text-primary-2"
            disableElevation={true}
            endIcon={<CheckOutlined />}
            onClick={() => {
              handleCancelBookingByPolicy()
            }}
            disabled={reason.length === 0 ? true : false}
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default UserBookingCancelForm
