import { combineReducers } from "redux"
import {
  HeaderSearchReducer,
  BookingArtistReducer,
  ArtistsFilterReducer,
  AuthReducer,
  ArtistManageProfileReducer,
  ArtistPricingReducer,
  UsersReducer,
  CategoriesReducer,
  EventTypesReducer,
  LanguagesReducer,
  ReviewsReducer,
  FavoriteReducer,
  RequestsReducer,
  WalletReducer,
  PersonalizedMessageReducer,
  BookingsReducer,
  NotificationsReducer,
  BlockedDatesReducer,
  AdminReducer,
  AssignedUsersReducer,
  ArtistMediasReducer,
  LoaderReducer,
  PromoCodeReducer,
  MailReducer,
  ArtistDashboardReducer,
} from "redux/reducers"

const rootReducer = combineReducers({
  headerSearchState: HeaderSearchReducer,
  bookingArtistState: BookingArtistReducer,
  artistFilterState: ArtistsFilterReducer,
  authState: AuthReducer,
  artistProfileState: ArtistManageProfileReducer,
  artistPricingState: ArtistPricingReducer,
  usersState: UsersReducer,
  categoriesState: CategoriesReducer,
  eventTypesState: EventTypesReducer,
  languagesState: LanguagesReducer,
  reviewsState: ReviewsReducer,
  favoriteState: FavoriteReducer,
  requestsState: RequestsReducer,
  walletState: WalletReducer,
  personalizedMessageState: PersonalizedMessageReducer,
  bookingsState: BookingsReducer,
  notificationsState: NotificationsReducer,
  blockedDatesState: BlockedDatesReducer,
  adminState: AdminReducer,
  assignedUsersState: AssignedUsersReducer,
  artistMediasState: ArtistMediasReducer,
  loaderState: LoaderReducer,
  promoCodeState: PromoCodeReducer,
  mailState: MailReducer,
  artistDashboardState: ArtistDashboardReducer,
})

export default rootReducer
