import { useCallback, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  currentUserIDSelector,
  fetchTopSearchesArtistsAction,
  fetchUsers,
  getAllUsers,
  getArtists,
  getCurrentUser,
  getManagers,
  getUserDetails,
  getUsers,
  loadingTopSearchesArtistsSelector,
  loadingUserDetailsSelector,
  topSearchesArtistsSelector,
  topTrendingArtistsSelector,
  userDetailsSelector,
} from "redux/actions"
import useIsMounted from "./useIsMounted"

const useUsers = () => {
  const currentUser = useSelector((state) => getCurrentUser(state))
  const currentUserID = useSelector((state) => currentUserIDSelector(state))
  // users state
  const usersState = useSelector((state) => getUsers(state))
  const allUsers = useSelector((state) => getAllUsers(state))
  //   artists data
  const artistsData = useSelector((state) => getArtists(state))
  const topTrendingArtists = useSelector((state) =>
    topTrendingArtistsSelector(state)
  )
  //   managers data
  const managersData = useSelector((state) => getManagers(state))
  const dispatch = useDispatch()
  const isMounted = useIsMounted()
  //   get loading state of users
  const isLoadingUsers = useMemo(() => {
    return usersState?.loading
  }, [usersState])

  // userDetails of individual user
  const userDetails = useSelector((state) => userDetailsSelector(state))
  const loadingUserDetails = useSelector((state) =>
    loadingUserDetailsSelector(state)
  )

  useEffect(() => {
    if (isMounted()) {
      dispatch(fetchUsers())
    }
  }, [isMounted, dispatch])

  const handleGetUserDetails = useCallback(
    (userID) => dispatch(getUserDetails(userID)),
    [dispatch]
  )

  // top search artists
  const topSearchesArtists = useSelector((state) =>
    topSearchesArtistsSelector(state)
  )
  const isLoadingTopSearchesArtists = useSelector((state) =>
    loadingTopSearchesArtistsSelector(state)
  )
  const handleGetTopSearchesArtists = useCallback(
    () => dispatch(fetchTopSearchesArtistsAction()),
    [dispatch]
  )

  useEffect(() => {
    if (isMounted()) {
      handleGetTopSearchesArtists()
    }
  }, [isMounted, handleGetTopSearchesArtists])

  return {
    usersState,
    artistsData,
    managersData,
    isLoadingUsers,
    allUsers,
    currentUser,
    currentUserID,
    topTrendingArtists,
    // user Details
    handleGetUserDetails,
    userDetails,
    loadingUserDetails,
    // top search artists
    topSearchesArtists,
    handleGetTopSearchesArtists,
    isLoadingTopSearchesArtists,
  }
}

export default useUsers
