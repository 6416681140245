import { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { Navigate, useParams } from "react-router-dom"
import { getAuthState } from "redux/actions"
import { Loader } from "components"
import { useAssignedUsers, useIsMounted } from "hooks"
const ManagedArtistRoutes = ({ component: Component }) => {
  const { artistID } = useParams()
  const auth = useSelector((state) => getAuthState(state))
  const { managedArtists, handleFetchManagedArtists, loadingManagedArtists } =
    useAssignedUsers()
  const isMounted = useIsMounted()
  useEffect(() => {
    if (isMounted()) {
      handleFetchManagedArtists(auth?.currentUser?._id)
    }
  }, [isMounted, handleFetchManagedArtists, auth?.currentUser?._id])

  const isAssignedManager = useMemo(
    () =>
      auth?.userRole?.toLowerCase() === "manager" &&
      managedArtists?.map((ar) => ar?.artist?._id)?.includes(artistID),
    [auth?.userRole, managedArtists, artistID]
  )

  return auth?.isLoading || loadingManagedArtists ? (
    <Loader />
  ) : isAssignedManager ? (
    <Component />
  ) : (
    <Navigate to="/" replace />
  )
}

export default ManagedArtistRoutes
