import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material"
import { Formik } from "formik"
import { useEffect } from "react"
// import from other files
import {
  CustomPhoneNumberPicker,
  CustomSnackbar,
  Loader,
  PlaceAutoComplete,
} from "components"
import { ManageProfileSaveBtn } from "."
import { useApi, useIsMounted, useSnackbar } from "hooks"
import { artistProfileErrSchema } from "schemas"

const ArtistManageProfileInfo = ({
  currentUser,
  currentUserID,
  loadingUserDetails,
  isArtist,
}) => {
  const { snackbar, handleOpenSnackbar, handleCloseSnackbar } = useSnackbar()
  const isMounted = useIsMounted()
  const { postData, isPosting, isPosted, handleResetPostApi } = useApi()

  const handleSubmitArtistProfile = (values) => {
    postData(
      `user/account-update/${currentUserID}`,
      {
        ...values,
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        phoneNumber: values?.phoneNumber,
        countryCode: values?.countryCode,
        gender: values?.gender,
        address: values?.address,
        country: values?.country,
        lat: values?.lat,
        lng: values?.lng,
        yearsOfExperience: values?.yearsOfExperience,
        bio: values?.bio,
      },
      "PUT"
    )
  }

  useEffect(() => {
    if (isMounted()) {
      if (isPosted) {
        handleOpenSnackbar({
          message: "Updated successfully",
          variant: "success",
        })
      }
    }
  }, [isPosted, isMounted, handleOpenSnackbar])

  return (
    <div className="artist-manage-profile-info-root ">
      <CustomSnackbar
        open={snackbar?.open}
        variant={snackbar?.variant}
        message={snackbar?.message}
        handleClose={() => {
          handleCloseSnackbar()
        }}
        autoHideDuration={2000}
        resetFunction={handleResetPostApi}
      />
      <div className="artist-manage-profile-info-title">
        <Typography variant="h6" className="text-primary-8">
          Personal Information
        </Typography>
      </div>
      {/* personal information body */}
      {currentUser?._id && !loadingUserDetails && (
        <div className="artist-manage-profile-info-body m-1-0">
          {/* name & other details */}
          <Formik
            initialValues={{
              firstName: currentUser?.firstName || "",
              lastName: currentUser?.lastName || "",
              email: currentUser?.email || "",
              countryCode: currentUser?.countryCode || "+27",
              phoneNumber: currentUser?.phoneNumber || "",
              address: currentUser?.location?.address || "",
              country: currentUser?.location?.country || "",
              lat: currentUser?.location?.lat || "",
              lng: currentUser?.location?.lng || "",
              yearsOfExperience: currentUser?.yearsOfExperience || "",
              gender: currentUser?.gender || "Male",
              bio: currentUser?.bio || "",
            }}
            validationSchema={artistProfileErrSchema}
            onSubmit={handleSubmitArtistProfile}
          >
            {({
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              touched,
              errors,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="artist-manage-profile-info-details m-1-0">
                  <Grid container spacing={2} alignItems="flex-end">
                    {/* first name */}
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1" gutterBottom>
                        First Name <sup>*</sup>
                      </Typography>
                      <Typography className="font-size-small text-primary-1 font-bold">
                        {touched.firstName && errors.firstName}
                      </Typography>
                      <TextField
                        fullWidth
                        value={values.firstName}
                        onChange={(ev) => {
                          handleChange(ev)
                          setFieldTouched("firstName", true)
                        }}
                        name="firstName"
                        error={touched.firstName && Boolean(errors.firstName)}
                      />
                    </Grid>
                    {/* last name */}
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1" gutterBottom>
                        Last Name <sup>*</sup>
                      </Typography>
                      <Typography className="font-size-small text-primary-1 font-bold">
                        {touched.lastName && errors.lastName}
                      </Typography>
                      <TextField
                        fullWidth
                        value={values.lastName}
                        onChange={(ev) => {
                          setFieldTouched("lastName", true)
                          handleChange(ev)
                        }}
                        name="lastName"
                        error={touched?.lastName && Boolean(errors.lastName)}
                      />
                    </Grid>
                    {/* email */}
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">Email</Typography>
                      <Typography className="font-size-small text-primary-1 font-bold">
                        You can not change your email address.
                      </Typography>
                      <TextField
                        fullWidth
                        value={values.email || ""}
                        disabled
                        name="email"
                      />
                    </Grid>

                    {/* mobile number */}
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1" gutterBottom>
                        Phone Number
                      </Typography>
                      <Typography className="font-size-small text-primary-1 font-bold">
                        {touched.phoneNumber && errors.phoneNumber}
                      </Typography>
                      <CustomPhoneNumberPicker
                        value={values.phoneNumber}
                        onChange={(ev) => {
                          setFieldTouched("phoneNumber", true)
                          setFieldValue("phoneNumber", ev.target.value)
                        }}
                        countryCode={values.countryCode}
                        onChangeCountryCode={(ev) => {
                          setFieldTouched("countryCode", true)
                          setFieldValue("countryCode", ev.target.value)
                        }}
                        error={
                          touched?.phoneNumber && Boolean(errors.phoneNumber)
                        }
                        name="phoneNumber"
                        countryCodeName={`countryCode`}
                      />
                    </Grid>
                    {/* city */}
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1" gutterBottom>
                        Base City
                      </Typography>

                      <PlaceAutoComplete
                        className={`border-5`}
                        address={values.address}
                        handleLocationAddress={(address) => {
                          setFieldValue("address", address)
                          setFieldTouched("address", true)
                        }}
                        handleCountry={(country) => {
                          setFieldValue("country", country)
                          setFieldTouched("country", true)
                        }}
                        handleLocation={(lat, lng) => {
                          setFieldValue("lat", lat)
                          setFieldValue("lng", lng)
                          setFieldTouched("lat", true)
                          setFieldTouched("lng", true)
                        }}
                        name="address"
                      />
                    </Grid>
                    {/* year of experience */}
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1" gutterBottom>
                        Year of Experience
                      </Typography>
                      <TextField
                        fullWidth
                        value={values.yearsOfExperience}
                        onChange={(ev) => {
                          setFieldTouched("yearsOfExperience", true)
                          setFieldValue("yearsOfExperience", ev.target.value)
                        }}
                        name="yearsOfExperience"
                      />
                    </Grid>
                    {/* gender */}
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1" gutterBottom>
                        Select Gender
                      </Typography>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        value={values.gender}
                        onChange={(ev) => {
                          setFieldTouched("gender", true)
                          setFieldValue("gender", ev.target.value)
                        }}
                        name="gender"
                      >
                        {["Male", "Female", "Other"]?.map((item) => (
                          <FormControlLabel
                            key={item}
                            control={<Radio className="text-primary-2" />}
                            label={item}
                            value={item}
                          />
                        ))}
                      </RadioGroup>
                    </Grid>
                    {/* bio */}
                    <Grid item xs={12}>
                      <Typography variant="body1" gutterBottom>
                        Add Bio
                      </Typography>
                      <TextField
                        fullWidth
                        multiline
                        rows={5}
                        value={values.bio}
                        onChange={(ev) => {
                          setFieldTouched("bio", true)
                          handleChange(ev)
                          setFieldValue("bio", ev.target.value)
                        }}
                        name="bio"
                      />
                    </Grid>

                    {/* save Button */}
                    <Grid item xs={12}>
                      {isArtist && (
                        <ManageProfileSaveBtn
                          type="submit"
                          disabled={isPosting}
                        />
                      )}
                      {!isArtist && (
                        <div className="text-center flex-column w-100 flex-align-center">
                          <span className="text-primary-1 font-bold font-size-small">
                            You can not save changes for this user.
                          </span>
                          <Button
                            disabled
                            className="disabled-btn m-1-0"
                            size="large"
                          >
                            SAVE CHANGES
                          </Button>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </form>
            )}
          </Formik>
        </div>
      )}
      {(loadingUserDetails || !currentUser?._id) && (
        <div className="h-50vh">
          <Loader isCircularLoader={true} />
        </div>
      )}
    </div>
  )
}

export default ArtistManageProfileInfo
