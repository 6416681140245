import { Typography } from "@mui/material"
import {
  setEventLocation,
  setLocCountryAction,
  setPricing,
} from "redux/actions"
import { PlaceAutoComplete } from "components"
import { setLocDetailsAction } from "redux/actions"

const ChoseLocation = ({ bookingArtistState, dispatch }) => {
  return (
    <div>
      <div className="booking-artist-date-root w-100 flex-column position-center m-1-0">
        {
          <div className="booking-artist-other-city p-0_5em-0 booking-artist-input-root-width">
            <Typography variant="body1" gutterBottom>
              Enter City Name
            </Typography>
            <PlaceAutoComplete
              address={bookingArtistState?.eventLocation}
              handleLocationAddress={(address) => {
                localStorage.removeItem("selectedPricing")
                const res = address?.trimStart().split("  ").join(" ")
                dispatch(setEventLocation(res))
                dispatch(setPricing({}))
              }}
              handleLocation={(lat, lng) => {
                dispatch(
                  setLocDetailsAction({
                    lat,
                    lng,
                  })
                )
              }}
              handleCountry={(country) => {
                dispatch(setLocCountryAction(country))
              }}
              className="border-10"
            />
          </div>
        }
      </div>
    </div>
  )
}

export default ChoseLocation
