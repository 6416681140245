import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  deleteNotification,
  fetchNotifications,
  getNotifications,
  getUnreadNotifications,
  loadingNotificationsSelector,
  notificationsLengthSelector,
  readNotification,
  readNotificationActionSelector,
  readNotificationLoadingSelector,
  removeNotificationActionSelector,
  removeNotificationLoadingSelector,
  sortedNotificationsSelector,
} from "redux/actions"
const useNotifications = (currentUserID) => {
  const dispatch = useDispatch()
  const notifications = getNotifications(useSelector((state) => state))
  const isLoadingNotifications = useSelector((state) =>
    loadingNotificationsSelector(state)
  )
  const unreadNotifications = useSelector((state) =>
    getUnreadNotifications(state)
  )
  const sortedNotifications = useSelector((state) =>
    sortedNotificationsSelector(state)
  )
  const notificationsLength = useSelector((state) =>
    notificationsLengthSelector(state)
  )
  // read notification action
  const readNotificationActionState = useSelector((state) =>
    readNotificationActionSelector(state)
  )
  const isReadNotification = useSelector((state) =>
    readNotificationLoadingSelector(state)
  )
  // delete notification action
  const deleteNotificationActionState = useSelector((state) =>
    removeNotificationActionSelector(state)
  )
  const isDeleteNotification = useSelector((state) =>
    removeNotificationLoadingSelector(state)
  )

  // fetch notifications data
  const handleFetchNotifications = useCallback(
    (pageNum) => {
      dispatch(fetchNotifications(currentUserID, pageNum))
    },
    [dispatch, currentUserID]
  )

  // handle read notification
  const handleReadNotifications = useCallback(
    (notificationIDs, isReadAll) => {
      dispatch(readNotification(notificationIDs, currentUserID, isReadAll))
    },
    [dispatch, currentUserID]
  )
  // handle delete notification
  const handleDeleteNotifications = useCallback(
    (notificationIDs, isAllDelete) => {
      dispatch(deleteNotification(notificationIDs, currentUserID, isAllDelete))
    },
    [dispatch, currentUserID]
  )

  return {
    notifications,
    isLoadingNotifications,
    unreadNotifications,
    sortedNotifications,
    notificationsLength,
    // read notifications
    handleReadNotifications,
    readNotificationActionState,
    isReadNotification,
    // delete notifications
    handleDeleteNotifications,
    deleteNotificationActionState,
    isDeleteNotification,
    // fetch
    handleFetchNotifications,
  }
}

export default useNotifications
