import { Typography } from "@mui/material"
import { NO_DATA_IMG } from "assets"
import { useWindow } from "hooks"
import { CustomButton } from "."

const EmptyData = ({ description, btnText, isShowActionBtn, handleAction }) => {
  const isBelowSm = useWindow().windowData.width < 700
  return (
    <div className="w-100 h-100 position-center flex-column m-b-1em">
      <img src={NO_DATA_IMG} alt="" width={isBelowSm ? "95%" : "25%"} />
      <div className="no-data-caption text-center">
        <Typography variant="h6" className="text-secondary">
          {description || `No data found`}
        </Typography>
        {isShowActionBtn && (
          <CustomButton
            className="btn-gradient font-bold m-0_5em-0"
            variant="contained"
            disableElevation
            btnText={btnText}
            onClick={() => handleAction()}
          />
        )}
      </div>
    </div>
  )
}

export default EmptyData
