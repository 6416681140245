import {
  BaseBottomTab,
  BaseFooter,
  BaseHeader,
  BottomFooter,
} from "components/layouts"
import { useWindow } from "hooks"
import { useMemo } from "react"
import { useSelector } from "react-redux"

const withMainLayOut = (WrappedComponent, props) => {
  const { isShowBottomTab, isShowSearchBar } = props
  const HOCMainLayout = () => {
    const isSmallScreen = useWindow().windowData.width < 670
    const auth = useSelector((state) => state?.authState)
    const isArtist = useMemo(() => auth?.userRole === "artist", [auth])
    const isManager = useMemo(() => auth?.userRole === "manager", [auth])
    return (
      <>
        <BaseHeader isShowSearchBar={isShowSearchBar} />
        <div className="min-h-80vh">
          <WrappedComponent />
        </div>
        {isSmallScreen && isShowBottomTab ? <BaseBottomTab /> : null}
        {!isSmallScreen && !isArtist && !isManager && <BaseFooter />}
        {!isSmallScreen && (isArtist || isManager) && <BottomFooter />}
      </>
    )
  }
  return HOCMainLayout
}

export default withMainLayOut
