import { apiInstance } from "configs"
import { lowerCaseText } from "helpers"
import Cookies from "js-cookie"
import { createSelector } from "reselect"

// selector using reselect
export const getAuthState = (state) => state.authState
export const isUserLoggedIn = createSelector(
  (state) => state.authState,
  (authState) => authState.isLoggedIn
)
export const getCurrentUser = createSelector(
  (state) => state.authState,
  (authState) => authState.currentUser
)
export const currentUserIDSelector = createSelector(
  (state) => state.authState,
  (authState) => authState?.currentUser?._id
)

// set auth token
export const setAuthToken = (token) => {
  return {
    type: "SET_AUTH_TOKEN",
    payload: token,
  }
}

// loading & error
export const setAuthLoading = () => {
  return {
    type: "SET_AUTH_LOADING",
  }
}
export const setAuthError = (error) => {
  return {
    type: "SET_AUTH_ERROR",
    payload: error,
  }
}

// set logged in user
export const setCurrentUser = (isLoggedIn, userDetails) => {
  return {
    type: "SET_CURRENT_USER",
    payload: {
      isLoggedIn,
      currentUser: userDetails,
      isUserBlocked: lowerCaseText(userDetails?.status) === "blocked" ? 1 : 0,
    },
  }
}

export const setUserRole = (userRole) => {
  return {
    type: "SET_USER_ROLE",
    payload: userRole,
  }
}

// logout user
export const logoutUser = () => (dispatch) => {
  Cookies.remove("skyRiseToken")
  return dispatch({
    type: "LOGOUT_USER",
  })
}

// get user details from token
export const userBlockedSelector = createSelector(
  [getAuthState],
  (authState) => authState.isUserBlocked
)
export const getCurrentUserDetails = (token) => {
  return async (dispatch) => {
    dispatch(setAuthLoading())
    try {
      if (token) {
        await apiInstance
          .get("user/accounts-self", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            const data = res?.data?.success?.data
            dispatch(setCurrentUser(true, data))
            dispatch(setUserRole(data?.role?.toLowerCase()))
          })
          .catch((err) => {
            dispatch(setAuthError(err?.message))
          })
      } else {
        dispatch(setCurrentUser(false, {}))
        dispatch(setUserRole(""))
      }
    } catch (error) {
      dispatch(setAuthError(error?.message))
    }
  }
}
