import { apiInstance } from "configs";
import { sortNewToOld } from "helpers";
import { createSelector } from "reselect";
import { endAction, startAction } from "./LoaderAction";

export const getArtistPricingState = (state) => state.artistPricingState;
export const resetPricingStateAction = () => {
  return {
    type: "RESET_ARTIST_PRICING",
  };
};
// set crowd size
export const setArtistCrowdSize = (crowdSize) => {
  return {
    type: "SET_CROWD_SIZE",
    payload: crowdSize,
  };
};
// set service type
export const setServiceType = (serviceType) => {
  return {
    type: "SET_SERVICE_TYPE",
    payload: serviceType,
  };
};
// set crowd size
export const setPrice = (price) => {
  return {
    type: "SET_PRICE",
    payload: price,
  };
};
// if crowd is set to Other, set other crowd size
export const setArtistOtherCrowdSize = (otherCrowdSize) => {
  return {
    type: "SET_OTHER_CROWD_SIZE",
    payload: otherCrowdSize,
  };
};

// location
export const setArtistLocation = (location) => {
  return {
    type: "SET_LOCATION",
    payload: location,
  };
};
// if location is set to Other, set other location
export const setArtistOtherLocation = (otherLocation) => {
  return {
    type: "SET_OTHER_LOCATION",
    payload: otherLocation,
  };
};
export const setArtistLocLatLng = (lat, lng) => {
  return {
    type: "SET_LAT_LNG",
    payload: {
      lat,
      lng,
    },
  };
};
// day
export const setArtistDay = (day) => {
  return {
    type: "SET_DAY",
    payload: day,
  };
};
// if day is set to Other, set other day
export const setArtistOtherDay = (otherDay) => {
  return {
    type: "SET_OTHER_DAY",
    payload: otherDay,
  };
};
// price
export const setArtistPricePerHour = (price) => {
  return {
    type: "SET_PRICE",
    payload: price,
  };
};

// pricing lists set by the artist
export const artistPricingListsSelector = createSelector(
  [getArtistPricingState],
  (artistPricingState) => artistPricingState?.pricingLists?.data
);
export const artistPricingListsLoadingSelector = createSelector(
  [getArtistPricingState],
  (artistPricingState) => artistPricingState?.pricingLists?.isLoading
);
export const sortedArtistPricingListsSelector = createSelector(
  [artistPricingListsSelector],
  ([...pricingLists]) => {
    return pricingLists?.sort((a, b) => {
      return sortNewToOld(a, b, "timestamp");
    });
  }
);

export const fetchArtistPricingLists = (artistID) => async (dispatch) => {
  try {
    await apiInstance
      .get(`pricing/all-pricing/${artistID}`)
      .then(({ data }) => {
        const { success } = data;
        dispatch({
          type: "ARTIST_PRICING_LISTS_SUCCESS",
          payload: success?.data,
        });
      })
      .catch(({ response }) => {
        const errMsg = response?.data?.error?.message;
        dispatch({
          type: "ARTIST_PRICING_LISTS_ERROR",
          payload: errMsg,
        });
      });
  } catch (error) {
    dispatch({
      type: "ARTIST_PRICING_LISTS_ERROR",
      payload: error.message,
    });
  }
};

// add pricing list
export const isPricingListAddedSelector = createSelector(
  [getArtistPricingState],
  (artistPricingState) => artistPricingState?.addPricingList?.isDone
);
export const addPricingErrMsgSelector = createSelector(
  [getArtistPricingState],
  (artistPricingState) => artistPricingState?.addPricingList?.errorMsg
);
export const addPricingList = (artistID, data) => async (dispatch) => {
  dispatch(startAction(`ADD_PRICING_LIST_${artistID}`));
  dispatch({
    type: "ADD_PRICING_LIST_RESET",
  });
  await apiInstance
    .post(`pricing/create`, {
      ...data,
    })
    .then(({ data }) => {
      dispatch({
        type: "ADD_PRICING_LIST_SUCCESS",
        payload: data?.success?.message,
      });
      dispatch({
        type: "RESET_ARTIST_PRICING",
      });
    })
    .catch(({ response }) => {
      const errMsg = response?.data?.error?.message;
      dispatch({
        type: "ADD_PRICING_LIST_ERROR",
        payload: errMsg,
      });
    })
    .finally(() => {
      dispatch(endAction(`ADD_PRICING_LIST_${artistID}`));
    });
};

// remove pricing list
export const isPricingListRemovedSelector = createSelector(
  [getArtistPricingState],
  (artistPricingState) => artistPricingState?.removePricing?.isDone
);
export const removePricingListAction =
  (artistID, pricingListID) => async (dispatch) => {
    dispatch({
      type: "REMOVE_PRICING_LIST_RESET",
    });
    dispatch(startAction(`REMOVE_PRICING_LIST_${pricingListID}`));
    await apiInstance
      .put(`pricing/delete`, {
        artistId: artistID,
        pricingIds: [pricingListID],
      })
      .then(({ data }) => {
     
        dispatch({
          type: "REMOVE_PRICING_LIST_SUCCESS",
          payload: data?.success?.message,
        });
      })
      .catch(({ response }) => {
      
        dispatch({
          type: "REMOVE_PRICING_LIST_ERROR",
          payload: response?.data?.error?.message,
        });
      })
      .finally(() => {
        dispatch(endAction(`REMOVE_PRICING_LIST_${pricingListID}`));
      });
  };

// set & reset pricing list
export const artistPricingListSelector = createSelector(
  [getArtistPricingState],
  (artistPricingState) => artistPricingState?.pricingList
);
export const setPricingListAction =
  (artistID, pricingList) => async (dispatch) => {
    dispatch({
      type: "SET_PRICING_LIST",
      payload: {
        artistID,
        pricingList: pricingList,
      },
    });
  };

export const resetPricingListAction = () => async (dispatch) => {
  dispatch({
    type: "RESET_PRICING_LIST",
  });
  dispatch({
    type: "RESET_ARTIST_PRICING",
  });
};

// update pricing
export const updatePricingListAction =
  (artistID, pricingID, data) => async (dispatch) => {
    dispatch(startAction(`UPDATE_PRICING_${pricingID}`));
    dispatch({
      type: "ADD_PRICING_LIST_RESET",
    });
    // alert(JSON.stringify(data));
    await apiInstance
      .put(`pricing/update`, {
        artistId: artistID,
        pricingId: pricingID,
        numberOfDays: data?.otherDay ? data?.otherDay : data?.day,
        pricePerHour: +data?.pricePerHour || 0,
        personalizePrice: +data?.pricePerHour || 0,
        maxCrowdSize: data?.otherCrowdSize?.max
          ? data?.otherCrowdSize?.max
          : data?.crowdSize?.max,
        minCrowdSize: data?.otherCrowdSize?.min
          ? data?.otherCrowdSize?.min
          : data?.crowdSize?.min,
        otherCrowdSize: Boolean(data?.otherCrowdSize?.max),
        otherLocation: Boolean(data?.otherLocation),
        otherDay: Boolean(data?.otherDay),
        address: data?.otherLocation ? data?.otherLocation : data?.location,
        lat: data?.latLng?.lat,
        lng: data?.latLng?.lng,
        country: data?.country,
      })
      .then(({ data }) => {
        const { success } = data;
        success?.message && dispatch(endAction(`UPDATE_PRICING_${pricingID}`));
      })
      .catch(({ response }) => {
        const errMsg = response?.data?.error?.message;
        errMsg && dispatch(endAction(`UPDATE_PRICING_${pricingID}`));
      });
  };
