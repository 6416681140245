import { apiInstance } from "configs"
import { useCallback, useState } from "react"

const useApi = () => {
  const [isPosting, setIsPosting] = useState(false)
  const [postStatus, setPostStatus] = useState({
    status: "",
    message: "",
    data: null,
  })
  const [isPosted, setIsPosted] = useState(false)
  const [isPostErr, setIsPostErr] = useState(false)
  const [isGetting, setIsGetting] = useState(false)
  const [apiGetData, setApiGetData] = useState({
    status: "",
    message: "",
    data: null,
  })

  const handleResetPostApi = useCallback(() => {
    setPostStatus((pre) => {
      return {
        ...pre,
        status: "",
        data: null,
        message: "",
      }
    })
    setIsPosted(false)
    setIsPostErr(false)
  }, [])
  // post data
  const postData = useCallback(
    async (url = "", data, type) => {
     
      try {
        setIsPosting(true)
        handleResetPostApi()
        const postOptions = {
          url: url,
          method: type ? type : "POST",
          headers: {},
          data: data,
        }
        await apiInstance(postOptions)
          .then((response) => {
         
            const data = response?.data?.success
          
            setPostStatus({
              status: response?.status,
              message: data?.message,
              data: data?.data,
            })

            setIsPosted(true)
            return{
              status: response?.status,
              message: data?.message,
              data: data?.data,
            }
          })
          .catch((error) => {
          
            const errRes = error?.response
            setPostStatus({
              status: errRes?.status,
              message: errRes?.data?.error?.message,
              data: null,
            })
            setIsPostErr(true)
            return {
              status: errRes?.status,
              message: errRes?.data?.error?.message,
              data: null,
            }
          })
          .finally(() => {
            setIsPosting(false)
            return false
          })
      } catch (error) {
        setIsPosting(false)
        setPostStatus({
          status: 500,
          message: error?.message,
        })
        setIsPostErr(true)
        return {
          status: 500,
          message: error?.message,
        }
      }
    },
    [handleResetPostApi]
  )

  // get data from api
  const getData = useCallback(async (URL) => {
    if (URL) {
      setIsGetting(true)
      const getOptions = {
        url: URL,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
      await apiInstance(getOptions)
        .then(({ data, status }) => {
          setApiGetData({
            status: status,
            message: "data fetched successfully",
            data: data?.success?.data,
          })
          setIsGetting(false)
        })
        .catch(({ response }) => {
          setApiGetData({
            status: response?.status,
            message: response?.data?.error?.message,
            data: null,
          })
          setIsGetting(false)
        })
    }
  }, [])

  return {
    postData,
    isPosting,
    isPosted,
    setIsPosted,
    postStatus,
    apiGetData,
    isPostErr,
    handleResetPostApi,
    getData,
    isGetting,
  }
}

export default useApi
