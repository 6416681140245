import { apiInstance } from "configs"
import moment from "moment"
import { createSelector } from "reselect"
import { endAction, startAction } from "./LoaderAction"

export const getNotifications = createSelector(
  (state) => state?.notificationsState,
  (notificationsState) => notificationsState?.notifications
)
export const loadingNotificationsSelector = createSelector(
  (state) => state?.notificationsState,
  (notificationsState) => notificationsState?.isLoading
)
export const getUnreadNotifications = createSelector(
  [getNotifications],
  (notifications) =>
    notifications?.filter((notification) => !notification?.isRead)
)

export const sortedNotificationsSelector = createSelector(
  [getNotifications],
  ([...notifications]) =>
    notifications?.sort((a, b) => {
      return moment(b?.timestamp) - moment(a?.timestamp)
    })
)
export const notificationsLengthSelector = createSelector(
  (state) => state?.notificationsState,
  (notificationsState) => notificationsState?.notificationsLength
)

export const fetchNotifications = (userID, pageNum) => async (dispatch) => {
  try {
    await apiInstance
      .get(`notification/all-notification/${userID}/${pageNum ?? 0}`)
      .then(({ data }) => {
        const { success } = data
        dispatch({
          type: "NOTIFICATIONS_SUCCESS",
          payload: {
            data: success?.data,
            length: success?.length,
          },
        })
      })
      .catch(({ response }) => {
        const errMsg = response?.data?.error?.message
        dispatch({
          type: "NOTIFICATIONS_ERROR",
          payload: errMsg,
        })
      })
      .finally(() => {
        dispatch(endAction(`READ_PAGE_${pageNum}`))
      })
  } catch (error) {
    dispatch({
      type: "NOTIFICATIONS_ERROR",
      payload: error.message,
    })
  }
}

// read notifications
export const readNotificationActionSelector = createSelector(
  (state) => state?.notificationsState,
  (notificationsState) => notificationsState?.readAction
)
export const readNotificationLoadingSelector = createSelector(
  [readNotificationActionSelector],
  (readAction) => readAction?.isLoading
)

export const readNotification =
  (notificationIDs, uid, isReadAll) => async (dispatch) => {
    try {
      dispatch({
        type: "READ_NOTIFICATION_LOADING",
      })
      await apiInstance
        .put(`notification/make-read`, {
          notificationIds: notificationIDs,
          isAllRead: isReadAll,
          userId: uid,
        })
        .then(({ data }) => {
          const { success } = data
          dispatch({
            type: "READ_NOTIFICATION_SUCCESS",
            payload: success?.message,
          })
        })
        .catch(({ response }) => {
          const errMsg = response?.data?.error?.message
          dispatch({
            type: "READ_NOTIFICATION_ERROR",
            payload: errMsg,
          })
        })
    } catch (error) {
      dispatch({
        type: "READ_NOTIFICATION_ERROR",
        payload: error?.message,
      })
    }
  }

// remove notifications
export const removeNotificationActionSelector = createSelector(
  (state) => state?.notificationsState,
  (notificationsState) => notificationsState?.removeAction
)
export const removeNotificationLoadingSelector = createSelector(
  [removeNotificationActionSelector],
  (removeAction) => removeAction?.isLoading
)
export const deleteNotification =
  (notificationIDs, uid, isDeleteAll) => async (dispatch) => {
    try {
      dispatch({
        type: "REMOVE_NOTIFICATION_LOADING",
      })
      dispatch(startAction(`REMOVE_NOTIFICATION_${notificationIDs}`))
      await apiInstance
        .put(`notification/delete`, {
          notificationIds: notificationIDs,
          isAllDelete: isDeleteAll,
          userId: uid,
        })
        .then(({ data }) => {
          const { success } = data
          dispatch({
            type: "REMOVE_NOTIFICATION_SUCCESS",
            payload: success?.message,
          })
        })
        .catch(({ response }) => {
          const errMsg = response?.data?.error?.message
          dispatch({
            type: "REMOVE_NOTIFICATION_ERROR",
            payload: errMsg,
          })
        })
        .finally(() => {
          dispatch(endAction(`REMOVE_NOTIFICATION_${notificationIDs}`))
        })
    } catch (error) {
      dispatch({
        type: "REMOVE_NOTIFICATION_ERROR",
        payload: error?.message,
      })
    }
  }
