import { createTheme, responsiveFontSizes } from "@mui/material/"

const CustomTheme = createTheme((theme) => ({
  palette: {
    primary: {
      main: "var(--col-primary-2) !important",
    },
    secondary: {
      main: "var(--col-secondary-3)",
    },
  },
  typography: {
    fontFamily: "Typold, serif",
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          paddingBottom: "8px",
          paddingTop: "8px",
        },
        contained: {
          boxShadow: "#6a1b9a3d 0px 8px 16px 0px",
        },
      },
    },
  },
}))

export default responsiveFontSizes(CustomTheme)
